import styled from '@emotion/styled';
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';

import { useDashboardGraphsFilterPeriodContext } from './use-dashboard-graphs-filter-period-context';

export const DashboardGraphsFilterPeriodButtons = () => {
  const { handlePeriodChange } = useDashboardGraphsFilterPeriodContext();

  return (
    <Stack gap={1} direction={'row'}>
      <StyledButton
        variant="secondary"
        size="small"
        onClick={() => handlePeriodChange('previous')}
      >
        <ArrowBackIosRounded
          fontSize="small"
          htmlColor={lightTheme.palette.neutral.n400}
        />
      </StyledButton>
      <StyledButton
        variant="secondary"
        size="small"
        onClick={() => handlePeriodChange('next')}
      >
        <ArrowForwardIosRounded
          fontSize="small"
          htmlColor={lightTheme.palette.neutral.n400}
        />
      </StyledButton>
    </Stack>
  );
};

const StyledButton = styled(Button)`
  height: 40px;
  width: 40px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
