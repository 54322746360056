import { Stack } from '@mui/material';

import {
  AllowGuestsCancelBookingFeature,
  AllowGuestsMoveBookingFeature,
  EventRequestsFeature,
  GiftCardsFeature,
  PassOnBookingFeeFeature,
  ShowUpcomingEventsInStorefrontFeature,
} from './features';
import { BookingReminderFeature } from './features/BookingReminderFeature';

export const StorefrontFeaturesSection = () => {
  return (
    <Stack gap={3}>
      <GiftCardsFeature />
      <EventRequestsFeature />
      <AllowGuestsMoveBookingFeature />
      <AllowGuestsCancelBookingFeature />
      <ShowUpcomingEventsInStorefrontFeature />
      <BookingReminderFeature />
      <PassOnBookingFeeFeature />
    </Stack>
  );
};
