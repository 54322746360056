import {
  capitalize,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { Text } from '@understory-io/pixel';
import { Controller, useFormContext } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { RRule } from 'rrule';

import { useTranslate } from '../../../../../../../../../Hooks/useTranslate';
import { EventUpsertRecurringCustomFormTypes } from './use_recurring_custom_form_validation';

export const EventUpsertTimeFormRecurringFrequencySelect = () => {
  const { t } = useTranslate('utils.generic');

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<EventUpsertRecurringCustomFormTypes, 'frequency'>>();

  return (
    <FormControl error={!!errors.frequency}>
      <InputLabel id="time-form-recurring-frequency-select-label" />
      <Controller
        name="frequency"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            value={field.value as FrequencyOption}
            label=""
            labelId="time-form-recurring-frequency-select-label"
            aria-required={true}
            aria-disabled={false}
            renderValue={(frequency: FrequencyOption) => (
              <StyledText fontSize="small">
                {formatFrecuencyOption(frequency, t)}
              </StyledText>
            )}
            error={!!errors.frequency}
          >
            {frequencyOptions.map((frequency) => (
              <MenuItem key={frequency} value={frequency}>
                <StyledText fontSize="small">
                  {formatFrecuencyOption(frequency, t)}
                </StyledText>
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors.frequency && (
        <FormHelperText error>
          {String(errors.frequency.message) ?? ' '}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const frequencyOptions = [RRule.DAILY, RRule.WEEKLY, RRule.MONTHLY] as const;

type FrequencyOption = (typeof frequencyOptions)[number];

const formatFrecuencyOption = (frequency: FrequencyOption, t: TFunction) => {
  return capitalize(t(FREQUENCY_OPTION_MAP[frequency]));
};

const FREQUENCY_OPTION_MAP: Record<FrequencyOption, string> = {
  [RRule.DAILY]: 'day',
  [RRule.WEEKLY]: 'week',
  [RRule.MONTHLY]: 'month',
};

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
