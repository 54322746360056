import { ExperiencesGetWaitlistResponse } from '@understory-io/utils-types';

import { apiServices } from './apiServices';
import axios from './index';

export const getExperienceWaitlist = async (experienceId: string) => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await axios.get<ExperiencesGetWaitlistResponse>(
    `${apiServices.internalApi}/v1/experiences/${experienceId}/waitlist`
  );

  return data;
};
