import { Grid } from '@mui/material';
import { Text } from '@understory-io/pixel';
import type { ExperienceVariant } from '@understory-io/utils-types';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLocalizedStringFormatter } from '../../../../../Hooks/useLocalizedStringFormatter';
import { EconomicAccountAutocomplete } from '../economic-account-autocomplete';

interface EconomicConfigurationVariantAddonsProps {
  variant: ExperienceVariant;
  includeHeader: boolean;
}

export const EconomicConfigurationAddonsVariant = memo(
  ({ variant, includeHeader }: EconomicConfigurationVariantAddonsProps) => {
    const localizer = useLocalizedStringFormatter();
    const { watch } = useFormContext();

    return (
      <>
        {variant.addons && variant.addons?.length >= 1 && includeHeader && (
          <Grid item xs={12}>
            <Text variant="normal" fontSize="small">
              {localizer(variant.name)}
            </Text>
          </Grid>
        )}
        {variant.addons?.map((addon) => (
          <Grid item xs={12} md={6} key={addon.id}>
            <EconomicAccountAutocomplete
              label={localizer(addon.name)}
              path={`experienceSales.${addon.id}`}
              defaultValue={watch(`accounts.salesWithVat`)}
            />
          </Grid>
        ))}
      </>
    );
  }
);
EconomicConfigurationAddonsVariant.displayName = 'VariantAddons';
