import { InsertPhotoOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { MediaItem } from '@understory-io/utils-types';

import { DraggableVideoPreview } from '../../../Components/DraggableGallery/DraggableVideoPreview/DraggableVideoPreview';

export type LocalMediaItem = MediaItem & { localUrl?: string };

export const MediaPreview = ({ media }: { media?: LocalMediaItem[] }) => {
  return (
    <Stack
      direction="row"
      position="relative"
      minHeight={0}
      width="100%"
      gap={1}
      sx={{ aspectRatio: '1/0.5' }}
      data-test-id="experience-gallery-desktop"
    >
      {media && media?.length ? (
        <>
          <Box
            position="relative"
            width="100%"
            maxWidth={media.length > 1 ? '70%' : '100%'}
            flexGrow={1}
            minHeight={0}
          >
            <MediaItemPreview mediaItem={media[0]} />
          </Box>
          {media[1] && (
            <Stack gap={1} flexGrow={1} maxWidth="30%" position="relative">
              <Box
                position="relative"
                maxHeight={media[2] ? 'calc(50% - 4px)' : 'unset'}
                width="100%"
                flexGrow={1}
              >
                <MediaItemPreview mediaItem={media[1]} />
              </Box>
              {media[2] && (
                <Box
                  position="relative"
                  maxHeight="calc(50% - 4px)"
                  width="100%"
                  flexGrow={1}
                >
                  <MediaItemPreview mediaItem={media[2]} />
                </Box>
              )}
            </Stack>
          )}
        </>
      ) : (
        <>
          <Stack
            width="100%"
            maxWidth="70%"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            borderRadius={1}
            color={lightTheme.palette.neutral.n300}
            sx={{
              border: `1px solid ${lightTheme.palette.neutral.n100}`,
              background: '#F4F4F9',
            }}
          >
            <InsertPhotoOutlined color="inherit" />
          </Stack>
          <Stack gap={1} flexGrow={1} maxWidth="30%" position="relative">
            <Stack
              maxHeight="calc(50% - 4px)"
              width="100%"
              flexGrow={1}
              justifyContent="center"
              alignItems="center"
              borderRadius={1}
              color={lightTheme.palette.neutral.n300}
              sx={{
                border: `1px solid ${lightTheme.palette.neutral.n100}`,
                background: '#F4F4F9',
              }}
            >
              <InsertPhotoOutlined color="inherit" />
            </Stack>
            <Stack
              maxHeight="calc(50% - 4px)"
              width="100%"
              flexGrow={1}
              justifyContent="center"
              alignItems="center"
              borderRadius={1}
              color={lightTheme.palette.neutral.n300}
              sx={{
                border: `1px solid ${lightTheme.palette.neutral.n100}`,
                background: '#F4F4F9',
              }}
            >
              <InsertPhotoOutlined color="inherit" />
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

const MediaItemPreview = ({ mediaItem }: { mediaItem: LocalMediaItem }) => {
  if (
    mediaItem.type === 'video' &&
    (mediaItem.provider === 'holdbar' || mediaItem.provider === 'understory')
  )
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '12px',
        }}
      >
        <DraggableVideoPreview
          styles={videoJSStyles}
          options={{
            autoplay: true,
            loop: true,
            muted: true,
            sources: [
              {
                src: mediaItem.playback.hls,
                type: 'application/x-mpegURL',
              },
            ],
          }}
        />
      </Box>
    );

  return (
    <Box
      component="img"
      src={mediaItem.localUrl}
      sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
    />
  );
};

const videoJSStyles = {
  parent: {
    cursor: 'pointer',
    'background-color': '#F6F6F6',
    width: '100%',
    height: '100%',
    outline: 'none',
    'border-radius': '12px',
  },
  video: {
    'object-fit': 'cover',
    'border-radius': '12px',
  },
};
