import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';

export const SectionHeadline = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  if (!title && !description) {
    return null;
  }

  return (
    <Stack gap={1}>
      {title && (
        <Text fontSize={'large'} variant={'medium'}>
          {title}
        </Text>
      )}
      {description && <Text fontSize={'small'}>{description}</Text>}
    </Stack>
  );
};
