import { lightTheme } from '@understory-io/pixel';

import { ampli } from '../../../Ampli';
import { getLocalized } from '../../../Hooks/useBookings';
import { IExperience } from '../../../Hooks/useExperience';
import { t } from '../../../i18n/config';
import { launchDarklyClient } from '../../../launch-darkly';

interface ExperienceActionBase {
  key: string;
  label: string;
  variant: 'primary' | 'secondary';
  textColor?: string;
  scopes: string[];
  trackingFn?: () => void;
  'data-intercom-target'?: string;
}

interface ExperienceActionButton extends ExperienceActionBase {
  type: 'button';
  method?: 'post' | 'get';
  action?: string;
}

export interface ExperienceActionLink extends ExperienceActionBase {
  type: 'link';
  to: string;
}

export type ExperienceAction = ExperienceActionButton | ExperienceActionLink;

export const getExperienceActions = async (
  experience: IExperience,
  isShared: boolean,
  query: string
) => {
  const actions: ExperienceAction[] = [];
  await launchDarklyClient.waitUntilReady();
  const newCreateExperience = launchDarklyClient.variation(
    'feature-create-experience'
  );

  if (experience.status === 'active') {
    actions.push({
      key: 'deactivate',
      label: t('experience.details.action.deactivateLabel'),
      type: 'button',
      variant: 'secondary',
      textColor: lightTheme.palette.error.e300,
      method: 'post',
      action: `update-status${query}`,
      scopes: ['experience.write'],
      trackingFn: () =>
        ampli.experienceDeactivated({
          experience_id: experience.id,
          experience_name: getLocalized(experience.headline, 'en'),
          is_private: experience.visibility === 'private',
          language_list: experience.languages,
        }),
    });
  }

  if (experience.status === 'inactive') {
    actions.push({
      key: 'activate',
      label: t('experience.details.action.activateLabel'),
      type: 'button',
      variant: 'secondary',
      method: 'post',
      action: `update-status${query}`,
      scopes: ['experience.write'],
      trackingFn: () =>
        ampli.experienceActivated({
          experience_id: experience.id,
          experience_name: getLocalized(experience.headline, 'en'),
          is_private: experience.visibility === 'private',
          language_list: experience.languages,
        }),
    });
  }

  if (!isShared) {
    actions.push({
      key: 'edit',
      label: t('experience.details.action.editLabel'),
      type: 'link',
      variant: 'secondary',
      to: newCreateExperience ? `/v2/experience/${experience.id}/edit` : 'edit',
      scopes: ['experience.write'],
    });

    actions.push({
      key: 'createEvent',
      label: t('experience.details.action.createEventLabel'),
      type: 'link',
      variant: 'primary',
      to: `/event/create`,
      scopes: ['event.write'],
      trackingFn: () =>
        ampli.experienceEventCreationStarted({
          experience_id: experience.id,
          experience_name: getLocalized(experience.headline, 'en'),
          is_private: experience.visibility === 'private',
          language_list: experience.languages,
        }),
      'data-intercom-target': 'experience-create-new-event',
    });
  }

  return actions;
};
