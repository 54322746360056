import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useEffect } from 'react';

import { isSharedExperience } from '../../../../../features/experiences/utils/is-shared-experience';
import { useExperience } from '../../../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import {
  trackStorefrontImplementationExperienceOpened,
  trackStorefrontImplementationExperienceSelected,
} from '../../../../../tracking/storefront/implementation-events';
import {
  ActionTypeEnum,
  ExperiencePayloadType,
  FlowTypeEnum,
} from '../../domain/types';
import { useBookingFlowSectionContext } from '../../domain/use_booking_flow_section_context';

export const IntegrationLinksDropdownExperience = () => {
  const { t } = useTranslate('storefront.bookingflow');

  const { selectedFlowType, selectedExperience, dispatch } =
    useBookingFlowSectionContext();

  const {
    experiences: { data: experiences, isLoading: experiencesLoading },
  } = useExperience();

  useEffect(() => {
    if (
      selectedFlowType === FlowTypeEnum.Checkout &&
      selectedExperience === 'all'
    ) {
      if (experiences?.length && experiences[0].id) {
        dispatch({
          type: ActionTypeEnum.SetExperience,
          payload: {
            experienceId: experiences[0].id,
            companyId: experiences[0].companyId,
          },
        });
      }
    }
  }, [selectedFlowType, experiences]);

  const getLocalizedHeadline = useLocalizedStringFormatter();

  return (
    <FormControl disabled={experiencesLoading || !experiences?.length}>
      <InputLabel sx={{ textTransform: 'capitalize', fontWeight: '600' }}>
        {t('experience', 'utils.generic')}
      </InputLabel>
      <Select
        name={'experience'}
        label={t('experience', 'utils.generic')}
        aria-label={t('experience', 'utils.generic')}
        value={
          typeof selectedExperience === 'string'
            ? selectedExperience
            : selectedExperience.experienceId
        }
        onOpen={trackStorefrontImplementationExperienceOpened}
        onChange={(e) => {
          const selectedId = e.target.value;

          trackStorefrontImplementationExperienceSelected(selectedId);

          if (selectedId === 'all') {
            return dispatch({
              type: ActionTypeEnum.SetExperience,
              payload: 'all',
            });
          }

          const selectedExperience = experiences?.find(
            (experience) => experience.id === selectedId
          );
          if (!selectedExperience) return;

          const payload: ExperiencePayloadType = isSharedExperience(
            selectedExperience
          )
            ? {
                companyId: selectedExperience.ownerCompanyId,
                experienceId: selectedExperience.id,
                ownerExperienceId: selectedExperience.ownerExperienceId,
                distributorId: selectedExperience.companyId,
              }
            : {
                companyId: selectedExperience.companyId,
                experienceId: selectedExperience.id,
              };

          dispatch({
            type: ActionTypeEnum.SetExperience,
            payload,
          });
        }}
        sx={{
          width: 360,
        }}
      >
        {selectedFlowType !== FlowTypeEnum.Checkout && (
          <MenuItem key={'all'} value={'all'}>
            <StyledText fontSize="small">
              {
                selectedFlowType === FlowTypeEnum.Storefront
                  ? t('links.experience.all')
                  : t('links.experience.all')?.split('(')?.[0]?.trim() // Hides the "overview" part of the string
              }
            </StyledText>
          </MenuItem>
        )}
        {experiences?.map((experience) => (
          <MenuItem key={experience.id} value={experience.id}>
            <StyledText fontSize="small">
              {getLocalizedHeadline(experience.headline, '-')}
            </StyledText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
