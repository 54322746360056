import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { AllFilters } from '../../filters/AllFilters';

export const FiltersModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <Dialog
      sx={{ justifyContent: 'flex-end' }}
      fullWidth
      fullScreen={true}
      open={modal.visible}
      PaperProps={{
        sx: {
          mt: 4,
          p: 2,
          pb: 0,
          gap: 4,
          height: '100%',
          maxHeight: '480px',
          background: `linear-gradient(to bottom, ${lightTheme.palette.contrast.white} 90%, #eeeeee 100%)`,
          display: 'flex',
        },
      }}
    >
      <Stack direction="row" justifyContent="flex-start" gap={2}>
        <CloseRounded sx={{ cursor: 'pointer' }} onClick={() => modal.hide()} />
        <Text variant="medium" fontSize="large">
          Filters
        </Text>
      </Stack>
      <Stack gap={1}>
        <AllFilters />
      </Stack>
    </Dialog>
  );
});
