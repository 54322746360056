import { ExperienceSortOption } from '@understory-io/utils-types';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

export const useStorefrontCustomizeFormValidation = (t: TFunction) =>
  useMemo(
    () =>
      yup
        .object({
          fontFamily: yup.string().required(),
          primaryColor: yup
            .string()
            .required()
            .matches(/^#[0-9A-F]{6}$/i, t('errors.invalidPrimaryColor')),
          buttonStyle: yup.string().required(),
          sortOrder: yup.array().of(yup.string().required()).required(),
          sortExperiencesBy: yup.mixed<ExperienceSortOption>().required(),
        })
        .required(),
    [t]
  );

export type StorefrontCustomizeFormData = yup.InferType<
  ReturnType<typeof useStorefrontCustomizeFormValidation>
>;
