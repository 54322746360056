import { Stack, styled } from '@mui/material';
import { EconomicConfiguration as EconomicConfigurationType } from '@understory-io/utils-types';
import { Value } from '@understory-io/utils-types/typebox';
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useEconomicConfiguration } from '../data/use-economic-configuration';
import { EconomicConfigurationAccounts } from './accounts/economic-configuration-accounts';
import { EconomicConfigurationActions } from './economic-configuration-actions';
import { EconomicConfigurationJournal } from './journal/economic-configuration-journal';
import { EconomicConfigurationVatCodes } from './vat-codes/economic-configuration-vat-codes';

type Props = {
  configuration: EconomicConfigurationType | null;
  setConfigurationIsDirty?: (isDirty: boolean) => void;
};

export const EconomicConfiguration: FC<Props> = ({
  configuration,
  setConfigurationIsDirty,
}) => {
  const { t } = useTranslate('settings.integrations.economic.configuration');
  const navigate = useNavigate();
  const { isSm } = useResponsive();
  const { updateConfiguration } = useEconomicConfiguration();

  const methods = useForm({
    defaultValues: configuration ?? { enabled: true },
  });

  useEffect(() => {
    setConfigurationIsDirty?.(methods.formState.isDirty);
  }, [methods.formState.isDirty, setConfigurationIsDirty]);

  const onSubmit: SubmitHandler<EconomicConfigurationType> = async (data) => {
    cleanUndefined(data);

    if (!Value.Check(EconomicConfigurationType, data)) {
      const errors = Array.from(
        Value.Value.Errors(EconomicConfigurationType, data)
      );
      if (errors.length > 0) {
        errors.forEach((error) => {
          const path = error.path.split('/').filter(Boolean).join('.');
          methods.setError(path as any, { message: error.message });
        });
      }

      toast.error(t('invalidError'));
      return;
    }

    try {
      const newConfiguration = await updateConfiguration.mutateAsync(data);
      methods.reset(newConfiguration);
      toast.success(t('saveSuccess'));
      navigate('/settings/integrations/economic');
    } catch (e) {
      toast.error(t('saveError'));
    }
  };

  return (
    <StyledStack isSm={isSm}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack gap={5} width="100%">
            <EconomicConfigurationJournal />
            <EconomicConfigurationVatCodes />
            <EconomicConfigurationAccounts />
            <EconomicConfigurationActions />
          </Stack>
        </form>
      </FormProvider>
    </StyledStack>
  );
};

/**
 * Removes all undefined values from an object
 */
const cleanUndefined = (input: Record<string, unknown>) => {
  for (const [key, value] of Object.entries(input)) {
    if (value === undefined || value === null) {
      delete input[key];
    } else if (
      typeof input === 'object' &&
      !Array.isArray(input) &&
      input !== null
    ) {
      cleanUndefined(value as Record<string, unknown>);
    }
  }
};

// Add isMobile as prop:
const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isSm',
})<{
  isSm?: boolean;
}>(({ isSm }) => ({
  backgroundColor: isSm ? 'transparent' : 'white',
  padding: isSm ? '10px 0 0 0' : '48px',
  width: '100%',
  borderRadius: '16px',
}));
