import { VatRegistration } from '@understory-io/utils-types';

import { ampli } from '../../Ampli';
import { CompanyFormData } from '../../Sections/SettingsSections/Company/domain/use-company-form-validation';

// Profile
export const trackProfileLanguageSelected = (
  language: string,
  userId: string
) => {
  ampli.profileLanguageSettingsSelected({ language });
  ampli.identify(userId, { 'User Profile Language': language });
};

export const trackProfileLanguageSettingsOpened = () =>
  ampli.profileLanguageSettingsOpened();

// Company
export const trackCompanyFormCompleted = (
  formData: CompanyFormData,
  userId: string
) => {
  const vatRegistration = formData.vatCompliance
    .vatRegistration as VatRegistration;
  ampli.identify(userId, {
    'Vat Status': formData.vatCompliance.isVatRegistered
      ? 'Registered'
      : 'Not Registered',
    'Vat Country': formData.vatCompliance.isVatRegistered
      ? vatRegistration.country
      : 'Not Registered',
  });

  if (formData.vatCompliance.isVatRegistered) {
    ampli.vatCountrySelectorCompleted({
      vat_country: vatRegistration.country,
    });
  }
};

// Notifications
const trackNotificationTipsSelected = (is_enabled: boolean, userId: string) => {
  ampli.notificationTipsSelected({ is_enabled });
  ampli.identify(userId, { 'Notification Enabled Tips': is_enabled });
};

const trackNotificationBookingSelected = (
  is_enabled: boolean,
  userId: string
) => {
  ampli.notificationBookingSelected({ is_enabled });
  ampli.identify(userId, { 'Notifications Enabled Bookings': is_enabled });
};

const trackNotificationEventsSelected = (
  is_enabled: boolean,
  userId: string
) => {
  ampli.notificationEventsSelected({ is_enabled });
  ampli.identify(userId, { 'Notification Enabled Events': is_enabled });
};

const trackNotificationGiftcardSelected = (
  is_enabled: boolean,
  userId: string
) => {
  ampli.notificationGiftcardSelected({ is_enabled });
  ampli.identify(userId, { 'Notification Enabled GiftCard': is_enabled });
};

export const trackNotificationToggled = (
  notification: string,
  is_enabled: boolean,
  userId: string
) => {
  notificationTrackingFns[notification as keyof typeof notificationTrackingFns](
    is_enabled,
    userId
  );
};

const notificationTrackingFns = {
  'b-account-welcome-email': trackNotificationTipsSelected,
  'b-booking-new-email': trackNotificationBookingSelected,
  'b-events-upcoming-event-reminder-email': trackNotificationEventsSelected,
  'b-voucher-sold-email': trackNotificationGiftcardSelected,
};
