import { LinkButton } from '@understory-io/pixel';
import { ComponentProps } from 'react';
import { useHref, useLinkClickHandler } from 'react-router';

export function RouterLinkButton({
  href,
  size,
  children,
  ...rest
}: {
  href: string;
  children: React.ReactNode;
  size?: ComponentProps<typeof LinkButton>['size'];
} & Omit<ComponentProps<typeof LinkButton>, 'children' | 'size'>) {
  const to = useHref(href);
  const clickHandler = useLinkClickHandler(to);
  return (
    <LinkButton
      size={size ?? 'medium'}
      {...rest}
      href={to}
      onClick={(event) => {
        if (!event.defaultPrevented) {
          clickHandler(event);
        }
      }}
    >
      {children}
    </LinkButton>
  );
}
