import { Skeleton } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { FC } from 'react';

import { useExperience } from '../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../Hooks/useLocalizedStringFormatter';

type Props = {
  experienceId: string;
};

export const TicketDetailsExperienceName: FC<Props> = ({ experienceId }) => {
  const {
    experience: { data, isLoading },
  } = useExperience(experienceId);

  const localizer = useLocalizedStringFormatter();

  if (isLoading || !data)
    return <Skeleton variant="text" width={200} height={24} />;

  return (
    <Text variant="normal" fontSize="medium">
      {localizer(data.headline)}
    </Text>
  );
};
