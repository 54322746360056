import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ExperienceTag } from '@understory-io/utils-types';

import {
  createExperienceTag,
  deleteExperienceTag,
  getExperienceTags,
  updateExperienceTag,
} from '../Api';

export const useExperienceTags = (tagId?: string) => {
  const queryClient = useQueryClient();

  const ExperienceTagQueryKey = ['tag', tagId];
  const ExperienceTagsQueryKey = ['tags'];

  const tag = useQuery({
    queryKey: ExperienceTagQueryKey,

    queryFn: () => {
      const tags = queryClient.getQueryData<ExperienceTag[]>(
        ExperienceTagsQueryKey
      );
      return tags?.find((el) => el.id === tagId) ?? ({} as ExperienceTag);
    },

    enabled: !!tagId,
  });

  const tags = useQuery({
    queryKey: ExperienceTagsQueryKey,

    queryFn: async () => {
      return await getExperienceTags();
    },

    retry: false,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  tags.data?.forEach((el) => {
    queryClient.setQueryData([ExperienceTagQueryKey[0], el.id], () => ({
      ...el,
    }));
  });

  const createTag = useMutation({
    mutationFn: (data: ExperienceTag['name']) => createExperienceTag(data),

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: ExperienceTagQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ExperienceTagsQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ['experience'],
      });
      queryClient.invalidateQueries({
        queryKey: ['experiences'],
      });
    },
  });

  const updateTag = useMutation({
    mutationFn: (data: ExperienceTag) => updateExperienceTag(data),

    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ExperienceTagQueryKey,
      });

      const previous = queryClient.getQueryData<ExperienceTag>(
        ExperienceTagQueryKey
      );

      queryClient.setQueryData<ExperienceTag[]>(
        ExperienceTagsQueryKey,
        (prev) => {
          if (!tagId) return prev ?? [];

          const mapped =
            prev?.map((el) => {
              return el.id === data.id ? { ...data } : el;
            }) ?? [];
          return [
            ...mapped,
            ...(data.id
              ? []
              : [
                  {
                    ...data,
                    tagId,
                  },
                ]),
          ];
        }
      );

      queryClient.setQueryData<ExperienceTag>(ExperienceTagQueryKey, (prev) => {
        return { ...prev!, ...data };
      });

      return { previous };
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: ExperienceTagQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ExperienceTagsQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ['experience'],
      });
      queryClient.invalidateQueries({
        queryKey: ['experiences'],
      });
    },
  });

  const deleteTag = useMutation({
    mutationFn: (id: string) => deleteExperienceTag(id),

    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ExperienceTagQueryKey,
      });

      queryClient.setQueryData<ExperienceTag[]>(
        ExperienceTagsQueryKey,
        (prev) => {
          return prev?.filter((el) => el.id !== tagId) ?? [];
        }
      );
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: ExperienceTagQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ExperienceTagsQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ['experience'],
      });
      queryClient.invalidateQueries({
        queryKey: ['experiences'],
      });
    },
  });

  return {
    tag,
    tags,
    createTag,
    updateTag,
    deleteTag,
  };
};
