import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ExperienceTag } from '@understory-io/utils-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useExperienceTags } from '../../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { trackStorefrontTagManagementTagEdited } from '../../../../tracking/storefront/tags-events';
import { TagDialogWrapper } from './tag-dialog-wrapper';
import { ExperienceTagForm } from './tag-form';

type UpdateTagDialogProps = {
  tagId: string;
};

export const UpdateTagDialog = NiceModal.create(
  ({ tagId }: UpdateTagDialogProps) => {
    const { t } = useTranslate('tags.dialog.update');
    const [isUpdating, setIsUpdating] = useState(false);

    const {
      tag: { data: tag },
      updateTag,
    } = useExperienceTags(tagId);

    if (!tag) return null;

    const handleSubmit = (data: ExperienceTag['name']) => {
      setIsUpdating(true);
      const payload = { ...tag, name: data };

      updateTag
        .mutateAsync(payload)
        .then(() => {
          handleClose();
          trackStorefrontTagManagementTagEdited();
        })
        .catch((error) => {
          toast.error(t('utils.errors.generic'));
          console.error(error);
        })
        .finally(() => setIsUpdating(false));
    };

    const modal = useModal();

    const handleClose = () => {
      modal.resolve();
      modal.remove();
    };

    return (
      <TagDialogWrapper
        open={modal.visible}
        handleClose={handleClose}
        title={t('title')}
        description={t('description')}
      >
        <ExperienceTagForm
          onSubmit={handleSubmit}
          handleCancel={handleClose}
          defaultValues={tag.name}
          isSubmitting={isUpdating}
        />
      </TagDialogWrapper>
    );
  }
);
