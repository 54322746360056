const routes = {
  login: '/login',
  signup: '/signup',
  dashboard: '/dashboard',
  experience: {
    overview: '/experiences',
    create: '/experience/create/description',
    details: (experienceId: string) => {
      const experienceIndex = `/experience/${experienceId}`;

      // TODO: Remove v2
      const editIndex = `/v2/experience/${experienceId}/edit`;
      return {
        index: experienceIndex,
        edit: {
          index: editIndex,
          cutoff: editIndex + '/cutoff',
          description: editIndex + '/description',
          whatsIncluded: editIndex + '/whats-included',
          infoForQuests: editIndex + '/info-for-quests',
          tags: editIndex + '/tags',
          tickets: {
            index: editIndex + '/tickets',
            edit: (variantId: string) => editIndex + `/tickets/${variantId}`,
            delete: (variantId: string) =>
              editIndex + `/tickets/${variantId}/delete`,
          },
          displayPrice: editIndex + '/display-price',
        },
      };
    },
  },
  event: {
    create: '/event/create',
    details: (eventId: string) => {
      const eventIndex = `/event/${eventId}`;
      return {
        index: eventIndex,
        edit: eventIndex + '/edit',
      };
    },
  },
  booking: {
    overview: '/bookings',
    details: (bookingId: string) => {
      const bookingIndex = `/booking/${bookingId}`;
      return {
        index: bookingIndex,
      };
    },
  },
  calendar: '/calendar',
  giftCard: {
    overview: '/giftcards',
    details: (giftCardId: string) => {
      const giftCardIndex = `/giftcard/${giftCardId}`;
      return {
        index: giftCardIndex,
        adjustValue: giftCardIndex + '/adjust-value',
        refund: giftCardIndex + '/refund',
        deactivate: giftCardIndex + '/deactivate',
      };
    },
  },
  storefrontSettings: {
    index: '/storefront',
    customize: '/storefront/customize',
    features: '/storefront/features',
    integration: '/storefront/integration',
    locations: '/storefront/locations',
    tags: '/storefront/tags',
    languages: '/storefront/languages',
  },
  growth: {
    index: '/growth',
    opportunities: '/growth/opportunities',
    tracking: '/growth/tracking',
    discounts: '/growth/discounts',
    emails: '/growth/emails',
  },
  resourceManagement: {
    index: '/resource-management',
    types: {
      create: '/resource-management/types/create',
      details: (resourceTypeId: string) => {
        return {
          index: `/resource-management/types/${resourceTypeId}`,
          createResource: `/resource-management/types/${resourceTypeId}/resources/create`,
        };
      },
    },
  },
  settings: {
    index: '/settings',
    profile: '/settings/profile',
    company: '/settings/company',
    users: '/settings/users',
    connect: '/settings/connect',
    integrations: '/settings/integrations',
    notifications: '/settings/notifications',
    terms: '/settings/terms',
  },
  connect: {
    index: '/settings/connect',
    invitation: (invitationId: string) => {
      const invitationIndex = '/settings/connect';
      return {
        accept: invitationIndex + `/accept/${invitationId}`,
        decline: invitationIndex + `/decline/${invitationId}`,
      };
    },
    details: (connectionId: string) => {
      const connectionIndex = '/settings/connect/details';
      return {
        index: connectionIndex,
        edit: connectionIndex + `/${connectionId}/edit`,
        disconnect: connectionIndex + `/${connectionId}/disconnect`,
      };
    },
  },
};

export default routes;
