import { CompanyProfile } from '@understory-io/utils-types';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { FixedActionsBar } from '../../../../../Components/FixedActionsBar/FixedActionsBar';
import { useCountryVatRates } from '../../../../../Hooks/useCountryVatRates';
import { usePreventWindowUnload } from '../../../../../Hooks/usePreventWindowUnload';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { trackCompanyFormCompleted } from '../../../../../tracking/settings/settings-events';
import { getCompanyFormDefaults, getVatCompliance } from '../../domain/helpers';
import { CompanyFormData } from '../../domain/use-company-form-validation';

export const CompanyFormActions = () => {
  const { t } = useTranslate('toast.saving');

  const { data: countryVatRates } = useCountryVatRates();

  const {
    me: { data: me },
    company: { data: company },
    updateCompany,
  } = useProfile();

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useFormContext<CompanyFormData>();

  usePreventWindowUnload(isDirty);

  const onSubmit = async (formData: CompanyFormData) => {
    const updatedCompanyData = {
      ...formData,
      vatCompliance: getVatCompliance(formData, countryVatRates, company),
    } as Partial<CompanyProfile>;

    try {
      await updateCompany.mutateAsync(updatedCompanyData);
      toast.success(t('success'));
      reset(getCompanyFormDefaults(updatedCompanyData));

      trackCompanyFormCompleted(formData, me?.id);
    } catch {
      toast.error(t('error'));
    }
  };

  return (
    <FixedActionsBar
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => reset()}
      isSubmitting={isSubmitting}
      unsavedChanges={isDirty}
    />
  );
};
