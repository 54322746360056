import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { isSameDay } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { isIntervalSelected } from '../../../../domain/types';

export const EventUpsertTimeFormAddIntervalButton = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const selectedStartDateTime = useMemo(
    () => new Date(currentEvent.startDateTime),
    [currentEvent.startDateTime]
  );
  const selectedEndDateTime = useMemo(
    () => new Date(currentEvent.endDateTime),
    [currentEvent.endDateTime]
  );

  const isStartEndOnDifferentDays = useMemo(
    () => !isSameDay(selectedStartDateTime, selectedEndDateTime),
    [selectedEndDateTime, selectedStartDateTime]
  );

  const handleAddTime = () => {
    try {
      const fromTime =
        isIntervalSelected(currentEvent.intervals) &&
        currentEvent.intervals.value.length > 0
          ? parseInt(
              currentEvent.intervals.value[
                currentEvent.intervals.value.length - 1
              ].fromTime
            ) +
            parseInt(currentEvent.intervals.value[0].fromTime) -
            calculateFromTime(currentEvent.startDateTime)
          : calculateFromTime(currentEvent.endDateTime);

      let toTime = fromTime + currentEvent.startEndTimeDiff;

      // (23:55 or 1435 minutes from midnight)
      const endOfDayInMinutes = 1435;

      if (toTime > endOfDayInMinutes) {
        toTime = endOfDayInMinutes;
      }

      const newInterval = {
        fromTime: fromTime.toString(),
        toTime: toTime.toString(),
      };

      const updatedIntervals = isIntervalSelected(currentEvent.intervals)
        ? [...currentEvent.intervals.value, newInterval]
        : [newInterval];

      updateEventField('intervals', {
        selectedOptionKey: 'yes',
        value: updatedIntervals,
      });
    } catch (error) {
      console.error('Error adding time: ', error);
    }
  };

  const hasBookings = currentEvent.bookings && currentEvent.bookings.length > 0;

  return (
    <StyledButton
      variant="secondary"
      size="medium"
      type="button"
      disabled={
        currentEvent.visibility === 'private' ||
        isStartEndOnDifferentDays ||
        hasBookings
      }
      leftIcon={<AddIcon />}
      onClick={handleAddTime}
    >
      {t('eventUpsert.flow.time.form.interval.button.label')}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  height: 40px;
  width: fit-content;
`;

const calculateFromTime = (dateString: string) => {
  const date = new Date(dateString);
  return date.getHours() * 60 + date.getMinutes();
};
