import { Box, Stack } from '@mui/material';

import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { EventUpsertDetailsVisibility } from '../EventUpsertDetailsVisibility';
import { EventUpsertDetailsFormExperienceSelect } from './EventUpsertDetailsFormExperienceSelect';
import { EventUpsertDetailsFormGuideSelect } from './EventUpsertDetailsFormGuideSelect';
import { EventUpsertDetailsFormInformation } from './EventUpsertDetailsFormInformation';
import { EventUpsertDetailsFormLanguageSelect } from './EventUpsertDetailsFormLanguageSelect';
import { EventUpsertDetailsFormLocationSelect } from './EventUpsertDetailsFormLocationSelect';
import { EventUpsertDetailsFormSeatsField } from './EventUpsertDetailsFormSeatsField';

export const EventDetailsUpsertForm = () => {
  const { currentEvent } = useEventUpsert();

  const isCreate = currentEvent.variant === 'create';

  return (
    <Box component="form">
      <Stack gap={5}>
        <Stack gap={3} maxWidth="424px">
          <EventUpsertDetailsFormExperienceSelect disabled={!isCreate} />
          <EventUpsertDetailsFormLocationSelect />
          <Stack direction="row" gap={2}>
            <EventUpsertDetailsFormSeatsField />
            <EventUpsertDetailsFormGuideSelect />
          </Stack>
          <EventUpsertDetailsFormLanguageSelect />
          <EventUpsertDetailsFormInformation />
        </Stack>
        {isCreate && <EventUpsertDetailsVisibility />}
      </Stack>
    </Box>
  );
};
