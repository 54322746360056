import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ControlledPhoneInput from '../../../../../../../Components/PhoneInput/ControlledPhoneInput';
import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { BookingFormData } from './use_booking_form_validation';

export const EventUpsertBookingFormPhoneField = () => {
  const { currentEvent, updateEventField } = useEventUpsert();

  const { control, watch } = useFormContext<Pick<BookingFormData, 'phone'>>();

  useEffect(() => {
    updateEventField('booking', {
      ...currentEvent.booking,
      phone: watch('phone') ?? undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('phone')]);

  return <ControlledPhoneInput name="phone" control={control} />;
};
