import styled from '@emotion/styled';
import { css, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  startOfWeek,
} from 'date-fns';

import { useCalendar } from '../context';

const DAYS_TO_DISPLAY = 7;

export const DaySelector = () => {
  const { selectedDate, setSelectedDate, minimumDate, maximumDate } =
    useCalendar();

  const startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });

  return (
    <StyledGrid>
      {[...new Array(DAYS_TO_DISPLAY)].map((_, index) => {
        const day = addDays(startDate, index);

        return (
          <Stack key={day.toISOString()} alignItems="center">
            <DayLabel fontSize="xsmall">{format(day, 'EEE')}</DayLabel>
            <DayButton
              $selected={isSameDay(day, selectedDate)}
              onClick={() => setSelectedDate(day)}
              disabled={isBefore(day, minimumDate) || isAfter(day, maximumDate)}
            >
              {format(day, 'd')}
            </DayButton>
          </Stack>
        );
      })}
    </StyledGrid>
  );
};

const DayLabel = styled(Text)`
  text-transform: capitalize;
  color: ${lightTheme.palette.neutral.n300};
`;

const DayButton = styled.button<{ $selected?: boolean }>`
  background: none;
  border: none;
  border-radius: 50%;

  width: 28px;
  aspect-ratio: 1 / 1;

  font-weight: 500;
  font-size: 14px;

  font-family: 'Inter', sans-serif;

  color: ${lightTheme.palette.contrast.black};

  ${({ $selected }) =>
    $selected &&
    css`
      color: white;
      background: ${lightTheme.palette.action.a300};
    `};
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${DAYS_TO_DISPLAY}, 1fr);
  gap: 1rem;

  text-align: center;
`;
