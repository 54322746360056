import NiceModal from '@ebay/nice-modal-react';
import {
  GlobeAltIcon,
  LockClosedIcon,
  MapPinIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { Stack, TextField } from '@mui/material';
import { Location } from '@understory-io/utils-types';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useYupForm } from '../../../Hooks/use-yup-form';
import { CreateLocationDialog } from '../../../Sections/StorefrontSections/location-management/dialogs/create-location-dialog';
import {
  type BasicExperienceFields as BasicExperienceFieldsType,
  basicExperienceFieldsSchema,
} from '../schemas/basicExperienceFieldsSchema';
import { CustomSelect } from './components/custom-select';

type BasicExperienceFieldsProps = {
  locations: Location[];
  defaultValues: BasicExperienceFieldsType;
  onSubmit: (data: BasicExperienceFieldsType) => void;
};

const DEBOUNCE_SUBMIT_MS = 500;
const ICON_HEIGHT_WIDTH_PX = 20;
const ICON_STROKE_WIDTH_PX = 2;

export const BasicExperienceFields = ({
  locations,
  defaultValues,
  onSubmit,
}: BasicExperienceFieldsProps) => {
  const { t } = useTranslation('');
  const formMethods = useYupForm({
    schema: basicExperienceFieldsSchema,
    defaultValues,
  });
  const [isLocationOpen, setIsLocationOpen] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: {
      errors: { locationIds: locationIdsError, visibility: visibilityError },
    },
  } = formMethods;

  const { field: visibility } = useController({
    name: `visibility`,
    control,
    rules: { required: 'Visibility is required' },
  });

  const { field: locationIds } = useController({
    name: `locationIds`,
    control,
    rules: { required: 'One location is required' },
  });

  const handleVisibilityChange = (newValue: string | string[]) => {
    if (Array.isArray(newValue)) return;

    visibility.onChange(newValue);
  };

  const handleCreateLocation = async () => {
    setIsLocationOpen(false);
    const locationId = await NiceModal.show(CreateLocationDialog);
    if (locationId && typeof locationId === 'string') {
      locationIds.onChange([...locationIds.value, locationId]);
    }
  };

  useEffect(() => {
    // Debounce submission of form
    const debouncedSubmit = debounce(
      // We still want to submit on handleSubmit's onInvalid handler
      handleSubmit(onSubmit, () => onSubmit(getValues())),
      DEBOUNCE_SUBMIT_MS
    );
    // Subscribe to form changes
    const subscription = watch(() => debouncedSubmit());

    return () => subscription.unsubscribe();
  }, [getValues, handleSubmit, onSubmit, watch]);

  const visibilityOptions = {
    public: {
      value: 'public',
      label: t('experience.card.visibility.public'),
      icon: (
        <GlobeAltIcon
          strokeWidth={ICON_STROKE_WIDTH_PX}
          width={ICON_HEIGHT_WIDTH_PX}
          height={ICON_HEIGHT_WIDTH_PX}
        />
      ),
    },
    private: {
      value: 'private',
      label: t('experience.card.visibility.private'),
      icon: (
        <LockClosedIcon
          strokeWidth={ICON_STROKE_WIDTH_PX}
          width={ICON_HEIGHT_WIDTH_PX}
          height={ICON_HEIGHT_WIDTH_PX}
        />
      ),
    },
  };

  const locationFooterOptions = [
    {
      label: t('location.dialog.create.title'),
      onClick: handleCreateLocation,
      icon: (
        <PlusIcon
          strokeWidth={ICON_STROKE_WIDTH_PX}
          width={ICON_HEIGHT_WIDTH_PX}
          height={ICON_HEIGHT_WIDTH_PX}
        />
      ),
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Stack
        component="form"
        noValidate
        sx={{ gap: 2, alignItems: 'flex-start' }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            gap: { xs: 5, md: 10 },
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <TitleInput />
          <CustomSelect
            selectedValue={visibility.value}
            options={Object.values(visibilityOptions)}
            onChange={handleVisibilityChange}
            Icon={visibilityOptions[visibility.value].icon}
            error={!!visibilityError}
            helperText={visibilityError?.message}
          />
        </Stack>
        <CustomSelect
          multiple
          selectedValue={locationIds.value}
          open={isLocationOpen}
          onClose={() => setIsLocationOpen(false)}
          onOpen={() => setIsLocationOpen(true)}
          options={locations.map(({ locationId, locationName }) => ({
            value: locationId,
            label: locationName,
            icon: (
              <MapPinIcon
                strokeWidth={ICON_STROKE_WIDTH_PX}
                width={ICON_HEIGHT_WIDTH_PX}
                height={ICON_HEIGHT_WIDTH_PX}
              />
            ),
          }))}
          footerOptions={locationFooterOptions}
          onChange={locationIds.onChange}
          emptyLabel={t(
            'location.dialog.delete.replaceLocation.selectLocation'
          )}
          Icon={
            <MapPinIcon
              strokeWidth={ICON_STROKE_WIDTH_PX}
              width={ICON_HEIGHT_WIDTH_PX}
              height={ICON_HEIGHT_WIDTH_PX}
            />
          }
          error={!!locationIdsError}
          helperText={locationIdsError?.message}
        />
      </Stack>
    </FormProvider>
  );
};

const TitleInput = () => {
  const {
    control,
    formState: {
      errors: { headline: headlineError },
    },
  } = useFormContext<BasicExperienceFieldsType>();

  const { field: headline } = useController({
    name: 'headline',
    control,
    // TODO: Remove when validation schema is implemented
    rules: {
      required: 'Headline is required',
      maxLength: {
        value: 50,
        message: 'Headline must not exceed 50 characters',
      },
    },
  });

  return (
    <TextField
      multiline
      value={headline.value}
      onChange={(e) => headline.onChange(e.target.value)}
      autoFocus={!headline.value}
      variant="standard"
      fullWidth
      placeholder="Experience title"
      inputProps={{ sx: { fontSize: 44, lineHeight: '100%' } }}
      error={!!headlineError}
      helperText={headlineError?.message}
    />
  );
};
