import {
  HelpCenterOutlined,
  LabelImportantOutlined,
  LocalOfferOutlined,
  TagOutlined,
} from '@mui/icons-material';
import { Grid, Stack, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme, Text } from '@understory-io/pixel';
import { useMemo } from 'react';

import { TranslateFunction, useTranslate } from '../../../Hooks/useTranslate';
import routes from '../../../Utils/routes';
import { Experience } from '../schemas';
import { useNavigateWithParams } from './utils/use-navigate-with-params';

type MoreOptionsProps = {
  experience: Experience;
};

// TODO: Use different icons, update paths, add more options?
const optionsConfig = (experienceId: string, t: TranslateFunction) => ({
  tags: {
    key: 'tags',
    Icon: TagOutlined,
    title: t('label.tags'),
    description: t('description.tags'),
    path: routes.experience.details(experienceId).edit.tags,
  },
  practicalInfo: {
    key: 'practicalInfo',
    Icon: HelpCenterOutlined,
    title: 'Add practical information',
    description: 'For the guest booking confirmation',
    path: 'practical-info',
  },
  customData: {
    key: 'customData',
    Icon: LabelImportantOutlined,
    title: 'Request guest informations',
    description: 'Collect allergies, shoe sizes etc.',
    path: 'custom-data',
  },
  displayPrice: {
    key: 'displayPrice',
    Icon: LocalOfferOutlined,
    title: t('label.displayPrice'),
    description: t('description.displayPrice'),
    path: routes.experience.details(experienceId).edit.displayPrice,
  },
});

export const MoreOptions = ({ experience }: MoreOptionsProps) => {
  const { t } = useTranslate('experience.edit.options');
  const navigateWithParams = useNavigateWithParams();

  const availableOptions = useMemo(() => {
    const options = [];

    const config = optionsConfig(experience.id, t);

    if (!experience.tagIds.length) {
      options.push(config.tags);
    }

    if (!experience.practicalInfo) {
      options.push(config.practicalInfo);
    }

    if (!experience.customData?.yes?.length) {
      options.push(config.customData);
    }

    if (
      !experience.price?.displayVariantId &&
      !!experience.price?.variants?.length
    ) {
      options.push(config.displayPrice);
    }

    return options;
  }, [experience, t]);

  if (availableOptions.length === 0) return null;

  return (
    <Stack sx={{ gap: 1 }}>
      <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
        More options
      </Text>
      <Grid container spacing={2}>
        {availableOptions.map(({ key, path, ...props }) => (
          <Grid item key={key} xs={12} md={6} lg={4}>
            <OptionItem {...props} onClick={() => navigateWithParams(path)} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

type OptionItemProps = {
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  title: string;
  description: string;
  onClick: () => void;
};

const OptionItem = ({ Icon, title, description, onClick }: OptionItemProps) => {
  return (
    <Stack
      sx={{
        cursor: 'pointer',
        backgroundColor: lightTheme.palette.contrast.surface1,
        borderRadius: 1,
        padding: 2,
        gap: 1,
        transition: 'background-color 0.1s',
        ':hover': {
          backgroundColor: lightTheme.palette.neutral.n100,
        },
      }}
      onClick={onClick}
    >
      <Icon sx={{ color: lightTheme.palette.neutral.n300 }} />
      <Stack sx={{ gap: '1px' }}>
        <Text fontSize="small">{title}</Text>
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          {description}
        </Text>
      </Stack>
    </Stack>
  );
};
