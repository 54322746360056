import styled from '@emotion/styled';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { Box, Stack } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { theme } from '../../../../../Utils/theme';
import { useEventUpsert } from '../../domain/event_upsert_context';

type EventUpsertContentProps = {
  title: string;
  children: ReactNode;
  action?: ReactNode;
  hasInvalidInput?: boolean;
  handleSaveAndClose?: () => void;
  onBack?: () => void;
};

export const EventUpsertContent = ({
  title,
  children,
  action,
  hasInvalidInput = false,
  handleSaveAndClose,
  onBack,
}: EventUpsertContentProps) => {
  const { t } = useTranslation();
  const { isSm, isMd } = useResponsive();
  const displayBackButton = !!(onBack && !isSm);

  const { currentEvent } = useEventUpsert();

  const isLastStep = currentEvent.step === 'time';
  const isEdit = currentEvent.variant === 'edit';

  const saveAndClose = (
    <Button
      disabled={hasInvalidInput}
      onClick={handleSaveAndClose}
      variant="secondary"
      size="medium"
      width={isSm ? 'auto' : 208}
    >
      {t('buttons.saveAndClose')}
    </Button>
  );

  return (
    <Stack width="100%" height="100%" gap={3} flexGrow={1} p={isSm ? 0 : 4}>
      <Text as="h1" fontSize={isMd ? 'medium' : 'xlarge'} variant="medium">
        {title}
      </Text>
      <Box flexGrow={1} paddingBottom={isSm ? theme.spacing(12) : 0}>
        {children}
      </Box>
      {isSm && (
        <StyledAction>
          {isEdit && !isLastStep ? (
            <Stack gap={2} direction="column">
              {saveAndClose}
              {action}
            </Stack>
          ) : (
            action
          )}
        </StyledAction>
      )}
      {!isSm && (
        <Stack direction="row" alignSelf="flex-end" width="auto" gap={2}>
          {isEdit && !isLastStep && saveAndClose}
          {displayBackButton && !isSm && (
            <Button
              size="medium"
              variant="secondary"
              width={isSm ? 'auto' : 208}
              onClick={onBack}
              leftIcon={<ArrowLeftIcon height={24} width={24} />}
            >
              {t('eventUpsert.actions.previousStep')}
            </Button>
          )}
          {action}
        </Stack>
      )}
    </Stack>
  );
};

const StyledAction = styled(Stack)`
  opacity: 1;
  z-index: 1000;
  position: fixed;
  padding: ${theme.spacing(0)} ${theme.spacing(2)};
  bottom: ${theme.spacing(4)};
  width: 100%;
  align-self: center;
`;
