import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { useTranslate } from '../../Hooks/useTranslate';

const POSSIBLE_METHODS = ['GET', 'POST', 'PATCH', 'PUT', 'DELETE'] as const;

const API_KEY_RESOURCES: ReadonlyArray<{
  resource: string;
  methods: ReadonlyArray<(typeof POSSIBLE_METHODS)[number]>;
}> = [
  {
    resource: '/account',
    methods: ['GET'],
  },
  {
    resource: '/account/private-key',
    methods: ['GET'],
  },
  {
    resource: '/payments',
    methods: ['GET', 'POST'],
  },
  {
    resource: '/payments/{id}',
    methods: ['GET', 'PATCH'],
  },
  {
    resource: '/payments/{id}/link',
    methods: ['PUT'],
  },
  {
    resource: '/payments/{id}/refund',
    methods: ['POST'],
  },
] as const;

export const QuickpayPermissionsTable = () => {
  const { t } = useTranslate('settings.integrations.payments.quickpay');

  return (
    <Card
      sx={{
        width: 'fit-content',
        padding: 0,
        marginX: 'auto',
        background: lightTheme.palette.neutral.n50,
      }}
      variant="outlined"
      elevation={0}
    >
      <Box marginY={1} textAlign="center" fontWeight={500}>
        {t('configuration.permissionNeeded')}
      </Box>
      <Table
        sx={{ maxWidth: 650, margin: 'auto' }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Resource</TableCell>
            {POSSIBLE_METHODS.map((method) => (
              <TableCell key={method} align="center">
                {method}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {API_KEY_RESOURCES.map((row) => (
            <TableRow
              key={row.resource}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                {row.resource}
              </TableCell>
              {POSSIBLE_METHODS.map((method) => (
                <TableCell align="center" key={method}>
                  {row.methods.includes(method) && <CheckBoxIcon />}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};
