import {
  API,
  Resource,
  ResourceType,
} from '@understory-io/resource-management-types';

import api from '.';
import { apiServices } from './apiServices';

export const createResourceType = async (
  item: API.CreateResourceType.Request
): Promise<ResourceType> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.post<API.CreateResourceType.Response>(
    `${apiServices.internalApi}/v1/resource-management/resource-types`,
    item
  );

  return data.item;
};

export const getResourceTypes = async (): Promise<ResourceType[]> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.get<API.GetResourceTypes.Response>(
    `${apiServices.internalApi}/v1/resource-management/resource-types`
  );

  return data.items;
};

export const createResource = async (
  item: API.CreateResource.Request
): Promise<Resource> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }
  ``;
  const { data } = await api.post<API.CreateResource.Response>(
    `${apiServices.internalApi}/v1/resource-management/resources`,
    item
  );

  return data.item;
};

export const getResources = async (
  resourceTypeId: string
): Promise<Resource[]> => {
  if (typeof apiServices.internalApi === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.get<API.GetResources.Response>(
    `${apiServices.internalApi}/v1/resource-management/resource-types/${resourceTypeId}/resources`
  );

  return data.items;
};
