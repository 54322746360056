import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Checkbox,
  Dialog,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { Button } from '@understory-io/pixel';
import { Booking } from '@understory-io/utils-types';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ControlledPhoneInput from '../../../../Components/PhoneInput/ControlledPhoneInput';
import { useGetEvent } from '../../../../Hooks/events/useGetEvent';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useBookings } from '../../../../Hooks/useBookings';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { DialogHeader } from '../../../../Modals/create-booking/dialog-header';
import { emailRegex } from '../../../../Utils/helpers';

type EditCustomerFormInputs = Pick<Booking, 'language' | 'customer'> & {
  sendConfirmation: boolean;
};

export const EditCustomerDialog = NiceModal.create<{
  booking: Booking;
}>(({ booking }) => {
  const { t } = useTranslate('dialogs.editBooking');
  const { isSm } = useResponsive();
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const { updateBooking } = useBookings();

  const formMethods = useForm<EditCustomerFormInputs>({
    defaultValues: {
      language: booking.language,
      customer: booking.customer,
      sendConfirmation: true,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;

  const handleClose = async (shouldRemove = true) => {
    modal.reject('edit-customer-dialog');
    if (shouldRemove) {
      modal.remove();
    } else {
      modal.hide();
    }
  };

  const onSubmit = async (data: EditCustomerFormInputs) => {
    try {
      setIsLoading(true);

      await updateBooking.mutateAsync({
        id: booking.id,
        shouldSendConfirmation: data.sendConfirmation,
        ...data,
      });

      toast.success(t('toast.success'));
      handleClose();
    } catch (error) {
      captureException(error);
      toast.error(t('toast.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const { event } = useGetEvent(booking.eventId);

  if (!event.data) return null;

  return (
    <Dialog
      sx={{ justifyContent: 'flex-end' }}
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={modal.visible}
      onClose={() => handleClose(true)}
    >
      <DialogHeader
        title={t('title', 'dialogs.editCustomer')}
        handleClose={handleClose}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack paddingX={isSm ? 2 : 4} gap={3}>
            <TextField
              {...register('customer.name', {
                required: t('required', 'utils.errors'),
              })}
              label={t('name', 'utils.generic')}
              fullWidth
              error={Boolean(errors.customer?.name?.message)}
              helperText={errors.customer?.name?.message}
              required
            />
            <Stack sx={{ flexDirection: isSm ? 'column' : 'row', gap: 2 }}>
              <TextField
                {...register('customer.email', {
                  required: t('required', 'utils.errors'),
                  pattern: {
                    value: emailRegex,
                    message: t('invalidEmail', 'auth.errors'),
                  },
                })}
                type="email"
                label={t('email', 'utils.generic')}
                sx={{ width: isSm ? '100%' : '50%' }}
                error={Boolean(errors.customer?.email?.message)}
                helperText={errors.customer?.email?.message}
                required
              />
              <ControlledPhoneInput
                sx={{ width: isSm ? '100%' : '50%' }}
                name="customer.phone"
                control={control}
              />
            </Stack>
            <Controller
              control={control}
              name="language"
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  error={Boolean(errors?.language)}
                  required
                >
                  {event.data.languages.map((language) => (
                    <MenuItem key={language} value={language}>
                      {t(language, 'utils.languages')}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <FormControlLabel
              {...register('sendConfirmation')}
              label={t('sendConfirmation')}
              control={<Checkbox defaultChecked />}
            />
          </Stack>
          <Stack
            padding={isSm ? 2 : 4}
            direction="row"
            justifyContent="end"
            gap={1}
          >
            <Button
              variant="primary"
              size="large"
              type="submit"
              loading={isLoading}
            >
              {t('buttonLabel')}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </Dialog>
  );
});
