import { lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';
import {
  type ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { RichEditor } from '../../../../Components/RichEditor/RichEditor';
import { Language } from '../../../../i18n/config';
import type { Experience } from '../../schemas';
import { whatsIncludedSchema } from '../../schemas/whatsIncludedSchema';
import { loaderName } from '../edit-experience';
import { getCompanyProfile, getExperience } from '../queries';
import { getFormDataValue } from '../utils/form-helpers';
import { getActiveLanguage } from '../utils/get-active-language';
import { getAutoTranslate } from '../utils/get-auto-translate';
import { translateInput } from '../utils/translate-input';
import { EditExperienceDialog } from './edit-experience-dialog';

const PROPERTY_NAME = 'whatsIncluded';

export default function WhatsIncludedForm() {
  const actionData = useActionData() as ActionData;
  const { experience, activeLanguage } = useRouteLoaderData(loaderName) as {
    experience: Experience;
    activeLanguage: Language;
    autoTranslate: boolean;
  };

  const [whatsIncluded, setWhatsIncluded] = useState(
    experience.whatsIncluded[activeLanguage]
  );

  return (
    <EditExperienceDialog
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
    >
      <input name={PROPERTY_NAME} type="text" value={whatsIncluded} hidden />
      <RichEditor
        placeholder="List key informations about the experience in bullets..."
        value={whatsIncluded}
        onChange={(e: string) => {
          setWhatsIncluded(e);
        }}
      />
      {actionData?.error && (
        <Text color={lightTheme.palette.error.e300} variant="normal">
          {actionData.error}
        </Text>
      )}
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: string;
} | null;

export async function action({
  params,
  request,
}: ActionFunctionArgs): Promise<ActionData> {
  const id = params.id;
  const autoTranslate = getAutoTranslate(request);

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    // Validate input
    const formData = await request.formData();
    const whatsIncluded = getFormDataValue(formData.get(PROPERTY_NAME));

    const [experience, companyProfile] = await Promise.all([
      getExperience(id),
      getCompanyProfile(),
    ]);

    const activeLanguage = getActiveLanguage(request, companyProfile);

    const translatedWhatsIncluded = await translateInput(
      whatsIncluded,
      experience.whatsIncluded,
      activeLanguage,
      companyProfile.languages as Language[],
      autoTranslate
    );

    const result = whatsIncludedSchema.validateSync(translatedWhatsIncluded);

    const experienceToSave = {
      ...experience,
      whatsIncluded: result,
    } as Experience;

    console.log('Save experience in whats included action', experienceToSave);

    // 3. Save draft
    // TODO: Save draft - US-2713

    // TODO: Track changes to this component to see when we can deprecate it

    return { shouldClose: true };
  } catch (error) {
    if (error instanceof ValidationError) {
      return {
        error: error.message,
      };
    }

    toast.error("We couldn't save included data. Try again later");
    return null;
  }
}
