import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogProps, Fade, Slide, Stack } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { forwardRef, PropsWithChildren } from 'react';
import { Form, useLocation, useNavigate, useNavigation } from 'react-router';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import routes from '../../../../Utils/routes';
import { useNavigateWithParams } from '../utils/use-navigate-with-params';

type DialogContentProps = {
  title?: string;
  description?: string;
  shouldClose?: boolean;
  experienceId: string;
  isDelete?: boolean;
};

type EditExperienceDialogProps = PropsWithChildren & DialogContentProps;

export const EditExperienceDialog = ({
  title,
  description,
  children,
  shouldClose,
  experienceId,
  isDelete,
}: EditExperienceDialogProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateWithParams = useNavigateWithParams();
  const { state } = useNavigation();

  const disableButton = state === 'submitting' || state === 'loading';

  if (shouldClose) {
    navigateWithParams(routes.experience.details(experienceId).edit.index, {
      replace: true,
    });
  }

  const handleClose = () => {
    if (location.key === 'default') {
      navigateWithParams(routes.experience.details(experienceId).edit.index, {
        replace: true,
      });
    } else {
      navigate(-1);
    }
  };

  return (
    <DialogWrapper
      maxWidth="sm"
      handleClose={handleClose}
      title={title}
      description={description}
    >
      <Form method="post">
        {children}
        <Stack
          sx={{
            marginTop: 3,
            gap: 2,
            flexDirection: { xs: 'column-reverse', md: 'row' },
          }}
        >
          <Button
            type="button"
            size="large"
            variant="secondary"
            disabled={disableButton}
            onClick={handleClose}
            fullWidth
            style={{
              flexShrink: 'unset',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="large"
            variant={isDelete ? 'danger' : 'primary'}
            loading={disableButton}
            fullWidth
            style={{
              flexShrink: 'unset',
            }}
          >
            {isDelete ? 'Delete' : 'Save'}
          </Button>
        </Stack>
      </Form>
    </DialogWrapper>
  );
};

type DialogWrapperProps = Omit<EditExperienceDialogProps, 'experienceId'> &
  Pick<DialogProps, 'maxWidth'> & {
    handleClose: () => void;
  };

export const DialogWrapper = ({
  handleClose,
  title,
  description,
  maxWidth = 'sm',
  children,
}: DialogWrapperProps) => {
  const { isSm } = useResponsive();

  const shouldShowDialogInFullscreen = isSm;

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      TransitionComponent={shouldShowDialogInFullscreen ? Transition : Fade}
      fullScreen={shouldShowDialogInFullscreen}
      disableRestoreFocus
      PaperProps={{
        style: shouldShowDialogInFullscreen
          ? {
              height: '90%',
              overflow: 'auto',
              position: 'fixed',
              bottom: 0,
              width: '100%',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }
          : { width: '100%' },
      }}
    >
      <Stack
        sx={{
          paddingTop: { xs: 5.5, sm: 4 },
          paddingInline: { xs: 2, sm: 4 },
          paddingBottom: 4,
          gap: 2,
          flexGrow: 1,
        }}
      >
        <CloseOutlined
          sx={{
            position: 'absolute',
            top: { xs: 24, sm: 32 },
            right: { xs: 24, sm: 32 },
            zIndex: 1,
            ':hover': { cursor: 'pointer' },
          }}
          onClick={handleClose}
        />
        <Stack gap={1}>
          {!!title && (
            <Text variant="medium" fontSize="xlarge">
              {title}
            </Text>
          )}
          {!!description && (
            <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
              {description}
            </Text>
          )}
        </Stack>
        {children}
      </Stack>
    </Dialog>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
