import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Box, CircularProgress, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import axios from 'axios';
import { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { initPaymentSetup } from '../../Api/Api';
import { InfoBox } from '../../Components/InfoBox/InfoBox';
import { Input } from '../../Components/Input/Input';
import { Link } from '../../Components/Link/Link';
import { useOnBoarding } from '../../Hooks/useOnBoarding';
import { useTranslate } from '../../Hooks/useTranslate';
import { QuickpayPermissionsTable } from './quickpay-permissions-table';

const QUICKPAY_VOUCHER_FREE_LINK =
  'https://manage.quickpay.net/login/signup?id=0f4e84e5-ca0e-5181-9461-6876b3bc1cd4&referral=';

export const QuickpayIntegrationConfigure: FC = () => {
  const { t } = useTranslate('settings.integrations.payments.quickpay');

  const [isLoading, setIsLoading] = useState(false);

  const schema = useValidationSchema();

  const { onBoarding } = useOnBoarding();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async ({ apiKey }: yup.InferType<typeof schema>) => {
    try {
      setIsLoading(true);
      await initPaymentSetup('quickpay', 'new', {
        apiKey,
      });

      await onBoarding.refetch();
    } catch (error) {
      captureException(error);

      if (
        axios.isAxiosError(error) &&
        error.response?.data.errorCode === 'QUICKPAY_SETUP_ERROR'
      ) {
        setError('apiKey', {
          message: t('configuration.setupError'),
        });
        return;
      }

      setError('apiKey', {
        message: t('configuration.unknownError'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <InfoBox title={t('configuration.configure')}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={2} width={'100%'}>
          <Stack gap={2} direction="row">
            <Avatar sx={{ backgroundColor: lightTheme.palette.action.a300 }}>
              1
            </Avatar>
            <Stack>
              <Text variant="medium">
                {t('configuration.createQuickpayAccountDescription')}
              </Text>
              <Link
                fontSize="small"
                href={QUICKPAY_VOUCHER_FREE_LINK}
                target="_blank"
              >
                {QUICKPAY_VOUCHER_FREE_LINK}
              </Link>
            </Stack>
          </Stack>
          <Stack gap={2} direction="row">
            <Avatar sx={{ backgroundColor: lightTheme.palette.action.a300 }}>
              2
            </Avatar>
            <Stack gap={2}>
              <Text variant="medium">
                {t('configuration.createSystemUserDescription')}
              </Text>
              <QuickpayPermissionsTable />
              <Input
                {...register('apiKey')}
                error={!!errors.apiKey}
                helperText={errors.apiKey?.message}
                label={t('apiKey')}
                required
              />
              <Box>
                <Button
                  size="medium"
                  variant="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="1em" color="inherit" />
                  ) : (
                    t('configuration.configure')
                  )}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </InfoBox>
  );
};

const API_KEY_NUMBER_OF_CHARACTERS = 64;

const useValidationSchema = () => {
  const { t } = useTranslate('settings.integrations.payments.quickpay');

  return useMemo(
    () =>
      yup.object({
        apiKey: yup
          .string()
          .length(
            API_KEY_NUMBER_OF_CHARACTERS,
            t('validation.apiKeyLength', {
              numberOfCharacters: API_KEY_NUMBER_OF_CHARACTERS,
            })
          )
          .required(),
      }),
    [t]
  );
};

const defaultValues = {
  apiKey: '',
};
