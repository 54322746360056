import { Grid, keyframes, Skeleton, Stack, styled } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { useTranslation } from 'react-i18next';

import { EVENT_LIST_COLUMNS, EventListLabel } from '../event-list/event-list';

export function ExperienceEventFilterSkeleton() {
  return (
    <Skeleton
      sx={{ transform: 'unset', display: { xs: 'none', md: 'flex' } }}
      height={38}
      width={140}
    />
  );
}

export function ExperienceEventTableSkeleton() {
  const { t } = useTranslation();
  return (
    <>
      <Stack>
        <Stack
          sx={{
            paddingY: 1,
            paddingX: 2,
            paddingRight: 8,
            backgroundColor: lightTheme.palette.neutral.n100,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <Grid container columns={8} columnSpacing={3}>
            {EVENT_LIST_COLUMNS.map(({ key, icon, span }) => (
              <Grid key={key} item xs={span}>
                <EventListLabel
                  label={t(`experience.details.eventList.label.${key}`)}
                  Icon={icon}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Stack>
          {Array.from({ length: 5 }).map((_, index) => (
            <AnimatedRow
              height={56}
              sx={{
                flexDirection: 'row',
                gap: 3,
                padding: 2,
                transition: 'background 0.1s ease-in-out',
                '&:hover': {
                  background: lightTheme.palette.neutral.n100,
                },
                display: { xs: 'none', md: 'flex' },
                transform: 'unset',
              }}
              key={index}
              delay={index * 0.5}
            >
              <Grid
                container
                columns={8}
                columnSpacing={3}
                sx={{ flexGrow: 1 }}
              >
                {EVENT_LIST_COLUMNS.map(({ key, span }) => (
                  <Grid key={key} item xs={span}>
                    <Stack sx={{ height: '100%', justifyContent: 'center' }}>
                      <Skeleton height={'100%'} width={'100%'} />
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </AnimatedRow>
          ))}
        </Stack>
      </Stack>
      <Stack flexDirection="row-reverse">
        <Skeleton
          variant="rectangular"
          sx={{ transform: 'unset' }}
          height={32}
          width={400}
        />
      </Stack>
    </>
  );
}

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const AnimatedRow = styled(Stack)<{ delay?: number }>`
  animation: ${fadeAnimation} 3s ease infinite forwards;
  opacity: 0;
  animation-delay: ${({ delay }) => delay || 0}s;
`;
