import { Controller } from 'react-hook-form';

import { LanguageChips } from '../../../../../Components/LanguageChips/LanguageChips';
import { useCurrencies } from '../../../../../Hooks/locales/use-currencies';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { SyiSection } from '../../../../../Pages/SyiPage/SyiSection';

export const CompanyFormCurrency = () => {
  const { t } = useTranslate('settings.company');

  const currencies = useCurrencies();

  const currencyOptions = currencies.data?.items.map(
    ({ code, symbol, flagSrc }) => ({
      key: code.toLowerCase(),
      label: code + (symbol ? ` (${symbol})` : ''),
      flag: flagSrc,
    })
  );

  return (
    <SyiSection title={t('defaultCurrency.title')}>
      <Controller
        name={'defaultCurrency'}
        render={({ field }) => (
          <LanguageChips
            onChange={(keys) => {
              field.onChange(keys[0]);
            }}
            languageOptions={currencyOptions}
            languages={field.value}
            multiple={false}
          />
        )}
      />
    </SyiSection>
  );
};
