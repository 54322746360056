import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { AppShell } from '../Components/AppShell/AppShell';
import { useAuth } from '../Hooks/useAuth';
import { useMenu } from '../Hooks/useMenu';

export const PrivateRoute = ({
  children,
  showAppShell = true,
  title,
  titleTranslationsKey,
  changeTitle = true,
  scopes,
  hideContainerPadding = false,
}: {
  children: JSX.Element;
  showAppShell?: boolean;
  title?: string;
  /** This replaces the title prop when the `t()` function is not in scope */
  titleTranslationsKey?: string;
  changeTitle?: boolean;
  scopes: string[];
  hideContainerPadding?: boolean;
}) => {
  const { isAuthenticated, canAccess } = useAuth();
  const navigate = useNavigate();
  const { frontPageBasedOnScopes } = useMenu();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (changeTitle)
      document.title = titleTranslationsKey
        ? t(titleTranslationsKey)
        : title
          ? `${t(title)} - Understory`
          : 'Understory';
  }, [title, changeTitle, titleTranslationsKey, t]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true, state: { from: location } });
      return;
    }

    if (
      frontPageBasedOnScopes &&
      scopes &&
      scopes.length > 0 &&
      !scopes.some(canAccess)
    ) {
      // Authenticated user is not allowed to access page
      const loc = frontPageBasedOnScopes || '/';
      if (loc === location.pathname) {
        // If the user is already on the designated front page, something is off.
        console.warn(
          'User is not allowed to access this page, and there is no front page to redirect to.'
        );
        navigate('/login', { replace: true });
        return;
      }
      navigate(loc, { replace: true });
    }
  }, [
    isAuthenticated,
    canAccess,
    scopes,
    navigate,
    location,
    frontPageBasedOnScopes,
  ]);

  return showAppShell ? (
    <AppShell hideContainerPadding={hideContainerPadding}>{children}</AppShell>
  ) : (
    children
  );
};
