import { yupResolver } from '@hookform/resolvers/yup';
import type { UseFormProps } from 'react-hook-form';
import { useForm as useHookForm } from 'react-hook-form';
import type * as yup from 'yup';

export function useYupForm<Schema extends yup.AnyObjectSchema>(
  props: Omit<UseFormProps<yup.InferType<Schema>>, 'resolver'> & {
    schema: Schema;
  }
) {
  const form = useHookForm<yup.InferType<Schema>>({
    ...props,
    resolver: async (data, context, options) => {
      return yupResolver(props.schema)(data, context, options);
    },
  });

  return form;
}
