import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Location } from '@understory-io/utils-types';

import {
  createLocation,
  deleteLocation,
  getLocation,
  getLocations,
  getLocationsByExperienceId,
  updateLocation,
} from '../../Api/Locations';
import { ILocationView } from '../../types/api/location';

export const QueryKeys = {
  locations: 'getLocations',
  location: 'getLocation',
};

export const locationsQuery = (companyId: string) => ({
  queryKey: [QueryKeys.locations, companyId],
  queryFn: () => getLocations(companyId),
  enabled: !!companyId,
});

export const useGetLocations = (
  companyId: string
): { locations: UseQueryResult<Location[]> } => {
  const locations = useQuery({
    ...locationsQuery(companyId),
    enabled: !!companyId,
  });

  return { locations };
};

export const useGetLocationsByExperienceId = (
  experienceId?: string
): { locations: UseQueryResult<Location[]> } => {
  const queryKey = [QueryKeys.locations, experienceId];

  const locations = useQuery({
    queryKey: queryKey,
    queryFn: () => getLocationsByExperienceId(experienceId!),
    enabled: !!experienceId,
  });

  return { locations };
};

export const useGetLocation = (
  locationId: string
): { location: UseQueryResult<Location> } => {
  const queryKey = [QueryKeys.location, locationId];

  const location = useQuery({
    queryKey: queryKey,
    queryFn: () => getLocation(locationId).then((data) => data.item),
    enabled: !!locationId,
  });

  return { location };
};

export const useCreateLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createLocation,
    onMutate: async (newLocation) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QueryKeys.locations],
      });

      // Snapshot the previous value
      const previousLocations = queryClient.getQueryData<ILocationView[]>([
        QueryKeys.locations,
      ]);

      // Optimistically update to the new value
      if (previousLocations) {
        queryClient.setQueryData<ILocationView[]>(
          [QueryKeys.locations],
          (old) => [...(old ?? []), newLocation]
        );
      }

      // Return a context object with the snapshotted value
      return { previousLocations };
    },
    onSuccess: async () => {
      // Invalidate and refetch
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.locations],
      });
    },
    onError: (error, variables, context) => {
      // Rollback to the previous cache value
      if (context?.previousLocations) {
        queryClient.setQueryData<ILocationView[]>(
          [QueryKeys.locations],
          context.previousLocations
        );
      }
    },
  });
};

export const useUpdateLocation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ILocationView) => updateLocation(data),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [QueryKeys.location] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.location] });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.locations] });
    },
  });
};

export const useDeleteLocation = (locationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteLocation(locationId),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [QueryKeys.location] });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.locations] });
    },
  });
};
