import { DeleteOutlined } from '@mui/icons-material';
import { IconButton, MenuItem, Stack, TextField } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { VariantInputFields } from './variant-form';
import { VatRegistrationPopover } from './vat-registration-popover';

export type TicketItemFormProps = {
  inputNameBase: string;
  vatCategories: { key: string; label: string }[];
  defaultValues: VariantInputFields;
  defaultCurrency: string;
  errors?: { [key: string]: { type: string; message: string } };
  onDelete?: () => void;
};

export const TicketItemForm = ({
  inputNameBase,
  vatCategories,
  defaultValues,
  defaultCurrency,
  errors,
  onDelete,
}: TicketItemFormProps) => {
  const { t } = useTranslate('experience.edit.dialog.tickets.input');

  const isAddon = onDelete !== undefined;

  return (
    <Stack sx={{ flexDirection: 'row', gap: 1 }}>
      <Stack sx={{ gap: 2 }}>
        <Stack sx={{ flexDirection: 'row', gap: 2 }}>
          <TextField
            hiddenLabel
            name={`${inputNameBase}_name`}
            error={Boolean(errors?.[`${inputNameBase}_name`])}
            helperText={errors?.[`${inputNameBase}_name`]?.message}
            label={t(isAddon ? 'label.addonName' : 'label.name')}
            InputLabelProps={{ shrink: true }}
            defaultValue={defaultValues.name}
            fullWidth
            sx={{ width: { md: '50%' } }}
          />
          <Stack sx={{ flexDirection: 'row', gap: 2, width: { md: '50%' } }}>
            <TextField
              name={`${inputNameBase}_price`}
              error={Boolean(errors?.[`${inputNameBase}_price`])}
              helperText={errors?.[`${inputNameBase}_price`]?.message}
              label={t('label.price', {
                currency: t(defaultCurrency.toUpperCase(), 'utils.currency'),
              })}
              type="number"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: 0 }}
              defaultValue={defaultValues.price}
              fullWidth
              sx={{ width: { md: '50%' } }}
            />
            {vatCategories.length > 0 ? (
              <TextField
                select
                label={t('label.vatCategory')}
                name={`${inputNameBase}_vatCategory`}
                error={Boolean(errors?.[`${inputNameBase}_vatCategory`])}
                sx={{ width: { md: '50%' } }}
                defaultValue={defaultValues.vatCategory}
              >
                {vatCategories.map(({ key, label }) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <VatRegistrationPopover />
            )}
          </Stack>
        </Stack>
        <TextField
          name={`${inputNameBase}_explanation`}
          error={Boolean(errors?.[`${inputNameBase}_explanation`])}
          helperText={errors?.[`${inputNameBase}_explanation`]?.message}
          label={t('label.explanation')}
          InputLabelProps={{ shrink: true }}
          defaultValue={defaultValues.explanation}
        />
      </Stack>
      {onDelete && (
        <IconButton
          sx={{
            padding: 0.5,
            alignSelf: 'flex-start',
            color: lightTheme.palette.neutral.n300,
            transition: 'color 0.1s',
            ':hover': { color: lightTheme.palette.neutral.n500 },
            ':focus-visible': {
              outline: 1,
              color: lightTheme.palette.neutral.n500,
            },
          }}
        >
          <DeleteOutlined fontSize="small" onClick={onDelete} />
        </IconButton>
      )}
    </Stack>
  );
};
