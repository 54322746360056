import { Box, Divider, Skeleton, Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { useCalendar } from '../../../context';
import { ListEmpty } from '../../../shared';
import { GridExperienceCell } from '../../calendar-grid/GridExperienceCell';
import { useGroupedEvents } from '../../hooks/useGroupedEvents';
import { ListItem } from '../ListItem';
import { ListContainer, LoadingEventItem } from './shared';

export const CalendarDayListGrouped = () => {
  return (
    <ListContainer>
      <List />
    </ListContainer>
  );
};

const List = () => {
  const { isLoading } = useCalendar();

  const groups = useGroupedEvents();

  if (isLoading || !groups) return <LoadingList />;

  if (!Object.entries(groups).length) return <ListEmpty />;

  return (
    <Stack divider={<Divider />}>
      {Object.values(groups).map(({ events, experience }) => {
        return (
          <Stack key={experience.id} direction="row">
            <GridExperienceCell
              experience={experience}
              width={192}
              borderRight={`1px solid ${lightTheme.palette.neutral.n100}`}
            />
            {events ? (
              <Stack gap={1} flexGrow={1} padding={1} overflow="hidden">
                {events.map((event) => (
                  <ListItem key={event.id} event={event} />
                ))}
              </Stack>
            ) : (
              <Box height={48} />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

const LoadingList = () => {
  return (
    <Stack divider={<Divider />}>
      <LoadingGroupItem />
      <LoadingGroupItem />
      <LoadingGroupItem />
    </Stack>
  );
};

const LoadingGroupItem = () => {
  return (
    <Stack direction="row">
      <GridExperienceCell
        display="flex"
        width={192}
        borderRight={`1px solid ${lightTheme.palette.neutral.n100}`}
      >
        <Skeleton height={18.5} width={100} />
      </GridExperienceCell>
      <Stack gap={1} flexGrow={1} padding={1}>
        <LoadingEventItem />
        <LoadingEventItem />
        <LoadingEventItem />
      </Stack>
    </Stack>
  );
};
