import { styled } from '@mui/material';
import { DateCalendar, DayCalendarSkeleton } from '@mui/x-date-pickers';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { lightTheme } from '@understory-io/pixel';
import * as React from 'react';
import { useEffect } from 'react';

const dayPickerWidth = 72;

const Styled = styled(DateCalendar)<{ isLarge: boolean }>(
  ({ isLarge = true }) => ({
    margin: 0,
    width: isLarge ? dayPickerWidth * 10 : '330px',
    minWidth: isLarge ? dayPickerWidth * 10 : '100%',
    maxHeight: isLarge ? 680 : 450,
    height: isLarge ? 680 : 'auto',
    ['& .MuiPickersDay-root']: {
      minWidth: isLarge ? dayPickerWidth : 42,
      width: isLarge ? dayPickerWidth : 42,
      height: isLarge ? dayPickerWidth : 42,
      margin: isLarge ? 8 : 3,
      ['&.Mui-selected']: {
        fontSize: '1em',
        fontWeight: 600,
      },
    },
    ['& .MuiTypography-caption']: {
      width: isLarge ? dayPickerWidth : 42,
      margin: `0 ${isLarge ? 8 : 2}px`,
    },
    ['& .PrivatePickersSlideTransition-root']: {
      minHeight: isLarge ? 550 : 310,
    },
    ['& > div div[role="presentation"]']: {
      fontSize: '1.111em',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    ['& > div:first-of-type']: {
      marginBottom: isLarge ? 40 : 16,
      ['& > div:first-of-type .MuiButtonBase-root']: {
        display: 'none',
      },
    },
    ['& .MuiCalendarPickerSkeleton-daySkeleton']: {
      width: `${isLarge ? dayPickerWidth : 42}px !important`,
      height: `${isLarge ? dayPickerWidth : 42}px !important`,
      margin: isLarge ? 8 : 3,
    },
  })
);

const initialValue = new Date();

const Calendar = ({
  isLoading,
  cellWidth,
  highlightedDays,
  selectedDate,
  defaultDate,
  onChangeMonth,
  onClickDate,
  onChangeYear,
}: {
  isLoading: boolean;
  selectedDate?: string;
  defaultDate?: Date | null;
  cellWidth?: number;
  highlightedDays: number[];
  onChangeYear?: (date: any) => void;
  onChangeMonth: (date: any) => void;
  onClickDate: (date: any) => void;
}) => {
  const [_value, setValue] = React.useState<Date | null>(
    defaultDate === undefined ? initialValue : defaultDate
  );
  useEffect(() => {
    if (selectedDate !== undefined) {
      setValue(new Date(selectedDate));
    }
  }, [selectedDate]);

  return (
    <Styled
      isLarge={!cellWidth}
      value={_value}
      loading={isLoading}
      disablePast={true}
      sx={{
        ['& .MuiPickersDay-root.Mui-disabled']: {
          background: '#F2F2F7',
          color: '#8E8E93',
        },
      }}
      views={['day']}
      onMonthChange={onChangeMonth}
      onYearChange={onChangeYear}
      onChange={(newValue) => {
        setValue(newValue as Date);
      }}
      shouldDisableMonth={() => true}
      renderLoading={() => <DayCalendarSkeleton />}
      slots={{
        day: Day,
      }}
      slotProps={{
        day: ({ day, selected }) => {
          return {
            selected,
            isHighlighted: highlightedDays.includes(day.getDate()),
            cellWidth,
            onDaySelect: () => onClickDate(day),
          };
        },
      }}
    />
  );
};

const Day = ({
  selected,
  isHighlighted,
  cellWidth,
  ...props
}: PickersDayProps<Date> & {
  isHighlighted?: boolean;
  cellWidth?: number;
}) => {
  return (
    <PickersDay
      {...props}
      disabled={!isHighlighted}
      sx={{
        backgroundColor: `${
          isHighlighted && !selected
            ? lightTheme.palette.success.s100
            : selected
              ? lightTheme.palette.action.a100
              : 'inherit'
        } !important`,

        color: `${
          selected ? lightTheme.palette.action.a200 : 'black'
        } !important`,

        margin: cellWidth ? 8 : 12 / 2,

        fontSize: `${lightTheme.typography.xsmall.normal.fontSize} !important`,
        fontWeight: '500 !important',

        border: `${selected ? '2px' : '1px'} solid ${
          isHighlighted && !selected
            ? lightTheme.palette.success.s200
            : selected
              ? lightTheme.palette.action.a200
              : 'none'
        }`,

        pointerEvents: isHighlighted ? 'initial' : 'none',
      }}
    />
  );
};

export default Calendar;
