import { ArrowBackRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Card,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { ReactNode } from 'react';
import { Navigate } from 'react-router';

import { LanguageSelect } from '../../Components/LanguageSelect/LanguageSelect';
import { Logo } from '../../Components/logo';
import { Page } from '../../Components/Page/Page';
import { DashboardRebrandBanner } from '../../features/dashboard/dashboard-graphs/dashboard-rebrand-banner';
import { useAuth } from '../../Hooks/useAuth';
import { DEFAULT_PAGE } from '../../Utils/constants';

const Styled = styled(Page)(({ theme }) => ({
  minHeight: '100vh',
  margin: '0 auto',
  background: lightTheme.palette.contrast.surface2,
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  // mobile breakpoint  600px to 0px
  [theme.breakpoints.down('sm')]: {
    background: lightTheme.palette.contrast.white,
    padding: '16px',
  },

  [theme.breakpoints.down('md')]: {
    margin: '0',
    justifyContent: 'flex-start',
  },
}));

const AuthCard = styled(Card)(({ theme }) => ({
  flexGrow: '0',
  maxWidth: '480px',
  width: '100%',
  padding: '56px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  background: lightTheme.palette.contrast.white,
  boxShadow: '0 0 16px 0 rgba(0,0,0,0.18)',
  [theme.breakpoints.down('sm')]: {
    padding: '32px 32px 32px 32px',
  },
}));

const StyledFooter = styled((props: BoxProps) => (
  <Box component={'footer'} {...props} />
))(({ theme }) => ({
  padding: 24,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    marginTop: '40px',
  },
}));

export const AuthLayout = ({
  title,
  description,
  hideFooter = false,
  children,
  onBack,
  showRebrandBanner = false,
}: {
  title: string;
  description?: string;
  hideFooter?: boolean;
  children?: ReactNode;
  onBack?: () => void;
  showRebrandBanner?: boolean;
}) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={DEFAULT_PAGE} replace />;
  }

  return (
    <Styled>
      {showRebrandBanner && (
        <>
          <Box
            sx={{
              marginBottom: 2,
              width: '100%',
              top: 0,
              position: {
                sm: 'absolute',
              },
              padding: { sm: 2 },
            }}
          >
            <DashboardRebrandBanner showCloseButton={false} />
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'block' },
              height: { sm: 166 },
            }}
          />
        </>
      )}
      <AuthCard className={'auth-layout-header'}>
        {!onBack && <Logo mt={{ xs: 1, md: 0 }} mr={'auto'} height={32} />}

        {onBack && (
          <Box>
            <IconButton onClick={onBack}>
              <ArrowBackRounded />
            </IconButton>
          </Box>
        )}
        <Typography
          mb={description ? 1 : 2}
          variant={'h3'}
          sx={{
            mt: {
              xs: onBack ? 3 : '56px',
              md: onBack ? 3 : 5,
            },
          }}
        >
          {title}
        </Typography>
        {description && <Typography mb={2}>{description}</Typography>}
        {children}
        {!hideFooter && (
          <StyledFooter>
            <LanguageSelect />
          </StyledFooter>
        )}
      </AuthCard>
    </Styled>
  );
};
