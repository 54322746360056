import { Link, Stack, styled } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { HTMLAttributeAnchorTarget } from 'react';

import { theme } from '../../../../../../../../Utils/theme';

export const LinkItem = ({
  label,
  linkText,
  link,
  target,
}: {
  label?: string;
  linkText: string;
  link: string;
  target?: HTMLAttributeAnchorTarget;
}) => {
  return (
    <Stack
      gap={1}
      sx={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '35%',
      }}
    >
      {label && (
        <StyledLinkLabelText variant="medium" fontSize="small">
          {label}
        </StyledLinkLabelText>
      )}
      <Link
        href={link}
        target={target}
        style={{
          fontSize: 'inherit',
          color: 'inherit',
          display: 'inline-block',
          textDecoration: 'underline',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {linkText}
      </Link>
    </Stack>
  );
};

export const ListItem = ({
  label,
  value,
}: {
  label: string;
  value?: string;
}) => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <StyledLabelText variant="medium" fontSize="small">
        {label}
      </StyledLabelText>
      {value && (
        <StyledValueText variant="medium" fontSize="small">
          {value}
        </StyledValueText>
      )}
    </Stack>
  );
};

const StyledLinkLabelText = styled(Text)({
  color: theme.palette.info.contrastText,
  fontWeight: '500',
});

const StyledLabelText = styled(Text)({
  color: theme.palette.info.contrastText,
  fontWeight: '500',
  width: '40%',
});

const StyledValueText = styled(Text)({
  fontWeight: '500',
  width: '60%',
});
