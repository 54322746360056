import './index.css';

import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntercomProvider } from 'react-use-intercom';

import App from './App';
import { launchDarklyClient } from './launch-darkly';
import { ErrorProvider } from './Pages/SyiPage/useErrors';
import { queryClient } from './query-client';
import { getHostingEnvironmentName, isProduction } from './Utils/environment';
import { theme } from './Utils/theme';

const container = document.getElementById('root') as HTMLElement;

if (isProduction) {
  Sentry.init({
    environment: getHostingEnvironmentName(),
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.4,
  });
}

async function runApp() {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID ?? '',
    ldClient: launchDarklyClient,
    options: {
      streaming: true,
      logger: basicLogger({
        level: 'warn',
      }),
    },
  });
  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <LDProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <ErrorProvider>
              <IntercomProvider appId={`${process.env.REACT_APP_INTERCOM_ID}`}>
                <App />
              </IntercomProvider>
            </ErrorProvider>
          </ThemeProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </LDProvider>
    </React.StrictMode>
  );
}

runApp();
