import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { BookingFlowSectionContextProvider } from '../domain/use_booking_flow_section_context';
import { FlowType } from './flow_type/FlowType';
import { IntegrationLinks } from './integration_links/IntegrationLinks';

export const StorefrontBookingFlowSection = () => {
  const { t } = useTranslate('storefront.bookingflow');

  return (
    <BookingFlowSectionContextProvider>
      <Stack gap={6}>
        <Stack gap={0.5}>
          <Text fontSize={'large'} variant={'medium'}>
            {t('title')}
          </Text>
          <Text fontSize={'small'}>{t('description')}</Text>
        </Stack>
        <FlowType />
        <IntegrationLinks />
      </Stack>
    </BookingFlowSectionContextProvider>
  );
};
