import { Stack } from '@mui/material';
import { Skeleton, Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { Location } from '@understory-io/utils-types';
import { Suspense } from 'react';
import { Await } from 'react-router';

import { InfoBox } from '../../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { renderDurationString } from '../../../experiences/utils/render-duration';

type Props = {
  resourceType: ResourceType;
  locations: Promise<Array<Location>>;
};

export const ResourceTypeDetailsCard = ({ resourceType, locations }: Props) => {
  const { isSm } = useResponsive();
  const { t } = useTranslate('resourceManagement.resourceType');

  return (
    <InfoBox title={resourceType.name} sx={{ width: isSm ? '100%' : '50%' }}>
      <Stack gap={2}>
        <Text>
          {t('capacity')} {resourceType.capacity}
        </Text>
        <ResourceTypeLocation
          locationId={resourceType.locationId}
          locations={locations}
        />
        <Text>
          {t('recoverTime')}{' '}
          {renderDurationString(
            t,
            (resourceType.recoverTimeInSeconds ?? 0) / 60
          )}
        </Text>
        <Text>
          {t('description')} {resourceType.description}
        </Text>
      </Stack>
    </InfoBox>
  );
};

const ResourceTypeLocation = ({
  locationId,
  locations,
}: {
  locationId: string;
  locations: Promise<Array<Location>>;
}) => {
  const { t } = useTranslate('resourceManagement.resourceType');

  return (
    <Suspense
      fallback={<Skeleton variant={'rounded'} height={50} width={'100%'} />}
    >
      <Await resolve={locations}>
        {(locations) => (
          <Text>
            {t('location')}{' '}
            {locations.find((x) => x.locationId === locationId)?.locationName}
          </Text>
        )}
      </Await>
    </Suspense>
  );
};
