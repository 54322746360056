import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { HelpOutlineOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { updateExperience } from '../../../../Api';
import { Tooltip } from '../../../../Components/tooltip/tooltip';
import { QueryKeys as GetLocationQueryKeys } from '../../../../Hooks/data/useLocations';
import { getLocalized } from '../../../../Hooks/useBookings';
import { IExperience } from '../../../../Hooks/useExperience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { TagDialogWrapper } from '../../tag-management/dialogs/tag-dialog-wrapper';

type BulkAddLocationDialogProps = {
  locationId: string;
  locationName: string;
  experiences: IExperience[];
};

export const BulkAddLocationDialog = NiceModal.create(
  ({ locationId, locationName, experiences }: BulkAddLocationDialogProps) => {
    const { t } = useTranslate('location.dialog.bulkAdd');

    const initialExperiences = experiences.reduce((selected, experience) => {
      if (experience.locationIds?.includes(locationId)) {
        return [...selected, experience.id];
      }
      return selected;
    }, [] as string[]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedExperiences, setSelectedExperiences] =
      useState(initialExperiences);

    const queryClient = useQueryClient();

    const handleSubmit = (experienceIds: string[]) => {
      setIsSubmitting(true);
      Promise.all(
        experiences
          // We only want to update the experience if:
          // 1. The experience already has the location, but it should be removed
          // 2. The experience does not have the location, but it should be added
          .filter(
            (experience) =>
              (experience.locationIds?.includes(locationId) &&
                !experienceIds.includes(experience.id)) ||
              (experienceIds.includes(experience.id) &&
                !experience.locationIds?.includes(locationId))
          )
          .map((experience) => {
            const newLocationIds = experienceIds.includes(experience.id)
              ? Array.from(
                  new Set([...(experience.locationIds ?? []), locationId])
                )
              : [
                  ...(experience.locationIds?.filter(
                    (id) => id !== locationId
                  ) ?? []),
                ];

            if (newLocationIds.length === 0) {
              throw new Error('Experience must have 1 location');
            }

            return updateExperience(experience.id, {
              ...experience,
              locationIds: newLocationIds,
            });
          })
      )
        .then(async () => {
          await queryClient.invalidateQueries({
            queryKey: ['experiences'],
          });
          await queryClient.invalidateQueries({
            queryKey: [GetLocationQueryKeys.locations],
          });
          await queryClient.invalidateQueries({
            queryKey: ['events', 'bookings'],
          });
          handleClose();
        })
        .catch((error) => {
          toast.error(t('utils.errors.generic'));
          console.error(error);
        })
        .finally(() => setIsSubmitting(false));
    };

    const modal = useModal();

    const handleClose = () => {
      modal.resolve();
      modal.remove();
    };

    return (
      <TagDialogWrapper
        open={modal.visible}
        handleClose={handleClose}
        title={t('title')}
        description={t('description', { locationName })}
      >
        <ExperienceSelect
          locationId={locationId}
          experiences={experiences}
          selected={selectedExperiences}
          setSelected={setSelectedExperiences}
        />
        <Stack
          sx={{
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: { xs: 1, sm: 2 },
            '& > button': {
              flexGrow: 1,
              flexBasis: { md: 0 },
            },
          }}
        >
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={handleClose}
          >
            {t('cancel', 'location.dialog.action')}
          </Button>
          <Button
            type="button"
            variant="primary"
            size="large"
            onClick={() => handleSubmit(selectedExperiences)}
            disabled={!experiences.length}
            loading={isSubmitting}
          >
            {t('save', 'location.dialog.action')}
          </Button>
        </Stack>
      </TagDialogWrapper>
    );
  }
);

const ExperienceSelect = ({
  locationId,
  experiences,
  selected,
  setSelected,
}: {
  locationId: string;
  experiences: IExperience[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
}) => {
  const { t, i18n } = useTranslation();

  if (!experiences.length) {
    return (
      <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
        {t('storefront.tags.experienceListEmpty')}
      </Text>
    );
  }

  return (
    <Stack>
      {experiences.map((experience) => {
        const isChecked = selected.includes(experience.id);
        const isDisabled = experience.locationIds?.every(
          (id) => id === locationId
        );
        const onChange = isChecked
          ? () =>
              setSelected((prev) => prev.filter((id) => id !== experience.id))
          : () => setSelected((prev) => [...prev, experience.id]);

        return (
          <Stack
            key={experience.id}
            sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}
          >
            <FormControlLabel
              sx={{ marginRight: 0 }}
              label={getLocalized(experience.headline, i18n.language)}
              control={
                <Checkbox
                  value={isChecked}
                  onChange={onChange}
                  defaultChecked={isChecked}
                  disabled={isDisabled}
                />
              }
            />
            {isDisabled && (
              <Tooltip
                title={t('location.dialog.bulkAdd.disabledTip')}
                sx={{ color: lightTheme.palette.neutral.n300 }}
              >
                <HelpOutlineOutlined fontSize="inherit" color="inherit" />
              </Tooltip>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
