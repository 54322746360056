import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EconomicConfiguration } from '@understory-io/utils-types';

import {
  getEconomicConfiguration,
  saveEconomicConfiguration,
} from '../../../Api';
import { useProfile } from '../../../Hooks/useProfile';
import { EconomicConfigurationQueryKey } from './economic-query-keys';

export type UseEconomicConfigurationReturn = ReturnType<
  typeof useEconomicConfiguration
>;

export const useEconomicConfiguration = () => {
  const { company } = useProfile();
  const queryClient = useQueryClient();

  const configuration = useQuery({
    queryKey: EconomicConfigurationQueryKey,

    queryFn: async () => {
      const configuration = await getEconomicConfiguration();
      return configuration && configuration.item;
    },
    enabled: !!company.data?.id,
    retryOnMount: false,
  });

  const updateConfiguration = useMutation({
    mutationFn: async (payload: EconomicConfiguration) => {
      await saveEconomicConfiguration({ ...payload });
      return payload;
    },

    onMutate: async (payload) => {
      await queryClient.cancelQueries({
        queryKey: EconomicConfigurationQueryKey,
      });

      const previous = queryClient.getQueryData(EconomicConfigurationQueryKey);

      queryClient.setQueryData(EconomicConfigurationQueryKey, payload);

      return { previous };
    },

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(
          EconomicConfigurationQueryKey,
          context.previous
        );
      }
    },
  });

  return {
    configuration,
    updateConfiguration,
  };
};
