import { SvgIconComponent } from '@mui/icons-material';
import { Box, BoxProps, styled, Tab, Tabs } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { SyntheticEvent } from 'react';

const Styled = styled(Box)`
  width: 100%;
  z-index: 20;
  border-bottom: 1px solid rgba(200, 200, 200, 0.2);
`;

const StyledTab = styled(Tab)<{ selected?: boolean }>(() => ({
  fontWeight: 600,
  minHeight: 32,
  minWidth: 100,
  '&.Mui-selected': {
    color: lightTheme.palette.action.a300,
  },
}));

const StyledTabs = styled(Tabs)({
  minHeight: 32,
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
    borderBottom: `2px solid ${lightTheme.palette.action.a300}`,
    borderRadius: 0,
  },
});

export type TTab = {
  key: string;
  label?: string;
  scopes?: string[];
  allowedOnMobile?: boolean;
  Icon?: SvgIconComponent;
  trackingFn?: () => void;
};

interface ITabBarProps {
  selected?: number;
  onChange?: (index: number) => void;
  items?: TTab[];
}

export const TabBar = ({
  items,
  selected,
  onChange,
  ...props
}: BoxProps<'div', ITabBarProps>) => {
  const handleChange = (e: SyntheticEvent, newValue: number) => {
    onChange?.(newValue);
  };

  return (
    <Styled {...props}>
      <StyledTabs value={selected} onChange={handleChange}>
        {items?.map((el, index) => {
          const { Icon, key, label } = el;
          return (
            <StyledTab
              key={key}
              data-intercom-target={`tab-${key}`}
              value={index}
              label={label}
              icon={Icon ? <Icon fontSize={'small'} /> : undefined}
              iconPosition="start"
            />
          );
        })}
      </StyledTabs>
    </Styled>
  );
};
