import { AddRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { Outlet, useLoaderData } from 'react-router';

import { ShowForScope } from '../../../Components/AllowForScope/AllowForScope';
import { RouterLinkButton } from '../../../Components/button/router-link-button';
import { Page } from '../../../Components/Page/Page';
import { useTranslate } from '../../../Hooks/useTranslate';
import routes from '../../../Utils/routes';
import { RESOURCE_MANAGEMENT_SCOPES } from '../auth/resource-management-scopes';
import { resourceTypesQuery } from '../data/use-resource-types';
import { ResourceTypesList } from './resource-types-list';

type LoaderData = {
  resourceTypes: Promise<ResourceType[]>;
};

export const loader =
  (client: QueryClient) => async (): Promise<LoaderData> => {
    const resourceTypes = client.fetchQuery(resourceTypesQuery());

    return {
      resourceTypes,
    };
  };

export const ResourceTypesOverview = () => {
  const { t } = useTranslate('resourceManagement');
  const { resourceTypes } = useLoaderData<LoaderData>();

  // TOOD: Add list and calendar page

  return (
    <Page
      sx={{
        maxWidth: '1400px',
        pr: { xs: 0, md: 2 },
        pb: { xs: 0, md: 10 },
      }}
    >
      <Stack gap={2} direction="row" justifyContent="space-between">
        <Text fontSize="h5">{t('title')}</Text>
        <ShowForScope scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <RouterLinkButton
              href={routes.resourceManagement.types.create}
              variant="primary"
              leftIcon={<AddRounded fontSize="small" sx={{ mr: 1 }} />}
            >
              {t('newResourceType')}
            </RouterLinkButton>
          </Stack>
        </ShowForScope>
      </Stack>
      <ResourceTypesList resourceTypes={resourceTypes} />
      <Outlet />
    </Page>
  );
};
