import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  type: 'start' | 'creating' | 'finished';
};

export const PreviewLayoutHeader: FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0, x: 10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ duration: 0.8 }}
    >
      <Stack gap={1}>
        <Text fontSize="h5">{t(`auth.preview.titles.${type}`)}</Text>
        <Text>{t(`auth.preview.subtitles.${type}`)}</Text>
      </Stack>
    </motion.div>
  );
};
