import { Box, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { isSameDay } from 'date-fns';
import { FC, useMemo } from 'react';
import { Link } from 'react-router';

import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import { useEventPopover } from '../event-popover/event-popover-context';
import { useCalendarLink } from '../hooks/useCalendarLink';
import { getGroupedBackgroundColor } from './utils';

type Props = {
  events: Array<TEventWithTitle>;
  date: Date;
};

export const GroupedEventList: FC<Props> = ({ events, date }) => {
  const getCalendarLink = useCalendarLink();

  const { handleSelectEvent } = useEventPopover();

  const eventsForDate = useMemo(() => {
    return events.filter((event) =>
      isSameDay(new Date(event.startDateTime), date)
    );
  }, [date, events]);

  return (
    <Box
      key={date.toISOString()}
      component={Link}
      to={getCalendarLink(date, 'day')}
      sx={{
        backgroundColor: getGroupedBackgroundColor(date),
      }}
    >
      <Stack p="1px" gap="1px">
        {eventsForDate.map((event) => {
          const fullyBooked = event.slots.total <= (event.slots.booked ?? 0);

          return (
            <Box
              key={event.id}
              style={{
                aspectRatio: '1 / 1',
                background: fullyBooked
                  ? lightTheme.palette.error.e200
                  : lightTheme.palette.success.s200,
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={(e) => {
                e.preventDefault();
                handleSelectEvent(event, e);
              }}
            >
              <Text fontSize="xsmall" variant="medium" color="white">
                {event.slots.booked}
              </Text>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
