import { Experience } from '@understory-io/utils-types';
import {
  differenceInDays,
  differenceInSeconds,
  format,
  secondsInDay,
  startOfDay,
  subSeconds,
} from 'date-fns';

import { TEventWithTitle } from '../../Utils/eventHelpers';

export type EventSourceType =
  | 'Calendar'
  | 'Event Details Actions'
  | 'Event Details Popup'
  | 'Event Details Bookings List'
  | 'Event Details Context Menu';

export const eventProperties = (
  event: TEventWithTitle,
  experience: Experience | undefined,
  source: EventSourceType
) => {
  const numberOfVariants = event.variants?.length ?? 0;
  const numberOfAddons =
    event.variants?.flatMap(({ addons }) => addons ?? []).length ?? 0;
  return {
    days_to_booking_closing:
      differenceInSeconds(
        subSeconds(
          startOfDay(new Date(event.startDateTime)),
          Number(experience?.cutoffTimeSeconds ?? 0)
        ),
        startOfDay(new Date())
      ) / secondsInDay,
    days_until_event: differenceInDays(
      startOfDay(new Date(event.startDateTime)),
      startOfDay(new Date())
    ),
    event_capacity: event.slots.total,
    event_scheduled_date: format(new Date(event.startDateTime), 'dd-MM-yyyy'),
    experience_id: event.experienceId,
    experience_name: event.title,
    language_list: event.languages,
    location: event.location,
    number_of_guests: event.slots.booked ?? 0,
    number_of_guides: event.assignedGuides.length,
    source,
    number_of_variants: numberOfVariants,
    numberOfAddons: numberOfAddons,
  };
};
