import isEqual from 'lodash.isequal';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSubmit } from 'react-router';

import { TEventFilter } from '../../../../Utils/eventHelpers';

export type EventListFormInput = TEventFilter & {
  page: number;
};

type EventListFormProps = {
  eventFilters: EventListFormInput;
} & PropsWithChildren;

export const EventListForm = ({
  eventFilters,
  children,
}: EventListFormProps) => {
  const submit = useSubmit();

  const formMethods = useForm<EventListFormInput>({
    defaultValues: eventFilters,
  });

  const { handleSubmit, watch } = formMethods;

  const onSubmit = useCallback(
    (data: EventListFormInput) => {
      if (data.locationId === 'all') {
        delete data['locationId'];
      }

      const { page: queryPage, ...rest } = data;
      const { page: defaultPage, ...defaultRest } = eventFilters;

      // If filters has changed go to first page
      if (!isEqual(rest, defaultRest)) {
        data.page = 1;
      }

      submit(data, {
        method: 'get',
      });
    },
    [eventFilters, submit]
  );

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};
