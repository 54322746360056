import {
  AddOutlined,
  ArticleOutlined,
  ConfirmationNumberOutlined,
  DeleteOutlined,
  EditOutlined,
  FormatListBulleted,
  SnoozeOutlined,
  TagOutlined,
} from '@mui/icons-material';
import { Stack, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme, Text } from '@understory-io/pixel';
import { ExperienceTag } from '@understory-io/utils-types';
import { PropsWithChildren } from 'react';

import { Tooltip } from '../../../Components/tooltip/tooltip';
import { useTranslate } from '../../../Hooks/useTranslate';
import { Language } from '../../../i18n/config';
import routes from '../../../Utils/routes';
import { Experience } from '../schemas';
import { renderDurationString } from '../utils/render-duration';
import PreviewMarkdown from './components/preview-markdown';
import { hasLocalizedValue } from './utils/form-helpers';
import { getTagsLabel } from './utils/get-tags-label';
import { useNavigateWithParams } from './utils/use-navigate-with-params';

type DetailsOverviewProps = {
  experience: Experience;
  activeLanguage: Language;
  tags: ExperienceTag[];
};

export const DetailsOverview = ({
  experience,
  activeLanguage,
  tags,
}: DetailsOverviewProps) => {
  const { t } = useTranslate('experience.edit.details');

  const baseRoute = routes.experience.details(experience.id).edit;

  const navigateWithParams = useNavigateWithParams();

  const variants = experience.price?.variants;
  const hasVariants = variants && variants.length > 0;
  const displayVariant = experience.price?.variants?.find(
    (variant) => variant.id === experience.price?.displayVariantId
  );

  return (
    <Stack sx={{ gap: 1 }}>
      <DetailItem
        title={t('label.tickets')}
        Icon={ConfirmationNumberOutlined}
        onClick={() => navigateWithParams(baseRoute.tickets.index)}
        showAddButton
      >
        {hasVariants && (
          <Stack>
            {variants.map((variant) => (
              <Stack
                key={variant.id}
                sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}
              >
                <Stack
                  sx={{
                    color: lightTheme.palette.neutral.n300,
                    flexDirection: 'row',
                    gap: 1,
                  }}
                >
                  <Text fontSize="small" color="inherit">
                    {variant.name[activeLanguage]}
                  </Text>
                  <Text fontSize="small" color="inherit">
                    {`${variant.priceBreakdown.vatInclusivePriceCents / 100} ${t(
                      variant.priceBreakdown.currency.toUpperCase(),
                      'utils.currency'
                    )}`}
                  </Text>
                </Stack>
                <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                  <EditOutlined
                    sx={{
                      color: lightTheme.palette.neutral.n300,
                      cursor: 'pointer',
                      transition: 'color 0.1s',
                      ':hover': { color: lightTheme.palette.neutral.n500 },
                    }}
                    fontSize="small"
                    onClick={() =>
                      navigateWithParams(baseRoute.tickets.edit(variant.id))
                    }
                  />
                  <DeleteOutlined
                    sx={{
                      color: lightTheme.palette.neutral.n300,
                      cursor: 'pointer',
                      transition: 'color 0.1s',
                      ':hover': { color: lightTheme.palette.neutral.n500 },
                    }}
                    fontSize="small"
                    onClick={() =>
                      navigateWithParams(baseRoute.tickets.delete(variant.id))
                    }
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
      </DetailItem>

      {displayVariant && (
        <DetailItem
          title={t('label.displayPrice')}
          Icon={SnoozeOutlined}
          onClick={() => navigateWithParams(baseRoute.displayPrice)}
          selectedValue={`
            ${displayVariant.name[activeLanguage]} ${displayVariant.priceBreakdown.vatInclusivePriceCents / 100} ${t(
              displayVariant.priceBreakdown.currency.toUpperCase(),
              'utils.currency'
            )}`}
        />
      )}

      <DetailItem
        title={
          experience.practicalInfo[activeLanguage]
            ? 'Description'
            : 'Add a description'
        }
        Icon={ArticleOutlined}
        onClick={() => navigateWithParams(baseRoute.description)}
      >
        {experience.practicalInfo[activeLanguage] && (
          <PreviewMarkdown>
            {experience.practicalInfo[activeLanguage]}
          </PreviewMarkdown>
        )}
      </DetailItem>

      <DetailItem
        title="Cut-off time"
        Icon={SnoozeOutlined}
        onClick={() => navigateWithParams(baseRoute.cutoff)}
        selectedValue={renderDurationString(
          t,
          experience.cutoffTimeSeconds / 60
        )}
      />
      {hasLocalizedValue(experience.whatsIncluded) && (
        <DetailItem
          title="Whats included"
          Icon={FormatListBulleted}
          onClick={() => navigateWithParams(baseRoute.whatsIncluded)}
        >
          {experience.whatsIncluded[activeLanguage] && (
            <PreviewMarkdown>
              {experience.whatsIncluded[activeLanguage]}
            </PreviewMarkdown>
          )}
        </DetailItem>
      )}

      <DetailItem
        title={t('label.tags')}
        Icon={TagOutlined}
        onClick={() => navigateWithParams(baseRoute.tags)}
        selectedValue={getTagsLabel(experience.tagIds, tags, activeLanguage)}
        tooltip={getTagsLabel(experience.tagIds, tags, activeLanguage, true)}
      />
    </Stack>
  );
};

type DetailItemProps = PropsWithChildren & {
  title: string;
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  onClick: () => void;
  selectedValue?: string;
  tooltip?: string;
  showAddButton?: boolean;
};

const DetailItem = ({
  title,
  Icon,
  onClick,
  selectedValue,
  children,
  tooltip,
  showAddButton = false,
}: DetailItemProps) => {
  const hasValue = selectedValue || children;

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        cursor: hasValue ? 'default' : 'pointer',
        padding: 2,
        backgroundColor: lightTheme.palette.contrast.white,
        borderRadius: 1,
        gap: 1,
        transition: 'background-color 0.1s',
        ':hover': !hasValue
          ? {
              backgroundColor: lightTheme.palette.neutral.n100,
            }
          : undefined,
      }}
      onClick={hasValue ? undefined : onClick}
    >
      <Icon sx={{ color: lightTheme.palette.neutral.n300 }} fontSize="small" />
      <Stack
        sx={{
          gap: 1,
          flexGrow: 1,
        }}
      >
        <Text fontSize="small">{title}</Text>
        {children}
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        {selectedValue && (
          <Tooltip title={tooltip}>
            <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
              {selectedValue}
            </Text>
          </Tooltip>
        )}
        {!showAddButton && hasValue ? (
          <EditOutlined
            sx={{
              color: lightTheme.palette.neutral.n300,
              cursor: 'pointer',
              transition: 'color 0.1s',
              ':hover': { color: lightTheme.palette.neutral.n500 },
            }}
            fontSize="small"
            onClick={onClick}
          />
        ) : (
          <AddOutlined
            sx={
              showAddButton
                ? {
                    color: lightTheme.palette.neutral.n300,
                    cursor: 'pointer',
                    transition: 'color 0.1s',
                    ':hover': { color: lightTheme.palette.neutral.n500 },
                  }
                : { color: lightTheme.palette.neutral.n300 }
            }
            fontSize="small"
            onClick={() => (showAddButton ? onClick() : undefined)}
          />
        )}
      </Stack>
    </Stack>
  );
};
