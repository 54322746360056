import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { ExperienceSortOption } from '@understory-io/utils-types';

import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  trackStorefrontCustomizeOrderDropdownOpened,
  trackStorefrontCustomizeOrderDropdownSelected,
} from '../../../../tracking/storefront/customize-events';

export const SelectSortBy = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (selectedOption: ExperienceSortOption) => void;
}) => {
  const { t } = useTranslate('experience.order');

  const label = t('label');

  return (
    <FormControl fullWidth style={{ maxWidth: 200 }} size="small">
      <InputLabel shrink id="sortExperiencesBy">
        {label}
      </InputLabel>
      <Select
        size="small"
        labelId="sortExperiencesBy"
        input={<OutlinedInput notched label={label} />}
        sx={{ minWidth: 220, height: 40, alignSelf: 'flex-start' }}
        MenuProps={{
          autoFocus: false,
        }}
        value={value}
        onChange={(e) => {
          onChange(e.target.value as ExperienceSortOption);
          trackStorefrontCustomizeOrderDropdownSelected(e.target.value);
        }}
        onOpen={trackStorefrontCustomizeOrderDropdownOpened}
      >
        <MenuItem value="alphanumeric">
          {t('alphanumeric', 'experience.sort')}
        </MenuItem>
        <MenuItem value="createdAtDesc">
          {t('createdAtDesc', 'experience.sort')}
        </MenuItem>
        <MenuItem value="createdAtAsc">
          {t('createdAtAsc', 'experience.sort')}
        </MenuItem>
        <MenuItem value="sortOrder">{t('sortOrder')}</MenuItem>
      </Select>
    </FormControl>
  );
};
