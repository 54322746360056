import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CloseRounded } from '@mui/icons-material';
import { CircularProgress, Dialog, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';

interface ConfirmDialogProps {
  title: string;
  description: string;
  cta: string;
  onConfirm: () => void | Promise<void>;
}

export const ConfirmDialog = NiceModal.create<ConfirmDialogProps>(
  ({ title, description, cta, onConfirm }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslate('utils.generic');
    const { isSm } = useResponsive();
    const modal = useModal();

    const handleClose = () => {
      modal.reject('confirm-dialog');
      modal.hide();
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        fullScreen={isSm}
      >
        <Stack gap={2} p={4} height={'100%'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
            <Stack gap={1}>
              <Text fontSize="xlarge" variant="medium">
                {title}
              </Text>
              <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
                {description}
              </Text>
            </Stack>
            <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </Stack>
          <Stack
            gap={isSm ? 1 : 2}
            width={'100%'}
            direction={isSm ? 'column-reverse' : 'row'}
          >
            <Button
              variant="secondary"
              size="medium"
              onClick={handleClose}
              style={{ flexGrow: 1, maxWidth: isSm ? '100%' : '50%' }}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="secondary"
              size="medium"
              type="submit"
              style={{
                flexGrow: 1,
                color: lightTheme.palette.error.e400,
                maxWidth: isSm ? '100%' : '50%',
              }}
              onClick={async () => {
                setIsLoading(true);
                try {
                  await onConfirm();
                  handleClose();
                } finally {
                  setIsLoading(false);
                }
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size="1em" color="inherit" />
              ) : (
                cta
              )}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);
