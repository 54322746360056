import { Box } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { PropsWithChildren } from 'react';

export const CardContainer = ({ children }: PropsWithChildren) => {
  return (
    <Box
      sx={{
        padding: { sm: '16px', lg: '32px 56px 48px 40px' },
        backgroundColor: lightTheme.palette.contrast.white,
        borderRadius: '16px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: lightTheme.palette.neutral.n100,
      }}
    >
      {children}
    </Box>
  );
};
