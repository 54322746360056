import { addMinutes, toISODateTime } from '@understory-io/utils-date';
import { format, parseISO, set } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DateInput } from '../../../../../../../Components/inputs/date-input';
import { trackEventFlowStartDateOpened } from '../../../../../../../tracking/events/flow/time/form/trackEventFlowStartDateOpened';
import { trackEventFlowStartDateSelected } from '../../../../../../../tracking/events/flow/time/form/trackEventFlowStartDateSelected';
import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { nextInterval, parseTime } from './event_upsert_time_form';
import { EventUpsertTimeFormTypes } from './use_time_form_validation';

export const EventUpsertTimeFormStartDateField = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();
  const {
    control,
    setValue,
    formState: { errors },
    setError,
  } = useFormContext<
    Pick<EventUpsertTimeFormTypes, 'startDateTime' | 'endDateTime'>
  >();

  const handleChangeDate = (dateInput: string | undefined) => {
    if (!dateInput)
      return setError('startDateTime', {
        type: 'required',
        message: t('eventUpsert.flow.time.form.errors.required'),
      });

    const [hours, minutes] = parseTime(
      format(parseISO(currentEvent.startDateTime), 'HH:mm')
    );

    const date = set(parseISO(dateInput), { hours, minutes });

    const updatedEndDateTime = nextInterval(
      addMinutes(date, currentEvent.startEndTimeDiff),
      5
    );

    setValue('startDateTime', toISODateTime(date), { shouldValidate: true });
    setValue('endDateTime', toISODateTime(updatedEndDateTime));
    updateEventField('startDateTime', toISODateTime(date));
    updateEventField('endDateTime', toISODateTime(updatedEndDateTime));

    trackEventFlowStartDateSelected(date);
  };

  const onClickField = () => {
    trackEventFlowStartDateOpened();
  };

  const hasBookings = currentEvent.bookings && currentEvent.bookings.length > 0;

  return (
    <Controller
      name="startDateTime"
      control={control}
      render={({ field }) => (
        <DateInput
          label={t('eventUpsert.flow.time.form.startDate.label')}
          {...field}
          onChange={handleChangeDate}
          onClick={onClickField}
          minDate={format(new Date(), 'yyyy-MM-dd')}
          required
          disabled={hasBookings}
          error={!!errors.startDateTime}
          helperText={errors.startDateTime?.message}
        />
      )}
    />
  );
};
