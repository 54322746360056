import * as yup from 'yup';

import { locationSchema } from './locationSchema';
import { visibilitySchema } from './visibilitySchema';

const HEADLINE_MAX_LENGTH = 50;

export const basicExperienceFieldsSchema = yup.object().shape({
  headline: yup.string().max(HEADLINE_MAX_LENGTH).required(),
  locationIds: locationSchema,
  visibility: visibilitySchema,
});

export type BasicExperienceFields = yup.InferType<
  typeof basicExperienceFieldsSchema
>;
