import { Stack } from '@mui/material';
import { Button, LinkButton, Text } from '@understory-io/pixel';
import { Experience } from '@understory-io/utils-types';
import { differenceInDays, startOfDay } from 'date-fns';
import randomBytes from 'randombytes';
import { useEffect } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import {
  ActionFunctionArgs,
  LoaderFunction,
  redirect,
  useHref,
  useLoaderData,
  useNavigate,
  useNavigation,
} from 'react-router';
import { useLinkClickHandler, useSubmit } from 'react-router';
import { toast } from 'react-toastify';

import {
  acceptConnectionRequest,
  getExperiences,
  getInvitation,
} from '../../../Api';
import { getPublicCompanyProfile } from '../../../Api';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useTranslate } from '../../../Hooks/useTranslate';
import { t } from '../../../i18n/config';
import {
  trackAcceptConnectionCompleted,
  trackAcceptConnectionStarted,
} from '../../../tracking/connect/connect-events';
import { DialogWrapper } from '../components/dialog-wrapper';
import {
  SelectExperiences,
  SelectExperiencesInputs,
} from '../components/select-experiences';
import { connectStore } from '../connect-store';

type LoaderData = {
  companyName: string;
  experiences: Promise<Experience[]>;
};
export const loader: LoaderFunction<LoaderData> = async ({ params }) => {
  if (!params.token) {
    return {
      status: 404,
    };
  }
  const invitationItem = await getInvitation(params.token);

  if (!invitationItem) {
    return {
      status: 404,
    };
  }

  const { name } = await getPublicCompanyProfile(
    invitationItem.connectedCompanyId
  );

  // Intentionally not awaited. Returned as a promise to defer loading to after render.
  const experiences = getExperiences({
    type: 'owned',
  });

  return {
    companyName: name,
    experiences,
  };
};

export async function action({ request, params }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('connect.dialog.accept.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    const { share, experiences } =
      (await request.json()) as AcceptDialogFormInputs;

    const token = params.token as string;

    // Get invitation to use for tracking later.
    // Needs to be fetched here because the token is cleared after accepting.
    const connectionRequest = await getInvitation(token);
    if (!connectionRequest) {
      throw new Error(`Could not find connection request with token ${token}`);
    }

    await acceptConnectionRequest(
      token,
      share === 'selection' ? experiences : share
    );

    connectStore.getState().removeInvitation(token);

    toast.dismiss(loadingToastId);
    toast.success(t('connect.dialog.accept.toast.success'), {
      autoClose: 5000,
    });

    const shareableExperiences = await getExperiences({ type: 'owned' });
    const sharedCount =
      share === 'all'
        ? shareableExperiences.length
        : share === 'selection'
          ? experiences.length
          : 0; // 'none' = 0

    trackAcceptConnectionCompleted(
      share,
      shareableExperiences.length,
      sharedCount,
      differenceInDays(
        startOfDay(new Date()),
        startOfDay(new Date(connectionRequest.createdAt))
      )
    );

    return redirect('../');
  } catch (error) {
    console.error(error);
    toast.dismiss(loadingToastId);
    toast.error(t('connect.dialog.accept.toast.error'), { delay: 500 });
    return {
      success: false,
    };
  }
}

type AcceptDialogFormInputs = SelectExperiencesInputs;

export const AcceptConnectionDialog = () => {
  const { t } = useTranslate('connect.dialog.accept');
  const { isSm } = useResponsive();
  const { companyName, experiences } = useLoaderData() as LoaderData;
  const navigate = useNavigate();
  const { location, state } = useNavigation();
  const submit = useSubmit();
  const closeHref = useHref(`../`);
  const cancelLinkClicked = useLinkClickHandler(closeHref);
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() => trackAcceptConnectionStarted());
  }, [fireOnce]);

  const formMethods = useForm<AcceptDialogFormInputs>({
    defaultValues: { share: 'all', experiences: [] },
  });

  const { handleSubmit, watch } = formMethods;

  const share = watch('share');
  const selectedExperiences = watch('experiences');

  const disableButton =
    state === 'submitting' ||
    state === 'loading' ||
    (share === 'selection' && selectedExperiences.length === 0);

  const handleClose = () => {
    navigate(closeHref);
  };

  const onSubmit = (data: FieldValues) => {
    submit(data, {
      method: 'post',
      action: location?.pathname,
      encType: 'application/json',
    });
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('title', { account: companyName })}
    >
      <FormProvider {...formMethods}>
        <Stack
          gap={4}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Text variant="normal" fontSize="small">
            {t('description')}
          </Text>

          <SelectExperiences experiences={experiences} />

          <Stack
            gap={2}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={{ xs: 'stretch', md: 'stretch' }}
          >
            <LinkButton
              href={closeHref}
              onClick={cancelLinkClicked}
              variant="secondary"
              size="large"
              fullWidth
              style={{
                flexShrink: 'unset',
              }}
            >
              {t('cancel')}
            </LinkButton>
            <Button
              type="submit"
              disabled={disableButton}
              variant="primary"
              size="large"
              fullWidth
              style={{
                flexShrink: 'unset',
              }}
            >
              {t('confirm')}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </DialogWrapper>
  );
};
