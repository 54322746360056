import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CompanyProfile, StorefrontFeatures } from '@understory-io/utils-types';
import isEmpty from 'lodash.isempty';
import { useCallback, useMemo } from 'react';
import { object, string } from 'yup';

import * as api from '../Api';
import { refreshToken } from '../Api';
import { localizedSchema } from '../Pages/SyiPage/config';
import { useAuthStore } from '../Store/useAuthStore';
import { EXPERIENCE_URL } from '../Utils/constants';

export const meSchema = object().shape({
  name: string().required(),
  email: string().required(),
  phone: string().required(),
  description: localizedSchema(true),
  pictures: object()
    .shape({
      profile: object().required(),
    })
    .required(),
});

export const companySchema = object().shape({
  name: string().required(),
  companyPhone: string().required(),
  companyEmail: string().required(),
  description: localizedSchema(true),
  cvrNr: string().required(),
  location: object()
    .shape({
      address: string().required(),
      city: string().required(),
      zipCode: string().required(),
    })
    .required(),
  pictures: object()
    .shape({
      logo: object().required(),
      cover: object().required(),
    })
    .required(),
});

export interface IMyInfo extends Record<string, any> {
  name?: string;
  email?: string;
  phone?: string;
}

const METADATA_KEY = 'metadata';
const PREFERENCES_KEY = 'preferences';

export enum SignupType {
  AISignup = 'AI-signup',
  SelfSignup = 'self-signup',
  InviteSignup = 'invite-signup',
}

const UserInfoQueryKey = ['userinfo'];

export const userInfoQuery = () => ({
  queryKey: UserInfoQueryKey,
  queryFn: () => api.getUserinfo(),
});

export const useProfile = () => {
  const queryClient = useQueryClient();
  const { auth, setAuth } = useAuthStore();

  const CompanyQueryKey = ['company'];
  const MeQueryKey = ['me'];

  const userinfo = useQuery({
    ...userInfoQuery(),
    enabled: Boolean(auth),
    retry: Boolean(auth),
  });

  const company = useQuery({
    queryKey: CompanyQueryKey,
    queryFn: () => api.getCompanyProfile(),
    enabled: Boolean(auth) && Boolean(userinfo.data?.org),
    retry: true,
  });

  const me = useQuery({
    queryKey: MeQueryKey,
    queryFn: () => api.getMyProfile(),
    enabled: Boolean(auth),
    retry: true,
  });

  const companyProfileUrl = useCallback(
    (domain?: string) => {
      if (domain || company.data?.domains) {
        return `${EXPERIENCE_URL?.replaceAll('{{domain}}', domain ?? company.data?.domains?.[0] ?? '')}`;
      }
      return '';
    },
    [company]
  );

  const myRole = useMemo(() => {
    return userinfo.data?.role ?? '';
  }, [userinfo]);

  const onboardingCompleted = useMemo(() => {
    if (company.data) {
      return company.data.onboardingCompleted === true;
    }
  }, [company]);

  const updateCompanyFeature = useMutation({
    mutationFn: (variables: {
      feature: keyof StorefrontFeatures;
      payload: StorefrontFeatures[keyof StorefrontFeatures];
    }) => {
      return api.updateCompanyFeature(variables.feature, variables.payload);
    },

    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: CompanyQueryKey,
      });

      const previousCompany =
        queryClient.getQueryData<CompanyProfile>(CompanyQueryKey);

      queryClient.setQueryData<CompanyProfile>(CompanyQueryKey, (prev) => {
        const changedFeature = {
          [variables.feature]: variables.payload,
        } as Partial<StorefrontFeatures>;

        return {
          ...prev!,
          features: {
            ...prev?.features,
            ...changedFeature,
          },
        };
      });

      return { previousCompany };
    },

    onError: (err, variables, context) => {
      if (context?.previousCompany) {
        queryClient.setQueryData<CompanyProfile>(
          CompanyQueryKey,
          context.previousCompany
        );
      }
    },

    onSuccess: async (data, variables, context) => {
      if (isEmpty(context?.previousCompany) && auth?.refresh_token) {
        const tokens = await refreshToken(auth.refresh_token, company.data?.id);
        setAuth({ ...tokens });
      }
    },

    onSettled: async (data, err, vars, context) => {
      if (
        isEmpty(context?.previousCompany) &&
        auth?.refresh_token &&
        company.data?.id
      ) {
        const tokens = await refreshToken(auth.refresh_token, company.data?.id);
        setAuth({ ...tokens });
      }
      queryClient.invalidateQueries({
        queryKey: CompanyQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: UserInfoQueryKey,
      });
    },
  });

  const updateCompany = useMutation({
    mutationFn: (data: Partial<CompanyProfile>) => {
      return api.updateCompanyProfile(data, !userinfo.data?.org);
    },

    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: CompanyQueryKey,
      });

      const previousCompany =
        queryClient.getQueryData<CompanyProfile>(CompanyQueryKey);

      queryClient.setQueryData<CompanyProfile>(CompanyQueryKey, (prev) => {
        return { ...prev!, ...data };
      });

      return { previousCompany };
    },

    onError: (err, variables, context: any) => {
      if (context?.previousCompany) {
        queryClient.setQueryData<CompanyProfile>(
          CompanyQueryKey,
          context.previousDetails
        );
      }
    },

    onSuccess: async (data, variables, context) => {
      if (isEmpty(context?.previousCompany) && auth?.refresh_token) {
        const tokens = await refreshToken(auth.refresh_token, data.id);
        setAuth({ ...tokens });
      }
    },

    onSettled: async (data, err, vars, context) => {
      if (
        isEmpty(context?.previousCompany) &&
        auth?.refresh_token &&
        data?.id
      ) {
        const tokens = await refreshToken(auth.refresh_token, data?.id);
        setAuth({ ...tokens });
      }
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: CompanyQueryKey,
        });
        queryClient.invalidateQueries({
          queryKey: UserInfoQueryKey,
        });
      }, 500);
    },
  });

  const updateMe = useMutation({
    mutationFn: (data: IMyInfo) => api.updateMyProfile(data),

    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: MeQueryKey,
      });

      const previousDetails = queryClient.getQueryData<IMyInfo>(MeQueryKey);

      queryClient.setQueryData<IMyInfo>(MeQueryKey, (prev) => {
        return { ...prev!, ...data };
      });

      return { previousDetails };
    },

    onError: (err, variables, context: any) => {
      if (context?.previousDetails) {
        queryClient.setQueryData<IMyInfo>(MeQueryKey, context.previousDetails);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: MeQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: UserInfoQueryKey,
      });
    },
  });

  const updatePreferences = useCallback(
    <T,>(preferenceKey: string, fn: (previousValue: T) => T) => {
      const { data } = me ?? {};
      if (!data) return;

      const metadata = data[METADATA_KEY] ?? {};
      const preferences = metadata[PREFERENCES_KEY] ?? {};

      const newProfile = {
        ...data,
        [METADATA_KEY]: {
          ...metadata,
          [PREFERENCES_KEY]: {
            ...preferences,
            [preferenceKey]: fn(preferences[preferenceKey]),
          },
        },
      };

      updateMe.mutate(newProfile);
    },
    [me, updateMe]
  );

  const finishOnboarding = useMutation({
    mutationFn: () => {
      const existing =
        queryClient.getQueryData<CompanyProfile>(CompanyQueryKey);
      return api.updateCompanyProfile({
        ...existing!,
        onboardingCompleted: true,
      });
    },

    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: CompanyQueryKey,
      });

      const previous =
        queryClient.getQueryData<CompanyProfile>(CompanyQueryKey);

      queryClient.setQueryData<CompanyProfile>(CompanyQueryKey, (prev) => {
        return {
          ...prev!,
          onboardingCompleted: true,
        };
      });

      return { previous };
    },

    onError: (err, variables, context: any) => {
      queryClient.setQueryData<CompanyProfile>(
        CompanyQueryKey,
        context.previous
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: CompanyQueryKey,
      });
    },
  });

  const firstLogin = useMutation({
    mutationFn: () => api.firstLogin(),

    onMutate: async (id: string) => {
      await queryClient.cancelQueries({
        queryKey: CompanyQueryKey,
      });

      const previousUsers =
        queryClient.getQueryData<CompanyProfile>(CompanyQueryKey);

      queryClient.setQueryData<CompanyProfile>(CompanyQueryKey, (prev) => {
        return {
          ...prev!,
          users: [...(prev?.users ?? []), id],
        };
      });

      return { previousUsers };
    },

    onError: (err, variables, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData<CompanyProfile>(
          CompanyQueryKey,
          context.previousUsers
        );
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: CompanyQueryKey,
      });
    },
  });

  const defaultCurrency = useMemo(() => {
    return company.data?.defaultCurrency?.toUpperCase() ?? 'DKK';
  }, [company.data]);

  const preferences = useMemo(
    () => me.data?.[METADATA_KEY]?.[PREFERENCES_KEY] ?? {},
    [me.data]
  );

  return {
    company,
    me,
    updateCompany,
    updateMe,
    firstLogin,
    userinfo,
    UserInfoQueryKey,
    companyProfileUrl,
    myRole,
    finishOnboarding,
    onboardingCompleted,
    defaultCurrency,
    preferences,
    updatePreferences,
    updateCompanyFeature,
  };
};
