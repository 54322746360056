import { useCallback } from 'react';
import { useSearchParams } from 'react-router';

import { changeSearchParam } from '../../../../Utils/search-params';
import { CalendarView } from '../../context';
import { serializers } from '../../search-params/calendar-search-params';

export const useCalendarLink = () => {
  const [searchParams] = useSearchParams();

  return useCallback(
    (date: Date, view: CalendarView) => {
      const params = changeSearchParam(searchParams, 'view', view);
      params['date'] = serializers.date.serialize(date);

      return `?${new URLSearchParams(params).toString()}`;
    },
    [searchParams]
  );
};
