import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { State } from './use_edit_participants_reducer';

type EventsListItemEditParticipantsFieldIconProps = { state: State };

const iconStyles = {
  saving: { color: 'black' },
  success: { color: lightTheme.palette.success.s400 },
  error: { color: lightTheme.palette.error.e400 },
};

export const EventsListItemEditParticipantsFieldIcon = ({
  state,
}: EventsListItemEditParticipantsFieldIconProps) => {
  switch (true) {
    case state.isSaving:
      return <CircularProgress style={iconStyles.saving} size={14} />;
    case state.isSaved:
      return <DoneIcon style={iconStyles.success} fontSize="small" />;
    case state.isError:
      return <ErrorOutlineIcon style={iconStyles.error} fontSize="small" />;
    default:
      return <DoneIcon fontSize="small" />;
  }
};
