import { EventStateFilter } from '@understory-io/utils-types';

import { ICancelEventData } from '../Modals/cancel_event/domain/types';
import { fetchAllPages } from '../Utils/api-pagination';
import { IEvent, TEvent, TEventFilter } from '../Utils/eventHelpers';
import { apiServices } from './apiServices';
import api, { IShallowSuccessResponse } from './index';

export type EventOptions = {
  from?: Date;
  to?: Date;
  locationIds?: string;
  states?: EventStateFilter;
};

export const getEvents = (options?: EventOptions) => {
  if (typeof apiServices.events === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  return fetchAllPages<TEvent>(api, apiServices.events, options);
};

export const getEvent = async (id: string) => {
  if (typeof apiServices.events === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data } = await api.get<TEvent>(`${apiServices.events}/${id}`);

  return data;
};

export const getEventsInExperience = async (
  experienceId: string,
  offset: number,
  limit: number,
  eventFilter?: TEventFilter
) => {
  if (typeof apiServices.events === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data, headers } = await api.get<TEvent[]>(
    `${apiServices.events}/experience/${experienceId}`,
    {
      params: {
        limit,
        offset,
        filter: eventFilter?.state,
        locationIds: eventFilter?.locationId,
        states:
          eventFilter?.status === 'anyStatus' ? undefined : eventFilter?.status,
      },
    }
  );

  return { events: data, totalCount: parseInt(headers['x-total-count'], 10) };
};

export const updateEvent = async (
  payload: Record<string, any>,
  id?: string
) => {
  if (!id) {
    throw new Error('No id');
  }
  if (typeof apiServices.events === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const response = await api.put<{ id: string }>(
    `${apiServices.events}/${id}`,
    payload
  );

  return response.data;
};

export const addExcludedDate = async (dateTime: string, id?: string) => {
  if (!id) {
    throw new Error('No id');
  }
  if (typeof apiServices.events === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }
  await api.put<IShallowSuccessResponse>(
    `${apiServices.events}/${id}/exclude`,
    { dateTime }
  );
};

export const manifestVirtualEvent = async (
  id: string,
  parentId: string,
  payload: unknown
) => {
  if (!id || !parentId) {
    throw new Error('No ids');
  }
  if (typeof apiServices.events === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }
  await api.put<IShallowSuccessResponse>(
    `${apiServices.events}/${parentId}/manifest/${id}`,
    payload
  );
};

export const deleteEvent = async (id: string) => {
  if (typeof apiServices.events === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data } = await api.delete<IShallowSuccessResponse>(
    `${apiServices.events}/${id}`
  );

  return data;
};

export const cancelEvent = async (payload: ICancelEventData, id?: string) => {
  if (!id) {
    throw new Error('No id');
  }
  if (typeof apiServices.events === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data } = await api.post<{
    event: IEvent;
    message?: string;
  }>(`${apiServices.events}/${id}/cancel`, payload);

  return data;
};
