import { lightTheme } from '@understory-io/pixel';
import { isToday as isTodayFn, isWeekend as isWeekendFn } from 'date-fns';

export const getGroupedBackgroundColor = (date: Date, isHeader?: boolean) => {
  const isToday = isTodayFn(date);
  const isWeekend = isWeekendFn(date);

  if (isHeader && isToday) return lightTheme.palette.action.a300;
  if (isToday) return `${lightTheme.palette.action.a200}40`;
  if (isWeekend) return `${lightTheme.palette.neutral.n100}80`;
  return 'transparent';
};
