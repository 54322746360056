import { CloseRounded } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Stack,
} from '@mui/material';
import { Text } from '@understory-io/pixel';
import { PropsWithChildren } from 'react';

type DialogWrapperProps = PropsWithChildren &
  Pick<DialogProps, 'maxWidth' | 'fullWidth' | 'fullScreen' | 'open'> & {
    title: string;
    onClose?: () => void;
  };

export const DialogWrapper = ({
  title,
  onClose,
  children,
  ...props
}: DialogWrapperProps) => {
  return (
    <Dialog onClose={onClose} {...props}>
      <DialogContent sx={{ padding: 4 }}>
        <DialogHeader title={title} onClose={onClose} />
        {children}
      </DialogContent>
    </Dialog>
  );
};

type DialogHeaderProps = Pick<DialogWrapperProps, 'title' | 'onClose'>;

const DialogHeader = ({ title, onClose }: DialogHeaderProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" marginBottom={1}>
      <Text fontSize="xlarge" variant="medium">
        {title}
      </Text>
      {onClose ? (
        <IconButton sx={{ padding: 0 }} onClick={onClose} tabIndex={-1}>
          <CloseRounded sx={{ fontSize: { xs: '24px', md: '32px' } }} />
        </IconButton>
      ) : null}
    </Stack>
  );
};
