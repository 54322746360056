import { Experience } from '@understory-io/utils-types';

/**
 * Sorts experiences according to metadata.sortOrder
 * @param experiences
 * @returns Experiences[]
 */
export const getSortOrder = (experiences: Experience[]) => {
  return [...experiences].sort((a, b) => {
    // Experiences without sortOrder should be moved to the end
    // 1. Neither exists, ignore
    // 2. a first if b doesn't exist
    // 3. b first if a doesn't exist
    if (!a.metadata?.sortOrder && !b.metadata?.sortOrder) {
      return 0;
    } else if (!b.metadata?.sortOrder) {
      return -1;
    } else if (!a.metadata?.sortOrder) {
      return 1;
    }
    return a.metadata.sortOrder - b.metadata.sortOrder;
  });
};

/**
 * @param experiences
 * @returns string[] - Array of experienceIds
 */
export const getOrder = (experiences: Experience[]) => {
  return experiences.flatMap(({ id }) => id);
};
