import { BookingStatus } from '@understory-io/utils-types';
import { useMemo } from 'react';

import { useGetBookingsForEvent } from '../../../Hooks/data/useBookings';
import { useLanguages } from '../../../Hooks/locales/use-languages';

const CANCELLABLE_BOOKING_STATUSES: BookingStatus[] = [
  'active',
  'unpaid',
  'checked-in',
];

export const useCancelEventLanguages = (eventId: string) => {
  const {
    bookingsForEvent: { data: bookingsForEvent, isLoading: isBookingsLoading },
  } = useGetBookingsForEvent(eventId);

  const languages = Array.from(
    new Set(
      (bookingsForEvent ?? [])
        ?.filter(
          ({ language, status }) =>
            Boolean(language) && CANCELLABLE_BOOKING_STATUSES.includes(status)
        )
        .map(({ language }) => language as string)
    )
  );

  const {
    languages: { data: supportedLanguages },
  } = useLanguages();

  const selectedLanguages = useMemo(
    () => mapSelectedLanguages(languages, supportedLanguages),
    [languages, supportedLanguages]
  );

  return {
    languages,
    isBookingsLoading,
    selectedLanguages,
  };
};

const mapSelectedLanguages = (
  selected: string[],
  langs: ReturnType<typeof useLanguages>['languages']['data']
) => {
  return selected.map((key) => {
    const k = key === 'se' ? 'sv' : key;
    const lang = langs?.items.find((item) => item.code === k);
    return { key: k, label: lang?.name ?? '', icon: lang?.flagSrc ?? '' };
  });
};
