import {
  AddRounded,
  HelpOutlineRounded,
  KeyboardArrowDown,
  RemoveRounded,
} from '@mui/icons-material';
import {
  Box,
  BoxProps,
  capitalize,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  Popover,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { FC, ReactNode, useMemo, useState } from 'react';

import { TCreateBookingPayload } from '../../Hooks/useBookings';
import { useProducts } from '../../Hooks/useProducts';
import { useProfile } from '../../Hooks/useProfile';
import { TranslateFunction, useTranslate } from '../../Hooks/useTranslate';
import {
  cleanupGuestSelection,
  getGuestCount,
  isAddonId,
} from '../../Utils/eventHelpers';
import { SimpleSkeleton } from '../SimpleSkeleton/SimpleSkeleton';

export const formatMoney =
  (t: TranslateFunction, showZero = false) =>
  ({
    value,
    nativeCurrency = 'dkk',
  }: {
    value: number;
    nativeCurrency: string;
  }) => {
    if (value === undefined) return '';
    if ((value === 0 || value === null) && !showZero) return t('free');
    return value.toLocaleString(nativeCurrency, {
      style: 'currency',
      currency: nativeCurrency,
      maximumFractionDigits: 0,
    });
  };

export const getUnitCount = (count: number, seatProps?: object | null) => {
  const { max = 1, type = 'single' } = seatProps ?? ({} as any);
  const _m = type === 'couple' ? 2 : max === null ? 1 : max;
  return Math.floor(count / _m) + (count % _m > 0 ? 1 : 0);
};

type Props = {
  experienceId: string;
  maxGuestCount: number;
  required?: boolean;
  onChange: (value: { [k: string]: number }) => void;
  errorMessage?: string;
  defaultValue?: TCreateBookingPayload['items'];
};

export const VariantSelect: FC<Props> = ({
  experienceId,
  required = false,
  maxGuestCount,
  onChange,
  errorMessage,
  defaultValue,
}) => {
  const { t } = useTranslate('utils.generic');

  const { products, variants } = useProducts(experienceId);

  const [guests, setGuests] = useState<{ [k: string]: number }>(
    defaultValue ?? {}
  );

  const [showPopover, setShowPopover] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement>();

  const { defaultCurrency } = useProfile();

  const totalAmount = useMemo(() => {
    return Object.entries(guests as { [k: string]: number }).reduce(
      (acc, [id, count]) => {
        const { price = 0 } = products?.find((el) => el.id === id) ?? {};
        return acc + count * price;
      },
      0
    );
  }, [guests, products]);

  const handleSubmit = () => {
    setShowPopover(false);
    onChange(guests);
  };

  const currentGuestCount = getGuestCount(guests);

  const maximumGuestCountPerBooking = Math.max(
    0,
    ...variants.map((variant) => variant.seatProps.max ?? Infinity)
  );

  const maxAvailable = Math.max(
    0,
    Math.min(maxGuestCount, maximumGuestCountPerBooking) - currentGuestCount
  );

  const minimumRequiredSeats = Math.max(
    ...variants.map((variant) => variant.seatProps.min ?? 0)
  );

  const handleStep =
    (key: string) =>
    (direction: 'up' | 'down', step = 1) =>
    () => {
      setGuests((currentGuests) => {
        if (direction === 'up' && step > maxAvailable && !isAddonId(key)) {
          return currentGuests;
        }

        const currentValue = currentGuests[key] ?? 0;
        const newValue = currentValue + (direction === 'up' ? step : -step);

        const nextGuests = {
          ...currentGuests,
          [key]: Math.max(0, newValue),
        };

        return cleanupGuestSelection(nextGuests, products);
      });
    };

  const handleClickGuests = () => {
    setShowPopover(true);
  };

  return (
    <Box position={'relative'} width={'100%'}>
      <Box
        component={FormControl}
        width={'100%'}
        onClick={handleClickGuests}
        error={!!errorMessage}
        sx={{
          cursor: 'pointer',
          '&:hover fieldset': { borderColor: '#1A2027' },
        }}
      >
        <InputLabel
          required={required}
          sx={{ background: 'white', textTransform: 'capitalize' }}
          htmlFor="time"
        >
          {t('guests')}
        </InputLabel>
        <OutlinedInput
          ref={(ref) => setAnchor(ref as HTMLElement)}
          size={'medium'}
          fullWidth
          sx={{
            width: '100%',
            pointerEvents: 'none',
            pr: 0.5,
            fontSize: { xs: '0.9em' },
          }}
          endAdornment={
            <KeyboardArrowDown htmlColor="#636366" sx={{ mr: 0.4 }} />
          }
          value={
            currentGuestCount > 0
              ? `${currentGuestCount} ${t(`guest`)}${
                  currentGuestCount > 1 ? t('plural') : ''
                } - ${formatMoney(t)({
                  value: totalAmount,
                  nativeCurrency: defaultCurrency,
                })}`
              : t('selectGuests')
          }
          id="guests"
        />

        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </Box>
      <Popover
        open={showPopover}
        onClose={handleSubmit}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack spacing={2} p={3} width={anchor?.clientWidth ?? 'auto'}>
          {variants?.map((el) => {
            return (
              <Box key={el.id}>
                <ProductRow
                  label={
                    el.name ??
                    el.seatProps?.customName ??
                    capitalize(t(el.seatProps?.type ?? ''))
                  }
                  price={formatMoney(t)({
                    value: el.price,
                    nativeCurrency: el.currency,
                  })}
                  maxAvailable={maxAvailable}
                  step={el.seatProps?.type === 'couple' ? 2 : 1}
                  count={guests?.[el.id] ?? 0}
                  onStep={handleStep(el.id)}
                />

                {Boolean(guests?.[el.id]) &&
                  el.addons?.map((ad) => (
                    <ProductRow
                      key={ad.id}
                      label={ad.name}
                      explanation={ad.explanation}
                      price={formatMoney(t)({
                        value: ad.price,
                        nativeCurrency: ad.currency,
                      })}
                      count={guests?.[ad.id] ?? 0}
                      onStep={handleStep(ad.id)}
                      p={1.5}
                      mt={1}
                      borderRadius={1}
                      border={'1px dashed #C7C7CC'}
                    />
                  ))}
              </Box>
            );
          }) ?? <SimpleSkeleton />}
          {currentGuestCount > 0 &&
            minimumRequiredSeats > currentGuestCount && (
              <Text
                textAlign="center"
                fontSize="small"
                color={lightTheme.palette.error.e200}
              >
                {t(
                  'minSeatsBookedSlots',
                  'eventUpsert.flow.details.form.errors',
                  {
                    totalBookedSlots: minimumRequiredSeats,
                  }
                )}
              </Text>
            )}
        </Stack>
      </Popover>
    </Box>
  );
};

const ProductRow = ({
  label,
  price,
  explanation,
  step = 1,
  maxAvailable = 100,
  count,
  onStep,
  ...props
}: BoxProps & {
  label: string;
  explanation?: string;
  count: number;
  maxAvailable?: number;
  step?: number;
  onStep: (direction: 'up' | 'down', step?: number) => () => void;
  price: string;
}) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      {...props}
    >
      <Box>
        <Typography
          variant={'h5'}
          fontSize={'1.1em'}
          display="flex"
          alignItems="center"
        >
          {label}
          {explanation && (
            <Tooltip title={explanation}>
              <HelpOutlineRounded
                sx={{ pl: 0.5 }}
                fontSize="medium"
                htmlColor="#8E8E93"
              />
            </Tooltip>
          )}
        </Typography>
        <Typography variant={'body2'} color={'grey.700'}>
          {price}
        </Typography>
      </Box>
      <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
        <IconButton
          size={'small'}
          disabled={count === 0}
          onClick={onStep('down', step)}
          sx={{ border: '1px solid', borderColor: '#C7C7CC' }}
        >
          <RemoveRounded fontSize={'small'} />
        </IconButton>
        <Typography variant={'h4'} textAlign={'center'} width={'40px'}>
          {count}
        </Typography>
        <IconButton
          size={'small'}
          disabled={step > maxAvailable}
          onClick={onStep('up', step)}
          sx={{ border: '1px solid', borderColor: '#C7C7CC' }}
        >
          <AddRounded fontSize={'small'} />
        </IconButton>
      </Stack>
    </Box>
  );
};

export const Row = ({
  left,
  right,
  leftProps = {},
  rightProps = {},
  ...props
}: BoxProps<
  any,
  {
    left: string | ReactNode;
    right?: string | ReactNode;
    leftProps?: TypographyProps;
    rightProps?: TypographyProps;
  }
>) => (
  <Box display={'flex'} justifyContent={'space-between'} {...props}>
    {typeof left === 'string' ? (
      <Typography {...leftProps}>{left}</Typography>
    ) : (
      left
    )}
    {typeof right === 'string' ? (
      <Typography {...rightProps}>{right}</Typography>
    ) : (
      right
    )}
  </Box>
);
