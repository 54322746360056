import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { endOfWeek, format, isSameMonth, startOfWeek } from 'date-fns';
import { FC, useMemo } from 'react';

import { useTranslate } from '../../../Hooks/useTranslate';
import { CalendarView, useCalendar } from '../context';

const DATE_WITHOUT_MONTH = 'd.';
const DATE_WITH_SHORT_MONTH = `${DATE_WITHOUT_MONTH} MMM`;

type Props = {
  interval: CalendarView;
};

export const MonthSelectorLabel: FC<Props> = ({ interval }) => {
  const { selectedDate } = useCalendar();

  const { t } = useTranslate('utils.generic');

  const monthSelectorLabel = useMemo(() => {
    if (interval === 'day') {
      return format(selectedDate, 'eeee, d. LLLL yyyy');
    }

    if (interval === 'week') {
      const weekStart = startOfWeek(selectedDate, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(selectedDate, { weekStartsOn: 1 });

      const sameMonth = isSameMonth(weekStart, weekEnd);

      return (
        <Stack direction="row" gap={1.5} alignItems="center">
          <Text>
            {t('week')} {format(weekStart, 'w')}
          </Text>
          {format(
            weekStart,
            sameMonth ? DATE_WITHOUT_MONTH : DATE_WITH_SHORT_MONTH
          )}
          -{format(weekEnd, `${DATE_WITH_SHORT_MONTH} yyyy`)}
        </Stack>
      );
    }

    return format(selectedDate, 'LLLL yyyy');
  }, [selectedDate, interval, t]);

  return (
    <Text
      fontSize="large"
      variant="medium"
      textTransform="capitalize"
      style={{ lineHeight: 1 }}
    >
      {monthSelectorLabel}
    </Text>
  );
};
