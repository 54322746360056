import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { Text } from '@understory-io/pixel';
import { format, intervalToDuration, set } from 'date-fns';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { isIntervalSelected } from '../../../../domain/types';
import { generateTimeOptions } from './event_upsert_time_form';

type EventUpsertTimeFormIntervalFieldProps = {
  index: number;
  fromTime: string;
  toTime: string;
  onDelete: (index: number) => void;
};

export const EventUpsertTimeFormIntervalField = ({
  index,
  fromTime,
  toTime,
  onDelete,
}: EventUpsertTimeFormIntervalFieldProps) => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const { setValue } = useFormContext();

  const fromTimeFormatted = convertMinutesToTime(parseInt(fromTime));
  const toTimeFormatted = convertMinutesToTime(parseInt(toTime));
  const fromTimeError = !fromTime;
  const toTimeError = !toTime || fromTimeFormatted >= toTimeFormatted;

  const timeOptions = useMemo(
    () => generateTimeOptions(currentEvent.startDateTime),
    [currentEvent.startDateTime]
  );

  const handleFromTimeChange = (event: SelectChangeEvent<string>) => {
    if (isIntervalSelected(currentEvent.intervals)) {
      const newFromTime = convertTimeToMinutes(event.target.value);
      const newToTime = newFromTime + parseInt(toTime) - parseInt(fromTime);

      const duration = intervalToDuration({
        start: 0,
        end: newToTime * 60 * 1000,
      });

      const toTimestamp = set(new Date(currentEvent.startDateTime), {
        ...duration,
      });

      const updatedIntervals = currentEvent.intervals.value.map(
        (interval, idx) =>
          idx === index
            ? {
                ...interval,
                fromTime: newFromTime.toString(),
                toTime: newToTime.toString(),
              }
            : interval
      );

      updateEventField('intervals', {
        selectedOptionKey: 'yes',
        value: updatedIntervals,
      });
      setValue(`intervals.value.${index}.fromTime`, event.target.value, {
        shouldValidate: true,
      });
      setValue(
        `intervals.value.${index}.toTime`,
        format(toTimestamp, 'HH:mm'),
        {
          shouldValidate: true,
        }
      );
    }
  };

  const handleToTimeChange = (event: SelectChangeEvent<string>) => {
    if (isIntervalSelected(currentEvent.intervals)) {
      const newToTime = convertTimeToMinutes(event.target.value);
      const updatedIntervals = currentEvent.intervals.value.map(
        (interval, idx) =>
          idx === index
            ? { ...interval, toTime: newToTime.toString() }
            : interval
      );

      updateEventField('intervals', {
        selectedOptionKey: 'yes',
        value: updatedIntervals,
      });
      setValue(`intervals.value.${index}.toTime`, event.target.value, {
        shouldValidate: true,
      });
    }
  };

  const hasBookings = currentEvent.bookings && currentEvent.bookings.length > 0;

  return (
    <Stack direction="row" width="100%">
      <Stack direction="row" gap={2} width="100%">
        <FormControl fullWidth error={!!fromTimeError} disabled={hasBookings}>
          <InputLabel id={`time-interval-from-time-select-${index}-label`}>
            {t('eventUpsert.flow.time.form.interval.fromTime.label')}
          </InputLabel>
          <Select
            id={`time-interval-from-time-select-${index}`}
            labelId={`time-interval-from-time-select-${index}-label`}
            label={t('eventUpsert.flow.time.form.interval.fromTime.label')}
            value={fromTimeFormatted}
            onChange={handleFromTimeChange}
            disabled={hasBookings}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 248,
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {timeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Text fontSize="small">{option}</Text>
              </MenuItem>
            ))}
          </Select>
          {fromTimeError && (
            <FormHelperText error>
              {t('eventUpsert.flow.time.form.errors.required')}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth error={!!toTimeError}>
          <InputLabel id={`time-interval-to-time-select-${index}-label`}>
            {t('eventUpsert.flow.time.form.interval.toTime.label')}
          </InputLabel>
          <Select
            id={`time-interval-to-time-select-${index}`}
            labelId={`time-interval-to-time-select-${index}-label`}
            label={t('eventUpsert.flow.time.form.interval.toTime.label')}
            value={toTimeFormatted}
            onChange={handleToTimeChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 248,
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {timeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Text fontSize="small">{option}</Text>
              </MenuItem>
            ))}
          </Select>
          {toTimeError && (
            <FormHelperText error>
              {t('eventUpsert.flow.time.form.errors.endBeforeStartTime')}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
      <IconButton onClick={() => onDelete(index)}>
        <DeleteOutlineIcon />
      </IconButton>
    </Stack>
  );
};

const convertMinutesToTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0');
  const mins = (minutes % 60).toString().padStart(2, '0');
  return `${hours}:${mins}`;
};

export const convertTimeToMinutes = (time: string) => {
  if (typeof time !== 'string') return NaN;
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};
