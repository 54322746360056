import { DeleteForeverRounded } from '@mui/icons-material';
import { Box, BoxProps, Stack, StackProps, Typography } from '@mui/material';
import { Button } from '@understory-io/pixel';

import { useTranslate } from '../../Hooks/useTranslate';
import { PhotoIcon } from '../../Icons';
import type { TImage } from '../../Utils/types';
import { Dropzone } from '../Dropzone/Dropzone';

export const ProfileUpload = ({
  image,
  id,
  containerProps,
  fileType,
  modelType,
  onUploadPrepared,
  onDelete,
  label,
  ...props
}: StackProps & {
  containerProps?: BoxProps;
  image?: TImage;
  modelType: 'company' | 'me' | 'voucher';
  fileType: 'logo' | 'profile' | 'cover';
  id?: string;
  onUploadPrepared: (...params: any[]) => void;
  onDelete: (image: TImage) => void;
  label?: string;
}) => {
  const { t } = useTranslate('buttons');
  return (
    <Stack spacing={2} direction={'row'} alignItems={'center'} {...props}>
      <Dropzone
        fileType={fileType}
        modelType={modelType}
        urls={image?.url ? [image.url] : undefined}
        id={id}
        onPrepared={onUploadPrepared}
        maxFiles={1}
        containerProps={{
          ...(containerProps ?? {
            width: 80,
            height: 80,
            padding: 0,
            borderRadius: 100,
          }),
        }}
      >
        <PhotoIcon />
      </Dropzone>
      <Box>
        {image?.url && (
          <Button
            size="small"
            variant="secondary"
            leftIcon={<DeleteForeverRounded fontSize="small" />}
            onClick={() => onDelete(image)}
          >
            {t('delete')}
          </Button>
        )}
        {label && (
          <Typography variant={'body2'} display={'block'}>
            {label}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
