import { useQuery } from '@tanstack/react-query';

import { useTranslate } from '../useTranslate';

export const useLanguages = () => {
  const { t } = useTranslate('utils.languages');

  const LanguagesQueryKey = ['languages'];

  const languages = useQuery({
    queryKey: LanguagesQueryKey,
    queryFn: async () => {
      return { items: defaultLanguages };
    },
  });

  const languagesKeys = languages?.data?.items.map(({ code }) => code) ?? [];

  const getLanguageIcon = (language: string) =>
    languages.data?.items?.find((item) => item.code === language)?.flagSrc;

  const languageOptions = (key: string) => {
    const defaultKey = 'en';
    const languageKey = key === 'se' ? 'sv' : key;
    const item =
      languages.data?.items.find((item) => item.code === languageKey) ||
      languages.data?.items.find((item) => item.code === defaultKey);

    return {
      ...item,
      label: item ? t(item.code) : '',
    };
  };

  return {
    languages,
    languagesKeys,
    getLanguageIcon,
    languageOptions,
  };
};

const defaultLanguages = [
  {
    name: 'Danish',
    code: 'da',
    flagSrc: '/flags/svg/da.svg',
    locale: 'da',
  },
  {
    name: 'English',
    code: 'en',
    flagSrc: '/flags/svg/en.svg',
    locale: 'enGB',
  },
  {
    name: 'Swedish',
    code: 'sv',
    flagSrc: '/flags/svg/sv.svg',
    locale: 'sv',
  },
  {
    name: 'Norwegian',
    code: 'no',
    flagSrc: '/flags/svg/no.svg',
    locale: 'nn',
  },
  {
    name: 'German',
    code: 'de',
    flagSrc: '/flags/svg/de.svg',
    locale: 'de',
  },
  {
    name: 'Dutch',
    code: 'nl',
    flagSrc: '/flags/svg/nl.svg',
    locale: 'nl',
  },
];
