import { useQuery } from '@tanstack/react-query';

import { getResourceTypes } from '../../../Api/resource-management';
import { useProfile } from '../../../Hooks/useProfile';

export const resourceTypesQuery = () => ({
  queryKey: ['resource-management', 'resource-types'],
  queryFn: getResourceTypes,
});

export const useResourceTypes = () => {
  const { company } = useProfile();

  return useQuery({
    ...resourceTypesQuery(),
    enabled: !!company.data?.id,
  });
};
