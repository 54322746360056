import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button, LinkButton } from '@understory-io/pixel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { deleteUpload } from '../../Api';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { trackQuickSetupSkipStepClicked } from '../../tracking/quick-setup/global-events';
import {
  trackQuickSetupCreateManuallyClicked,
  trackQuickSetupGoToExperienceClicked,
  trackQuickSetupOpenPreviewClicked,
} from '../../tracking/quick-setup/step-events';
import { stepOrder, useOnboardingFlow } from './onboarding-context';
import { OnboardingFormInputs } from './onboarding-flow-form';

export const OnboardingFormActions = () => {
  const { step, currentStep, nextStep, currentStepKey } = useOnboardingFlow();
  const { t } = useTranslate('onboarding.dialog.action.tertiary');
  const { isMd } = useResponsive();

  const { resetField, getValues } = useFormContext<OnboardingFormInputs>();

  const resetDescription = () => {
    resetField('experienceDescription');
  };

  const resetMedia = () => {
    const uploadedMedia = getValues().experienceMedia;

    if (uploadedMedia) {
      try {
        for (const mediaItem of uploadedMedia) {
          deleteUpload(mediaItem);
        }
      } catch (error) {
        console.error(error);
      }
    }

    resetField('experienceMedia');
  };

  const handleSkipStep = () => {
    if (currentStepKey === 'description') {
      resetDescription();
    }

    if (currentStepKey === 'media') {
      resetMedia();
    }

    trackQuickSetupSkipStepClicked(currentStepKey, stepOrder[step + 1]);
    nextStep(false);
  };

  return (
    <Stack
      sx={{
        justifyContent: 'flex-end',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        gap: { xs: 1, md: 2 },
        marginTop: { md: 6 },
        zIndex: 1,
      }}
      justifyContent="flex-end"
    >
      {currentStep.isSkippable && (
        <Button
          type="button"
          variant="text"
          size={isMd ? 'medium' : 'large'}
          onClick={handleSkipStep}
          disabled={!currentStep.isSkippable}
        >
          {t('skipStep')}
        </Button>
      )}
      <SecondaryAction />
      <PrimaryAction />
    </Stack>
  );
};

const SecondaryAction = () => {
  const { t } = useTranslate(`onboarding.dialog.action.secondary`);
  const { isMd } = useResponsive();

  const { currentStepKey, nextStep, prevStep, closeSetupFlow } =
    useOnboardingFlow();
  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext<OnboardingFormInputs>();
  const navigate = useNavigate();

  const experienceId = watch('experienceId');
  const flags = useFlags();

  if (currentStepKey === 'start') {
    if (flags.featureAiSetupFlow) {
      return (
        <Button
          type="button"
          variant="secondary"
          size={isMd ? 'medium' : 'large'}
          onClick={() => {
            nextStep(false);
            trackQuickSetupCreateManuallyClicked();
          }}
          disabled={isSubmitting}
        >
          {t('startManualFlow')}
        </Button>
      );
    }

    return (
      <Button
        type="button"
        variant="secondary"
        size={isMd ? 'medium' : 'large'}
        onClick={() => closeSetupFlow()}
        disabled={isSubmitting}
      >
        {t('manual.closeDialog')}
      </Button>
    );
  }

  if (currentStepKey === 'end') {
    return (
      <Button
        type="button"
        variant="secondary"
        size={isMd ? 'medium' : 'large'}
        onClick={() => {
          navigate(`/experience/${experienceId}`);
          closeSetupFlow();
          trackQuickSetupGoToExperienceClicked();
        }}
        disabled={isSubmitting}
      >
        {t('goToExperience')}
      </Button>
    );
  }

  return (
    <Button
      type="button"
      variant="secondary"
      size={isMd ? 'medium' : 'large'}
      onClick={prevStep}
      leftIcon={!isMd && <ArrowBackRounded />}
      disabled={isSubmitting}
    >
      {t('goBack')}
    </Button>
  );
};

const PrimaryAction = () => {
  const { t } = useTranslate(`onboarding.dialog.action.primary`);
  const { isMd } = useResponsive();

  const { currentStepKey } = useOnboardingFlow();
  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext<OnboardingFormInputs>();

  const experienceId = watch('experienceId');
  const { companyProfileUrl } = useProfile();
  const companyUrl = companyProfileUrl();
  const url = `${companyUrl}/experience/${experienceId}`;

  const flags = useFlags();

  if (currentStepKey === 'start') {
    return (
      <Button
        type="submit"
        variant="primary"
        size={isMd ? 'medium' : 'large'}
        disabled={isSubmitting}
      >
        {flags.featureAiSetupFlow ? t('draftSite') : t('manual.startFlow')}
      </Button>
    );
  }

  if (currentStepKey === 'price') {
    return (
      <Button
        type="submit"
        variant="primary"
        size={isMd ? 'medium' : 'large'}
        disabled={isSubmitting}
      >
        {t('createExperience')}
      </Button>
    );
  }

  if (currentStepKey === 'end') {
    return (
      <LinkButton
        target="_blank"
        href={url}
        type="button"
        variant="primary"
        size={isMd ? 'medium' : 'large'}
        disabled={isSubmitting}
        onClick={() => trackQuickSetupOpenPreviewClicked(url)}
      >
        {t('openPreview')}
      </LinkButton>
    );
  }

  return (
    <Button
      type="submit"
      variant="primary"
      size={isMd ? 'medium' : 'large'}
      rightIcon={!isMd && <ArrowForwardRounded />}
      disabled={isSubmitting}
    >
      {t('goForward')}
    </Button>
  );
};
