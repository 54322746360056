import { Box, Stack, styled } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { trackStorefrontImplementationFlowTypeChanged } from '../../../../../tracking/storefront/implementation-events';
import { ActionTypeEnum, FlowTypeEnum } from '../../domain/types';
import { useBookingFlowSectionContext } from '../../domain/use_booking_flow_section_context';

interface FlowTypeSelectProps {
  type: FlowTypeEnum;
}

export const FlowTypeSelect = ({ type }: FlowTypeSelectProps) => {
  const { t } = useTranslate('storefront.bookingflow');
  const { selectedFlowType, dispatch } = useBookingFlowSectionContext();
  const isSelected = selectedFlowType === type;

  return (
    <StyledContainer
      data-intercom-target={`flow-type-${type}`}
      isSelected={isSelected}
      onClick={() => {
        trackStorefrontImplementationFlowTypeChanged(type);
        dispatch({ type: ActionTypeEnum.SetFlowType, payload: type });
      }}
    >
      <Box
        component={'img'}
        src={`/graphics/storefront-bookingflow-section/graphic-flowtype-${type}.svg`}
        sx={{
          width: 96,
          height: 'auto',
        }}
      />
      <Stack>
        <Text
          variant={'medium'}
          style={{
            color: isSelected ? lightTheme.palette.action.a300 : '#000000',
          }}
        >
          {t(`flowType.${type}.title`)}
        </Text>
        <Text fontSize={'xsmall'}>{t(`flowType.${type}.subtitle`)}</Text>
      </Stack>
    </StyledContainer>
  );
};

const StyledContainer = styled(Stack)((props: { isSelected: boolean }) => ({
  gap: '8px',
  backgroundColor: lightTheme.palette.contrast.white,
  border: `2px solid ${props.isSelected ? lightTheme.palette.action.a300 : 'transparent'}`,
  boxShadow: `0 0 2px ${lightTheme.palette.neutral.n100}`,
  borderRadius: '20px',
  minWidth: 200,
  padding: '16px',
  cursor: 'pointer',
  userSelect: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '&:hover': {
    boxShadow: `0 0 4px ${lightTheme.palette.neutral.n100}`,
  },
}));
