import { setDefaultOptions } from 'date-fns';
import { da } from 'date-fns/locale';
import * as locales from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguages } from './locales/use-languages';
import { useProfile } from './useProfile';

export const LANGUAGES_PREFERENCES_KEY = 'languages';

const localesMap: Record<string, Locale> = locales;

export const useLocale = () => {
  const [_locale, setLocale] = useState(da);

  const { i18n } = useTranslation();

  const { languageOptions } = useLanguages();

  const { me, preferences, updatePreferences } = useProfile();

  const preferredLanguage =
    preferences?.[LANGUAGES_PREFERENCES_KEY]?.defaultLanguage;

  useEffect(() => {
    const { locale = 'da' } =
      (languageOptions(i18n.language.split('-')[0]) as {
        locale: string;
      }) ?? {};
    if (locale && localesMap[locale]) {
      setLocale(() => localesMap[locale]);
      setDefaultOptions({ locale: localesMap[locale] });
    }
  }, [i18n.language, languageOptions]);

  useEffect(() => {
    if (!me.isFetched) return;
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage);
    } else {
      updatePreferences(
        LANGUAGES_PREFERENCES_KEY,
        (previous: Record<string, string> | undefined) => ({
          ...previous,
          defaultLanguage: i18n.language,
        })
      );
    }
  }, [i18n, me.isFetched, preferredLanguage]);

  return {
    _locale,
  };
};
