import NiceModal from '@ebay/nice-modal-react';
import { Skeleton } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { Suspense } from 'react';
import { Await } from 'react-router';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { getExperienceWaitlistQuery } from '../queries';
import { WaitlistDialog } from './waitlist-dialog';

export const WaitlistAction = ({
  waitlist,
}: {
  waitlist?: ReturnType<typeof getExperienceWaitlistQuery>;
}) => {
  const { t } = useTranslate('experience.details.value');

  if (!waitlist) {
    return null;
  }

  return (
    <Suspense fallback={<Skeleton variant="text" width={80} />}>
      <Await resolve={waitlist}>
        {({ items }) => {
          const handleOpenWaitlist = () => {
            NiceModal.show(WaitlistDialog, {
              items,
            });
          };

          if (items.length === 0) {
            return null;
          }

          return (
            <Text
              fontSize="xsmall"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: lightTheme.palette.action.a200,
              }}
              onClick={handleOpenWaitlist}
            >
              {t('waitlist', {
                count: items.length,
              })}
            </Text>
          );
        }}
      </Await>
    </Suspense>
  );
};
