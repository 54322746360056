import { Stack } from '@mui/material';
import { Button, LinkButton, Text } from '@understory-io/pixel';
import { Connection } from '@understory-io/utils-types';
import randomBytes from 'randombytes';
import { useEffect } from 'react';
import {
  ActionFunctionArgs,
  redirect,
  useHref,
  useNavigate,
  useRouteLoaderData,
} from 'react-router';
import { Form, useLinkClickHandler } from 'react-router';
import { toast } from 'react-toastify';

import {
  declineConnectionRequest,
  disconnectConnection,
  getPublicCompanyProfile,
} from '../../../Api';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useTranslate } from '../../../Hooks/useTranslate';
import { t } from '../../../i18n/config';
import {
  trackConnectDisconnectCompleted,
  trackConnectDisconnectStarted,
} from '../../../tracking/connect/connect-events';
import { DialogWrapper } from '../components/dialog-wrapper';
import { decodeConnectionPath } from '../list/connection-path-tools';
import { ConnectionDetailsLoaderData } from './connection-details-page';

export async function loader() {
  return {};
}

export async function action({ params, request }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('connect.dialog.disconnect.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    toast.dismiss(loadingToastId);

    const formData = await request.formData();

    const state = formData.get('state') as Connection['state'];
    if (state === 'connected') {
      const { companyId, token } = decodeConnectionPath(params.id!);
      await disconnectConnection({
        companyId,
        token,
      });
      const connectedCompany = await getPublicCompanyProfile(companyId);
      trackConnectDisconnectCompleted(connectedCompany.name);
    } else {
      const token = formData.get('token') as string;
      await declineConnectionRequest(token);
    }

    toast.success(t('connect.dialog.disconnect.toast.success'), {
      autoClose: 5000,
    });

    return redirect('/settings/connect');
  } catch (error) {
    console.error(error);
    toast.dismiss(loadingToastId);
    toast.error(t('connect.dialog.disconnect.toast.error'), { delay: 500 });
    return {
      success: false,
    };
  }
}

export function DisconnectConnectionDialog() {
  const { t } = useTranslate('connect.dialog.disconnect');
  const { isSm } = useResponsive();
  const { connectedCompanyLabel, state, token } = useRouteLoaderData(
    'connection-details'
  ) as ConnectionDetailsLoaderData;
  const closeHref = useHref(`..`);
  const closeLinkClick = useLinkClickHandler(closeHref);

  const navigate = useNavigate();
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() => trackConnectDisconnectStarted(connectedCompanyLabel));
  }, [connectedCompanyLabel, fireOnce]);

  const handleClose = () => {
    navigate(closeHref);
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('title')}
    >
      <Stack gap={4} component={Form} method="POST">
        <input type="hidden" name="state" value={state} />
        <input type="hidden" name="token" value={token} />
        <Text variant="normal" fontSize="medium">
          {t('description', { companyName: connectedCompanyLabel })}
        </Text>

        <Stack
          gap={2}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={{ xs: 'stretch', md: 'stretch' }}
        >
          <LinkButton
            href={closeHref}
            onClick={closeLinkClick}
            variant="secondary"
            size="large"
            fullWidth
            style={{
              flexShrink: 'unset',
            }}
          >
            {t('cancel')}
          </LinkButton>
          <Button
            type="submit"
            variant="danger"
            size="large"
            fullWidth
            style={{
              flexShrink: 'unset',
            }}
          >
            {t('confirm')}
          </Button>
        </Stack>
      </Stack>
    </DialogWrapper>
  );
}
