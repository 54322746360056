import { renderDateTime } from '@understory-io/utils-date';
import { FC } from 'react';

import { useGetEvent } from '../../../../../../Hooks/events/useGetEvent';
import { PageBreadcrumbItem } from '../page-breadcrumb-item';

type Props = {
  eventId: string | undefined;
};

export const PageBreadcrumbBreadcrumbsEvent: FC<Props> = ({ eventId }) => {
  const {
    event: { data: event },
  } = useGetEvent(eventId ?? '');

  const title = event?.startDateTime
    ? renderDateTime(event.startDateTime, event.endDateTime, {
        standard: 'ddmmyyyyhh',
        sameDay: 'dmhh',
      })
    : undefined;

  return <PageBreadcrumbItem title={title} href={`/event/${event?.id}`} />;
};
