import randomBytes from 'randombytes';
import {
  ComponentPropsWithoutRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { EventDetailsView } from '../features/events/event_details/EventDetailsView';
import { useDeleteEvent } from '../Hooks/events/useDeleteEvent';
import { useGetEvent } from '../Hooks/events/useGetEvent';
import { useUpdateEvent } from '../Hooks/events/useUpdateEvent';
import { useExperience } from '../Hooks/useExperience';
import { useTranslate } from '../Hooks/useTranslate';
import { isVirtualId } from '../Utils/eventHelpers';
import { eventSchema, eventSyiSteps } from './SyiPage/config';
import { SyiPage } from './SyiPage/SyiPage';

export const EventPage = ({ children }: ComponentPropsWithoutRef<'main'>) => {
  const { t } = useTranslate('dialogs.recurringChanges');

  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams<{ id: string }>();

  const location = useLocation();
  const navigate = useNavigate();

  const { returnUrl, experienceId } = useMemo(() => {
    const { returnUrl, experienceId } = (location?.state as any) ?? {};
    return {
      experienceId,
      returnUrl,
    };
  }, [location.state]);

  const {
    params: { section },
  } = useMatch(':key/:id/:section/*') ?? { params: {} };

  const {
    experience: { data: experience },
  } = useExperience(experienceId);

  const _id = useMemo(
    () => (id !== 'create' && id ? id : randomBytes(16).toString('hex')),
    [id]
  );

  const defaultValues: { [key: string]: any } & { parentId?: string } = {
    experienceId: experienceId ?? null,
    status: 'draft',
    ...(id === 'create'
      ? {
          intervals: {
            selectedOptionKey: 'no',
          },
          recurring: {
            selectedOptionKey: 'no',
          },
        }
      : {}),
  };

  const { event } = useGetEvent(_id);
  const { deleteEvent } = useDeleteEvent();
  const { updateEvent } = useUpdateEvent(_id, experienceId);

  const _steps = useMemo(() => {
    return eventSyiSteps.map((el) => {
      return {
        ...el,
        label: t(el.key, 'events.create.progress'),
      };
    });
  }, []);

  const prefiller = useCallback(
    (setValue: UseFormReturn['setValue']) => {
      if (experience) {
        setValue('seatCount', {
          selectedOptionKey: 'experienceDefault',
          value: experience?.seats?.seatCount,
        });
      }
    },
    [experience]
  );

  if (id !== 'create' && section !== 'edit') {
    return <EventDetailsView />;
  }

  const onSubmit = isVirtualId(_id)
    ? async (payload: any, dirtyFields?: { [k: string]: boolean }) => {
        if (!dirtyFields) {
          return navigate(returnUrl ?? `/event/${payload.id}`, {
            replace: true,
          });
        }

        setIsLoading(true);

        await updateEvent
          .mutateAsync({
            ...payload,
            id: payload.id,
          })
          .then(() => {
            toast.success(
              t(
                id === 'create' ? 'creatingSuccess' : 'editingSuccess',
                'events.toast'
              )
            );

            navigate(returnUrl ?? `/event/${payload.id}`, { replace: true });
          })
          .catch(() => {
            toast.error(t('editingError', 'events.toast'));
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    : async (data: any) => {
        await updateEvent
          .mutateAsync({ ...data, id: _id })
          .then(() => {
            toast.success(
              t(
                id === 'create' ? 'creatingSuccess' : 'editingSuccess',
                'events.toast'
              )
            );
            navigate(returnUrl ?? `/event/${_id}`, { replace: true });
          })
          .catch(() => {
            toast.error(t('editingError', 'events.toast'));
          })
          .finally(() => {
            setIsLoading(false);
          });
        return;
      };

  const getReturnUrl = () => {
    return (
      returnUrl ??
      `/event/${_id}` ??
      `/experience/${experienceId}` ??
      '/experiences'
    );
  };

  return (
    <SyiPage
      modelType={'event'}
      steps={_steps}
      title={
        id === 'create'
          ? `${t('new', 'utils.generic')} ${t('event', 'utils.generic')}`
          : `${t('edit', 'utils.generic')} ${t('event', 'utils.generic')}`
      }
      deleteItem={deleteEvent}
      updateItem={updateEvent}
      item={event}
      id={_id}
      schema={eventSchema}
      returnUrl={getReturnUrl()}
      prefiller={prefiller}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      isCreateFlow={id === 'create'}
      isLoading={isLoading}
    >
      {children}
    </SyiPage>
  );
};
