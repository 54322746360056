import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import isEqual from 'lodash.isequal';
import { useCallback, useState } from 'react';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { TagFilterItem } from '../tags';
import { EditTag } from './edit-tag';
import { TagListItem } from './list-item';

type TagListProps = {
  selectedTags: TagFilterItem[];
  tagOptions: TagFilterItem[];
  editingTag?: TagFilterItem;
  onEditTag: (tag?: TagFilterItem) => void;
  onSelectTag: (tag: TagFilterItem) => void;
  onUpdateTag: (id: string, name: string) => void;
};

export const TagList = ({
  tagOptions,
  selectedTags,
  editingTag,
  onEditTag,
  onSelectTag,
  onUpdateTag,
}: TagListProps) => {
  const { t } = useTranslate('experience.edit.dialog.tags');

  const [inputValue, setInputValue] = useState('');

  const handleEditTag = (tag: TagFilterItem) => {
    setInputValue(tag.name || '');
    onEditTag(tag);
  };

  const handleSubmit = useCallback(() => {
    if (!editingTag) return;
    if (isEqual(inputValue, editingTag?.name)) {
      return onEditTag(undefined);
    }

    onUpdateTag(editingTag?.id, inputValue);
  }, [editingTag, inputValue, onEditTag, onUpdateTag]);

  return (
    <Stack sx={{ gap: 1 }}>
      <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
        {t('list.title')}
      </Text>
      <Stack sx={{ maxHeight: '240px', overflow: 'scroll' }}>
        {tagOptions.map((tag) => {
          const isSelected = selectedTags.some((t) => t.id === tag.id);
          const isEditing = tag.id === editingTag?.id;

          return isEditing ? (
            <EditTag
              key={tag.id}
              value={inputValue}
              onChange={(value) => setInputValue(value)}
              onSubmit={handleSubmit}
              onClearEditing={() => onEditTag(undefined)}
            />
          ) : (
            <TagListItem
              key={tag.id}
              isSelected={isSelected}
              tag={tag}
              onSelectTag={onSelectTag}
              onSelectEdit={handleEditTag}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
