export function getFormDataValue(input: FormDataEntryValue | null) {
  if (typeof input !== 'string') return '';
  return input.trim();
}

export function hasLocalizedValue(obj: object) {
  return Object.values(obj).some(
    (value) => typeof value === 'string' && value.trim() !== ''
  );
}
