import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Dialog, Stack } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';

import { CloseButton } from '../Components/CloseButton/CloseButton';
import useResponsive from '../Hooks/layout/useResponsive';
import { useTranslate } from '../Hooks/useTranslate';
import { TEvent } from '../Utils/eventHelpers';

interface IConfirmDialogProps {
  onClose?: () => void;
  title: string;
  headline?: string;
  description: string;
  confirmLabel?: string;
  allowCancel?: boolean;
  important?: boolean;
}

export interface showConfirmDialogProps {
  id?: string;
  events?: TEvent[];
  dialogProps: {
    title: string;
    description: string;
    confirmLabel?: string;
    important?: boolean;
  };
  successMessage: string;
  loadingMessage?: string;
  onConfirm: () => Promise<unknown>;
}

export const ConfirmDialog = NiceModal.create<IConfirmDialogProps>(
  ({
    onClose,
    headline,
    title,
    allowCancel = true,
    description,
    confirmLabel,
    important,
  }) => {
    const { t } = useTranslate('utils.generic');
    const { isMd } = useResponsive();
    const modal = useModal();

    const handleClose = () => {
      modal.reject('ConfirmDialog');
      modal.hide();
      onClose?.();
    };

    const handleOk = () => {
      modal.resolve();
      modal.hide();
    };

    return (
      <Dialog open={modal.visible} onClose={handleClose}>
        <CloseButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 24, right: 24 }}
        />
        <Stack p={5} gap={5} component={'section'}>
          {headline && (
            <Text
              variant="medium"
              fontSize="xlarge"
              as="h1"
              style={{ maxWidth: 'calc(100% - 48px)' }}
            >
              {headline}
            </Text>
          )}
          {title && (
            <Text variant="normal" fontSize="medium">
              {title}
            </Text>
          )}
          {description && (
            <Text variant="normal" fontSize="medium">
              {description}
            </Text>
          )}
          <Stack
            direction={isMd ? 'column' : 'row'}
            spacing={2}
            justifyContent={'end'}
          >
            {allowCancel && (
              <Button size="large" variant="secondary" onClick={handleClose}>
                {t('cancel')}
              </Button>
            )}
            <Button
              size="large"
              variant={important ? 'danger' : 'primary'}
              onClick={handleOk}
            >
              {confirmLabel ?? t('ok')}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    );
  }
);
