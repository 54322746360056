import NiceModal from '@ebay/nice-modal-react';
import { Divider, Stack, Typography } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import {
  OptionGroup,
  OptionGroupSelectedSection,
} from '../../Components/OptionGroup/OptionGroup';
import { TimeSpanPicker } from '../../Components/TimeSpanPicker/timeSpanPicker';
import { TranslatableGroup } from '../../Components/TranslatableGroup/translatable-group';
import { useStorefrontLanguages } from '../../Hooks/translatable/use-storefront-languages';
import { useProfile } from '../../Hooks/useProfile';
import { SyiSection } from '../../Pages/SyiPage/SyiSection';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { CreateLocationDialog } from '../StorefrontSections/location-management/dialogs/create-location-dialog';
import { LocationsSelect } from './LocationsSelect';

export const visibilityOptions = [
  {
    key: 'public',
    label: 'Offentlig',
    prefillLabel: ' ',
  },
  {
    key: 'private',
    label: 'Privat',
    prefillLabel: '',
  },
];

const infoForGuestsOptions = [
  {
    key: 'no',
    label: 'Nej, de skal bare møde op',
  },
  {
    key: 'yes',
    label: 'Ja, lad mig beskrive',
  },
];

export const minimumParticipantsOptions = [
  {
    key: 'no',
    label: 'Nej, oplevelsen afholdes selv med 0 deltagere',
    prefillLabel: 'Skal eventet afholdes selv med 0 deltagere?',
  },
  {
    key: 'yes',
    label: 'Ja, lad mig definere antal pladser',
    prefillLabel: 'Er minimum deltager antal ${value} for eventet?',
  },
];

export const ExperienceSyiSectionPracticalities = () => {
  const { t } = useTranslation();

  const { company } = useProfile();

  const { control } = useFormContext();

  const { getError, clearError } = useErrors();

  const storefrontLanguages = useStorefrontLanguages();

  const _infoForGuestsOptions = infoForGuestsOptions.map((el) => ({
    ...el,
    label: t(
      `experience.create.practicalities.infoForGuests.options.${el.key}.label`
    ),
  }));

  const _visibilityOptions = visibilityOptions.map((el) => ({
    ...el,
    label: t(
      `experience.create.practicalities.visibility.options.${el.key}.label`
    ),
  }));

  const { field: cutoffTimeSeconds } = useController({
    name: 'cutoffTimeSeconds',
  });

  const { append: handleAddLocationId } = useFieldArray({
    control,
    name: 'locationIds',
  });

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>
          {t('experience.create.practicalities.title')}
        </Typography>
      </Stack>

      <Stack mt={2} divider={<Divider />} spacing={4}>
        <SyiSection
          title={t('experience.create.practicalities.visibility.title')}
          error={getError('visibility')}
        >
          <OptionGroup
            name={'visibility'}
            options={_visibilityOptions}
            onChangeValue={() => clearError('visibility')}
          ></OptionGroup>
        </SyiSection>

        <SyiSection
          title={t('experience.create.practicalities.infoForGuests.title')}
          error={getError('infoForGuests')}
        >
          <OptionGroup
            name={'infoForGuests.selectedOptionKey'}
            options={_infoForGuestsOptions}
            onChangeValue={() => clearError('infoForGuests')}
          >
            <OptionGroupSelectedSection optionKey={'yes'}>
              <TranslatableGroup
                langs={storefrontLanguages ?? []}
                id={'infoForGuests.yes'}
                input={{
                  type: 'editor',
                  placeholder: t(
                    `experience.create.practicalities.infoForGuests.options.yes.inputPlaceholder`
                  ),
                }}
              />
            </OptionGroupSelectedSection>
          </OptionGroup>
        </SyiSection>

        <SyiSection
          title={t('experience.create.practicalities.cutoffTimeSeconds.title')}
          error={getError('cutoffTimeSeconds')}
        >
          <TimeSpanPicker
            initialValueSeconds={cutoffTimeSeconds.value}
            onChange={cutoffTimeSeconds.onChange}
          />
        </SyiSection>

        <SyiSection
          title={t('experience.create.practicalities.addresses.title')}
          error={getError('locationIds')}
        >
          <Stack alignItems="flex-start" gap={2}>
            <Stack>
              <LocationsSelect
                companyId={company.data?.id ?? ''}
                control={control}
              />
              <Text
                fontSize="xsmall"
                color={lightTheme.palette.neutral.n400}
                style={{ fontStyle: 'italic' }}
              >
                {t('experience.locations.manageLocations.helperText')}{' '}
                <Link
                  style={{ textDecoration: 'underline' }}
                  to="/storefront/locations"
                >
                  {t('experience.locations.manageLocations.storefrontSettings')}
                </Link>
              </Text>
            </Stack>
            <Button
              size="medium"
              variant="primary"
              onClick={async () => {
                const locationId = await NiceModal.show(CreateLocationDialog);
                if (locationId) handleAddLocationId(locationId);
              }}
            >
              {t('experience.create.practicalities.addresses.addLocation')}
            </Button>
          </Stack>
        </SyiSection>
      </Stack>
    </>
  );
};
