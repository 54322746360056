import styled from '@emotion/styled';
import { AddRounded } from '@mui/icons-material';
import { ArrowBackOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { Text } from '@understory-io/pixel';
import { lightTheme } from '@understory-io/pixel';
import {
  Resource,
  ResourceType,
} from '@understory-io/resource-management-types';
import { Location } from '@understory-io/utils-types';
import { LoaderFunctionArgs, Outlet, useLoaderData } from 'react-router';

import { ShowForScope } from '../../../../Components/AllowForScope/AllowForScope';
import { RouterLinkButton } from '../../../../Components/button/router-link-button';
import { Page } from '../../../../Components/Page/Page';
import { PageBreadcrumbItem } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/page-breadcrumb-item';
import { PageBreadcrumbBreadcrumbs } from '../../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { locationsQuery } from '../../../../Hooks/data/useLocations';
import { useHistory } from '../../../../Hooks/useHistory';
import { useTranslate } from '../../../../Hooks/useTranslate';
import routes from '../../../../Utils/routes';
import { RESOURCE_MANAGEMENT_SCOPES } from '../../auth/resource-management-scopes';
import { resourceTypesQuery } from '../../data/use-resource-types';
import { resourcesQuery } from '../../data/use-resources';
import { ResourceTypeDetailsCard } from './resource-type-details-card';
import { ResourcesList } from './resources-list';

type LoaderData = {
  resourceType: ResourceType;
  resources: Promise<Array<Resource>>;
  locations: Promise<Array<Location>>;
};

export const loader =
  (client: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<LoaderData> => {
    const resourceTypes = await client.fetchQuery(resourceTypesQuery());

    const resourceType = resourceTypes.find(
      (x) => x.resourceTypeId === params.resourceTypeId
    );

    if (!resourceType) {
      throw new Response('Resource type not found', { status: 404 });
    }
    const locations = client.fetchQuery(locationsQuery(resourceType.companyId));
    const resources = client.fetchQuery(
      resourcesQuery(resourceType.resourceTypeId)
    );

    return {
      resourceType,
      resources,
      locations,
    };
  };

export const ResourceTypesDetails = () => {
  const { t } = useTranslate('resourceManagement');
  const { goBack } = useHistory();
  const { resourceType, resources, locations } = useLoaderData<LoaderData>();

  const handleBack = () => goBack(routes.resourceManagement.index);

  return (
    <>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbItem
          title={t('resourceManagement', 'utils.primaryNavigation')}
          href={routes.resourceManagement.index}
        />
        <PageBreadcrumbItem
          title={resourceType.name}
          href={
            routes.resourceManagement.types.details(resourceType.resourceTypeId)
              .index
          }
        />
      </PageBreadcrumbBreadcrumbs>
      <Page
        sx={{
          maxWidth: '1400px',
          pr: { xs: 0, md: 2 },
          pb: { xs: 0, md: 10 },
          pl: { xs: 0, lg: '50px' },
          pt: { xs: 5, md: 3 },
        }}
      >
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Stack gap={2} direction="row" alignItems={'center'}>
            <StyledBackButton fontSize="small" onClick={handleBack} />
            <Text fontSize="h5">{t('title')}</Text>
          </Stack>
          <ShowForScope scopes={RESOURCE_MANAGEMENT_SCOPES.MANAGE_RESOURCES}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <RouterLinkButton
                href={
                  routes.resourceManagement.types.details(
                    resourceType.resourceTypeId
                  ).createResource
                }
                variant="primary"
                leftIcon={<AddRounded fontSize="small" sx={{ mr: 1 }} />}
              >
                {t('newResource')}
              </RouterLinkButton>
            </Stack>
          </ShowForScope>
        </Stack>

        <Stack gap={2} marginTop={2}>
          <ResourceTypeDetailsCard
            resourceType={resourceType}
            locations={locations}
          />
          <ResourcesList resources={resources} />
        </Stack>
        <Outlet />
      </Page>
    </>
  );
};

const StyledBackButton = styled(ArrowBackOutlined)({
  cursor: 'pointer',
  borderRadius: '50%',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
