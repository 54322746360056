import { lightTheme } from '@understory-io/pixel';
import { useMemo } from 'react';

import { useExperience } from '../../../Hooks/useExperience';
import { useProfile } from '../../../Hooks/useProfile';
import { DEFAULT_EXPERIENCE_SORTING } from '../../../Pages/ExperiencesPage';
import { getOrder, getSortOrder } from '../../../Utils/experience-order';
import { CustomizeSectionForm } from './customize-section-form';
import {
  AVAILABLE_FONTS,
  BUTTON_BORDER_STYLES,
} from './styling/customization-options';

export const CustomizeSection = () => {
  const { experiences: experiencesQuery } = useExperience();
  const { company: companyQuery } = useProfile();

  const initialSortOrder = useMemo(
    () =>
      experiencesQuery.data
        ? getOrder(getSortOrder(experiencesQuery.data))
        : [],
    [experiencesQuery]
  );

  if (!experiencesQuery.data) return null;
  if (!companyQuery.data) return null;

  const { customization, sortExperiencesBy } = companyQuery.data;

  const defaultValues = {
    fontFamily: customization?.fontFamily ?? AVAILABLE_FONTS[0],
    primaryColor: customization?.primaryColor ?? lightTheme.palette.action.a300,
    buttonStyle:
      customization?.buttonStyle ?? Object.keys(BUTTON_BORDER_STYLES)[0],
    sortExperiencesBy: sortExperiencesBy ?? DEFAULT_EXPERIENCE_SORTING,
    sortOrder: initialSortOrder,
  };

  return (
    <CustomizeSectionForm
      defaultValues={defaultValues}
      company={companyQuery.data}
      experiencesQuery={experiencesQuery}
    />
  );
};
