import NiceModal from '@ebay/nice-modal-react';
import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { CompanyProfile } from '@understory-io/utils-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { generate } from 'random-words';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';

import { createDomain, domainAvailable } from '../../../Api';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useAuth } from '../../../Hooks/useAuth';
import { SignupType, useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { trackQuickSetupOpened } from '../../../tracking/quick-setup/global-events';
import { trackSignUpFlowCompleted } from '../../../tracking/signup/trackSignUpFlowCompleted';
import { useExperimentVariant } from '../../../Utils/experiments';
import { GTMEvent } from '../../../Utils/gtm';
import { OnboardingDialog } from '../../onboarding/onboarding-dialog';
import { SignupForm } from './signup-form';

export type InvitationData = {
  email: string;
  companyName: string;
  inviterName: string;
  name?: string;
  id: string;
};

export const SignupCard = () => {
  const { t, i18n } = useTranslate('signup');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isAuthenticated, createAccount, addAccountToCompany } = useAuth();
  const { updateCompany } = useProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isSm } = useResponsive();

  const flags = useFlags();

  const experimentVariant = useExperimentVariant(
    'ForcedQuickOnboardingExperiment',
    {
      variant: 'mobile-plg-forced-quick-onboarding',
      fallback: { value: 'control' },
    }
  );

  if (isAuthenticated && !isSubmitting) {
    navigate('/dashboard', { replace: true });
  }

  const prefilledEmail = searchParams.get('email') || undefined;

  const handleSubmit = async (password?: string, email?: string) => {
    if (!email || !password) {
      return;
    }

    const profileId = await handleCreateAccount(email, password);

    GTMEvent({
      event: 'create_account',
      type:
        searchParams.has('preview') || searchParams.get('source') === 'generic' // signed up from AI but chose generic storefront
          ? 'ai'
          : 'manual',
      user_data: { email },
    });
    trackSignUpFlowCompleted(profileId, false);
  };

  const handleCreateAccount = async (
    email: string,
    password: string
  ): Promise<string> => {
    const aiGeneratedCompanyId = searchParams.get('preview');
    const fromGenericStorefront = searchParams.get('source') === 'generic';
    const signupType =
      aiGeneratedCompanyId || fromGenericStorefront
        ? SignupType.AISignup
        : SignupType.SelfSignup;
    const profileId = await createAccount(email, password, signupType);

    if (aiGeneratedCompanyId) {
      const userId = await addAccountToCompany(profileId, aiGeneratedCompanyId);
      return userId;
    }

    const generatedDomain = await generateRandomDomain();

    const payload: Partial<CompanyProfile> = {
      users: [profileId],
      languages: [i18n.language],
      defaultLanguage: i18n.language,
      domains: [generatedDomain],
    };
    await updateCompany.mutateAsync(payload);
    await createDomain({ type: 'subdomain', domain: generatedDomain });

    if (flags.featureOnboardingFlow) {
      NiceModal.show(OnboardingDialog, {
        showCloseButton: experimentVariant?.value !== 'treatment' || !isSm,
      });
      trackQuickSetupOpened('auto');
    }

    return profileId;
  };

  return (
    <Stack
      gap={4}
      sx={{
        height: { xs: '100vh', md: 'unset' },
        maxWidth: { md: '416px' },
        padding: { xs: '32px 16px', md: '48px 32px 32px' },
        backgroundColor: lightTheme.palette.contrast.white,
        borderRadius: { md: '16px' },
        boxShadow: { md: lightTheme.shadows.large },
      }}
    >
      <Stack gap={1}>
        <Text variant="medium" fontSize="xlarge">
          {t('title')}
        </Text>
        <Text>{t('description')}</Text>
      </Stack>
      <SignupForm
        isSubmitting={isSubmitting}
        prefilledEmail={prefilledEmail}
        setIsSubmitting={setIsSubmitting}
        callback={handleSubmit}
      />
    </Stack>
  );
};

async function generateRandomDomain(): Promise<string> {
  let isAvailable = false;
  let generatedDomain: string | undefined = undefined;
  do {
    generatedDomain = generate({
      exactly: 3,
      join: '-',
    });

    const { available } = await domainAvailable(generatedDomain);
    isAvailable = available;
  } while (!isAvailable);

  return generatedDomain;
}
