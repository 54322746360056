import { CloseRounded } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

export const CloseButton = ({ sx, ...props }: IconButtonProps) => (
  <IconButton sx={sx} {...props}>
    <CloseRounded
      sx={{ fontSize: { xs: '24px', md: '32px' } }}
      htmlColor={lightTheme.palette.neutral['n400']}
    />
  </IconButton>
);
