import { keyframes, styled } from '@mui/material';
import * as Popover from '@radix-ui/react-popover';
import { Button, lightTheme } from '@understory-io/pixel';

const slideUpAndFade = keyframes`
  0% { opacity: 0; transform: translateY(2px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const slideRightAndFade = keyframes`
  0% { opacity: 0; transform: translateX(-2px); }
  100% { opacity: 1; transform: translateX(0); }
`;

const slideDownAndFade = keyframes`
  0% { opacity: 0; transform: translateY(-2px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const slideLeftAndFade = keyframes`
  0% { opacity: 0; transform: translateX(2px); }
  100% { opacity: 1; transform: translateX(0); }
`;

export const StyledPopoverContent = styled(Popover.Content)`
  z-index: 9999;
  border-radius: 8px;
  background-color: ${lightTheme.palette.contrast.white};
  box-shadow: ${lightTheme.shadows.medium};
  border: 1px solid ${lightTheme.palette.neutral.n100};

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='open'] {
    &[data-side='top'] {
      animation-name: ${slideDownAndFade};
    }
    &[data-side='right'] {
      animation-name: ${slideLeftAndFade};
    }
    &[data-side='bottom'] {
      animation-name: ${slideUpAndFade};
    }
    &[data-side='left'] {
      animation-name: ${slideRightAndFade};
    }
  }
`;

export const StyledContextMenuItem = styled(Button)({
  backgroundColor: lightTheme.palette.contrast.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  paddingInline: 12,
  paddingBlock: 8,
  borderRadius: 0,
  minWidth: 200,
  ':hover': { backgroundColor: lightTheme.palette.neutral.n100 },
});
