import styled from '@emotion/styled';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ReturnType } from '@understory-io/utils-types/typebox';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { CopyLink } from '../../../Components/CopyLink/CopyLink';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { ExperienceDetailsCard } from './experience-details-card';
import type { getExperienceWaitlistQuery } from './queries';
import { WaitlistAction } from './waitlist/waitlist-action';

export type ExperienceDetailsItem = {
  key: string;
  label: string;
  value: string;
  link?: string;
  onClickTrackingFn?: () => void;
  onCopyTrackingFn?: () => void;
}[];

export const ExperienceDetailsGrid = ({
  label,
  items,
  waitlist,
}: {
  label: string;
  items: ExperienceDetailsItem;
  waitlist?: ReturnType<typeof getExperienceWaitlistQuery>;
}) => {
  const { t } = useTranslation();
  const { isSm } = useResponsive();
  const [open, setOpen] = useState(false);

  return (
    <Grid item xs={1}>
      <ExperienceDetailsCard showDivider>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 1,
            paddingY: 1,
          }}
          onClick={() => setOpen((prev) => !prev)}
        >
          <Text variant="medium" fontSize="small">
            {label}
          </Text>
          {open ? (
            <ExpandLessOutlined sx={{ display: { md: 'none' } }} />
          ) : (
            <ExpandMoreOutlined sx={{ display: { md: 'none' } }} />
          )}
        </Stack>
        {(!isSm || open) && (
          <Grid container columns={isSm ? 3 : 2} spacing={1.5}>
            {items.map(
              ({
                key,
                label,
                value,
                link,
                onClickTrackingFn,
                onCopyTrackingFn,
              }) => (
                <Fragment key={key}>
                  <Grid item xs={1}>
                    <Text fontSize="xsmall">{label}</Text>
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <Stack direction="row" gap={1} alignItems="top">
                      <Text
                        fontSize="xsmall"
                        variant="medium"
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '100%',
                        }}
                        title={value}
                      >
                        {link ? (
                          <CopyLink
                            href={link}
                            label={value}
                            onClickText={() => onClickTrackingFn?.()}
                            onClickCopy={() => onCopyTrackingFn?.()}
                          />
                        ) : (
                          value
                        )}
                      </Text>
                      {(key === 'owner' || key === 'distributor') && (
                        <StyledLink
                          to="/settings/connect"
                          onClick={() => onClickTrackingFn?.()}
                        >
                          {t('experience.details.value.connectLink')}
                        </StyledLink>
                      )}
                      {key === 'guests' && (
                        <WaitlistAction waitlist={waitlist} />
                      )}
                    </Stack>
                  </Grid>
                </Fragment>
              )
            )}
          </Grid>
        )}
      </ExperienceDetailsCard>
    </Grid>
  );
};

const StyledLink = styled(Link)({
  fontSize: '12px',
  color: lightTheme.palette.action.a200,
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
});
