import { Box, Card, Stack, Typography } from '@mui/material';
import { StorefrontFeatures } from '@understory-io/utils-types';
import { Children, PropsWithChildren } from 'react';

import { useTranslate } from '../../Hooks/useTranslate';
import { FeatureSettingContextProvider } from '../../Sections/StorefrontSections/FeaturesSection/context/feature_setting_context';
import { FeatureEnabledSwitch } from '../../Sections/StorefrontSections/FeaturesSection/inputs/FeatureEnabledSwitch';
import { CopyLink } from '../CopyLink/CopyLink';

export type TFeatureItemProps = PropsWithChildren<{
  link?: string;
  feature: keyof StorefrontFeatures;
  disabled?: boolean;
  onToggle?: (enabled: boolean) => void;
}>;

const FeatureItem = ({
  feature,
  children,
  link,
  onToggle,
  disabled,
}: TFeatureItemProps) => {
  const { t } = useTranslate('settings.features');

  return (
    <FeatureSettingContextProvider feature={feature}>
      <Box
        component={Card}
        pt={4}
        pb={4}
        pr={4}
        display={'inline-flex'}
        alignItems={'flex-start'}
      >
        <Box ml={2} width={'100%'}>
          <Stack maxWidth={480} gap={4}>
            <Typography variant={'h5'} fontSize={'1.111em'}>
              {t(`${feature}.name`)}
            </Typography>
            <Stack flexDirection={'row'}>
              <FeatureEnabledSwitch
                disabled={disabled}
                disabledReason={t(`${feature}.disabledReason`)}
                onToggle={onToggle}
              />
              <Stack>
                <Typography variant={'body1'}>
                  {t(`${feature}.descriptionTitle`)}
                </Typography>
                <Typography variant={'body2'}>
                  {t(`${feature}.descriptionBody`)}
                </Typography>
              </Stack>
            </Stack>
            {link && <CopyLink href={link} label={t(`${feature}.linkText`)} />}
            {Children.count(children) > 0 && (
              <Stack spacing={3}>{children}</Stack>
            )}
          </Stack>
        </Box>
      </Box>
    </FeatureSettingContextProvider>
  );
};

export default FeatureItem;
