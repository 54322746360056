/**
 * Determines and returns the a boolean indicating if auto translation is enabled
 * @param request - the request accessed from a loader or action function
 */
export const getAutoTranslate = (request: Request) => {
  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const autoTranslateParam = searchParams.get('autoTranslate') ?? 'true';

  const autoTranslate = autoTranslateParam === 'true';

  return autoTranslate;
};
