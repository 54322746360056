import { ExperienceSortOption } from '@understory-io/utils-types';

import { Experience } from '../features/experiences/schemas';
import type { IExperience } from '../Hooks/useExperience';
import { apiServices } from './apiServices';
import api, { IShallowSuccessResponse } from './index';

export const getExperience = async (id: string) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.get<IExperience>(
    `${apiServices.experiences}/${id}`
  );

  return data;
};

export const getExperienceForForm = async (id: string) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.get<Experience>(
    `${apiServices.experiences}/${id}`
  );

  return data;
};

export type ExperienceQueryOptions = {
  sortExperiencesBy?: ExperienceSortOption;
  defaultLanguage?: string;
  language?: string;
  type?: 'all' | 'shared' | 'owned';
};

export const getExperiences = async (options?: ExperienceQueryOptions) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.get<IExperience[]>(`${apiServices.experiences}`, {
    params: options,
  });

  return data;
};

export const updateExperience = async (
  id: string,
  payload: Record<string, any>
) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.put<IShallowSuccessResponse>(
    `${apiServices.experiences}/${id}`,
    payload
  );

  return data;
};

export const deleteExperience = async (id: string) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.delete<IShallowSuccessResponse>(
    `${apiServices.experiences}/${id}`
  );

  return data;
};
