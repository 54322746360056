import { ExpandMoreRounded } from '@mui/icons-material';
import { Divider, Stack, styled, SxProps } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { ReactNode, useState } from 'react';

import { InfoBox } from '../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../Hooks/layout/useResponsive';

/**
    Copied from event details. Candidate for sharing more globally.
*/

export const ResponsiveAccordion = ({
  title,
  children,
  defaultExpanded,
  sx,
}: {
  title: string;
  children: ReactNode;
  defaultExpanded?: boolean;
  sx?: SxProps;
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const { isMd } = useResponsive();

  if (isMd) {
    return (
      <>
        <Stack gap={3} sx={sx}>
          <StyledAccordionButton
            size="large"
            variant="text"
            rightIcon={
              <ExpandMoreRounded
                fontSize="medium"
                style={{
                  transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
                  transition: 'all 0.3s ease-in-out',
                }}
              />
            }
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          >
            <Text fontSize="medium" variant="medium">
              {title}
            </Text>
          </StyledAccordionButton>
          {expanded && children}
          <Divider />
        </Stack>
      </>
    );
  }

  return (
    <InfoBox title={title} sx={sx}>
      {children}
    </InfoBox>
  );
};

const StyledAccordionButton = styled(Button)({
  cursor: 'pointer',
  padding: 16,
  marginInline: -16,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 16,
  flexDirection: 'row',
  backgroundColor: 'unset',
});
