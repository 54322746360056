import { useTranslation } from 'react-i18next';

const localizedTermsUrls: Record<string, string> = {
  en: 'https://understory.io/terms',
  da: 'https://understory.io/da/terms',
  sv: 'https://understory.io/sv/terms',
  nb: 'https://understory.io/no/terms',
  no: 'https://understory.io/no/terms',
  nl: 'https://understory.io/nl/terms',
};

export const useTermsUrl = (): string => {
  const { i18n } = useTranslation();

  return localizedTermsUrls[i18n.resolvedLanguage] ?? localizedTermsUrls.en;
};
