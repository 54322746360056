import { ArrowForwardIosRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { Badge } from '../../../../../Components/badge/badge';
import Connected from '../../../../../Components/icons/connected';
import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { IntegrationObject } from '../../domain/use-integrations';

type ActiveIntegrationsListItemProps = {
  integration: IntegrationObject;
};

export const ActiveIntegrationsListItem = ({
  integration: { icon, title, status, onInstall },
}: ActiveIntegrationsListItemProps) => {
  const { t } = useTranslate('utils.generic');
  const { isSm } = useResponsive();

  return (
    <Stack
      onClick={() => onInstall?.(status)()}
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 1,
        padding: 1,
        cursor: 'pointer',
        userSelect: 'none',
        ':hover': {
          backgroundColor: lightTheme.palette.neutral.n100,
        },
      }}
    >
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <Box
          component="img"
          src={icon}
          sx={{
            width: 40,
            height: 40,
            borderRadius: 100,
          }}
        />
        <Text fontSize={isSm ? 'small' : 'medium'}>{title}</Text>
      </Stack>
      <Stack direction={'row'} gap={3} alignItems={'center'}>
        {!isSm && (
          <>
            <Badge appearance="success">
              <Connected /> {t('integrated')}
            </Badge>
          </>
        )}
        <ArrowForwardIosRounded
          fontSize="small"
          htmlColor={lightTheme.palette.neutral.n300}
        />
      </Stack>
    </Stack>
  );
};
