import { MenuItem, TextField } from '@mui/material';
import {
  ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { LoaderData, loaderName } from '../edit-experience';
import { getExperience } from '../queries';
import { getFormDataValue } from '../utils/form-helpers';
import { EditExperienceDialog } from './edit-experience-dialog';

const PROPERTY_NAME = 'displayVariantId';

export default function DisplayPriceForm() {
  const { experience, activeLanguage } = useRouteLoaderData(
    loaderName
  ) as LoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.displayPrice');

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
    >
      <TextField
        select
        name={PROPERTY_NAME}
        fullWidth
        defaultValue={experience.price?.displayVariantId}
      >
        {experience.price?.variants?.map((variant) => (
          <MenuItem key={variant.id} value={variant.id}>
            {`${variant.name[activeLanguage]} (${variant.priceBreakdown.vatInclusivePriceCents / 100} ${t(variant.priceBreakdown.currency.toUpperCase(), 'utils.currency')})`}
          </MenuItem>
        ))}
      </TextField>
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
} | null;

export async function action({ params, request }: ActionFunctionArgs) {
  const id = params.id;

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    const formData = await request.formData();
    const displayVariantId = getFormDataValue(formData.get(PROPERTY_NAME));

    const experience = await getExperience(id);

    const variant = experience.price?.variants?.find(
      (variant) => variant.id === displayVariantId
    );

    if (!variant) {
      throw new Response('Invalid variant id', { status: 404 });
    }

    const experienceToSave = {
      ...experience,
      price: {
        ...experience.price,
        displayVariantId: variant.id,
        displayPriceCents: variant.priceBreakdown.vatInclusivePriceCents,
      },
    };

    console.log(experienceToSave);

    return { shouldClose: true };
  } catch (error) {
    return null;
  }
}
