import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import {
  Timeline,
  TimelineProps,
} from '../../../../../../../../Components/Timeline/Timeline';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';

type BookingDetailsSummaryNotificationsMobileProps = TimelineProps;

export const BookingDetailsSummaryNotificationsMobile = ({
  activities,
}: BookingDetailsSummaryNotificationsMobileProps) => {
  const { t } = useTranslate('dialogs.booking');

  return (
    <Stack gap={2} fontSize={lightTheme.typography.small.normal.fontSize}>
      <Text variant="medium">{t('sections.notifications')}</Text>
      <Timeline activities={activities} />
    </Stack>
  );
};
