import styled from '@emotion/styled';
import { DoneOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton, Input, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useLocation,
  useNavigation,
} from 'react-router';
import { useSubmit } from 'react-router';
import { toast } from 'react-toastify';

import { ampli } from '../../../../Ampli';
import { getEvent, updateEvent } from '../../../../Api';
import { t } from '../../../../i18n/config';
import { TEvent, transformEvents } from '../../../../Utils/eventHelpers';

type EditCapacityProps = {
  event: TEvent;
  onClose: () => void;
  bookedCount: number;
};

export const EditCapacity = ({
  event,
  onClose,
  bookedCount,
}: EditCapacityProps) => {
  const { state } = useNavigation();

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [lastValue] = useState(event.seatCount.value);
  const [inputValue, setInputValue] = useState(
    event.seatCount.value.toString()
  );

  useEffect(() => {
    ampli.editCapacityOpen();
    return () => {
      ampli.editCapacityClose();
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If the value stored on the event is not equal to the
  // initial value stored on the event, it means that the
  // seat count was updated, and we can end the edit state
  useEffect(() => {
    if (lastValue !== event.seatCount.value) onClose();
  }, [event.seatCount.value, lastValue, onClose]);

  // The input is not a number input because it adds up/down
  // arrows, which is bad given the limited horizonal space.
  // This makes sure that only numbers are accepted
  const validateAndSetInputValue = useCallback(
    (value: string) => {
      if (value === '' || /^\d+$/.test(value)) {
        setInputValue(value);
        setIsError(false);
      }
    },
    [setInputValue]
  );

  const isSaving = state !== 'idle';
  const [isError, setIsError] = useState(false);

  const location = useLocation();
  const submit = useSubmit();

  const handleUpdateParticipants = useCallback(
    async (trigger: 'click' | 'enter') => {
      const newValue = parseInt(inputValue, 10);
      if (isNaN(newValue) || newValue < bookedCount || newValue === 0) {
        return setIsError(true);
      }

      // Simply end the edit state if attempting to save the same value
      // that is already stored on the event
      if (newValue === parseInt(lastValue)) return onClose();

      submit(
        { eventId: event.id, newCapacity: newValue, trigger },
        {
          method: 'post',
          action: location.pathname + '/edit-capacity' + location.search,
        }
      );
    },
    [
      inputValue,
      bookedCount,
      lastValue,
      onClose,
      submit,
      event.id,
      location.pathname,
      location.search,
    ]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleUpdateParticipants('enter');
      }
    },
    [handleUpdateParticipants]
  );

  return (
    <Stack
      ref={containerRef}
      sx={{
        height: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 0.5,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Text fontSize="xsmall">{bookedCount}</Text>
      <Text fontSize="xsmall">/</Text>
      <StyledInput
        autoFocus
        isError={isError}
        value={inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          validateAndSetInputValue(e.target.value)
        }
        onKeyDown={handleKeyDown}
        style={{ outline: 'none' }}
        disableUnderline
        onClick={(e) => e.preventDefault()}
        endAdornment={
          <StyledIconButton
            onClick={() => {
              handleUpdateParticipants('click');
            }}
          >
            <EditCapacityIcon isSaving={isSaving} isError={isError} />
          </StyledIconButton>
        }
      />
    </Stack>
  );
};

const StyledInput = styled(Input)<{ isError: boolean }>`
  padding: 0px 4px 0px 8px;
  border: 1px solid
    ${({ isError }) =>
      isError
        ? lightTheme.palette.error.e400
        : lightTheme.palette.contrast.black};
  border-radius: 8px;
  font-size: 11px;
  max-width: clamp(65px, 100%, 70px);
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
  color: inherit;
`;

export const EditCapacityIcon = ({
  isSaving,
  isError,
}: {
  isSaving: boolean;
  isError: boolean;
}) => {
  if (isSaving)
    return <CircularProgress style={{ color: 'black' }} size={14} />;
  if (isError)
    return (
      <ErrorOutlineOutlined
        style={{ color: lightTheme.palette.error.e400 }}
        fontSize="small"
      />
    );
  return <DoneOutlined fontSize="small" />;
};

export const editCapacityLoader = async ({ params }: LoaderFunctionArgs) => {
  const id = params.id;

  if (!id) {
    return redirect('/experiences');
  }

  return redirect(`/experience/${id}`);
};

export async function editCapacityAction({
  request,
  params,
}: ActionFunctionArgs) {
  try {
    const id = params.id;
    if (!id) throw new Error('Missing id');

    const formData = await request.formData();
    const eventId = formData.get('eventId');

    if (typeof eventId === 'object') {
      throw new Error('Missing eventId');
    }

    const newCapacity = formData.get('newCapacity');

    if (typeof newCapacity === 'object') {
      throw new Error('Missing capacity');
    }

    const newValue = parseInt(newCapacity, 10);
    if (isNaN(newValue)) {
      throw new Error('Capacity is invalid');
    }

    const event = await getEvent(eventId);

    const bookedCount = transformEvents([event])[0].slots?.booked ?? 0;

    if (newValue < bookedCount) {
      throw new Error('Capacity cannot be less than booked count');
    }

    const trigger = formData.get('trigger');

    if (typeof trigger !== 'object') {
      ampli.editCapacitySave({
        previousCapacity:
          typeof event.seatCount.value === 'number'
            ? event.seatCount.value
            : parseInt(event.seatCount.value, 10),
        newCapacity: newValue,
        trigger,
      });
    }

    await updateEvent(
      {
        ...event,
        seatCount: { selectedOptionKey: 'custom', value: newValue },
      },
      event.id
    );

    const url = new URL(request.url);

    // Preserve query filters in the URL
    return redirect(`/experience/${id}${url.search}`);
  } catch (error) {
    console.error(error);
    toast.error(t('eventUpsert.toast.error.editEvent'));
    const url = new URL(request.url);

    return redirect(url.toString().replace('/edit-capacity', ''));
  }
}
