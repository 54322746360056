import { Box, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import useResponsive from '../../Hooks/layout/useResponsive';
import { useApplySearchParams } from '../../Hooks/useApplySearchParams';
import { AuthStatus, useAuth } from '../../Hooks/useAuth';
import { useMenu } from '../../Hooks/useMenu';
import type { TInput } from './AuthForm';
import { AuthForm } from './AuthForm';
import { AuthLayout } from './AuthLayout';
import { CompanyPicker } from './CompanyPicker';

interface LoginFormValues {
  email: string;
  password: string;
}
interface ChooseAccountFormValues {
  org: string;
}

const inputs: Array<TInput<LoginFormValues>> = [
  {
    key: 'email',
    type: 'text',
    isRequired: true,
    autoFocus: true,
    variant: 'outlined',
  },
  {
    key: 'password',
    type: 'password',
    isRequired: true,
    variant: 'outlined',
  },
];

enum PageState {
  Login,
  ChoseOrg,
}

export const LoginPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const menu = useMenu();
  const location = useLocation();
  const { signIn, switchToOrg } = useAuth();
  const { isSm } = useResponsive();

  useApplySearchParams();

  const [pageState, setPageState] = useState<PageState>(PageState.Login);
  const [companies, setCompanies] = useState<
    { id: string; name: string; logo?: string }[]
  >([]);

  const onSubmit = async (data: LoginFormValues | ChooseAccountFormValues) => {
    const { email, password, org } = data as {
      email: string;
      password: string;
      org?: string;
    };

    const auth = await (org ? switchToOrg(org) : signIn(email, password));

    if (auth.status === AuthStatus.SELECT_ORG) {
      setCompanies(auth.companies ?? []);
      setPageState(PageState.ChoseOrg);
    } else if (auth.status === AuthStatus.AUTHENTICATED) {
      const frontPage = menu.frontPageBasedOnScopes;
      const from = location.state?.from?.pathname || frontPage || '/';
      navigate(from, { replace: true });
    } else {
      // Handle error?
      throw new Error(auth.message);
    }
  };

  const handleGoToForgotPassword = () => navigate('/forgot');

  const handleGoToCreate = () => navigate('/signup');

  return pageState === PageState.Login ? (
    <AuthLayout title={t('auth.titles.login')} showRebrandBanner>
      <Stack gap={4}>
        <AuthForm inputs={inputs} onSubmit={onSubmit}>
          {isSm && (
            <Button
              variant="text"
              size="small"
              style={{ color: lightTheme.palette.action.a300 }}
              onClick={handleGoToForgotPassword}
            >
              {t('auth.buttons.forgotPassword')}
            </Button>
          )}
        </AuthForm>

        <Stack gap={0.5}>
          {!isSm && (
            <Button
              variant="text"
              size="small"
              onClick={handleGoToForgotPassword}
            >
              {t('auth.buttons.forgotPassword')}
            </Button>
          )}
          <Button variant="text" size="small" onClick={handleGoToCreate}>
            {t('auth.buttons.createAccount')}
          </Button>
        </Stack>
      </Stack>
    </AuthLayout>
  ) : (
    <AuthLayout title={companies.length > 0 ? t('auth.titles.selectOrg') : ''}>
      <Box my={2}>
        {companies.length > 0 ? (
          <CompanyPicker onSubmit={onSubmit} companies={companies} />
        ) : (
          <LoginPageNoCompanies />
        )}
      </Box>
    </AuthLayout>
  );
};

const LoginPageNoCompanies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack gap={2}>
      <Text>{t('auth.errors.noCompanies')}</Text>
      <Button variant="primary" size="medium" onClick={() => navigate(0)}>
        {t('utils.primaryNavigation.logout')}
      </Button>
    </Stack>
  );
};
