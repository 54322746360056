import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import * as api from '../Api';
import { TTerms } from '../Api/Terms';
import { useProfile } from './useProfile';

export const useTerms = () => {
  const queryClient = useQueryClient();
  const {
    company: { data: company },
  } = useProfile();

  const TermsQueryKey = ['terms'];

  const terms = useQuery({
    queryKey: TermsQueryKey,

    queryFn: async () => {
      return api.getTerms();
    },

    enabled: !!company?.id,
    retry: false,
  });

  const updateTerms = useMutation({
    mutationFn: ({
      type,
      payload,
    }: {
      type: string;
      payload: { [lang: string]: { content: string } };
    }) => api.updateTerms(type, payload),

    onMutate: async ({ type, payload }) => {
      await queryClient.cancelQueries({
        queryKey: TermsQueryKey,
      });

      const previous = queryClient.getQueryData(TermsQueryKey);

      queryClient.setQueryData<TTerms>(TermsQueryKey, (prev) => {
        return {
          ...prev,
          [type]: {
            ...(prev?.[type] ?? {}),
            versions: Object.entries(payload).reduce(
              (updated, [lang, latest]) => {
                return {
                  ...updated,
                  [lang]: latest,
                };
              },
              prev?.[type]?.versions ?? {}
            ),
          },
        };
      });

      return { previous };
    },

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData(TermsQueryKey, context.previous);
      }
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: TermsQueryKey,
      });
    },
  });

  return {
    terms,
    updateTerms,
  };
};
