import styled from '@emotion/styled';
import { Skeleton, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { isSameDay } from 'date-fns';
import { FC, useMemo } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { useCalendar } from '../../context';
import { GuestCountText } from '../../shared/GuestCountText';

type Props = {
  date: Date;
};

export const EventsSummary: FC<Props> = ({ date }) => {
  const { events, isLoading } = useCalendar();

  const { t } = useTranslate('utils.generic');

  const { numberOfEvents, bookedSeats, totalSeats } = useMemo(() => {
    if (!events) return { numberOfEvents: 0, bookedSeats: 0, totalSeats: 0 };

    const eventsOnDate = events.filter((event) =>
      isSameDay(new Date(event.startDateTime), date)
    );
    return {
      numberOfEvents: eventsOnDate.length,
      bookedSeats: eventsOnDate.reduce(
        (acc, event) => acc + (event.slots.booked ?? 0),
        0
      ),
      totalSeats: eventsOnDate.reduce(
        (acc, event) => acc + event.slots.total,
        0
      ),
    };
  }, [date, events]);

  if (isLoading)
    return (
      <Stack>
        <Skeleton width={90} />
        <Skeleton width={65} />
      </Stack>
    );

  if (numberOfEvents === 0) return null;

  return (
    <StyledStack>
      <Text variant="medium" fontSize="xsmall">
        {numberOfEvents} {t('countEvents')}
      </Text>
      <Stack direction="row" gap={0.5} alignItems="center">
        <BookingIndicator $success={bookedSeats < totalSeats} />
        <GuestCountText
          booked={bookedSeats}
          total={totalSeats}
          variant="medium"
          fontSize="xsmall"
          color={lightTheme.palette.neutral.n300}
        />
      </Stack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)({
  padding: 8,
  justifyContent: 'flex-start',
  width: '100%',
});

const BookingIndicator = styled.div<{ $success?: boolean }>`
  width: 4px;
  height: 4px;

  background-color: ${({ $success }) =>
    $success ? lightTheme.palette.success.s300 : lightTheme.palette.error.e300};
  border-radius: 50%;
`;
