import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ReactNode } from 'react';

import { useTranslate } from '../../../Hooks/useTranslate';
import { useOnboardingFlow } from '../onboarding-context';
import { ExperiencePreview } from '../preview-experience/experience-preview';

export const OnboardingStepLayout = ({
  titleIcon,
  customDescription,
  children,
  showPreview,
  boxContent,
}: {
  titleIcon?: ReactNode;
  customDescription?: ReactNode;
  children?: ReactNode;
  showPreview?: boolean;
  boxContent?: ReactNode;
}) => {
  const { currentStepKey } = useOnboardingFlow();
  const { t } = useTranslate(`onboarding.dialog.step.${currentStepKey}`);

  return (
    <Stack
      maxWidth="100%"
      height="100%"
      sx={{
        flexDirection: { md: 'row' },
        gap: { md: 4, lg: 10 },
        paddingBottom: { xs: 6, md: 'unset' },
        overflow: { xs: 'auto', md: 'unset' },
      }}
    >
      <Stack flexGrow={1} maxWidth="100%">
        <Stack gap={1} marginBottom={4}>
          <Stack direction="row" gap={1} alignItems="center">
            <Text variant="medium" fontSize="xlarge">
              {t('title')}
            </Text>
            {titleIcon}
          </Stack>
          {customDescription ?? <Text>{t('description')}</Text>}
        </Stack>
        {children}
      </Stack>
      {(boxContent || showPreview) && (
        <Stack
          sx={{
            display: { xs: boxContent ? 'flex' : 'none', md: 'flex' },
            aspectRatio: { md: '1 / 1' },
            height: { md: '446px' },
            boxShadow: !boxContent ? lightTheme.shadows.xlarge : 'unset',
            justifyContent: 'center',
          }}
        >
          {boxContent ?? <ExperiencePreview />}
        </Stack>
      )}
    </Stack>
  );
};
