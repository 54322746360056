import {
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  Select,
  Stack,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ReactNode } from 'react';

type CustomSelectProps = {
  selectedValue: string | string[];
  options: CustomMenuItemProps[];
  footerOptions?: CustomMenuItemProps[];
  onChange: (value: string | string[]) => void;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  multiple?: boolean;
  emptyLabel?: string;
  Icon?: ReactNode;
  error?: boolean;
  helperText?: string;
};

/**
 * @param options - If you pass a `undefined` value it wont show up as selected
 * @param footerOptions - If you pass a `undefined` value it wont show up as selected
 */

export const CustomSelect = ({
  selectedValue,
  options,
  footerOptions,
  onChange,
  onClose,
  onOpen,
  open,
  multiple = false,
  emptyLabel,
  Icon,
  error,
  helperText,
}: CustomSelectProps) => {
  return (
    <Stack sx={{ gap: 0.5 }}>
      <Select
        multiple={multiple}
        value={selectedValue}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        onChange={(e) => {
          const value = e.target.value;
          if (Array.isArray(value)) {
            const filteredValues = value.filter((v) => v !== undefined);
            onChange(filteredValues);
          } else {
            onChange(value);
          }
        }}
        inputProps={{
          sx: {
            paddingY: '6.5px',
            paddingX: 1.5,
          },
        }}
        displayEmpty
        renderValue={(value) => (
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 1,
              color: lightTheme.palette.neutral.n300,
            }}
          >
            {Icon}
            <Text fontSize="small" color={lightTheme.palette.contrast.black}>
              {renderSelectValue(value, options, multiple, emptyLabel)}
            </Text>
          </Stack>
        )}
        size="small"
        error={error}
      >
        {options.map(({ label, ...props }, index) => (
          <CustomMenuItem key={label + index} label={label} {...props} />
        ))}
        {footerOptions?.map(({ label, ...props }, index) => (
          <CustomMenuItem
            key={label + index}
            label={label}
            sx={{
              borderTop: index === 0 ? 1 : 0,
              borderColor: lightTheme.palette.neutral.n100,
            }}
            {...props}
          />
        ))}
      </Select>
      {helperText && (
        <Text fontSize="xsmall" color={lightTheme.palette.error.e400}>
          {helperText}
        </Text>
      )}
    </Stack>
  );
};

const renderSelectValue = (
  value: CustomSelectProps['selectedValue'],
  options: CustomSelectProps['options'],
  multiple: CustomSelectProps['multiple'],
  emptyLabel: CustomSelectProps['emptyLabel']
) => {
  if (!value || value.length === 0) return emptyLabel;

  if (Array.isArray(value) && multiple) {
    if (value.length > 1) {
      return `${value.length} selected`;
    }
    return options.find((option) => option.value === value[0])?.label;
  }

  return options.find((option) => option.value === value)?.label;
};

interface CustomMenuItemProps extends MenuItemProps {
  label: string;
  icon?: ReactNode;
}

function CustomMenuItem({ label, icon, sx, ...props }: CustomMenuItemProps) {
  return (
    <MenuItem
      sx={{
        paddingX: 2,
        paddingY: 1,
        paddingRight: 2,
        margin: '2px',
        ...sx,
      }}
      focusVisibleClassName={undefined}
      {...props}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <Text fontSize="small" fontWeight="normal">
        {label}
      </Text>
    </MenuItem>
  );
}
