import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Form, useSubmit } from 'react-router';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateResourceInputs } from './create-resource-inputs';

type Props = {
  resourceType: ResourceType;
};

export const CreateResourceForm: FC<Props> = ({ resourceType }) => {
  const { t } = useTranslate('resourceManagement.resource');

  const formMethods = useForm<CreateResourceInputs>({
    defaultValues: {
      resourceTypeId: resourceType.resourceTypeId,
      label: '',
      available: true,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = formMethods;

  const submit = useSubmit();
  const onSubmit = async (data: CreateResourceInputs) =>
    submit(data, { method: 'post', encType: 'application/json' });

  return (
    <FormProvider {...formMethods}>
      <Stack
        component={Form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        gap={4}
        pt={2}
      >
        <div>
          <FormLabel id="resourceType">
            <Text fontSize="small" variant="medium">
              {t('resourceType', 'resourceManagement.resourceType')}
            </Text>
          </FormLabel>

          <Select
            type="text"
            id="resourceTypeId"
            value={resourceType.resourceTypeId}
            label="Resource type"
            size="medium"
            disabled
            fullWidth
          >
            <MenuItem value={resourceType.resourceTypeId}>
              {resourceType.name}
            </MenuItem>
          </Select>
        </div>

        <TextField
          {...register('label', {
            required: {
              value: true,
              message: t('required', 'utils.errors'),
            },
          })}
          size="medium"
          fullWidth
          type="text"
          label={t('createNew.label')}
          placeholder={t('createNew.placeholder.label')}
          error={!!errors.label?.message}
          helperText={errors.label?.message}
          InputProps={{ sx: { maxHeight: 48 } }}
          InputLabelProps={{ shrink: true }}
        />
        <FormControlLabel
          sx={{
            mt: -2,
            mb: -2,
          }}
          label={t('available')}
          control={
            <Controller
              name="available"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
          }
        />

        <Button
          type="submit"
          variant="primary"
          size="large"
          disabled={isSubmitting}
        >
          {t('createNew.submitLabel')}
        </Button>
      </Stack>
    </FormProvider>
  );
};
