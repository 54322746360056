import { lightTheme } from '@understory-io/pixel';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { DashboardGraphsLoaderCardSeriesGraphTooltip } from './dashboard-graphs-loader-card-series-graph-tooltip';

/** This multiplier is used to calculate the width of the YAxis component based on the longest label in the chart.
 * 11 was chosen based on the average width of a character.
 */
const LONGEST_LABEL_LENGTH_MULTIPLIER = 11;

type DashboardGraphsLoaderCardSeriesGraphProps = {
  name: string;
  data: {
    keys: string[];
    values: {
      value: number;
      rest: { key: string; value: number }[];
    }[];
  };
  isPercentage: boolean;
};

export const DashboardGraphsLoaderCardSeriesGraph = ({
  name,
  data: { keys: labels, values: series },
  isPercentage,
}: DashboardGraphsLoaderCardSeriesGraphProps) => {
  const chartData = labels.map((label, index) => ({
    label,
    index,
    value: series[index].value,
    allData: series,
  }));

  const longestLabelLength = chartData
    .map((data) => data.value.toLocaleString().length)
    .reduce((a, b) => Math.max(a, b), 0);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={chartData}>
        <defs>
          <linearGradient id="value" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={lightTheme.palette.action.a500}
              stopOpacity={0.1}
            />
            <stop
              offset="95%"
              stopColor={lightTheme.palette.action.a500}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="label"
          axisLine={false}
          tickLine={false}
          tick={{ fill: lightTheme.palette.neutral.n300 }}
          fontSize={lightTheme.typography.tiny.normal.fontSize}
          tickMargin={20}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fill: lightTheme.palette.neutral.n300 }}
          tickFormatter={(value) => formatYAxisValue(value, isPercentage)}
          fontSize={lightTheme.typography.xsmall.normal.fontSize}
          width={longestLabelLength * LONGEST_LABEL_LENGTH_MULTIPLIER}
        />
        <Tooltip
          content={(props: TooltipProps<number, string>) => (
            <DashboardGraphsLoaderCardSeriesGraphTooltip
              {...props}
              isPercentage={isPercentage}
              seriesName={name}
            />
          )}
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke={lightTheme.palette.action.a400}
          strokeWidth={2}
          fill="url(#value)"
          fillOpacity={1}
          animationDuration={500}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const formatYAxisValue = (value: number, isPercentage = false) => {
  return isPercentage ? `${value}%` : value.toLocaleString();
};
