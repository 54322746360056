import { Stack } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';

import { useGetBookingsForEvent } from '../../../../../../Hooks/data/useBookings';
import { usePageBreadcrumbNavigation } from '../../../domain/use_page_breadcrumb_navigation';
import { PageBreadcrumbNavigationButton } from '../navigation/PageBreadcrumbNavigationButton';

type Props = {
  bookingId: string;
  eventId: string;
};

export const PageBreadcrumbBookingNavigation: FC<Props> = ({
  bookingId,
  eventId,
}) => {
  const navigate = useNavigate();

  const {
    bookingsForEvent: { data: bookingsForEventData },
  } = useGetBookingsForEvent(eventId ?? '');

  const { bookingsForEvent } = usePageBreadcrumbNavigation({
    bookingsForEventData,
  });

  const handleNavigation = (index: number) => {
    navigate(`/booking/${bookingsForEvent[index]}`, { replace: true });
  };

  return (
    <Stack direction="row" gap={1}>
      <PageBreadcrumbNavigationButton
        direction="up"
        bookingId={bookingId ?? ''}
        bookingsForEvent={bookingsForEvent}
        onNavigate={handleNavigation}
      />
      <PageBreadcrumbNavigationButton
        direction="down"
        bookingId={bookingId ?? ''}
        bookingsForEvent={bookingsForEvent}
        onNavigate={handleNavigation}
      />
    </Stack>
  );
};
