import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

import { ampli } from '../../Ampli';
import { useFireOnce } from '../../Hooks/useFireOnce';
import { useProfile } from '../../Hooks/useProfile';
import { changeSearchParam } from '../../Utils/search-params';
import {
  FILTER_PARAMS,
  SAVE_PARAM_ON_PROFILE,
  SearchParams,
  serializers,
} from './search-params/calendar-search-params';
import { SAVED_CALENDAR_KEY } from './use-calendar-search-params';

export const useRestoreUserPreferences = () => {
  const { preferences, me } = useProfile();

  const [searchParams, setSearchParams] = useSearchParams();

  const fireOnce = useFireOnce();

  // If date is missing in query param, we change the url to the current setting,
  // using the profile as the default.
  useEffect(() => {
    if (!me.isSuccess) return;

    if (searchParams.get('date')) {
      fireOnce(() =>
        ampli.calendarPageViewed({
          source: 'unknown',
        })
      );
      return;
    }

    const calendar = preferences[SAVED_CALENDAR_KEY] ?? {};

    let newParameters: Partial<Record<SearchParams, string>> = {
      date: serializers.date.serialize(new Date()),
      eventStatus: 'active',
    };

    const listOfSavedFilters: string[] = [];

    Object.values(SAVE_PARAM_ON_PROFILE).forEach((name) => {
      const savedValue = calendar[name];
      if (!savedValue) return;

      if (FILTER_PARAMS.includes(name)) listOfSavedFilters.push(name);

      newParameters = changeSearchParam(
        new URLSearchParams(newParameters),
        name,
        savedValue
      );
    });

    fireOnce(() =>
      ampli.calendarPageViewed({
        source: 'unknown',
        group_by_saved: calendar['grouped'] === 'true',
        date_view_saved: calendar['view'],
        list_of_filters_saved: listOfSavedFilters,
        number_of_filters_saved: listOfSavedFilters.length,
      })
    );

    setSearchParams(newParameters);
  }, [fireOnce, me.isSuccess, preferences, searchParams, setSearchParams]);
};
