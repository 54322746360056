import { PencilIcon } from '@heroicons/react/24/solid';
import { CheckOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { TagFilterItem } from '../tags';

type TagListItemProps = {
  isSelected: boolean;
  tag: TagFilterItem;
  onSelectTag: (tag: TagFilterItem) => void;
  onSelectEdit: (tag: TagFilterItem) => void;
};

export const TagListItem = ({
  isSelected,
  tag,
  onSelectTag,
  onSelectEdit,
}: TagListItemProps) => {
  return (
    <Stack
      component="button"
      type="button"
      sx={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        paddingY: 1,
        paddingX: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '#edit': {
          visibility: 'hidden',
        },
        ':hover': {
          backgroundColor: lightTheme.palette.neutral.n100,
        },
        ':focus-visible, :hover': {
          '#edit': {
            visibility: 'visible',
          },
        },
      }}
      onClick={() => onSelectTag(tag)}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          flexGrow: 1,
          widows: '100%',
        }}
      >
        <Text
          fontSize="small"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '75%',
            textOverflow: 'ellipsis',
          }}
        >
          {tag.label}
        </Text>
        {isSelected && <CheckOutlined fontSize="small" />}
      </Stack>
      <PencilIcon
        id="edit"
        height={20}
        width={20}
        cursor="pointer"
        onClick={(e) => {
          // Do not trigger add event
          e.stopPropagation();

          onSelectEdit(tag);
        }}
      />
    </Stack>
  );
};
