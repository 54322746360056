import { CheckOutlined, ClearOutlined } from '@mui/icons-material';
import { OutlinedInput, Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

type EditTagProps = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onClearEditing: () => void;
};

export const EditTag = ({
  value,
  onChange,
  onSubmit,
  onClearEditing,
}: EditTagProps) => {
  return (
    <Stack>
      <OutlinedInput
        autoFocus
        value={value}
        onChange={(e) => onChange(e.target.value)}
        size="small"
        inputProps={{ sx: { fontSize: '14px' } }}
        endAdornment={
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <ClearOutlined
              sx={{
                cursor: 'pointer',
                color: lightTheme.palette.error.e300,
              }}
              onClick={onClearEditing}
            />
            <CheckOutlined
              sx={{
                cursor: 'pointer',
                color: lightTheme.palette.success.s300,
              }}
              onClick={onSubmit}
            />
          </Stack>
        }
      />
    </Stack>
  );
};
