import { CloseRounded } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { FC } from 'react';

import { StatusChip } from '../../../Components/CustomCard/CustomCard';
import { Link } from '../../../Components/Link/Link';
import { TBooking } from '../../../Hooks/useBookings';
import { useExperience } from '../../../Hooks/useExperience';
import { useTranslate } from '../../../Hooks/useTranslate';
import { getGuestCount, renderGuestTypes } from '../../../Utils/eventHelpers';
import { TicketDetailsEventDate } from './ticket-details-event-date';
import { TicketDetailsExperienceName } from './ticket-details-experience-name';
import { TicketDetailsPaymentStatus } from './ticket-details-payment-status';

type Props = {
  booking: TBooking;
  onCheckIn: () => void;
  isCheckInLoading: boolean;
  onClose: () => void;
};

export const TicketDetails: FC<Props> = ({
  booking,
  isCheckInLoading: checkInLoading,
  onCheckIn,
  onClose,
}) => {
  const { t, i18n } = useTranslate('utils.tables.header');

  const {
    experience: { data: experience },
  } = useExperience(booking.experienceId);

  const guestCount = getGuestCount(booking.items);

  const disableCheckInButton =
    booking.status !== 'active' && booking.status !== 'unpaid';

  return (
    <>
      <IconButton
        sx={{ position: 'absolute', top: 16, right: 16 }}
        onClick={onClose}
      >
        <CloseRounded htmlColor="black" />
      </IconButton>

      <Stack gap={3} flex={1}>
        <Stack gap={0.5}>
          <Stack direction="row" gap={1}>
            <Text variant="medium" fontSize="large">
              {guestCount} {guestCount > 1 ? t('guests') : t('guest')}
            </Text>

            <StatusChip
              sx={{ fontSize: { xs: '12px', md: '14px' } }}
              size={'small'}
              status={booking.status}
            />
          </Stack>
          <Text variant="normal" fontSize="medium">
            {renderGuestTypes(
              booking,
              'variant',
              i18n.language,
              experience?.price?.variants
            ).join(', ')}
          </Text>
          <Text variant="normal" fontSize="medium">
            {renderGuestTypes(
              booking,
              'addon',
              i18n.language,
              experience?.price?.variants
            ).join(', ')}
          </Text>
        </Stack>
        <Stack gap={0.5}>
          <TicketDetailsExperienceName experienceId={booking.experienceId} />
          <TicketDetailsEventDate eventId={booking.eventId} />
          <TicketDetailsPaymentStatus receiptId={booking.receiptId} />

          <Text variant="normal" fontSize="medium">
            {booking.customer.name}
          </Text>
        </Stack>

        <Link href={`/booking/${booking.id}`}>View booking details</Link>

        <Stack flex={1} justifyContent={'flex-end'}>
          <Button
            size={'large'}
            variant={'primary'}
            fullWidth
            onClick={() => onCheckIn()}
            disabled={disableCheckInButton || checkInLoading}
          >
            {t(booking.status, 'checkin.scan.ticketDetails.button', {
              defaultValue: t('checkIn', 'dialogs.booking.actions'),
            })}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
