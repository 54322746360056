import { useQuery } from '@tanstack/react-query';

import { getResources } from '../../../Api/resource-management';
import { useProfile } from '../../../Hooks/useProfile';

export const resourcesQuery = (resourceTypeId: string) => ({
  queryKey: ['resource-management', 'resources', resourceTypeId],
  queryFn: () => getResources(resourceTypeId),
});

export const useResources = (resourceTypeId: string) => {
  const { company } = useProfile();

  return useQuery({
    ...resourcesQuery(resourceTypeId),
    enabled: !!company.data?.id,
  });
};
