import { Stack, TextField } from '@mui/material';
import { Experience } from '@understory-io/utils-types';
import {
  intervalToDuration,
  secondsInDay,
  secondsInHour,
  secondsInMinute,
} from 'date-fns';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';

import { loaderName } from '../edit-experience';
import { getExperience } from '../queries';
import { EditExperienceDialog } from './edit-experience-dialog';

const PROPERTY_NAME = 'cutoffTimeSeconds';

export default function CutoffTimeForm() {
  const actionData = useActionData() as ActionData;

  const { t } = useTranslation();

  const { experience } = useRouteLoaderData(loaderName) as {
    experience: Experience;
  };

  const duration = intervalToDuration({
    start: 0,
    end: (experience.cutoffTimeSeconds || 0) * 1000,
  });

  const [timeSpan, setTimeSpan] = useState({
    days: duration.days ?? 0,
    hours: duration.hours ?? 0,
    minutes: duration.minutes ?? 0,
  });

  const value = useMemo(() => {
    const daysSeconds = timeSpan.days * secondsInDay;
    const hoursSeconds = timeSpan.hours * secondsInHour;
    const minutesSeconds = timeSpan.minutes * secondsInMinute;
    return daysSeconds + hoursSeconds + minutesSeconds;
  }, [timeSpan]);

  return (
    <EditExperienceDialog
      title="Cutoff time"
      description="Edit cutoff time"
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
    >
      <Stack sx={{ gap: 2 }}>
        <input name={PROPERTY_NAME} type="number" value={value} hidden />
        <TextField
          label={t('utils.generic.days')}
          type="number"
          value={timeSpan.days.toString()}
          fullWidth
          onChange={(e) =>
            setTimeSpan({
              ...timeSpan,
              days: parseInt(e.target.value || '0', 10),
            })
          }
          InputProps={{ inputProps: { min: 0 } }}
        />
        <TextField
          label={t('utils.generic.hours')}
          type="number"
          value={timeSpan.hours.toString()}
          fullWidth
          onChange={(e) =>
            setTimeSpan({
              ...timeSpan,
              hours: parseInt(e.target.value || '0', 10),
            })
          }
          InputProps={{ inputProps: { min: 0, max: 23 } }}
        />
        <TextField
          label={t('utils.generic.minutes')}
          type="number"
          value={timeSpan.minutes.toString()}
          fullWidth
          onChange={(e) =>
            setTimeSpan({
              ...timeSpan,
              minutes: parseInt(e.target.value || '0', 10),
            })
          }
          InputProps={{ inputProps: { min: 0, max: 59 } }}
        />
      </Stack>
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
};

export async function action({ params, request }: ActionFunctionArgs) {
  const id = params.id;

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    // Validate input
    const formData = await request.formData();
    const cutoffTimeSeconds = formData.get(PROPERTY_NAME);

    if (typeof cutoffTimeSeconds === 'object') {
      throw new Error('Invalid input');
    }

    const cutoffTimeSecondsNumber = parseInt(cutoffTimeSeconds, 10);

    if (isNaN(cutoffTimeSecondsNumber) || cutoffTimeSecondsNumber < 0) {
      throw new Error('Invalid input');
    }

    // Update experience
    const experience = await getExperience(id);
    experience.cutoffTimeSeconds = cutoffTimeSecondsNumber;

    // 3. Save draft
    // TODO: US-2713

    return { shouldClose: true };
  } catch (error) {
    return null;
  }
}
