import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { PropsWithChildren, useCallback } from 'react';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useLoaderData,
  useLocation,
  useNavigate,
  useSubmit,
} from 'react-router';

import { Language } from '../../../i18n/config';
import { BasicExperienceFields as BasicExperienceFieldsType } from '../schemas/basicExperienceFieldsSchema';
import { BasicExperienceFields } from './basic-experience-fields';
import { DetailsOverview } from './details-overview';
import { EditExperienceHeader } from './header/edit-experience-header';
import { MediaUpload } from './media-upload';
import { MoreOptions } from './more-options';
import {
  getCompanyProfile,
  getExperience,
  getLocations,
  getTags,
} from './queries';
import { getFormDataValue } from './utils/form-helpers';
import { getActiveLanguage } from './utils/get-active-language';
import { getAutoTranslate } from './utils/get-auto-translate';
import { translateInput } from './utils/translate-input';

export type LoaderData =
  ReturnType<typeof loader> extends Promise<infer R> ? R : never;

export const loaderName = 'EditExperienceLoader';

export async function loader({ params, request }: LoaderFunctionArgs) {
  const id = params.id;
  if (!id) {
    throw new Response(null, {
      status: 404,
    });
  }

  const [experience, companyProfile, tags] = await Promise.all([
    getExperience(id),
    getCompanyProfile(),
    getTags(),
  ]);

  const locations = await getLocations(companyProfile.id);

  const activeLanguage = getActiveLanguage(request, companyProfile);
  const autoTranslate = getAutoTranslate(request);
  return {
    tags,
    experience,
    activeLanguage,
    availableLanguages: companyProfile.languages as Language[],
    locations,
    autoTranslate,
    defaultCurrency: companyProfile.defaultCurrency,
    vatRegistrations: companyProfile.vatCompliance.vatRegistrations,
  };
}

export async function action({ request, params }: ActionFunctionArgs) {
  const id = params.id;
  if (!id) {
    throw new Response(null, {
      status: 404,
    });
  }

  const [experience, companyProfile] = await Promise.all([
    getExperience(id),
    getCompanyProfile(),
  ]);

  const activeLanguage = getActiveLanguage(request, companyProfile);

  const formData = await request.formData();
  const headline = getFormDataValue(formData.get('headline'));
  const locationIds = getFormDataValue(formData.get('locationIds'));
  const visibility = getFormDataValue(formData.get('visibility'));
  const shouldTranslate =
    getFormDataValue(formData.get('shouldTranslate')) === 'true';

  const newHeadline = await translateInput(
    headline,
    experience.headline,
    activeLanguage,
    companyProfile.languages as Language[],
    shouldTranslate
  );

  const experienceToSave = {
    ...experience,
    headline: newHeadline,
    locationIds,
    visibility,
  };

  console.log(experienceToSave);

  // Delay to simulate saving
  await new Promise((res) =>
    setTimeout(() => {
      res(null);
    }, 2000)
  );

  // TODO: Save draft

  return {};
}

export default function EditExperience() {
  const {
    experience,
    locations,
    activeLanguage,
    availableLanguages,
    autoTranslate,
    tags,
  } = useLoaderData() as LoaderData;
  // TODO: Submit change to be saved on experience

  const submit = useSubmit();
  const location = useLocation();
  const navigate = useNavigate();

  const handleChangeAutoTranslate = useCallback(
    (isEnabled: boolean) => {
      const newParams = new URLSearchParams(location.search);
      newParams.set('autoTranslate', `${isEnabled}`);
      navigate(`${location.pathname}?${newParams.toString()}`, {
        replace: true,
      });
    },
    [location, navigate]
  );

  const handleChangeLanguage = useCallback(
    (language: Language) => {
      const newParams = new URLSearchParams(location.search);
      newParams.set('language', language);
      navigate(`${location.pathname}?${newParams.toString()}`, {
        replace: true,
      });
    },
    [location, navigate]
  );

  const onSubmit = useCallback(
    (data: BasicExperienceFieldsType) => {
      submit(data, { method: 'post' });
    },
    [submit]
  );

  return (
    <Stack sx={{ gap: 2 }}>
      <EditExperienceHeader
        experience={experience}
        activeLanguage={activeLanguage}
        availableLanguages={availableLanguages}
        handleChangeLanguage={handleChangeLanguage}
        isAutoTranslateEnabled={autoTranslate}
        handleChangeAutoTranslate={handleChangeAutoTranslate}
      />
      <ExperienceFormWrapper>
        <MediaUpload />
        <BasicExperienceFields
          key={activeLanguage}
          locations={locations}
          defaultValues={{
            locationIds: experience.locationIds ?? [],
            visibility: experience.visibility,
            headline: experience.headline?.[activeLanguage] ?? '',
          }}
          onSubmit={onSubmit}
        />
        <DetailsOverview
          experience={experience}
          activeLanguage={activeLanguage}
          tags={tags}
        />
        <MoreOptions experience={experience} />
      </ExperienceFormWrapper>
    </Stack>
  );
}

type ExperienceFormWrapperProps = PropsWithChildren;

const CONTENT_MAX_WIDTH_PX = 900;

const ExperienceFormWrapper = ({ children }: ExperienceFormWrapperProps) => {
  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: CONTENT_MAX_WIDTH_PX,
        padding: { xs: 2, md: 4 },
        backgroundColor: lightTheme.palette.contrast.surface2,
        borderRadius: { md: 1 },
        gap: 3,
        marginX: 'auto',
      }}
    >
      {children}
    </Stack>
  );
};
