import { GiftCardStatus } from '@understory-io/utils-types';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import { ReactNode } from 'react';

import { StatusBadge } from '../../../../../Components/badge/status-badge';
import { TranslateFunction } from '../../../../../Hooks/useTranslate';
import { TBoughtVoucher } from '../../../../../Hooks/useVoucher';

export const useGiftCardsList = () => {
  const dialogRows: TDialogRow[] = [
    { key: 'code', value: (el) => el.code },
    {
      key: 'createdDate',
      value: (el) => format(new Date(el.createdDate), 'PPPp'),
    },
    { key: 'expireAt', value: (el) => format(new Date(el.expiresAt), 'PPPp') },
    {
      key: 'totalAmount',
      value: (el) => `${el?.originalAmount} ${el?.currency.toUpperCase()}`,
    },
    {
      key: 'availableAmount',
      value: (el) => `${el?.amountLeft} ${el?.currency.toUpperCase()}`,
    },
    {
      key: 'refund',
      value: (el) =>
        el.refund?.date && format(new Date(el.refund.date), 'PPPp'),
    },
    { key: 'customerName', value: (el) => el.customer.name },
    { key: 'customerEmail', value: (el) => el.customer.email ?? '' },
    {
      key: 'customerPhone',
      value: (el) => el.customer?.phone ?? 'Ikke angivet',
    },
  ];

  const listColumns = [
    {
      key: 'email',
      props: {
        xs: 2,
      },
      value: (el: TBoughtVoucher) => el?.customer?.email,
    },
    {
      key: 'created',
      props: {
        xs: 2,
      },
      value: (el: TBoughtVoucher) =>
        el?.createdDate
          ? format(new Date(el?.createdDate), 'PPP', { locale: da })
          : '',
    },
    {
      key: 'code',
      props: {
        xs: 1.5,
      },
      value: (el: TBoughtVoucher) => el?.code,
    },
    {
      key: 'totalAmount',
      props: {
        xs: 1.5,
      },
      value: (el: TBoughtVoucher) => {
        if (!el?.currency) {
          console.warn('No currency found for voucher', el.id);
          return `${el?.originalAmount} DKK`;
        }
        return `${el?.originalAmount} ${el?.currency.toUpperCase()}`;
      },
    },
    {
      key: 'amountLeft',
      props: {
        xs: 1.5,
      },
      value: (el: TBoughtVoucher) => {
        if (!el?.currency) {
          console.warn('No currency found for voucher', el.id);
          return `${el?.amountLeft} DKK`;
        }
        return `${el?.amountLeft} ${el?.currency.toUpperCase()}`;
      },
    },
    {
      key: 'expiry',
      value: (el: TBoughtVoucher) =>
        el?.expiresAt
          ? format(new Date(el.expiresAt ?? ''), 'PPP', { locale: da })
          : '',
      props: {
        xs: 2,
      },
    },
    {
      key: 'status',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      value: (el: TBoughtVoucher, _: TranslateFunction) => {
        return (
          el?.status && (
            <StatusBadge
              type="giftcard"
              size="small"
              state={el.status as GiftCardStatus}
            />
          )
        );
      },
    },
  ];

  const statusColorMap: Record<
    string,
    'success' | 'warning' | 'error' | 'default'
  > = {
    active: 'success',
    expired: 'warning',
    cancelled: 'error',
    spent: 'default',
  };

  return {
    dialogRows,
    listColumns,
    statusColorMap,
  };
};

type TDialogRow = {
  key: string;
  value: (
    el: TBoughtVoucher,
    t: TranslateFunction
  ) => string | number | ReactNode;
};
