import { ArrowRight } from '@mui/icons-material';
import { Card, Stack } from '@mui/material';
import { Skeleton, Text } from '@understory-io/pixel';
import { ResourceType } from '@understory-io/resource-management-types';
import { Suspense } from 'react';
import { Await, Link } from 'react-router';

import routes from '../../../Utils/routes';

type Props = {
  resourceTypes: Promise<ResourceType[]>;
};

export const ResourceTypesList = ({ resourceTypes }: Props) => {
  return (
    <Stack gap={2} marginTop={5}>
      <Suspense
        fallback={
          <>
            <Skeleton variant={'rectangular'} height={50} width={'100%'} />
            <Skeleton variant={'rectangular'} height={50} width={'100%'} />
          </>
        }
      >
        <Await resolve={resourceTypes}>
          {(resourceTypes) =>
            resourceTypes.map((item) => (
              <ListItem item={item} key={item.resourceTypeId} />
            ))
          }
        </Await>
      </Suspense>
    </Stack>
  );
};

const ListItem = ({ item }: { item: ResourceType }) => {
  return (
    <Link
      to={routes.resourceManagement.types.details(item.resourceTypeId).index}
    >
      <Card>
        <Stack justifyContent={'space-between'} direction={'row'} gap={2}>
          <Text>{item.name}</Text>
          <ArrowRight />
        </Stack>
      </Card>
    </Link>
  );
};
