import { Axios } from 'axios';

const NEXT_CURSOR_HEADER = 'next-cursor';
const NEXT_CURSOR_QUERY = 'nextCursor';

export const fetchAllPages = async <T>(
  api: Axios,
  baseUrl: string,
  params?: Partial<Record<string, unknown>>
): Promise<T[]> => {
  const { data, headers } = await api.get<T[]>(baseUrl, { params });

  const nextCursor = headers[NEXT_CURSOR_HEADER];
  if (!nextCursor || nextCursor === params?.[NEXT_CURSOR_QUERY]) {
    return data;
  }

  return data.concat(
    await fetchAllPages<T>(api, baseUrl, {
      ...params,
      [NEXT_CURSOR_QUERY]: nextCursor,
    })
  );
};
