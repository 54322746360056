import { Stack, Switch } from '@mui/material';
import { Text } from '@understory-io/pixel';

export type AutoTranslateToggleProps = {
  isAutoTranslateEnabled: boolean;
  onChange: (enabled: boolean) => void;
};

export const AutoTranslateToggle = ({
  isAutoTranslateEnabled,
  onChange,
}: AutoTranslateToggleProps) => {
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
      <Text fontSize="small">Auto-translate</Text>
      <Switch
        checked={isAutoTranslateEnabled}
        onChange={(_, checked) => onChange(checked)}
      />
    </Stack>
  );
};
