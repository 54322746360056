import { Experience } from '@understory-io/utils-types';

import { ampli } from '../../Ampli';
import { getLocalized } from '../../Hooks/useBookings';
import {
  endDateEventProperties,
  startDateEventProperties,
} from './trackOverviewPageViewed';

export const trackOverviewStartDateOpened = () => {
  ampli.overviewStartDateOpened();
};

export const trackOverviewStartDateSelected = (
  new_start_date: Date,
  current_end_date: Date
) => {
  const dateEventProperties = startDateEventProperties(
    new_start_date,
    current_end_date
  );

  ampli.overviewStartDateSelected(dateEventProperties);
};

export const trackOverviewEndDateOpened = () => {
  ampli.overviewEndDateOpened();
};

export const trackOverviewEndDateSelected = (
  current_start_date: Date,
  new_end_date: Date
) => {
  const dateEventProperties = endDateEventProperties(
    current_start_date,
    new_end_date
  );

  ampli.overviewEndDateSelected(dateEventProperties);
};

export const trackOverviewMetricTileClicked = (metric_name: string) => {
  ampli.overviewMetricTileClicked({ metric_name });
};

export const trackOverviewExperienceFilterOpened = (
  number_of_experiences: number
) => {
  ampli.overviewExperienceFilterOpened({ number_of_experiences });
};

export const trackOverviewExperienceFilterSelected = (
  selected_experience: string,
  experiences?: Experience[]
) => {
  const experienceIds = experiences?.map((experience) => experience.id) ?? [];
  const experienceHeadlines =
    experiences?.map(
      (experience) => getLocalized(experience.headline, 'en') ?? ''
    ) ?? [];
  ampli.overviewExperienceFilterSelected({
    list_of_experience_ids: experienceIds,
    list_of_experiences: experienceHeadlines,
    selected_experience,
  });
};

export const trackOverviewIntervalOpened = () => ampli.overviewIntervalOpened();

export const trackOverviewIntervalSelected = (interval: string) =>
  ampli.overviewIntervalSelected({ interval });

export const trackOverviewNextPeriodButtonClicked = () =>
  ampli.overviewNextPeriodButtonClicked();

export const trackOverviewPreviousPeriodButtonClicked = () =>
  ampli.overviewPreviousPeriodButtonClicked();
