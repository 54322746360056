import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  ActionFunctionArgs,
  useActionData,
  useRevalidator,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { useLanguages } from '../../../../../Hooks/locales/use-languages';
import { useExperienceTags } from '../../../../../Hooks/useExperienceTags';
import { getLocalizedString } from '../../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { t } from '../../../../../i18n/config';
import { tagSchema } from '../../../schemas/tagSchema';
import { LoaderData, loaderName } from '../../edit-experience';
import { getExperience } from '../../queries';
import { translateInput } from '../../utils/translate-input';
import { EditExperienceDialog } from '../edit-experience-dialog';
import { TagAutoComplete } from './auto-complete/tag-auto-complete';
import { TagList } from './tag-list/tag-list';

const PROPERTY_NAME = 'tagIds';

export type TagFilterItem = {
  id: string;
  /**
   * `name` is the value for the active language,
   * which can be empty if there is no translation
   */
  name?: string;
  /**
   * `label` is the displayed value of the tag.
   * If the translation is missing, we show a fallback name
   * from a different language, and an alert that the translation is missing.
   */
  label: string;
};

export default function TagsForm() {
  const { experience, activeLanguage, availableLanguages, tags } =
    useRouteLoaderData(loaderName) as LoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.tags');
  const revalidator = useRevalidator();

  const { createTag, updateTag } = useExperienceTags();
  const { languageOptions } = useLanguages();

  const tagOptions = useMemo(
    () =>
      tags.map((tag) => ({
        id: tag.id,
        name: tag.name[activeLanguage],
        label:
          tag.name[activeLanguage] ||
          t('tagOptions.noTranslation', {
            tagName: getLocalizedString(tag.name, activeLanguage),
            locale: languageOptions(activeLanguage).label,
          }),
      })),
    [activeLanguage, languageOptions, t, tags]
  );

  const [editingTag, setEditingTag] = useState<TagFilterItem | undefined>();
  const [selectedTags, setSelectedTags] = useState<TagFilterItem[]>(
    tagOptions.filter((tagOption) => experience.tagIds.includes(tagOption.id))
  );

  const handleCreateTag = async (tagName: string) => {
    try {
      const localizedTag = await translateInput(
        tagName,
        undefined,
        activeLanguage,
        availableLanguages,
        true
      );

      const { item: newTag } = await createTag.mutateAsync(localizedTag);

      // Since we are not submitting an action, we need to tell
      // the loader to revalidate the data so the new tag appears
      revalidator.revalidate();

      setSelectedTags((prev) => [
        ...prev,
        {
          id: newTag.id,
          name: newTag.name[activeLanguage],
          label: newTag.name[activeLanguage],
        },
      ]);
    } catch (error) {
      toast.error(t('generic', 'utils.errors'));
    }
  };

  const handleUpdateTag = async (tagId: string, tagName: string) => {
    try {
      const existingTag = tags.find((tag) => tag.id === tagId);

      if (!existingTag) throw new Error('Tag not found');

      const updatedTag = {
        ...existingTag,
        name: {
          ...existingTag.name,
          [activeLanguage]: tagName,
        },
      };

      await updateTag.mutateAsync(updatedTag);
      // Since we are not submitting an action, we need to tell
      // the loader to revalidate the data so the tag name is updated
      revalidator.revalidate();
      setEditingTag(undefined);
    } catch (error) {
      toast.error(t('generic', 'utils.errors'));
    }
  };

  const handleSelectTag = (tagItem: TagFilterItem) => {
    selectedTags.some((tag) => tag.id === tagItem.id)
      ? setSelectedTags((prev) => prev.filter((tag) => tag.id !== tagItem.id))
      : setSelectedTags((prev) => [...prev, tagItem]);
  };

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
    >
      <input
        type="hidden"
        name={PROPERTY_NAME}
        value={selectedTags.map((tag) => tag.id).join(',')}
      />
      <Stack sx={{ gap: 2 }}>
        <TagAutoComplete
          value={selectedTags}
          options={tagOptions}
          onChange={(newValue) => setSelectedTags(newValue)}
          onCreate={handleCreateTag}
          error={actionData?.error}
        />
        <TagList
          selectedTags={selectedTags}
          tagOptions={tagOptions}
          editingTag={editingTag}
          onEditTag={(tag) => setEditingTag(tag)}
          onSelectTag={handleSelectTag}
          onUpdateTag={handleUpdateTag}
        />
      </Stack>
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: string;
} | null;

export async function action({ params, request }: ActionFunctionArgs) {
  const id = params.id;

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    const formData = await request.formData();
    const tagIdsInput = formData.get('tagIds');

    if (typeof tagIdsInput !== 'string') {
      throw new Error('Invalid input');
    }

    const tagIds = tagIdsInput ? tagIdsInput.split(',') : [];
    const result = tagSchema.validateSync(tagIds);

    const experience = await getExperience(id);

    const experienceToSave = {
      ...experience,
      tagIds: result,
    };

    // TODO: Save experience
    console.log(experienceToSave);

    return { shouldClose: true };
  } catch (error) {
    if (error instanceof ValidationError) {
      return {
        error: error.message,
      };
    }
    toast.error(t('utils.errors.generic'));
    return null;
  }
}
