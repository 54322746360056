import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ArrowDropDownRounded } from '@mui/icons-material';
import {
  Box,
  Card,
  Dialog,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import {
  renderDate,
  renderDateTime,
  toISODateTime,
} from '@understory-io/utils-date';
import { createVirtualId } from '@understory-io/utils-events';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Calendar from '../Components/Calendar/Calendar';
import { StyledSelect } from '../Components/EventsList/EventsList';
import { Loading } from '../Components/Loading/Loading';
import { ProgressButton } from '../Components/ProgressButton/ProgressButton';
import { useGetLocationsByExperienceId } from '../Hooks/data/useLocations';
import { useExperienceEvents } from '../Hooks/events/useExperienceEvents';
import { useLanguages } from '../Hooks/locales/use-languages';
import { useBookings } from '../Hooks/useBookings';
import { useTranslate } from '../Hooks/useTranslate';
import { getGuestCount, TEvent } from '../Utils/eventHelpers';
import { toISODate } from '../Utils/helpers';
import { Header } from './Header';

const determineAvailable = (event: TEvent, guestCount: number) => {
  const freeSlots = event.slots.total - (event.slots.booked ?? 0);
  return freeSlots >= guestCount;
};

// Default is 100, but since we dont have pagination/lazy load, we have to fetch a lot of events here
const NUMBER_OF_EVENTS_TO_LOAD = 2500;

export const MoveBookingDialog = NiceModal.create(
  ({
    bookingId,
    experienceId,
  }: {
    bookingId: string;
    experienceId: string;
  }) => {
    const { t } = useTranslate('dialogs.moveBooking');
    const modal = useModal();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
      eventsForExperience: { data },
    } = useExperienceEvents(
      experienceId,
      undefined,
      undefined,
      NUMBER_OF_EVENTS_TO_LOAD
    );

    const { languageOptions } = useLanguages();

    const {
      booking: { data: booking, isLoading },
      moveBooking,
    } = useBookings(undefined, bookingId);

    const {
      locations: { data: locations },
    } = useGetLocationsByExperienceId(experienceId);
    const [locationFilter, setLocationFilter] = useState<string | null>(null);
    const handleLocationFilter = (evt: any) => {
      const newLocationId =
        evt.target.value === 'all' ? null : evt.target.value;
      setLocationFilter(newLocationId);
    };

    const events = useMemo(() => {
      if (!data?.events) return [];

      const relevantEvents = data.events.filter((event) =>
        ['active', 'inactive'].includes(event.status)
      );

      if (locationFilter === null) {
        return relevantEvents;
      }
      return relevantEvents.filter(
        (event) => event.locationId === locationFilter
      );
    }, [data?.events, locationFilter]);

    const [selectedMonth, setSelectedMonth] = useState<number | null>(
      new Date().getMonth()
    );
    const [selectedYear, setSelectedYear] = useState<number | null>(
      new Date().getFullYear()
    );
    const [selectedDateTime, setSelectedDateTime] = useState(
      toISODateTime(new Date())
    );
    const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
    const [dateShowing, setDateShowing] = useState(toISODate(new Date()));

    const originalBookingEventId = useMemo(() => {
      if (booking) {
        if (booking?.parentId) {
          const { eventId, startDateTime, endDateTime } = booking;
          return createVirtualId(eventId, startDateTime, endDateTime);
        }
        return booking?.eventId;
      }
    }, [booking]);

    useEffect(() => {
      if (booking?.startDateTime) {
        setSelectedYear(new Date(booking.startDateTime).getFullYear());
        setSelectedMonth(new Date(booking.startDateTime).getMonth());
        setDateShowing(toISODate(new Date(booking.startDateTime)));
        setSelectedDateTime(booking.startDateTime);
      }
    }, [booking]);

    useEffect(() => {
      if (selectedEventId === null && originalBookingEventId) {
        setSelectedEventId(originalBookingEventId);
      }
    }, [originalBookingEventId, selectedEventId]);

    const highlightedDays = useMemo(() => {
      return events
        .filter((event) => {
          const eventDate = new Date(event.startDateTime);
          return (
            eventDate.getMonth() === selectedMonth &&
            eventDate.getFullYear() === selectedYear &&
            eventDate >= new Date()
          );
        })
        .map((event) => new Date(event.startDateTime).getDate());
    }, [events, selectedMonth, selectedYear]);

    const handleClose = async (shouldRemove = true) => {
      await modal.hide();
      if (shouldRemove) {
        modal.remove();
      }
    };

    const times = useMemo(() => {
      return events
        .filter(
          (event) =>
            toISODate(new Date(event.startDateTime)) ===
            toISODate(new Date(dateShowing))
        )
        .filter((el) => {
          // Note that the toISODateTime takes care of time zone conversion, whereas the
          // toISOString on the Date-object would interpret the el.startDateTime as UTC,
          // even though it did not have any TZ information.
          const time = toISODateTime(new Date(el.startDateTime));
          const now = toISODateTime(new Date());
          return now < time;
        });
    }, [dateShowing, events]);

    const handleClickDate = (date: Date) => {
      setDateShowing(toISODate(date));
    };

    const handleChange = (date: Date) => {
      setSelectedMonth(date.getMonth());
      setSelectedYear(date.getFullYear());
    };

    const handleSelectTime = (e: unknown, value: string) => {
      const [eventId, startDateTime] = value.split('#');
      setSelectedEventId(eventId);
      setSelectedDateTime(startDateTime);
    };

    const handleConfirm = async () => {
      if (selectedEventId && selectedDateTime) {
        setIsSubmitting(true);
        await moveBooking
          .mutateAsync({
            id: bookingId,
            eventId: selectedEventId,
          })
          .then(() => {
            toast.success(t('toast.success'));
            modal.resolve();
          })
          .catch(() => {
            toast.error(t('toast.error'));
            modal.reject('MoveBookingDialog');
          })
          .finally(() => {
            setIsSubmitting(false);
            handleClose(true);
          });
      }
    };

    return (
      <Dialog
        sx={{ justifyContent: 'flex-end' }}
        fullWidth
        maxWidth={'md'}
        fullScreen={isMobile}
        open={modal.visible}
        onClose={() => handleClose()}
      >
        <Box
          sx={{
            fontSize: { xs: '12px', md: '16px' },
            display: 'block',
            width: '100%',
            height: 'auto',
            py: 4,
            px: { xs: 1, md: 4 },
          }}
        >
          <Header title={t('title')} onClose={handleClose} />

          <Typography mt={3} variant={'h5'}>
            {t('headline')}
          </Typography>
          {locations && locations.length > 1 && (
            <Stack direction={'row'} alignItems={'baseline'} mt={2}>
              <Typography mr={1}>{t('show', 'utils.generic')}: </Typography>
              <StyledSelect
                variant={'standard'}
                IconComponent={ArrowDropDownRounded}
                onChange={handleLocationFilter}
                value={locationFilter ?? 'all'}
                displayEmpty={true}
                label="Location"
              >
                <MenuItem value={'all'}>
                  {t('allLocations', 'utils.tables.filters')}
                </MenuItem>
                {locations.map((el, index) => (
                  <MenuItem value={el.locationId} key={`loc-${index}`}>
                    {el.locationName}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Stack>
          )}

          <Stack
            direction={isMobile ? 'column' : 'row'}
            mt={3}
            mb={4}
            justifyContent="space-evenly"
          >
            <Card sx={{ p: { xs: 1, md: 3 } }}>
              <Calendar
                cellWidth={42}
                isLoading={isLoading}
                defaultDate={new Date(dateShowing)}
                selectedDate={dateShowing}
                highlightedDays={highlightedDays}
                onChangeMonth={handleChange}
                onChangeYear={handleChange}
                onClickDate={handleClickDate}
              />
            </Card>
            <Box sx={{ ml: { xs: 0, md: 4 }, mt: { xs: 4, md: 0 } }}>
              <Typography mb={1} variant={'h5'}>
                {renderDate(dateShowing)}
              </Typography>
              <RadioGroup
                onChange={handleSelectTime}
                value={`${selectedEventId}#${selectedDateTime}`}
              >
                {times.map((event) => {
                  const guestCount = getGuestCount(booking?.items);
                  const hasAvailable = determineAvailable(event, guestCount);

                  const {
                    startDateTime,
                    id: eventId,
                    endDateTime,
                    languages,
                    locationId,
                    visibility,
                  } = event;

                  const value = `${eventId}#${startDateTime}`;

                  const langIcons =
                    languages === undefined || languages.length === 0
                      ? ''
                      : languages.map((el) => (
                          <img
                            key={el}
                            src={languageOptions(el).flagSrc}
                            width={20}
                            height={20}
                            alt={languageOptions(el).label}
                          />
                        ));

                  const location = locations?.find(
                    (el) => el.locationId === locationId
                  )?.locationName;

                  return (
                    <FormControlLabel
                      key={value}
                      disabled={!hasAvailable}
                      value={value}
                      control={<Radio />}
                      label={
                        <Stack my={1}>
                          <Text>
                            {renderDateTime(startDateTime, endDateTime)}
                            {hasAvailable
                              ? ''
                              : ' (' + t('soldOut', 'utils.generic') + ')'}{' '}
                            <Stack gap={2}>{langIcons}</Stack>
                          </Text>
                          {location && (
                            <Text fontSize={'xsmall'}>{location}</Text>
                          )}
                          {event.status === 'inactive' && (
                            <Text
                              fontSize={'xsmall'}
                              color={lightTheme.palette.warning.w300}
                            >
                              {t('inactive', 'utils.stateChips')}
                            </Text>
                          )}
                          {visibility === 'private' && (
                            <Text
                              fontSize={'xsmall'}
                              color={lightTheme.palette.warning.w300}
                            >
                              {t(
                                'private',
                                'events.detailsPage.card.visibility'
                              )}
                            </Text>
                          )}
                        </Stack>
                      }
                    />
                  );
                })}
              </RadioGroup>
            </Box>
          </Stack>
          <Stack
            sx={{ mt: { xs: 2, md: 12 }, minHeight: { xs: '40px', md: 0 } }}
            justifyContent={'flex-end'}
            direction={'row'}
            spacing={1.5}
          >
            <ProgressButton
              label={t('title')}
              variant={'contained'}
              onClick={handleConfirm}
              disabled={
                originalBookingEventId === selectedEventId &&
                booking?.startDateTime === selectedDateTime
              }
            />
          </Stack>
        </Box>
        <Loading isLoading={isSubmitting} />
      </Dialog>
    );
  }
);
