import { Tooltip } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useState } from 'react';

import { TEvent } from '../../../Utils/eventHelpers';
import { EventsListItemEditParticipantsField } from './EventsListItemEditParticipantsField';

type EventsListItemEditParticipantsProps = { event: TEvent };

export const EventsListItemEditParticipants = ({
  event,
}: EventsListItemEditParticipantsProps) => {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing)
    return (
      <EventsListItemEditParticipantsField
        event={event}
        onClose={() => setIsEditing(false)}
      />
    );

  return (
    <Tooltip title="Edit capacity">
      <Text
        role="button"
        fontSize="small"
        textDecorationLine="underline"
        onClick={(e) => {
          e.stopPropagation();
          setIsEditing(true);
        }}
        onMouseUp={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {event.slots?.booked} / {event.slots?.total}
      </Text>
    </Tooltip>
  );
};
