import { AddOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { Localized } from '@understory-io/utils-types';
import randomBytes from 'randombytes';
import { useMemo, useState } from 'react';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useActionData,
  useLoaderData,
  useRouteLoaderData,
} from 'react-router';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import { Language } from '../../../../../i18n/config';
import {
  LoaderData as RouterLoaderData,
  loaderName,
} from '../../edit-experience';
import { getCompanyProfile, getExperience } from '../../queries';
import { getFormDataValue } from '../../utils/form-helpers';
import { getActiveLanguage } from '../../utils/get-active-language';
import { getAutoTranslate } from '../../utils/get-auto-translate';
import { translateInput } from '../../utils/translate-input';
import { EditExperienceDialog } from '../edit-experience-dialog';
import { getVatOptions } from './get-vat-options';
import { VariantForm, VariantInput } from './variant-form';

export type LoaderData =
  ReturnType<typeof loader> extends Promise<infer R> ? R : never;

export async function loader({ params }: LoaderFunctionArgs) {
  const variantId = params.variantId;

  return { variantId };
}

export default function TicketsForm() {
  const { variantId } = useLoaderData() as LoaderData;
  const { experience, defaultCurrency, vatRegistrations, activeLanguage } =
    useRouteLoaderData(loaderName) as RouterLoaderData;
  const actionData = useActionData() as ActionData;
  const { t } = useTranslate('experience.edit.dialog.tickets');

  const variant = experience.price?.variants?.find(
    (variant) => variant.id === variantId
  );

  // Make sure the variant exists if an id is provided
  if (variantId && !variant) {
    throw new Response('Invalid variant id', { status: 404 });
  }

  const options = useMemo(
    () => getVatOptions(vatRegistrations, t),
    [vatRegistrations, t]
  );

  const [addons, setAddons] = useState<VariantInput[]>(
    variant?.addons?.map((addon) => ({
      id: addon.id,
      name: addon.name[activeLanguage],
      price: addon.priceBreakdown.vatInclusivePriceCents / 100,
      vatCategory: addon.priceBreakdown.vatSetting.vatCategory,
      explanation: addon.explanation?.[activeLanguage],
    })) ?? []
  );

  const handleCreateAddon = () => {
    setAddons((prev) => [
      ...prev,
      {
        id: randomBytes(16).toString('hex'),
        name: undefined,
        price: undefined,
        vatCategory: vatRegistrations[0]?.defaultVatCategory,
        explanation: undefined,
      },
    ]);
  };

  const handleDeleteAddon = (id: string) => {
    setAddons((prev) => prev.filter((addon) => addon.id !== id));
  };

  return (
    <EditExperienceDialog
      title={t('title')}
      description={t('description')}
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
    >
      <Stack sx={{ marginTop: 1, gap: 2, alignItems: 'start' }}>
        <VariantForm
          variant={variant}
          addons={addons}
          activeLanguage={activeLanguage}
          vatCategories={options}
          defaultCurrency={defaultCurrency}
          onDeleteAddon={handleDeleteAddon}
          errors={actionData?.errors}
        />
        <Button
          type="button"
          variant="text"
          size="medium"
          leftIcon={<AddOutlined />}
          onClick={handleCreateAddon}
        >
          {t('addAddon')}
        </Button>
      </Stack>
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  errors?: Record<string, { type: string; message: string }>;
} | null;

export async function action({ params, request }: ActionFunctionArgs) {
  const id = params.id;
  const variantId = params.variantId;

  if (!id) {
    throw new Response('Invalid id', { status: 400 });
  }

  try {
    const [experience, companyProfile] = await Promise.all([
      getExperience(id),
      getCompanyProfile(),
    ]);

    const activeLanguage = getActiveLanguage(request, companyProfile);
    const autoTranslate = getAutoTranslate(request);

    const existingVariant = experience.price?.variants?.find(
      (variant) => variant.id === variantId
    );

    if (variantId && !existingVariant) {
      throw new Response('Variant not found', { status: 404 });
    }

    const formData = await request.formData();

    const formEntries = Object.entries(Object.fromEntries(formData)).map(
      ([key, value]) => [key, getFormDataValue(value)]
    );

    const variantAndAddons = formEntries.reduce(
      (variantAndAddons, [key, value]) => {
        const [variantId, inputName] = key.split('_');

        return {
          ...variantAndAddons,
          [variantId]: {
            ...(variantAndAddons[variantId] ?? {}),
            [inputName]: value,
          },
        };
      },
      {} as Record<
        string,
        {
          name: string;
          explanation: string;
          vatCategory: string;
          price: string;
        }
      >
    );

    const translate = (input: string, existingValues?: Localized) => {
      return translateInput(
        input,
        existingValues,
        activeLanguage,
        companyProfile.languages as Language[],
        autoTranslate
      );
    };

    const errors = {} as { [key: string]: { type: string; message: string } };

    let newVariant = {} as {
      id: string;
      name: Localized;
      explanation: Localized;
      priceBreakdown: {
        vatInclusivePriceCents: number;
        vatSetting: { vatCategory: string };
      };
      addons?: {
        id: string;
        name: Localized;
        explanation: Localized;
        priceBreakdown: {
          vatInclusivePriceCents: number;
          vatSetting: { vatCategory: string };
        };
      }[];
    };
    for (const [id, values] of Object.entries(variantAndAddons)) {
      const priceCents = parseInt(values.price, 10) * 100;

      let hasError = false;

      if (!values.name) {
        errors[`${id}_name`] = {
          type: 'required',
          message: 'Name is required',
        };
        hasError = true;
      }

      if (isNaN(priceCents)) {
        errors[`${id}_price`] = {
          type: 'required',
          message: 'Price is required',
        };
        hasError = true;
      }

      if (hasError) continue;

      if (id === 'variant') {
        const [name, explanation] = await Promise.all([
          translate(values.name, existingVariant?.name),
          translate(values.explanation, existingVariant?.explanation),
        ]);

        const item = {
          id: variantId ?? randomBytes(16).toString('hex'),
          name,
          explanation,
          priceBreakdown: {
            vatInclusivePriceCents: priceCents,
            vatSetting: {
              vatCategory: values.vatCategory,
            },
          },
        };
        newVariant = { ...newVariant, ...item };
      } else {
        const existingAddon = existingVariant?.addons?.find(
          (addon) => addon.id === id
        );

        const [name, explanation] = await Promise.all([
          translate(values.name, existingAddon?.name),
          translate(values.explanation, existingAddon?.explanation),
        ]);

        const newAddon = {
          id,
          name,
          explanation,
          priceBreakdown: {
            vatInclusivePriceCents: priceCents,
            vatSetting: {
              vatCategory: values.vatCategory,
            },
          },
        };

        newVariant = {
          ...newVariant,
          addons: [...(newVariant?.addons ?? []), newAddon],
        };
      }
    }

    if (Object.keys(errors).length > 0) {
      return { errors };
    }

    const otherVariants =
      experience.price?.variants?.filter(
        (variant) => variant.id !== newVariant.id
      ) ?? [];

    const experienceToSave = {
      ...experience,
      price: {
        variants: [...otherVariants, newVariant],
      },
    };

    console.log(experienceToSave);

    return { shouldClose: true };
  } catch (error) {
    return null;
  }
}
