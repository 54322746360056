import styled from '@emotion/styled';
import { lightTheme, Text } from '@understory-io/pixel';

type Props = {
  strikeOut?: boolean;
};

export const StrikeOutText = styled(Text)<Props>`
  text-decoration: line-through 2px ${lightTheme.palette.neutral.n400};
`;
