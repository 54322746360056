import { ClearOutlined } from '@mui/icons-material';
import { AutocompleteRenderGetTagProps, Chip } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { TagFilterItem } from '../tags';

export const AutoCompleteTags = ({
  tags,
  getProps,
}: {
  tags: TagFilterItem[];
  getProps: AutocompleteRenderGetTagProps;
}) => {
  return (
    <>
      {tags.map((tag, index) => (
        <Chip
          {...getProps({ index })}
          key={tag.id}
          size="small"
          sx={{
            backgroundColor: lightTheme.palette.success.s100,
          }}
          deleteIcon={
            <ClearOutlined
              sx={{
                color: lightTheme.palette.neutral.n300 + ' !important',
                ':hover': {
                  color: lightTheme.palette.neutral.n500 + ' !important',
                },
              }}
            />
          }
          label={tag.label}
        />
      ))}
    </>
  );
};
