import {
  CompanyProfile,
  PublicCompanyProfile,
} from '@understory-io/utils-types';

import {
  getCompanyProfile,
  getCompanyUsers,
  getConnections,
  getEventsInExperience,
  getExperience,
  getPublicCompanyProfile,
} from '../../../Api';
import { getCompanyDomain } from '../../../Api/Domains';
import { getLocationsByExperienceId } from '../../../Api/Locations';
import { getExperienceWaitlist } from '../../../Api/waitlist';
import { queryClient } from '../../../query-client';
import { TEventFilter } from '../../../Utils/eventHelpers';

export const EVENT_LIMIT = 100;

type EventListOptions = {
  page: number;
  eventFilter: TEventFilter;
};

export async function getEventListQuery(
  experienceId: string,
  options?: Partial<EventListOptions>
) {
  const defaultOptions: EventListOptions = {
    page: 1,
    eventFilter: {
      state: 'future',
      status: 'anyStatus',
    },
  };

  const mergedOptions = { ...defaultOptions, ...options };

  const events = await queryClient.fetchQuery({
    queryKey: ['events', experienceId, options],
    queryFn: () =>
      getEventsInExperience(
        experienceId,
        (mergedOptions.page - 1) * EVENT_LIMIT,
        EVENT_LIMIT,
        mergedOptions.eventFilter
      ),
  });

  return events;
}

export async function getExperienceQuery(experienceId: string) {
  const experience = await queryClient.fetchQuery({
    queryKey: ['experience', experienceId],
    queryFn: () => getExperience(experienceId),
  });

  return experience;
}

export async function getCompanyProfileQuery(
  companyId: string,
  isShared: boolean = false
) {
  const companyProfile = await queryClient.fetchQuery<
    PublicCompanyProfile | CompanyProfile
  >({
    queryKey: ['companyProfile', companyId, isShared ? 'shared' : null],
    queryFn: () =>
      isShared ? getPublicCompanyProfile(companyId) : getCompanyProfile(),
  });

  return companyProfile;
}

export async function getCompanyUsersQuery() {
  const companyProfile = await queryClient.fetchQuery({
    queryKey: ['companyUsers'],
    queryFn: () => getCompanyUsers(),
  });

  return companyProfile;
}

export async function getCompanyDomainQuery(companyId: string) {
  const domain = await queryClient.fetchQuery({
    queryKey: ['companyDomain', companyId],
    queryFn: () => getCompanyDomain(companyId),
  });

  return domain;
}

export async function getConncetionsQuery(companyId: string) {
  const connections = await queryClient.fetchQuery({
    queryKey: ['connections', companyId],
    queryFn: () => getConnections(),
  });

  return connections;
}

export async function getLocationsByExperienceQuery(experienceId: string) {
  const locations = await queryClient.fetchQuery({
    queryKey: ['experienceLocations', experienceId],
    queryFn: () => getLocationsByExperienceId(experienceId),
  });

  return locations;
}

export async function getExperienceWaitlistQuery(experienceId: string) {
  const waitlist = await queryClient.fetchQuery({
    queryKey: ['experience', experienceId, 'waitlist'],
    queryFn: () => getExperienceWaitlist(experienceId),
  });

  return waitlist;
}
