import { useLanguages } from '../../../Hooks/locales/use-languages';
import { useProfile } from '../../../Hooks/useProfile';

export function useManageLanguages() {
  const { updateCompany, company } = useProfile();

  const { languages } = useLanguages();

  const languageOptions = languages.data?.items.map(({ code, flagSrc }) => ({
    key: code.toLowerCase(),
    label: code,
    flag: flagSrc,
  }));

  const notUsedLanguages = languageOptions
    ?.filter((x) => !company.data?.languages.includes(x.key))
    .map(({ key: language }) => language);

  const activeLanguages = languageOptions
    ?.filter((x) => company.data?.languages.includes(x.key))
    .map(({ key: language }) => ({
      key: language,
      isDefault: language === company.data?.defaultLanguage,
    }))
    .sort((a) => (a.isDefault ? -1 : 0));

  async function updateLanguages(options: {
    nextLanguages?: string[];
    defaultLanguage?: string;
  }): Promise<{ status: 'success' } | { status: 'error'; error: string }> {
    if (!company.data) {
      return { status: 'success' };
    }

    if (options.nextLanguages && options.nextLanguages.length === 0) {
      return { status: 'error', error: 'atLeastOneLanguage' };
    }

    const nextLanguages = options.nextLanguages ?? company.data.languages;

    // This makes sure there'll be a defaultLanguage.
    // If there's only one language left, it has to be default.
    const defaultLanguage =
      options.defaultLanguage ??
      (nextLanguages.length === 1
        ? nextLanguages[0]
        : company.data.defaultLanguage);

    try {
      await updateCompany.mutateAsync({
        ...company.data,
        languages: nextLanguages,
        defaultLanguage,
      });

      return { status: 'success' };
    } catch (error) {
      return { status: 'error', error: 'Unknown error' };
    }
  }

  async function addLanguage(language: string) {
    return updateLanguages({
      nextLanguages: company.data?.languages.concat(language) ?? [],
    });
  }

  function removeLanguage(languageToRemove: string) {
    return updateLanguages({
      nextLanguages: (company.data?.languages ?? []).filter(
        (language) => language !== languageToRemove
      ),
    });
  }

  function setDefaultLanguage(language: string) {
    return updateLanguages({
      defaultLanguage: language,
    });
  }

  return {
    activeLanguages,
    notUsedLanguages,
    addLanguage,
    removeLanguage,
    setDefaultLanguage,
    isLoading: company.isLoading,
  };
}
