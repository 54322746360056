import { styled } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useTranslation } from 'react-i18next';

export const PreviewFormNoWebsite = () => {
  const { t } = useTranslation();

  const handleNoWebsite = () => {
    window.location.href = '/signup';
  };

  return (
    <StyledLink as="a" fontSize="small" onClick={handleNoWebsite}>
      {t('auth.preview.actions.noWebsite')}
    </StyledLink>
  );
};

const StyledLink = styled(Text)({
  color: lightTheme.palette.neutral.n400,
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none',
  },
});
