import { AxiosError } from 'axios';
import { useParams } from 'react-router';

import { BookingDetailsContextProvider } from '../features/bookings/booking_details/domain/use_booking_details_context';
import { BookingDetailsView } from '../features/bookings/booking_details/ui/BookingDetailsView';
import { useBookings } from '../Hooks/useBookings';
import { ErrorPage } from './error-page';

export const BookingPage = () => {
  const { id } = useParams<{ id: string }>();

  const {
    booking: { isError, error },
    enrichedBooking: booking,
  } = useBookings(undefined, id);

  if (isError)
    return <ErrorPage error={error as AxiosError} resource="booking" />;

  return (
    <BookingDetailsContextProvider booking={booking}>
      <BookingDetailsView />
    </BookingDetailsContextProvider>
  );
};
