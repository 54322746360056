import { Stack } from '@mui/material';
import { endOfMonth, startOfMonth, subMonths } from '@understory-io/utils-date';
import { parseISO } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';

import { DateInput } from '../../../../Components/inputs/date-input';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import {
  trackOverviewReportFirstDateOpened,
  trackOverviewReportFirstDateSelected,
  trackOverviewReportSecondDateOpened,
  trackOverviewReportSecondDateSelected,
} from '../../../../tracking/overview/request-report';
import { RequestReportDialogFormType } from '../use-request-report-form-validation';

export const RequestReportFormDates = () => {
  const { t } = useTranslate('utils.generic');
  const { isSm } = useResponsive();

  const {
    watch,
    formState: { errors },
  } =
    useFormContext<
      Pick<RequestReportDialogFormType, 'startDate' | 'endDate'>
    >();

  const { startDate, endDate } = watch();

  return (
    <Stack direction={isSm ? 'column' : 'row'} gap={2}>
      <Controller
        name="startDate"
        defaultValue={startOfMonth(subMonths(new Date(), 1))}
        render={({ field }) => (
          <DateInput
            label={t('startDate')}
            {...field}
            onChange={(date) => {
              const parsedDate = parseISO(date);
              field.onChange(parsedDate);
              trackOverviewReportFirstDateSelected(parsedDate, endDate);
            }}
            onClick={trackOverviewReportFirstDateOpened}
            minDate="2022-01-01"
            required
            error={!!errors.startDate}
          />
        )}
      />
      <Controller
        name="endDate"
        defaultValue={endOfMonth(subMonths(new Date(), 1))}
        render={({ field }) => (
          <DateInput
            label={t('endDate')}
            {...field}
            onChange={(date) => {
              const parsedDate = parseISO(date);
              field.onChange(parsedDate);
              trackOverviewReportSecondDateSelected(startDate, parsedDate);
            }}
            onClick={trackOverviewReportSecondDateOpened}
            minDate="2022-01-01"
            required
            error={!!errors.endDate}
          />
        )}
      />
    </Stack>
  );
};
