import { CheckRounded } from '@mui/icons-material';
import { CircularProgress, Dialog, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { useTranslate } from '../../Hooks/useTranslate';

export type SetupProgress = {
  hasCreatedLocation: boolean;
  hasUploadedMedia: boolean;
  hasCreatedExperience: boolean;
  hasCreatedEvent: boolean;
};

export const SubmitSetupProgress = ({
  isCompletingSetup,
  progress: {
    hasCreatedLocation,
    hasUploadedMedia,
    hasCreatedExperience,
    hasCreatedEvent,
  },
}: {
  isCompletingSetup: boolean;
  progress: SetupProgress;
}) => {
  const { t } = useTranslate(`onboarding.submitDialog`);

  return (
    <Dialog open={isCompletingSetup} maxWidth="sm" fullWidth>
      <Stack sx={{ padding: 4 }} height="100%" width="100%">
        <Text variant="medium" fontSize="large">
          {t('title')}
        </Text>
        <Stack direction="row" gap={1} alignItems="center" marginTop={2}>
          <Text fontSize="small">{t('step.location')}</Text>
          {hasCreatedLocation ? <DoneIcon /> : <LoadingIcon />}
        </Stack>
        {hasCreatedLocation && (
          <Stack direction="row" gap={1} alignItems="center">
            <Text fontSize="small">{t('step.media')}</Text>
            {hasUploadedMedia ? <DoneIcon /> : <LoadingIcon />}
          </Stack>
        )}
        {hasUploadedMedia && (
          <Stack direction="row" gap={1} alignItems="center">
            <Text fontSize="small">{t('step.experience')}</Text>
            {hasCreatedExperience ? <DoneIcon /> : <LoadingIcon />}
          </Stack>
        )}
        {hasCreatedExperience && (
          <Stack direction="row" gap={1} alignItems="center">
            <Text fontSize="small">{t('step.event')}</Text>
            {hasCreatedEvent ? <DoneIcon /> : <LoadingIcon />}
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
};

const LoadingIcon = () => {
  return <CircularProgress size={16} />;
};

const DoneIcon = () => {
  return (
    <CheckRounded
      fontSize="small"
      sx={{
        width: 16,
        height: 16,
      }}
      htmlColor={lightTheme.palette.success.s300}
    />
  );
};
