import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

import { Language } from '../../../../../i18n/config';
import { Experience } from '../../../schemas';
import { TicketItemForm } from './ticket-item-form';

export type VariantInput = {
  id: string;
} & VariantInputFields;

export type VariantInputFields = {
  name?: string;
  price?: number;
  explanation?: string;
  vatCategory?: string;
};

type VariantFormProps = {
  variant?: NonNullable<NonNullable<Experience['price']>['variants']>[0];
  addons: VariantInput[];
  activeLanguage: Language;
  vatCategories: { key: string; label: string }[];
  defaultCurrency: string;
  errors?: { [key: string]: { type: string; message: string } };
  onDeleteAddon: (id: string) => void;
};

export const VariantForm = ({
  variant,
  addons,
  activeLanguage,
  vatCategories,
  defaultCurrency,
  errors,
  onDeleteAddon,
}: VariantFormProps) => {
  return (
    <Stack sx={{ gap: 3 }}>
      <TicketItemForm
        inputNameBase="variant"
        defaultValues={{
          name: variant?.name[activeLanguage],
          price: variant
            ? variant.priceBreakdown.vatInclusivePriceCents / 100
            : undefined,
          explanation: variant?.explanation?.[activeLanguage],
          vatCategory: variant?.priceBreakdown.vatSetting.vatCategory,
        }}
        vatCategories={vatCategories}
        defaultCurrency={defaultCurrency}
        errors={errors}
      />
      {!!addons.length && (
        <Stack
          sx={{
            paddingLeft: 2,
            paddingY: 2,
            gap: 4,
            borderLeft: '1px solid',
            borderLeftColor: lightTheme.palette.neutral.n100,
          }}
        >
          {addons.map((addon) => (
            <TicketItemForm
              inputNameBase={addon.id}
              key={addon.id}
              defaultValues={{
                name: addon.name,
                price: addon.price,
                vatCategory: addon.vatCategory,
                explanation: addon.explanation,
              }}
              vatCategories={vatCategories}
              defaultCurrency={defaultCurrency}
              errors={errors}
              onDelete={() => onDeleteAddon(addon.id)}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
