import { Experience } from '@understory-io/utils-types';

import { ampli } from '../../../../Ampli';
import { EventType } from '../../../../features/events/event_upsert/domain/types';
import { DetailsFormData } from '../../../../features/events/event_upsert/ui/details/ui/details_form/use_details_form_validation';
import { eventProperties } from './eventProperties';

export const trackEventFlowDetailsStepCompleted = (
  details: DetailsFormData,
  event: EventType,
  experiences?: Experience[]
) => {
  const properties = eventProperties(details, event, experiences);

  ampli.eventFlowDetailsStepCompleted(properties);
};
