import { Controller } from 'react-hook-form';

import { LanguageChips } from '../../../../../Components/LanguageChips/LanguageChips';
import { useLanguages } from '../../../../../Hooks/locales/use-languages';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { SyiSection } from '../../../../../Pages/SyiPage/SyiSection';

export const ProfileFormLanguages = () => {
  const { t } = useTranslate('settings.profile');

  const { languages } = useLanguages();

  return (
    <SyiSection title={t('languages')}>
      <Controller
        name={'languages'}
        render={({ field }) => (
          <LanguageChips
            onChange={field.onChange}
            languages={field.value}
            languageOptions={languages.data?.items.map(({ code, flagSrc }) => ({
              key: code.toLowerCase(),
              flag: flagSrc,
            }))}
            multiple={true}
          />
        )}
      />
    </SyiSection>
  );
};
