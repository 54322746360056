import { AddRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { ShowForScope } from '../../Components/AllowForScope/AllowForScope';
import { DiscountsList } from '../../Components/DiscountsList/DiscountsList';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useDiscount } from '../../Hooks/useDiscount';
import { useFireOnce } from '../../Hooks/useFireOnce';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import {
  trackGrowthDiscountCodeCreationStarted,
  trackGrowthDiscountCodesViewed,
} from '../../tracking/growth/growth-events';

export const GrowthDiscountsSection = () => {
  const { t } = useTranslate('discounts');
  const navigate = useNavigate();
  const { isSm } = useResponsive();
  const fireOnce = useFireOnce();

  const {
    me: { data: me },
  } = useProfile();

  const { discounts, deleteDiscount } = useDiscount();

  useEffect(() => {
    if (!discounts.data) return;
    fireOnce(() =>
      trackGrowthDiscountCodesViewed(discounts?.data ?? [], me?.id)
    );
  }, [discounts, me?.id, fireOnce]);

  const handleCreate = () => {
    navigate('/discount/create/details');
    trackGrowthDiscountCodeCreationStarted();
  };

  const handleDelete = (id: string) => {
    deleteDiscount.mutate(id);
  };

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
      >
        <Text fontSize={'large'} variant={'medium'}>
          {t('title')}
        </Text>
        {!isSm && (
          <ShowForScope scopes={[`marketing.write`]}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Button
                onClick={handleCreate}
                variant="primary"
                size="medium"
                leftIcon={<AddRounded fontSize="small" />}
              >
                {t('buttonLabel')}
              </Button>
            </Stack>
          </ShowForScope>
        )}
      </Stack>
      <Box sx={{ paddingTop: 2, marginTop: 2 }}>
        <DiscountsList onDelete={handleDelete} discounts={discounts.data} />
      </Box>
    </>
  );
};
