import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { SyiSection } from '../../../../../../Pages/SyiPage/SyiSection';
import { CompanyFormVatRegistrationCategory } from './CompanyFormVatRegistrationCategory';
import { CompanyFormVatRegistrationCountry } from './CompanyFormVatRegistrationCountry';
import { CompanyFormVatRegistrationNumber } from './CompanyFormVatRegistrationNumber';

const CompanyFormVatRegistration = () => {
  const { t } = useTranslate('settings.company');
  const flags = useFlags();

  const vatFormRef = useRef<HTMLDivElement>(null);

  const { watch } = useFormContext();
  const showVatForm = watch('vatCompliance.isVatRegistered');

  if (!flags?.featureCompanyVatSettings) {
    return null;
  }

  return (
    <SyiSection title={t('vatSettings.title')}>
      <Stack component={'form'} gap={3}>
        <Text fontSize="small" style={{ maxWidth: 680 }}>
          {t('vatSettings.helperText')}
        </Text>
        <Stack gap={2}>
          <Text fontSize="small">{t('vatSettings.isVatRegistered')}</Text>
          <Controller
            name={'vatCompliance.isVatRegistered'}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e, value) => field.onChange(value === 'true')}
                sx={{ width: 'fit-content' }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={t('yes', 'utils.generic')}
                  onClick={() => {
                    setTimeout(() => {
                      vatFormRef.current?.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }, 100);
                  }}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('no', 'utils.generic')}
                />
              </RadioGroup>
            )}
          />
        </Stack>
        {showVatForm && (
          <Stack gap={2} ref={vatFormRef}>
            <Stack direction={'row'} gap={2}>
              <CompanyFormVatRegistrationCountry fixedWidth />
              <CompanyFormVatRegistrationNumber fixedWidth />
            </Stack>
            <CompanyFormVatRegistrationCategory fixedWidth />
          </Stack>
        )}
      </Stack>
    </SyiSection>
  );
};

export default CompanyFormVatRegistration;
