import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../Hooks/useTranslate';

type RequestReportFormActionProps = {
  handleSubmit: () => void;
};

export const RequestReportFormAction = ({
  handleSubmit,
}: RequestReportFormActionProps) => {
  const { t } = useTranslate('dialogs.requestReport');
  const { isSm } = useResponsive();

  return (
    <Stack
      direction={isSm ? 'column' : 'row'}
      justifyContent={isSm ? 'initial' : 'flex-end'}
    >
      <Button
        variant="primary"
        size="medium"
        type="submit"
        onClick={handleSubmit}
      >
        {t('send')}
      </Button>
    </Stack>
  );
};
