import styled from '@emotion/styled';
import { ArrowBackOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { GiftCardStatus } from '@understory-io/utils-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ShowForScope } from '../../../Components/AllowForScope/AllowForScope';
import { StatusBadge } from '../../../Components/badge/status-badge';
import { RouterLinkButton } from '../../../Components/button/router-link-button';
import { ContextMenu } from '../../../Components/context-menu/context-menu';
import { useAuth } from '../../../Hooks/useAuth';
import routes from '../../../Utils/routes';

type GiftCardDetailsHeaderProps = {
  giftCardId: string;
  title: string;
  subtitle: string;
  status: GiftCardStatus;
  actions: GiftCardAction[];
};

type ActionSetting = {
  href: (id: string) => string;
  variant: 'primary' | 'secondary';
  textColor?: string;
  scopes?: string[];
};

const ACTION_CONFIG: Record<string, ActionSetting> = {
  deactivate: {
    href: (id) => routes.giftCard.details(id).deactivate,
    variant: 'secondary',
    scopes: ['giftCard.write'],
  },
  refund: {
    href: (id) => routes.giftCard.details(id).refund,
    variant: 'secondary',
    scopes: ['giftCard.write'],
  },
  adjustValue: {
    href: (id) => routes.giftCard.details(id).adjustValue,
    variant: 'primary',
    scopes: ['giftCard.write'],
  },
};

export type GiftCardAction = keyof typeof ACTION_CONFIG;

export const GiftCardDetailsHeader = ({
  giftCardId,
  title,
  subtitle,
  status,
  actions,
}: GiftCardDetailsHeaderProps) => {
  const { t } = useTranslation();

  const { canAccess } = useAuth();
  const hasActions = actions.some((key) =>
    ACTION_CONFIG[key].scopes?.every(canAccess)
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <GiftCardDetails title={title} subtitle={subtitle} status={status} />
      {hasActions && (
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 1,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          {actions.map((key) => {
            const { scopes, href, variant } = ACTION_CONFIG[key];
            return (
              <ShowForScope key={key} scopes={scopes ?? []}>
                <RouterLinkButton href={href(giftCardId)} variant={variant}>
                  {t(`giftCard.action.${key}`)}
                </RouterLinkButton>
              </ShowForScope>
            );
          })}
        </Stack>
      )}

      <GiftCardContextMenu giftCardId={giftCardId} actions={actions} />
    </Stack>
  );
};

const GiftCardDetails = ({
  title,
  subtitle,
  status,
}: Pick<GiftCardDetailsHeaderProps, 'title' | 'subtitle' | 'status'>) => {
  const navigate = useNavigate();
  const handleBack = () => navigate('/giftcards');

  return (
    <Stack sx={{ flexDirection: { md: 'row' }, gap: { xs: 2, md: 1 } }}>
      <StyledBackButton fontSize="small" onClick={handleBack} />
      <Stack gap={0.5}>
        <Stack direction="row" gap={2.5} alignItems="center">
          <Text variant="medium" fontSize="large">
            {title}
          </Text>
          <StatusBadge type="giftcard" size="small" state={status} />
        </Stack>
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          {subtitle}
        </Text>
      </Stack>
    </Stack>
  );
};

const GiftCardContextMenu = ({
  giftCardId,
  actions,
}: {
  giftCardId: string;
  actions: GiftCardAction[];
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Box sx={{ display: { md: 'none' } }}>
      <ContextMenu
        type="button"
        open={isOpen}
        onOpenChange={setIsOpen}
        options={actions.map((key) => {
          const action = ACTION_CONFIG[key];

          return {
            label: t(`giftCard.action.${key}`),
            onClick: () => navigate(action.href(giftCardId)),
            scopes: action.scopes,
            textColor: action.textColor,
          };
        })}
      />
    </Box>
  );
};

const StyledBackButton = styled(ArrowBackOutlined)({
  cursor: 'pointer',
  borderRadius: '50%',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
