import { lightTheme, Text } from '@understory-io/pixel';
import { useState } from 'react';
import {
  type ActionFunctionArgs,
  useActionData,
  useRouteLoaderData,
} from 'react-router';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';

import { RichEditor } from '../../../../Components/RichEditor/RichEditor';
import { Language } from '../../../../i18n/config';
import type { Experience } from '../../schemas';
import { localizedSchema } from '../../schemas/genericSchemas';
import { LoaderData, loaderName } from '../edit-experience';
import { getCompanyProfile, getExperience } from '../queries';
import { getFormDataValue } from '../utils/form-helpers';
import { getActiveLanguage } from '../utils/get-active-language';
import { getAutoTranslate } from '../utils/get-auto-translate';
import { translateInput } from '../utils/translate-input';
import { EditExperienceDialog } from './edit-experience-dialog';

const PROPERTY_NAME = 'infoForGuests';

export default function InfoForQuestsForm() {
  const actionData = useActionData() as ActionData;
  const { experience, activeLanguage } = useRouteLoaderData(
    loaderName
  ) as LoaderData;

  const [infoForQuests, setInfoForQuests] = useState(
    experience.infoForGuests?.yes?.[activeLanguage] ?? ''
  );

  return (
    <EditExperienceDialog
      shouldClose={actionData?.shouldClose}
      experienceId={experience.id}
    >
      <input name={PROPERTY_NAME} type="hidden" value={infoForQuests} />
      <RichEditor
        placeholder="E. g. please arrive 15 minutes early ..."
        value={infoForQuests}
        onChange={(e: string) => {
          setInfoForQuests(e);
        }}
      />
      {actionData?.error && (
        <Text color={lightTheme.palette.error.e400} variant="normal">
          {actionData.error}
        </Text>
      )}
    </EditExperienceDialog>
  );
}

type ActionData = {
  shouldClose?: boolean;
  error?: string;
} | null;

export async function action({
  params,
  request,
}: ActionFunctionArgs): Promise<ActionData> {
  const id = params.id;
  const autoTranslate = getAutoTranslate(request);

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    // Validate input
    const formData = await request.formData();
    const infoForQuests = getFormDataValue(formData.get(PROPERTY_NAME));

    const [experience, companyProfile] = await Promise.all([
      getExperience(id),
      getCompanyProfile(),
    ]);

    //
    if (infoForQuests === '') {
      const experienceToSave = {
        ...experience,
        infoForGuests: {
          selectedOptionKey: 'no',
        },
      } as Experience;

      // TODO: Save draft
    }

    const activeLanguage = getActiveLanguage(request, companyProfile);

    const translatedInfoForGuests = await translateInput(
      infoForQuests,
      experience.infoForGuests?.yes,
      activeLanguage,
      companyProfile.languages as Language[],
      autoTranslate
    );

    const result = localizedSchema.validateSync(translatedInfoForGuests);

    const experienceToSave = {
      ...experience,
      infoForGuests: {
        selectedOptionKey: 'yes',
        yes: result,
      },
    } as Experience;

    // 3. Save draft
    // TODO: Save draft - US-2713

    return { shouldClose: true };
  } catch (error) {
    if (error instanceof ValidationError) {
      return {
        error: error.message,
      };
    }

    toast.error("We couldn't save info for quests. Try again later");
    return null;
  }
}
