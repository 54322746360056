import { lightTheme } from '@understory-io/pixel';
import { useEffect } from 'react';

const toastStyling = {
  // All toasts
  '.Toastify__toast': {
    'font-size': lightTheme.typography.small.medium.fontSize,
    'font-weight': lightTheme.typography.small.medium.fontWeight,
    'font-family': 'Inter, sans-serif',
    'border-radius': '8px',
    'min-height': '55px',
  },
  '.Toastify__progress-bar': {
    visibility: 'hidden',
  },
  '.Toastify__close-button': {
    display: 'none',
  },

  // Success toast
  '.Toastify__toast--success': {
    border: `1px solid ${lightTheme.palette.success.s300}`,
    color: lightTheme.palette.success.s300,
    'background-color': lightTheme.palette.success.s100,
  },
  '.Toastify__toast--success .Toastify__toast-icon > svg': {
    fill: lightTheme.palette.success.s300,
  },

  // Loading toast
  '.Toastify__toast--default': {
    'background-color': lightTheme.palette.neutral.n500,
    color: 'white',
  },

  // Error toast
  '.Toastify__toast--error': {
    border: `1px solid ${lightTheme.palette.error.e300}`,
    color: lightTheme.palette.error.e300,
    'background-color': lightTheme.palette.error.e100,
  },
};

const mobileToastStyling = `
  @media (max-width: 768px) {
    .Toastify__toast-container {
      padding: 20px;
    }
    .Toastify__toast {
      margin-bottom: 10px;
    }
`;

export const useToastStyling = () => {
  useEffect(() => {
    const stylesheet = document.createElement('style');

    const cssText = Object.entries(toastStyling)
      .map(([selector, styles]) => {
        const formattedStyles = Object.entries(styles)
          .map(([property, value]) => {
            return `${property}: ${value};`;
          })
          .join(' ');
        return `${selector} { ${formattedStyles} }`;
      })
      .join(' ');

    stylesheet.innerText = cssText + mobileToastStyling;
    document.head.appendChild(stylesheet);

    return () => {
      document.head.removeChild(stylesheet);
    };
  }, []);
};
