import { Card, Stack } from '@mui/material';
import { Skeleton, Text } from '@understory-io/pixel';
import { Resource } from '@understory-io/resource-management-types';
import { Suspense } from 'react';
import { Await } from 'react-router';

type Props = {
  resources: Promise<Array<Resource>>;
};

export const ResourcesList = ({ resources }: Props) => {
  return (
    <Stack gap={2} marginTop={5}>
      <Suspense
        fallback={
          <>
            <Skeleton variant={'rectangular'} height={50} width={'100%'} />
            <Skeleton variant={'rectangular'} height={50} width={'100%'} />
          </>
        }
      >
        <Await resolve={resources}>
          {(resources) =>
            resources.map((item) => (
              <ListItem item={item} key={item.resourceTypeId} />
            ))
          }
        </Await>
      </Suspense>
    </Stack>
  );
};

const ListItem = ({ item }: { item: Resource }) => {
  return (
    <Card>
      <Stack justifyContent={'space-between'} direction={'row'} gap={2}>
        <Text>{item.label}</Text>
        <Text>{`Available:  ${item.available}`}</Text>
      </Stack>
    </Card>
  );
};
