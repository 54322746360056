import { Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { CardContainer } from '../card-container';
import { StyleForm } from './form/style-form';
import { PreviewStyling } from './preview/preview-styling';

export const StylingSection = () => {
  const { t } = useTranslate('storefront.customize');

  return (
    <CardContainer>
      <Stack>
        <Text variant="medium">{t('title')}</Text>
        <Text>{t('description')}</Text>
      </Stack>
      <Stack
        marginTop={3}
        direction="row"
        flexWrap="wrap"
        justifyContent="space-between"
        gap={3}
      >
        <StyleForm />
        <PreviewStyling />
      </Stack>
    </CardContainer>
  );
};
