import { Skeleton } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { renderDateTime } from '@understory-io/utils-date';
import { FC } from 'react';

import { useGetEvent } from '../../../Hooks/events/useGetEvent';

type Props = {
  eventId: string;
};

export const TicketDetailsEventDate: FC<Props> = ({ eventId }) => {
  const {
    event: { data: event, isLoading },
  } = useGetEvent(eventId);

  if (isLoading || !event) {
    return <Skeleton variant="text" width={200} height={24} />;
  }

  const eventTime = event
    ? renderDateTime(event.startDateTime, event.endDateTime, {
        standard: 'ddmmyyyyhh',
        sameDay: 'dmhh',
      })
    : undefined;

  return (
    <Text variant="normal" fontSize="medium">
      {eventTime}
    </Text>
  );
};
