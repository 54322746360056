import { CheckOutlined } from '@mui/icons-material';
import { CircularProgress, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigation } from 'react-router';

export const AutoSaveStatus = () => {
  const [showStatus, setShowStatus] = useState(false);
  const { state } = useNavigation();

  const isSaving = useMemo(() => state === 'submitting', [state]);

  // We do not want to show the status immediately when the
  // component is mounted because the animation will trigger
  useEffect(() => {
    if (showStatus) return;
    if (isSaving) setShowStatus(true);
  }, [isSaving, showStatus]);

  if (!showStatus) return null;

  if (isSaving) {
    return (
      <StatusWrapper label="Saving" icon={<CircularProgress size={12} />} />
    );
  }

  return (
    <StatusWrapper
      label="Saved"
      icon={<CheckOutlined fontSize="inherit" />}
      fadeOut
    />
  );
};

type StatusWrapperProps = {
  label: string;
  icon: ReactNode;
  fadeOut?: boolean;
};

const StatusWrapper = ({ label, icon, fadeOut }: StatusWrapperProps) => {
  return (
    <Stack
      sx={{
        color: lightTheme.palette.neutral.n400,
        gap: 0.5,
        alignItems: 'center',
        flexDirection: 'row',
        animation: fadeOut ? 'fade-out 0.25s forwards ease-out 2s' : undefined,
        '@keyframes fade-out': {
          '0%': {
            opacity: 1,
          },
          '100%': {
            opacity: 0,
          },
        },
      }}
    >
      <Text fontSize="small" color="inherit">
        {label}
      </Text>
      {icon}
    </Stack>
  );
};
