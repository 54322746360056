import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { FC } from 'react';

import { ShortcutTooltip } from '../../../../Components/tooltip/shortcut-tooltip';
import { useShortcuts } from '../../../../Hooks/useShortcuts';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { CalendarView } from '../../context';
import { useDateNavigation } from './use-date-navigation';

type Props = {
  interval: CalendarView;
};

export const DateNavigationButtons: FC<Props> = ({ interval }) => {
  const { t } = useTranslate('utils.generic');

  const { handlePrevDate, handleNextDate, isPrevDisabled, isNextDisabled } =
    useDateNavigation(interval);

  useShortcuts([
    {
      key: 'n',
      action: handleNextDate,
    },
    {
      key: 'p',
      action: handlePrevDate,
    },
  ]);

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <ShortcutTooltip title={t('previous')} shortcut="p" hideCommandKey>
        <StyledButton
          size="medium"
          variant="secondary"
          onClick={handlePrevDate}
          disabled={isPrevDisabled}
        >
          <ArrowBackIosRounded fontSize="inherit" sx={{ display: 'block' }} />
        </StyledButton>
      </ShortcutTooltip>
      <ShortcutTooltip title={t('next')} shortcut="n" hideCommandKey>
        <StyledButton
          size="medium"
          variant="secondary"
          onClick={handleNextDate}
          disabled={isNextDisabled}
        >
          <ArrowForwardIosRounded
            fontSize="inherit"
            sx={{ display: 'block' }}
          />
        </StyledButton>
      </ShortcutTooltip>
    </Stack>
  );
};

const StyledButton = styled(Button)({
  padding: 0,
  width: 32,
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
});
