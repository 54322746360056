import { Switch, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useController } from 'react-hook-form';

import { useProfile } from '../../../../Hooks/useProfile';
import { useFeatureSetting } from '../context/feature_setting_context';

type Props = {
  disabled?: boolean;
  disabledReason?: string;
  onToggle?: (enabled: boolean) => void;
};

export const FeatureEnabledSwitch: FC<Props> = ({
  disabled,
  disabledReason,
  onToggle,
}) => {
  const {
    company: { data: company },
  } = useProfile();

  const { control, save, feature } = useFeatureSetting();

  const enabled = company?.features?.[feature]?.enabled;
  const { field } = useController({
    name: 'enabled',
    control,
    defaultValue: enabled,
  });

  return (
    <Tooltip title={disabled ? disabledReason : ''} placement="top" arrow>
      <span>
        <Switch
          {...field}
          disabled={disabled}
          onChange={(_e, newValue) => {
            field.onChange(newValue);
            onToggle?.(newValue);
            save();
          }}
          checked={enabled}
        />
      </span>
    </Tooltip>
  );
};
