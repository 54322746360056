import { AddOutlined, CheckOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { HTMLAttributes } from 'react';

import { TagFilterItem } from '../tags';

export const AutoCompleteOption = ({
  tag,
  isSelected,
  ...params
}: {
  tag: TagFilterItem;
  isSelected: boolean;
} & HTMLAttributes<HTMLLIElement> & {
    key: string;
  }) => {
  return (
    <Stack
      component="li"
      {...params}
      key={tag.id}
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        paddingY: '8px !important',
        paddingX: '16px !important',
        transition: 'background-color 0.1s ease-in-out',
        ':hover': {
          backgroundColor: lightTheme.palette.neutral.n100,
        },
      }}
    >
      {tag.id === 'new' && <AddOutlined />}
      <Text fontSize="small">{tag.label}</Text>
      {isSelected && <CheckOutlined fontSize="small" />}
    </Stack>
  );
};
