import { Skeleton, TableCell, TableRow } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

export const EconomicDetailsTableRow = ({
  title,
  value,
}: {
  title: string;
  value?: string | React.ReactNode | null;
}) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" sx={{ display: 'block' }}>
        <Text
          fontSize="small"
          color={value ? lightTheme.palette.neutral.n400 : 'inherit'}
        >
          {title}
        </Text>
      </TableCell>
      <TableCell>
        {value !== undefined ? (
          typeof value === 'string' ? (
            <Text variant="medium" fontSize="small">
              {value}
            </Text>
          ) : (
            value
          )
        ) : (
          <Skeleton width={100} />
        )}
      </TableCell>
    </TableRow>
  );
};
