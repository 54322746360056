import { useLocation, useNavigate } from 'react-router';

export const useHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const hasHistory = location.key !== 'default';

  return {
    goBack: (fallbackPath: string) => {
      if (hasHistory) {
        navigate(-1);
      } else {
        navigate(fallbackPath, { replace: true });
      }
    },
  };
};
