import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isPast } from 'date-fns';

import * as api from '../Api';
import { DiscountBadge } from '../Components/badge/badge-config';

export type TDiscount = {
  id: string;
  status: string;
  name: string;
  created: string;
  companyId: string;
  code: string;
  rate: number;
  expiry: null | {
    startDateTime: string;
    endDateTime: string;
  };
  percentage: boolean;
  scope: 'unit' | 'total';
  currency: string;
  overrides?: {
    type: 'experience';
    id: string;
    enabled?: boolean;
    rate?: number;
    percentage?: boolean;
  }[];
  timesRedeemed: number;
  limits?: {
    timesRedeemed?: number;
  };
  enabled: boolean;
};

export const useDiscount = (discountId?: string) => {
  const queryClient = useQueryClient();

  const DiscountsQueryKey = ['discounts'];
  const DiscountQueryKey = ['discount', discountId];

  const discounts = useQuery({
    queryKey: DiscountsQueryKey,

    queryFn: async () => {
      return api.getDiscounts();
    },

    enabled: true,
  });

  const discount = useQuery({
    queryKey: DiscountQueryKey,

    queryFn: async () => {
      const discounts =
        queryClient.getQueryData<TDiscount[]>(DiscountsQueryKey);
      const found = discounts?.find((el) => el.id === discountId);
      if (found) {
        return found;
      }
      return api.getDiscount(discountId as string);
    },

    enabled: Boolean(discountId),
  });

  const updateDiscount = useMutation({
    mutationFn: ({ id, ...data }: TDiscount) =>
      api.updateDiscount(id ?? discountId, data),

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData<TDiscount>(DiscountQueryKey, context.previous);
      }
    },

    onSettled: async (data, error, variables) => {
      await queryClient.invalidateQueries({
        queryKey: DiscountsQueryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: ['discount', variables?.id ?? discountId],
      });
    },
  });

  const deleteDiscount = useMutation({
    mutationFn: (id: string) => api.deleteDiscount(id),

    onMutate: async (id) => {
      await queryClient.cancelQueries({
        queryKey: DiscountsQueryKey,
      });

      const previous = queryClient.getQueryData<TDiscount[]>(DiscountQueryKey);

      queryClient.setQueryData<TDiscount[]>(DiscountsQueryKey, (prev) => {
        return prev!.filter((el) => el.id !== id);
      });

      return { previous };
    },

    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData<TDiscount>(DiscountQueryKey, context.previous);
      }
    },

    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: DiscountsQueryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: DiscountQueryKey,
      });
    },
  });

  return {
    discount,
    discounts,
    updateDiscount,
    deleteDiscount,
  };
};

export const getDiscountStatus = (
  discount: TDiscount
): DiscountBadge['state'] => {
  const { limits, timesRedeemed, expiry, status } = discount;

  if (limits?.timesRedeemed && timesRedeemed >= limits.timesRedeemed)
    return 'expired';

  if (expiry?.endDateTime && isPast(new Date(expiry.endDateTime)))
    return 'expired';

  if (status === 'active') {
    return 'active';
  }

  if (status === 'expired') {
    return 'expired';
  }

  // fallback
  return 'none';
};
