import { Autocomplete, Popper } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { useState } from 'react';

import { TagFilterItem } from '../tags';
import { filterOptions } from './filter-options';
import { AutoCompleteInput } from './render-input';
import { AutoCompleteOption } from './render-option';
import { AutoCompleteTags } from './render-tags';

type TagAutoCompleteProps = {
  value: TagFilterItem[];
  options: TagFilterItem[];
  onChange: (newValue: TagFilterItem[]) => void;
  onCreate: (value: string) => void;
  error?: string;
};

export const TagAutoComplete = ({
  value,
  options,
  onChange,
  onCreate,
  error,
}: TagAutoCompleteProps) => {
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (newValue: TagFilterItem[]) => {
    // Update selection if creating a new tag was not selected
    if (!newValue.some((tag) => tag.id === 'new')) {
      return onChange(newValue);
    }

    // Create new tag
    onCreate(searchValue);
  };

  return (
    <Autocomplete
      value={value}
      options={options}
      multiple
      clearOnBlur
      selectOnFocus
      disableClearable
      handleHomeEndKeys
      filterOptions={filterOptions}
      onChange={(_, newValue) => handleChange(newValue)}
      renderTags={(tags, getProps) => (
        <AutoCompleteTags tags={tags} getProps={getProps} />
      )}
      renderInput={(params) => (
        <AutoCompleteInput
          onChange={(value) => setSearchValue(value)}
          params={params}
          error={!!error}
          helperText={error}
        />
      )}
      PopperComponent={({ children, ...props }) => (
        <Popper {...props} sx={{ boxShadow: lightTheme.shadows.xlarge }}>
          {children}
        </Popper>
      )}
      renderOption={(params, tag, ownerState) => (
        <AutoCompleteOption
          tag={tag}
          isSelected={ownerState.selected}
          {...params}
        />
      )}
    />
  );
};
