import { Stack } from '@mui/material';
import { renderShortDateTime } from '@understory-io/utils-date';
import {
  Receipt,
  Transaction,
  TransactionStatus,
} from '@understory-io/utils-types';

import { formatMoney } from '../../../../../../../../Components/VariantSelect/VariantSelect';
import {
  TranslateFunction,
  useTranslate,
} from '../../../../../../../../Hooks/useTranslate';
import { isProduction } from '../../../../../../../../Utils/environment';
import { getActiveRefunds } from '../../../../../../../../Utils/orders';
import { LinkItem, ListItem } from './shared';

type Props = {
  order: Receipt;
  transaction: Transaction;
  itemNumber: number;
};

export const OrderTransactionItem = ({
  order,
  transaction,
  itemNumber,
}: Props) => {
  const { t } = useTranslate('dialogs.booking');
  const moneyFormatter = formatMoney(t, true);

  const nativeCurrency = transaction.currency;

  const totalRefundedTransactionAmount = getActiveRefunds(order.refunds)
    .filter((x) => x.transactionId === transaction.transactionId)
    .reduce((acc, refund) => acc + refund.amountRefundedCents, 0);

  const passedOnBookingFee = transaction.feesCollected?.find(
    (fee) => fee.feeType === 'pass-on-booking-fee'
  );

  const formattedBookingFee = passedOnBookingFee
    ? (passedOnBookingFee.totalAmountCents / 100).toLocaleString(
        nativeCurrency,
        {
          style: 'currency',
          currency: nativeCurrency,
          maximumFractionDigits: 2,
        }
      )
    : undefined;

  const totalPaid = moneyFormatter({
    nativeCurrency,
    value: transaction.totalAmountChargedCents / 100,
  });

  const { giftCardCode, giftCardId, method, brand, last4 } =
    transaction.metadata as {
      [key: string]: string | undefined;
    };

  const showGiftCard =
    transaction.transactionMethod === 'giftcard' &&
    !!giftCardCode &&
    !!giftCardId;

  const showCardDetails = !!method && !!brand && !!last4;
  const transactionLink = getTransactionLink(transaction, order.id);

  return (
    <>
      <Stack gap={1}>
        <ListItem
          label={t('paymentItem', {
            itemNumber,
            totalItems: order.transactions.length,
          })}
          value={getTransactionStatus(transaction.status, t)}
        />
        <ListItem label={renderShortDateTime(transaction.paymentDate)} />
        {showCardDetails && (
          <ListItem label={`${getBrand(brand)} **** ${last4}`} />
        )}
        {showGiftCard && (
          <LinkItem
            label={t('giftCard', 'utils.generic')}
            link={`/giftcard/${giftCardId}`}
            linkText={`${giftCardCode}`}
          />
        )}
        {transactionLink && (
          <LinkItem
            label={t('transaction')}
            link={transactionLink}
            linkText={transaction.transactionId}
            target="_blank"
          />
        )}
      </Stack>
      <Stack gap={3}>
        <ListItem
          label={t('totalPaid')}
          value={`${totalPaid} ${formattedBookingFee ? `+ ${t('bookingFee', 'utils.generic')}: ${formattedBookingFee}` : ''}`}
        />
        {totalRefundedTransactionAmount > 0 && (
          <ListItem
            label={t('refunded')}
            value={moneyFormatter({
              nativeCurrency,
              value: -(totalRefundedTransactionAmount / 100),
            })}
          />
        )}
      </Stack>
    </>
  );
};

const getTransactionStatus = (
  status: TransactionStatus,
  t: TranslateFunction
) => {
  if (status === 'captured') {
    return t('paid', 'utils.statusOptions');
  }

  if (status === 'refunded-fully') {
    return t('refunded', 'utils.statusOptions');
  }

  return t(status, 'utils.statusOptions');
};

const getTransactionLink = (
  transaction: Transaction,
  orderId: string | undefined
): string | null => {
  if (transaction.transactionMethod === 'stripe') {
    return `https://dashboard.stripe.com/payments/${transaction.transactionId}`;
  }

  if (
    transaction.transactionMethod === 'quickpay' &&
    transaction.metadata.gatewayIdentifier
  ) {
    const {
      transactionId,
      metadata: { gatewayIdentifier },
    } = transaction;

    return `https://manage.quickpay.net/account/${gatewayIdentifier}/payment/${transactionId}`;
  }

  if (transaction.transactionMethod === 'paypal') {
    const paypalDomain = isProduction
      ? 'https://www.paypal.com'
      : 'https://www.sandbox.paypal.com';

    return `${paypalDomain}/unifiedtransactions/?filter=9&query=${orderId}`;
  }

  return null;
};

const getBrand = (brand: string | undefined) => {
  if (brand === 'visa') {
    return 'VISA';
  }

  return brand;
};
