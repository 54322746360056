import styled from '@emotion/styled';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { Card, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { Experience } from '@understory-io/utils-types';
import { useCallback, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ContextMenu } from '../../../../Components/context-menu/context-menu';
import { getLocalized } from '../../../../Hooks/useBookings';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { StorefrontCustomizeFormData } from '../styling/storefront-styling-validation';
import { SelectSortBy } from './select-sort-by';

export const ExperiencesList = ({
  experiences,
}: {
  experiences: Experience[];
}) => {
  const { resetField, control } = useFormContext<StorefrontCustomizeFormData>();

  const {
    field: { value: currentOrder, onChange: updateOrder },
  } = useController<StorefrontCustomizeFormData, 'sortOrder'>({
    name: 'sortOrder',
    control,
  });

  const {
    field: { value: sortBy, onChange: updateSortBy },
  } = useController<StorefrontCustomizeFormData, 'sortExperiencesBy'>({
    name: 'sortExperiencesBy',
    control,
  });

  const handleMoveUp = useCallback(
    (index: number) => {
      if (index <= 0) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp[index] = temp[index - 1];
      temp[index - 1] = experienceToMove;

      updateOrder(temp);
    },
    [currentOrder, updateOrder]
  );

  const handleMoveDown = useCallback(
    (index: number) => {
      if (index >= currentOrder.length - 1) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp[index] = temp[index + 1];
      temp[index + 1] = experienceToMove;

      updateOrder(temp);
    },
    [currentOrder, updateOrder]
  );

  const handleMoveToTop = useCallback(
    (index: number) => {
      if (index <= 0) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp.splice(index, 1);

      updateOrder([experienceToMove, ...temp]);
    },
    [currentOrder, updateOrder]
  );

  const handleMoveToBottom = useCallback(
    (index: number) => {
      if (index >= currentOrder.length - 1) return;

      const temp = [...currentOrder];
      const experienceToMove = temp[index];
      temp.splice(index, 1);

      updateOrder([...temp, experienceToMove]);
    },
    [currentOrder, updateOrder]
  );

  const experienceMap = useMemo(
    () =>
      experiences.reduce(
        (experiences, experience) => {
          return {
            ...experiences,
            [experience.id]: experience,
          };
        },
        {} as Record<string, Experience>
      ),
    [experiences]
  );

  return (
    <Stack marginTop={2} gap={2}>
      <SelectSortBy
        value={sortBy}
        onChange={(selected) => {
          updateSortBy(selected);
          resetField('sortOrder');
        }}
      />
      {sortBy === 'sortOrder' && (
        <Stack gap={1} flexGrow={1}>
          {currentOrder.map((experienceId, index) => {
            const experience = experienceMap[experienceId];

            if (!experience) return null;

            return (
              <ExperienceItem
                key={experience.id}
                experience={experience}
                handleMoveUp={() => handleMoveUp(index)}
                handleMoveDown={() => handleMoveDown(index)}
                handleMoveToTop={() => handleMoveToTop(index)}
                handleMoveToBottom={() => handleMoveToBottom(index)}
                isMoveUpDisabled={index === 0}
                isMoveDownDisabled={index >= currentOrder.length - 1}
              />
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};

const ExperienceItem = ({
  experience,
  isMoveUpDisabled,
  isMoveDownDisabled,
  handleMoveUp,
  handleMoveDown,
  handleMoveToTop,
  handleMoveToBottom,
}: {
  experience: Experience;
  isMoveUpDisabled: boolean;
  isMoveDownDisabled: boolean;
  handleMoveUp: () => void;
  handleMoveDown: () => void;
  handleMoveToTop: () => void;
  handleMoveToBottom: () => void;
}) => {
  const { t, i18n } = useTranslate('storefront.experiences');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack
      key={experience.id}
      component={Card}
      alignItems="center"
      direction="row"
      padding={1}
      justifyContent="space-between"
      gap={3}
      boxShadow="none"
      border={`1px solid ${lightTheme.palette.neutral.n100}`}
      borderRadius="8px"
      maxWidth="640px"
    >
      <Stack alignItems="center" direction="row" gap={3}>
        <Stack flexShrink={0} gap={0.5}>
          <StyledButton
            type="button"
            size="medium"
            variant="secondary"
            onClick={handleMoveUp}
            disabled={isMoveUpDisabled}
          >
            <KeyboardArrowUpRounded />
          </StyledButton>
          <StyledButton
            type="button"
            size="medium"
            variant="secondary"
            onClick={handleMoveDown}
            disabled={isMoveDownDisabled}
          >
            <KeyboardArrowDownRounded />
          </StyledButton>
        </Stack>
        <ClampedText fontSize="xsmall">
          {getLocalized(experience.headline, i18n.language)}
        </ClampedText>
      </Stack>

      <ContextMenu
        open={isOpen}
        onOpenChange={setIsOpen}
        options={[
          {
            label: t('moveToTop'),
            onClick: handleMoveToTop,
            disabled: isMoveUpDisabled,
          },
          {
            label: t('moveToBottom'),
            onClick: handleMoveToBottom,
            disabled: isMoveDownDisabled,
          },
        ]}
      />
    </Stack>
  );
};

const StyledButton = styled(Button)({
  height: '24px',
  borderRadius: '4px',
  aspectRatio: 1,
  padding: 0,
  span: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ClampedText = styled(Text)`
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
