import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { Text } from '@understory-io/pixel';

import { useExperienceTags } from '../../../../../Hooks/useExperienceTags';
import { useLocalizedStringFormatter } from '../../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import {
  trackStorefrontImplementationTagsOpened,
  trackStorefrontImplementationTagsSelected,
} from '../../../../../tracking/storefront/implementation-events';
import { ActionTypeEnum } from '../../domain/types';
import { useBookingFlowSectionContext } from '../../domain/use_booking_flow_section_context';

export const IntegrationLinksDropdownTags = () => {
  const { t } = useTranslate('storefront.bookingflow');

  const getLocalizedString = useLocalizedStringFormatter();
  const { selectedTags, dispatch } = useBookingFlowSectionContext();
  const { tags } = useExperienceTags();

  return (
    <FormControl disabled={tags.isLoading}>
      <InputLabel sx={{ fontWeight: '600' }}>
        {t('tags', 'utils.generic')}
      </InputLabel>
      <Select
        name={'tags'}
        label={t('tags', 'utils.generic')}
        aria-label={t('tags', 'utils.generic')}
        value={selectedTags || []}
        multiple
        defaultValue={[]}
        onOpen={trackStorefrontImplementationTagsOpened}
        onChange={(e) => {
          const payload = e.target.value as string[];
          trackStorefrontImplementationTagsSelected(payload);
          dispatch({
            type: ActionTypeEnum.SetTags,
            payload,
          });
        }}
        sx={{
          width: 160,
        }}
      >
        {tags.data?.map((tag) => (
          <MenuItem key={tag.id} value={tag.id}>
            <StyledText fontSize="small">
              {getLocalizedString(tag.name)}
            </StyledText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
