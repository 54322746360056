import NiceModal from '@ebay/nice-modal-react';
import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import {
  trackBookingResendNotificationFlowCompleted,
  trackBookingResendNotificationFlowExited,
  trackBookingResendNotificationFlowStarted,
} from '../../../../../../../../tracking/bookings/details';
import { useBookingDetailsContext } from '../../../../../domain/use_booking_details_context';
import { getNotificationEmail } from '../domain/helpers';
import { NotificationsPreviewModal } from './modals/preview/NotificationsPreviewModal';
import { NotificationsResendModal } from './modals/resend/NotificationsResendModal';

type BookingDetailsSummaryNotificationsActionsProps = {
  notificationId: string;
  bookingId: string;
  name: string;
  address: string;
  hidePreview?: boolean;
  disallowChangeRecipient?: boolean;
  daysSinceConfirmationSent?: number;
};

export const BookingDetailsSummaryNotificationsActions = ({
  notificationId,
  bookingId,
  name,
  address,
  hidePreview,
  disallowChangeRecipient,
  daysSinceConfirmationSent,
}: BookingDetailsSummaryNotificationsActionsProps) => {
  const { t } = useTranslate('dialogs.booking.notifications');
  const {
    booking,
    receipt: { data: receipt },
  } = useBookingDetailsContext();

  const [loadingEmail, setLoadingEmail] = useState(false);

  const handlePreview = useCallback(async () => {
    setLoadingEmail(true);
    try {
      const email = await getNotificationEmail(notificationId);
      NiceModal.show(NotificationsPreviewModal, { email });
    } catch {
      toast.error(t('previewError'));
    } finally {
      setLoadingEmail(false);
    }
  }, [notificationId]);

  const handleResend = useCallback(async () => {
    trackBookingResendNotificationFlowStarted(
      booking,
      receipt,
      daysSinceConfirmationSent
    );

    try {
      await NiceModal.show(NotificationsResendModal, {
        bookingRecipient: {
          name,
          address,
        },
        notificationId,
        bookingId,
        disallowChangeRecipient,
      });

      trackBookingResendNotificationFlowCompleted(
        booking,
        receipt,
        daysSinceConfirmationSent
      );
    } catch {
      trackBookingResendNotificationFlowExited(
        booking,
        receipt,
        daysSinceConfirmationSent
      );
    }
  }, [
    name,
    address,
    notificationId,
    bookingId,
    booking,
    receipt,
    daysSinceConfirmationSent,
    disallowChangeRecipient,
  ]);

  return (
    <Stack direction="row" gap={1}>
      {!hidePreview && (
        <Button
          variant="secondary"
          size="small"
          aria-label={t('preview')}
          loading={loadingEmail}
          onClick={handlePreview}
        >
          {t('preview')}
        </Button>
      )}
      <Button
        variant="secondary"
        size="small"
        aria-label={t('resend')}
        onClick={handleResend}
      >
        {t('resend')}
      </Button>
    </Stack>
  );
};
