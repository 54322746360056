import { Box, Stack, StackProps, Typography } from '@mui/material';
import { Button } from '@understory-io/pixel';

import { ShowForScope } from '../AllowForScope/AllowForScope';

interface IEmptyScreenProps {
  imageSrc: string;
  title: string;
  description: string;
  onClick?: () => void;
  buttonLabel?: string;
  scopes?: string[];
}

export const EmptyScreen = ({
  imageSrc,
  title,
  description,
  onClick,
  buttonLabel,
  scopes = [],
  ...props
}: Omit<StackProps, 'onClick' | 'title'> & IEmptyScreenProps) => {
  return (
    <Stack
      alignItems={'center'}
      maxWidth={560}
      ml={'auto'}
      mr={'auto'}
      {...props}
    >
      <Box
        component={'img'}
        width={180}
        height={180}
        sx={{
          objectFit: 'contain',
          objectPosition: 'center',
        }}
        src={imageSrc}
      />
      <Typography variant={'h2'} fontSize={'2em'} mt={3} mb={2}>
        {title}
      </Typography>
      <Typography mb={4} whiteSpace={'pre-wrap'} textAlign={'center'}>
        {description}
      </Typography>
      <ShowForScope scopes={scopes}>
        {buttonLabel && (
          <Button variant={'primary'} size={'medium'} onClick={onClick}>
            {buttonLabel}
          </Button>
        )}
      </ShowForScope>
    </Stack>
  );
};
