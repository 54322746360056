import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  styled,
} from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { Receipt } from '@understory-io/utils-types';
import { SyntheticEvent, useState } from 'react';

import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { OrderTransactionItem } from './OrderTransactionItem';

type Props = {
  order: Receipt;
};

export const BookingOrderDetails = ({ order }: Props) => {
  const { t } = useTranslate('dialogs.booking');
  const [showTransactions, setShowTransactions] = useState<boolean>(false);

  const handleShowTransactions = (
    _event: SyntheticEvent,
    isExpanded: boolean
  ) => {
    setShowTransactions(isExpanded);
  };

  if (order.transactions.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <StyledAccordion disableGutters onChange={handleShowTransactions}>
        <StyledAccordionSummary expandIcon={<ExpandMore />}>
          {showTransactions
            ? t('lessDetails', 'utils.generic')
            : t('moreDetails', 'utils.generic')}
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Stack marginTop={3} gap={2} divider={<Divider />}>
            {order.transactions.map((transaction, index) => (
              <OrderTransactionItem
                key={index}
                order={order}
                transaction={transaction}
                itemNumber={index + 1}
              />
            ))}
          </Stack>
        </StyledAccordionDetails>
      </StyledAccordion>
    </>
  );
};

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  width: '100%',
  marginTop: '16px',
  border: '1px solid transparent',
  '&:before': {
    display: 'none',
  },
  backgroundColor: lightTheme.palette.contrast.white,
});

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '0px',
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '.MuiAccordionSummary-content': {
    'flex-grow': 0,
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '0px',
});
