import styled from '@emotion/styled';
import { CircularProgress, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { Opportunity } from '../../domain/types';
import { useGrowthOpportunities } from '../../domain/use-growth-opportunities';

type OpportunityCardProps = {
  opportunity: Opportunity;
};

export const GrowthOpportunityListItem = ({
  opportunity,
}: OpportunityCardProps) => {
  const { t } = useTranslate('utils.generic');
  const { isNavigating, readMoreAction } = useGrowthOpportunities();
  const { isSm } = useResponsive();

  const Icon = opportunity.icon;

  return (
    <StyledListItem onClick={() => readMoreAction(opportunity)}>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <Stack
          sx={{
            background: lightTheme.palette.neutral.n50,
            p: 1.5,
            borderRadius: '50%',
            width: 'fit-content',
            height: 'fit-content',
          }}
        >
          <Icon />
        </Stack>
        <Stack>
          <Text variant="medium">{opportunity.name}</Text>
          <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
            {opportunity.teaser}
          </Text>
        </Stack>
      </Stack>
      {!isSm && (
        <Button
          variant="secondary"
          size="medium"
          disabled={isNavigating}
          leftIcon={
            isNavigating && <CircularProgress size={16} color="inherit" />
          }
          onClick={() => readMoreAction(opportunity)}
        >
          {t('readmore')}
        </Button>
      )}
    </StyledListItem>
  );
};

const StyledListItem = styled(Stack)`
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  background: ${lightTheme.palette.contrast.white};
  padding: 20px;
  border-radius: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  will-change: transform;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
    -webkit-transform: scale(1.01);

    box-shadow: 0 4px 12px rgba(20, 20, 20, 0.05);
  }
`;
