import { useParams } from 'react-router';

import { PreviewLayout } from '../preview-layout';
import { PreviewLayoutHeader } from '../preview-layout-header';
import { PreviewListLoader } from '../ui/PreviewListLoader';
import { GeneratingPreviewError } from './generating-preview-error';
import { GeneratingPreviewSuccess } from './generating-preview-success';
import { useGeneratingPreviewState } from './use-generating-preview-state';

export const GeneratingPreview = () => {
  const { id } = useParams<{ id: string }>();

  const previewState = useGeneratingPreviewState(id);

  return (
    <PreviewLayout>
      <PreviewLayoutHeader
        type={previewState.status === 'succeeded' ? 'finished' : 'creating'}
      />
      {previewState.status === 'initiated' && <PreviewListLoader />}
      {previewState.status === 'failed' && <GeneratingPreviewError />}
      {previewState.status === 'succeeded' && (
        <GeneratingPreviewSuccess storefrontUrl={previewState.storefrontUrl} />
      )}
    </PreviewLayout>
  );
};
