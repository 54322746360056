import { yupResolver } from '@hookform/resolvers/yup';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog, Fade, Slide, Stack, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Text } from '@understory-io/pixel';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RRule } from 'rrule';

import useResponsive from '../../../../../../../../../Hooks/layout/useResponsive';
import { theme } from '../../../../../../../../../Utils/theme';
import { useEventUpsert } from '../../../../../../domain/event_upsert_context';
import { RecurringCustom } from '../../../../../../domain/types';
import {
  getByweekdayFromRRuleString,
  getFrequencyFromRRuleString,
  getIntervalFromRRuleString,
} from '../../rrule_string_helpers';
import { EventUpsertTimeFormRecurringFrequencySelect } from './EventUpsertTimeFormRecurringFrequencySelect';
import { EventUpsertTimeFormRecurringIntervalSelect } from './EventUpsertTimeFormRecurringIntervalSelect';
import { EventUpsertTimeFormRecurringMonthField } from './EventUpsertTimeFormRecurringMonthField';
import { EventUpsertTimeFormRecurringWeeklySelect } from './EventUpsertTimeFormRecurringWeeklySelect';
import {
  EventUpsertRecurringCustomFormTypes,
  useRecurringCustomFormValidation,
} from './use_recurring_custom_form_validation';

type EventUpsertTimeFormRecurringModalProps = {
  onSubmit: (data?: EventUpsertRecurringCustomFormTypes) => void;
  onClose: () => void;
};

export const EventUpsertTimeFormRecurringDialog = ({
  onSubmit,
  onClose,
}: EventUpsertTimeFormRecurringModalProps) => {
  const { t } = useTranslation();
  const { currentEvent } = useEventUpsert();
  const { isSm } = useResponsive();

  const formValidationSchema = useRecurringCustomFormValidation(t);
  const form = useForm<EventUpsertRecurringCustomFormTypes>({
    resolver: yupResolver(formValidationSchema),
    defaultValues: {
      interval: getIntervalFromRRuleString(
        (currentEvent.recurring as RecurringCustom).rrulestr
      ),
      frequency: getFrequencyFromRRuleString(
        (currentEvent.recurring as RecurringCustom).rrulestr
      ),
      byweekday: getByweekdayFromRRuleString(
        (currentEvent.recurring as RecurringCustom).rrulestr
      ),
    } as EventUpsertRecurringCustomFormTypes,
  });

  const formValues = form.watch();

  const isWeeklyFrequency = formValues.frequency === RRule.WEEKLY;
  const isMonthlyFrequency = formValues.frequency === RRule.MONTHLY;

  const displayRepeatOn = isWeeklyFrequency || isMonthlyFrequency;

  return (
    <FormProvider {...form}>
      <form>
        <Dialog
          onClose={onClose}
          open
          fullScreen={isSm ? true : false}
          TransitionComponent={isSm ? Transition : Fade}
        >
          {isSm && (
            <StyledCloseButton onClick={onClose}>
              <CloseRoundedIcon />
            </StyledCloseButton>
          )}
          <Stack
            gap={4}
            p={isSm ? 2 : 5}
            pt={4}
            minWidth={isSm ? 'auto' : '544px'}
          >
            <Text fontSize="xlarge" variant="medium">
              {t('eventUpsert.flow.time.recurringCustomDialog.title')}
            </Text>
            <Stack gap={2}>
              <Stack direction="row" alignItems="baseline" gap={4}>
                <StyledText>
                  {t('eventUpsert.flow.time.recurringCustomDialog.repeatEvery')}
                </StyledText>
                <Stack direction="row" alignItems="baseline" gap={2}>
                  <EventUpsertTimeFormRecurringIntervalSelect />
                  <EventUpsertTimeFormRecurringFrequencySelect />
                </Stack>
              </Stack>
              {displayRepeatOn && (
                <Stack
                  gap={isWeeklyFrequency ? 1 : 4}
                  direction={isWeeklyFrequency ? 'column' : 'row'}
                  alignItems="baseline"
                >
                  <Text>
                    {t('eventUpsert.flow.time.recurringCustomDialog.repeatOn')}
                  </Text>
                  {isWeeklyFrequency && (
                    <EventUpsertTimeFormRecurringWeeklySelect />
                  )}
                  {isMonthlyFrequency && (
                    <EventUpsertTimeFormRecurringMonthField
                      startDateTime={currentEvent.startDateTime}
                    />
                  )}
                </Stack>
              )}
            </Stack>
            <Stack direction="row" justifyContent="flex-end" gap={2}>
              {!isSm && (
                <Button
                  size="medium"
                  variant="secondary"
                  onClick={onClose}
                  width="140px"
                >
                  {t(
                    'eventUpsert.flow.time.recurringCustomDialog.actions.cancel.label'
                  )}
                </Button>
              )}
              <Button
                size="medium"
                variant="primary"
                onClick={form.handleSubmit(onSubmit)}
                width={isSm ? 'auto' : '140px'}
                style={
                  isSm
                    ? {
                        position: 'fixed',
                        bottom: theme.spacing(4),
                        left: theme.spacing(2),
                        right: theme.spacing(2),
                        margin: `0 auto`,
                      }
                    : {}
                }
              >
                {t(
                  'eventUpsert.flow.time.recurringCustomDialog.actions.save.label'
                )}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      </form>
    </FormProvider>
  );
};

const StyledText = styled(Text)`
  min-width: fit-content;
`;

const StyledCloseButton = styled('button')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  padding: ${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)} ;
`
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
