import { ChevronRightRounded } from '@mui/icons-material';
import { Card, Skeleton, Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

export const GiftCardsSkeletonDesktop = () => {
  return (
    <Stack spacing={1}>
      <Stack spacing={4} pl={7} component={Card} direction={'row'}>
        <Skeleton width={60} />
        <Skeleton width={180} />
        <Skeleton width={240} />
        <Skeleton width={164} />
      </Stack>
      <Stack spacing={4} pl={7} component={Card} direction={'row'}>
        <Skeleton width={60} />
        <Skeleton width={180} />
        <Skeleton width={211} />
        <Skeleton width={120} />
      </Stack>
      <Stack spacing={4} pl={7} component={Card} direction={'row'}>
        <Skeleton width={60} />
        <Skeleton width={180} />
        <Skeleton width={240} />
        <Skeleton width={164} />
      </Stack>
    </Stack>
  );
};

export const GiftCardsSkeletonMobile = () => {
  return (
    <>
      <Stack
        spacing={1}
        direction={'row'}
        justifyContent={'space-between'}
        py={2}
      >
        <Skeleton width={80} />
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Skeleton width={180} />
          <ChevronRightRounded htmlColor={lightTheme.palette.neutral.n300} />
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        direction={'row'}
        justifyContent={'space-between'}
        py={2}
      >
        <Skeleton width={80} />
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Skeleton width={180} />
          <ChevronRightRounded htmlColor={lightTheme.palette.neutral.n300} />
        </Stack>
      </Stack>
    </>
  );
};
