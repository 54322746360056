import { CloseRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';
import { Text } from '@understory-io/pixel';

import { useTranslate } from '../../Hooks/useTranslate';
import { useEconomicConfiguration } from './data/use-economic-configuration';

export const EconomicIntegrationLoaderError = () => {
  const { t } = useTranslate('settings.integrations.economic.configuration');

  const {
    configuration: { refetch },
  } = useEconomicConfiguration();

  return (
    <Stack justifyContent="space-between" flex={1} gap={3}>
      <Stack gap={2} alignItems="center" marginY={3}>
        <Box position="relative" width={60} height={60}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-30 -30 60 60"
            fill={lightTheme.palette.error.e100}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <circle r="29.5" fill="#FBE9E9" />
          </svg>
          <CloseRounded
            sx={{
              color: lightTheme.palette.error.e300,
              zIndex: 1,
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: 34,
            }}
          />
        </Box>
        <Text fontSize="large" variant="medium">
          {t('generic', 'utils.errors')}
        </Text>
        <Box px={4} textAlign="center">
          {t('loadingConfigurationError')}
        </Box>
        <Button
          size="medium"
          variant="primary"
          onClick={() => {
            refetch();
          }}
        >
          {t('confirmLabel', 'dialogs.errorRetry')}
        </Button>
      </Stack>
    </Stack>
  );
};
