import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { RefObject } from 'react';
import Slider from 'react-slick';

import useResponsive from '../../../../../Hooks/layout/useResponsive';

type GrowthOpportunitiesSliderButtonsProps = {
  sliderRef: RefObject<Slider>;
};

export const GrowthOpportunitiesSliderButtons = ({
  sliderRef,
}: GrowthOpportunitiesSliderButtonsProps) => {
  const { isMd } = useResponsive();

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <Stack
      direction={'row'}
      gap={2}
      mt={isMd ? 1 : 0}
      mb={isMd ? 0 : '-40px'}
      justifyContent={isMd ? 'center' : 'flex-end'}
      zIndex={999}
      position={'relative'}
    >
      <Button
        onClick={goToPrevSlide}
        variant="secondary"
        size="small"
        style={{ aspectRatio: '1/1', padding: 22, border: 'none' }}
      >
        <ArrowBackRounded sx={{ mt: 0.5 }} />
      </Button>
      <Button
        onClick={goToNextSlide}
        variant="secondary"
        size="small"
        style={{ aspectRatio: '1/1', padding: 22, border: 'none' }}
      >
        <ArrowForwardRounded sx={{ mt: 0.5 }} />
      </Button>
    </Stack>
  );
};
