import { Experience } from '@understory-io/utils-types';

import { ampli } from '../../Ampli';
import { TEventWithTitle } from '../../Utils/eventHelpers';
import { eventProperties, EventSourceType } from './eventProperties';

export const trackEventMessageAllStarted = (
  event: TEventWithTitle,
  experience: Experience | undefined,
  source: EventSourceType
) => {
  ampli.eventMessageAllStarted(eventProperties(event, experience, source));
};
