import { Box, BoxProps, Typography } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { BookingsListPage } from '../Components/BookingsList/BookingsListPage';
import { TBooking, useBookings } from '../Hooks/useBookings';
import { useFireOnce } from '../Hooks/useFireOnce';
import { useProfile } from '../Hooks/useProfile';
import { useTranslate } from '../Hooks/useTranslate';
import { trackBookingsPageNextDayLoaded } from '../tracking/bookings/page/trackBookingsPageNextDayLoaded';
import { trackBookingsPageViewed } from '../tracking/bookings/page/trackBookingsPageViewed';
import { CrudPage } from './CrudPage';

export const BookingsPage = () => {
  const daysToLoad = 7;
  const now = new Date();
  const { t } = useTranslate('bookings.listPage');

  const { me } = useProfile();

  const [firstLoad, setFirstLoad] = useState(false);
  const [allBookings, setAllBookings] = useState<TBooking[][]>([]);
  const [datesToLoad, setDatesToLoad] = useState<Date[]>(
    [...Array(daysToLoad)].map((_, i) => {
      const date = new Date(now);
      date.setDate(date.getDate() - i);
      return date;
    })
  );

  useEffect(() => {
    if (allBookings.length === datesToLoad.length) {
      if (!firstLoad) {
        trackBookingsPageViewed(allBookings.flat(), me?.data?.id);
        setFirstLoad(true);
        return;
      }

      trackBookingsPageNextDayLoaded(allBookings.flat(), me?.data?.id);
    }
  }, [allBookings, datesToLoad, me?.data?.id]);

  return (
    <CrudPage baseScope="booking" title={'Bookings'}>
      {datesToLoad.map((date) => {
        return (
          <BookingsSection
            key={`bookings-${date}`}
            date={date}
            setAllBookings={setAllBookings}
          />
        );
      })}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="medium"
          variant="primary"
          onClick={() => {
            const date = new Date(datesToLoad[datesToLoad.length - 1]);
            date.setDate(date.getDate() - 1);
            const newDates = [...datesToLoad, date];
            setDatesToLoad(newDates);
          }}
        >
          {t('loadMore')}
        </Button>
      </Box>
    </CrudPage>
  );
};

const BookingsSection = ({
  date,
  setAllBookings,
}: BoxProps & {
  date: Date;
  setAllBookings: React.Dispatch<React.SetStateAction<TBooking[][]>>;
}) => {
  const { t } = useTranslate('bookings.listPage');

  const fireOnce = useFireOnce();

  const { bookingsByDate } = useBookings(
    undefined,
    undefined,
    date.toISOString().split('T')[0]
  );

  useEffect(() => {
    if (bookingsByDate.data) {
      fireOnce(() => setAllBookings((prev) => [...prev, bookingsByDate.data]));
    }
  }, [bookingsByDate.data]);

  return (
    <>
      <Typography variant={'h5'} mb={4}>
        {format(date, 'PPP')}
      </Typography>
      {!bookingsByDate.isLoading && bookingsByDate.data?.length === 0 ? (
        <Typography align="center" mb={4} variant={'subtitle2'}>
          {t('noBookingsOnDate')}
        </Typography>
      ) : (
        <BookingsListPage
          mb={4}
          isLoading={bookingsByDate.isLoading || bookingsByDate.isFetching}
          bookings={bookingsByDate.data}
          showExperience={true}
        />
      )}
    </>
  );
};
