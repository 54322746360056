import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

export const StrikeOutStack = styled(Stack)`
  width: fit-content;
  position: relative;

  ::after {
    border: 1px solid ${lightTheme.palette.neutral.n400};
    position: absolute;
    content: '';
    right: 0;
    top: calc(50% - 1px);
    left: 0;
  }
`;
