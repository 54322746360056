import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { AxiosError } from 'axios';

import { useTranslate } from '../Hooks/useTranslate';

// Supports resources defined in POEditor - will use generic error message if not defined
type RequestedResource = 'generic' | 'booking' | 'event' | 'experience';

export const ErrorPage = ({
  error,
  resource = 'generic',
}: {
  error: AxiosError;
  resource?: RequestedResource;
}) => {
  const { t } = useTranslate('utils.errors.status');

  const errorCode = error.request.status;

  return (
    <StyledErrorPage>
      <Text variant="medium" fontSize="xlarge">
        {`${errorCode} - ${t(`${errorCode}.${resource}`, { defaultValue: t('generic', 'utils.errors') })}`}
      </Text>
    </StyledErrorPage>
  );
};

const StyledErrorPage = styled(Box)({
  maxWidth: 1200,
  paddingInline: 50,
  marginBlock: 40,
  marginInline: 'auto',
});
