import styled from '@emotion/styled';
import {
  AddCardOutlined,
  AttachMoneyOutlined,
  AutorenewOutlined,
  CardGiftcardOutlined,
  EventAvailableOutlined,
  GroupsOutlined,
  PersonOutlined,
  RemoveCircleOutlineOutlined,
  RemoveRedEyeOutlined,
  ShoppingCartOutlined,
  ShowChartOutlined,
} from '@mui/icons-material';
import { Stack, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { lightTheme, Text } from '@understory-io/pixel';

import { Tooltip } from '../../../../../../Components/tooltip/tooltip';
import { useProfile } from '../../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../../Hooks/useTranslate';
import { useDashboardPageContext } from '../../../../use-dashboard-page-context';
import {
  CURRENCY_METRIC_KEYS,
  PERCENTAGE_METRIC_KEYS,
} from '../dashboard-graphs-loader-card';

type DashboardGraphsLoaderCardMetricsListProps = {
  selectedMetric: {
    key: string;
    value: number;
  } | null;
  metrics: {
    [key: string]: number;
  };
  handleSelectMetric: (key: string) => void;
};

export const DashboardGraphsLoaderCardMetricsList = ({
  selectedMetric,
  metrics,
  handleSelectMetric,
}: DashboardGraphsLoaderCardMetricsListProps) => {
  const { t, i18n } = useTranslate('dashboard');

  const { defaultCurrency } = useProfile();
  const { isLoadingMetrics } = useDashboardPageContext();

  return (
    <Stack gap={1}>
      {!isLoadingMetrics &&
        Object.entries(metrics).map(([key, value]) => (
          <Tooltip
            key={key}
            title={t(`statistics.cards.tooltips.${key}`)}
            placement={'top'}
            enterDelay={750}
            enterNextDelay={750}
            disableInteractive
            componentsProps={{
              tooltip: {
                sx: { backgroundColor: `${lightTheme.palette.neutral.n500}CC` },
              },
              arrow: {
                sx: { color: `${lightTheme.palette.neutral.n500}CC` },
              },
            }}
          >
            <StyledStack
              key={key}
              gap={3}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              isSelected={key === selectedMetric?.key}
              sx={{ cursor: 'pointer', userSelect: 'none' }}
              onClick={() => handleSelectMetric(key)}
            >
              <Stack gap={1} direction={'row'}>
                {getMetricIcon(key)}
                <Text
                  fontSize={'xsmall'}
                  color={lightTheme.palette.neutral.n300}
                >
                  {t(`statistics.cards.${key}`)}
                </Text>
              </Stack>
              <Text fontSize={'xsmall'}>
                {PERCENTAGE_METRIC_KEYS.includes(key)
                  ? value?.toLocaleString(i18n.language, {
                      style: 'percent',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : `${value?.toLocaleString(i18n.language)} ${CURRENCY_METRIC_KEYS.includes(key) ? defaultCurrency : ''}`}
              </Text>
            </StyledStack>
          </Tooltip>
        ))}
    </Stack>
  );
};

const StyledStack = styled(Stack)<{ isSelected: boolean }>`
  padding: 6px;
  border-radius: 8px;
  background-color: ${({ isSelected }) =>
    isSelected ? `${lightTheme.palette.neutral.n100}` : 'transparent'};
  outline: 1px solid transparent;
  transition: all 0.2s ease-in;

  &:hover {
    outline: ${({ isSelected }) =>
      isSelected
        ? '1px solid transparent'
        : `1px solid ${lightTheme.palette.neutral.n200}`};
  }
`;

const getMetricIcon = (key: string) => {
  const Icon = metricIcons[key as keyof typeof metricIcons];
  return <Icon fontSize={'small'} htmlColor={lightTheme.palette.action.a300} />;
};

const metricIcons: Record<string, OverridableComponent<SvgIconTypeMap>> = {
  totalSales: AttachMoneyOutlined,
  discounts: RemoveCircleOutlineOutlined,
  refunds: AutorenewOutlined,
  netRevenue: AddCardOutlined,
  orders: ShoppingCartOutlined,
  pageViews: RemoveRedEyeOutlined,
  ordersAvgValue: ShowChartOutlined,
  giftCardsSold: CardGiftcardOutlined,
  giftCardsValue: AttachMoneyOutlined,
  giftCardsAvgValue: ShowChartOutlined,
  guests: PersonOutlined,
  eventCapacity: GroupsOutlined,
  utilityRate: EventAvailableOutlined,
};
