import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { Button, Text } from '@understory-io/pixel';
import { CompanyProfile } from '@understory-io/utils-types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRevalidator } from 'react-router';
import { toast } from 'react-toastify';

import { StyledPopoverContent } from '../../../../../Components/Popover/radix_popover_styles';
import { useCountryVatRates } from '../../../../../Hooks/useCountryVatRates';
import { useProfile } from '../../../../../Hooks/useProfile';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import {
  useVatFormValidation,
  VatFormData,
} from '../../../../../Modals/vat-form/domain/use-vat-form-validation';
import { getVatCompliance } from '../../../../../Sections/SettingsSections/Company/domain/helpers';
import { CompanyFormVatRegistrationCategory } from '../../../../../Sections/SettingsSections/Company/ui/form/VatRegistration/CompanyFormVatRegistrationCategory';
import { CompanyFormVatRegistrationCountry } from '../../../../../Sections/SettingsSections/Company/ui/form/VatRegistration/CompanyFormVatRegistrationCountry';
import { CompanyFormVatRegistrationNumber } from '../../../../../Sections/SettingsSections/Company/ui/form/VatRegistration/CompanyFormVatRegistrationNumber';

export const VatRegistrationPopover = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslate('dialogs.vatForm');

  const { data: countryVatRates } = useCountryVatRates();

  const {
    company: { data: company },
    updateCompany,
  } = useProfile();

  const formValidationSchema = useVatFormValidation();
  const formMethods = useForm<VatFormData>({
    resolver: yupResolver(formValidationSchema),
    defaultValues: {
      vatCompliance: {
        isVatRegistered: true,
        vatCompliance: undefined,
      } as unknown as VatFormData['vatCompliance'],
    } as VatFormData,
  });

  const { handleSubmit, reset } = formMethods;

  const revalidator = useRevalidator();

  const onSubmit = async (formData: VatFormData) => {
    toast.loading(t('dialogs.vatForm.toast.saving'), { toastId: 'loading' });
    const updatedCompanyData = {
      ...company,
      vatCompliance: getVatCompliance(formData, countryVatRates, company),
    } as Partial<CompanyProfile>;

    try {
      await updateCompany.mutateAsync(updatedCompanyData);
      // Re-run loader to get updated company profile/VAT registrations
      revalidator.revalidate();
      toast.dismiss('loading');
      toast.success(t('toast.saving.success'));
      reset();
    } catch {
      toast.error(t('toast.saving.error'));
    }
  };

  return (
    <Popover.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <Popover.Trigger>
        <Button
          variant="secondary"
          size="medium"
          style={{ height: 'unset', lineHeight: 'unset' }}
        >
          {t(
            'inputPlaceholder',
            'experience.create.price.tickets.options.true.vat'
          )}
        </Button>
      </Popover.Trigger>
      <StyledPopoverContent sideOffset={5} align="end">
        <FormProvider {...formMethods}>
          <Stack
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ gap: 2, padding: 2 }}
            maxWidth={400}
          >
            <Stack>
              <Text variant="medium">{t('title')}</Text>
              <Text fontSize="small">{t('description')}</Text>
            </Stack>
            <CompanyFormVatRegistrationCountry />
            <CompanyFormVatRegistrationNumber />
            <CompanyFormVatRegistrationCategory />
            <Button size="medium" variant="primary" type="submit">
              {t('create', 'buttons')}
            </Button>
          </Stack>
        </FormProvider>
      </StyledPopoverContent>
    </Popover.Root>
  );
};
