import {
  ReducedVat,
  VatCompliance,
  VatRegistration,
} from '@understory-io/utils-types';

import { TranslateFunction } from '../../../../../Hooks/useTranslate';
import { filteredVatRates } from '../../../../../Sections/SettingsSections/Company/domain/helpers';

const getVatRates = (rates?: VatCompliance['vatRegistrations'][0]['rates']) => {
  if (!rates) return [];

  const result = [] as {
    [key: string]: number | ReducedVat;
  }[];
  if (rates.standard) {
    result.push({ standard: rates.standard });
  }
  if (rates.superReduced) {
    result.push({ superReduced: rates.superReduced });
  }
  if (rates.reduced) {
    result.push(...rates.reduced);
  }

  return result.filter(
    (rate) => !filteredVatRates.includes(Object.keys(rate)[0])
  );
};

export const getVatOptions = (
  vatRegistrations: VatRegistration[],
  t: TranslateFunction
) => {
  if (vatRegistrations.length === 0) return [];

  return getVatRates(vatRegistrations[0]?.rates).map((el) => {
    const [category, rate] = Object.entries(el)[0];
    const isExempt = typeof rate === 'object' && rate.exempt;
    const rateLabel = isExempt
      ? t('utils.generic.vatExempt')
      : `${((typeof rate === 'number' ? rate : rate.rate) * 100).toFixed(1)}%`;

    return {
      key: category,
      label: `${rateLabel} (${t(category, 'vat.categories')})`,
    };
  });
};
