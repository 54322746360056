import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog } from '@mui/material';
import { CompanyProfile } from '@understory-io/utils-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useCountryVatRates } from '../../../Hooks/useCountryVatRates';
import { useProfile } from '../../../Hooks/useProfile';
import { getVatCompliance } from '../../../Sections/SettingsSections/Company/domain/helpers';
import {
  useVatFormValidation,
  VatFormData,
} from '../domain/use-vat-form-validation';
import { VatForm } from './form/VatForm';

export const VatFormDialog = NiceModal.create(() => {
  const { t } = useTranslation();
  const modal = useModal();

  const { data: countryVatRates } = useCountryVatRates();

  const {
    company: { data: company },
    updateCompany,
  } = useProfile();

  const formValidationSchema = useVatFormValidation();
  const form = useForm<VatFormData>({
    resolver: yupResolver(formValidationSchema),
    defaultValues: {
      vatCompliance: {
        isVatRegistered: true,
        vatCompliance: undefined,
      } as unknown as VatFormData['vatCompliance'],
    } as VatFormData,
  });

  const handleClose = () => {
    form.reset();
    modal.reject('VatFormDialog');
    modal.hide();
  };

  const onSubmit = async (formData: VatFormData) => {
    toast.loading(t('dialogs.vatForm.toast.saving'), { toastId: 'loading' });
    const updatedCompanyData = {
      ...company,
      vatCompliance: getVatCompliance(formData, countryVatRates, company),
    } as Partial<CompanyProfile>;

    try {
      await updateCompany.mutateAsync(updatedCompanyData);
      toast.dismiss('loading');
      toast.success(t('toast.saving.success'));
      modal.resolve();
      form.reset();
      modal.hide();
    } catch {
      toast.error(t('toast.saving.error'));
    }
  };

  return (
    <FormProvider {...form}>
      <Dialog open={modal.visible} onClose={handleClose}>
        <VatForm onSubmit={form.handleSubmit(onSubmit)} onClose={handleClose} />
      </Dialog>
    </FormProvider>
  );
});
