import {
  AutocompleteRenderInputParams,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { useTranslate } from '../../../../../../Hooks/useTranslate';

export const AutoCompleteInput = ({
  onChange,
  params,
  error,
  helperText,
}: Omit<TextFieldProps, 'onChange'> & {
  onChange: (value: string) => void;
  params: AutocompleteRenderInputParams;
}) => {
  const { t } = useTranslate('experience.edit.dialog.tags.autoComplete');
  return (
    <TextField
      {...params}
      onChange={(e) => onChange(e.target.value)}
      placeholder={t('placeholder')}
      error={error}
      helperText={helperText}
    />
  );
};
