import { NavigateOptions, useNavigate, useSearchParams } from 'react-router';

export const useNavigateWithParams = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClick = (path: string, options?: NavigateOptions) => {
    navigate(
      `${path}${searchParams.toString() ? '?' + searchParams.toString() : ''}`,
      options
    );
  };

  return handleClick;
};
