import { List, ListItem, Stack, TextField } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../Hooks/useTranslate';
import { trackQuickSetupNameStepNameFieldOpened } from '../../../tracking/quick-setup/step-events';
import { OnboardingFormInputs } from '../onboarding-flow-form';
import { OnboardingStepLayout } from './step-layout';

export const NameStep = () => {
  const { t } = useTranslate('onboarding.dialog.step.name.input');

  const {
    register,
    formState: {
      errors: { experienceName: experienceNameError },
    },
  } = useFormContext<OnboardingFormInputs>();

  return (
    <OnboardingStepLayout
      showPreview
      customDescription={<CustomNameStepDescription />}
    >
      <TextField
        {...register('experienceName', {
          required: { value: true, message: t('requiredMessage') },
        })}
        label={t('label')}
        placeholder={t('placeholder')}
        InputLabelProps={{ shrink: true }}
        error={!!experienceNameError}
        helperText={experienceNameError?.message}
        onFocus={trackQuickSetupNameStepNameFieldOpened}
        required
      />
    </OnboardingStepLayout>
  );
};

const CustomNameStepDescription = () => {
  const { t } = useTranslate(`onboarding.dialog.step.name.description`);

  return (
    <Stack>
      <Text>{t('text')}</Text>
      <List sx={{ listStyleType: 'disc', pl: 3 }}>
        {Array(3)
          .fill(undefined)
          .map((_, i) => (
            <ListItem key={i} sx={{ display: 'list-item', pl: 0, pt: 0 }}>
              <Text variant="medium">{t(`list.${i}.title`)}</Text>{' '}
              <Text>{t(`list.${i}.description`)}</Text>
            </ListItem>
          ))}
      </List>
    </Stack>
  );
};
