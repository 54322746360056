import { createSvgIcon, Divider, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { TooltipProps } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

import { useTranslate } from '../../../../../../Hooks/useTranslate';

type DashboardGraphsLoaderCardSeriesGraphTooltipProps = TooltipProps<
  number,
  string
> & {
  isPercentage: boolean;
  seriesName: string;
  active?: boolean;
  payload?: Payload<number, string>[];
};

export const DashboardGraphsLoaderCardSeriesGraphTooltip = ({
  active,
  payload,
  isPercentage,
  seriesName,
}: DashboardGraphsLoaderCardSeriesGraphTooltipProps) => {
  const { t } = useTranslate('dashboard');

  if (!active || !payload || !payload.length) return null;

  const currentValue = payload[0].value;
  const dataPoint = payload[0].payload;
  const dataIndex = dataPoint.index;
  const allData = dataPoint.allData;

  const previousValue =
    dataIndex > 0 ? allData[dataIndex - 1].value : allData[dataIndex].value;
  const percentageChange = calculatePercentageChange(
    currentValue,
    previousValue
  );
  const isPositive = Number(percentageChange) >= 0;

  const showPercentage = percentageChange && percentageChange !== '-';

  return (
    <Stack
      sx={{
        border: 'none',
        borderRadius: 2,
        padding: 2,
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
        minWidth: '200px',
        gap: 1,
        backgroundColor: 'white',
      }}
      divider={<Divider />}
    >
      <Stack gap={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="16px"
        >
          <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
            {seriesName}
          </Text>

          {showPercentage ? (
            <Stack
              direction="row"
              sx={{
                fontSize: '12px',
                borderRadius: 0.5,
                color: `${isPositive ? lightTheme.palette.success.s500 : lightTheme.palette.error.e200}`,
                fontWeight: 500,
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              {isPositive ? (
                <UpArrowSvg style={{ width: '16px', height: '16px' }} />
              ) : (
                <DownArrowSvg style={{ width: '16px', height: '16px' }} />
              )}
              {isPositive ? '+' : ''}
              {percentageChange}%
            </Stack>
          ) : (
            ''
          )}
        </Stack>
        <Text fontSize="xlarge" variant="medium">
          {dataPoint.value.toLocaleString()}
          {isPercentage ? '%' : ''}
        </Text>
      </Stack>
      <Stack gap={1}>
        {allData[dataIndex].rest.map(
          ({ key, value }: { key: string; value: number }) => (
            <Stack key={key} direction="row" justifyContent="space-between">
              <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
                {t(`statistics.cards.${key}`)}
              </Text>
              <Text fontSize="xsmall" variant="medium">
                {value.toLocaleString()}
              </Text>
            </Stack>
          )
        )}
      </Stack>
    </Stack>
  );
};

const calculatePercentageChange = (current?: number, previous?: number) => {
  if (!current || !previous) return null;
  if (previous === 0) return '-';
  return (((current - previous) / previous) * 100).toFixed(1);
};

const UpArrowSvg = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8.00004 1.33333C4.31814 1.33333 1.33337 4.3181 1.33337 8C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667Z"
      fill="#00B141"
    />
    <path d="M10.0002 10V6.00003H6.00016" fill="#00B141" />
    <path
      d="M10.0002 10V6.00003M10.0002 6.00003H6.00016M10.0002 6.00003L6.00017 9.99996M14.6667 8C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8C1.33337 4.3181 4.31814 1.33333 8.00004 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'UpArrow'
);

const DownArrowSvg = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8.00004 1.33333C4.31814 1.33333 1.33337 4.3181 1.33337 8C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667Z"
      fill="#EF4040"
    />
    <path d="M10.0002 6.00003V10H6.00016" fill="#EF4040" />
    <path
      d="M10.0002 6.00003V10M10.0002 10H6.00016M10.0002 10L6.00017 5.99996M14.6667 8C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8C1.33337 4.3181 4.31814 1.33333 8.00004 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'DownArrow'
);
