import { isAfter, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import * as yup from 'yup';

export const useTimeFormValidation = (t: TFunction) =>
  useMemo(() => {
    return yup.object().shape({
      endDateTimeDisabled: yup.boolean(),
      startDateTime: yup
        .string()
        .required(t('eventUpsert.flow.time.form.errors.required'))
        .test(
          'is-valid-date-format',
          t('eventUpsert.flow.time.form.errors.dateFormat'),
          (value) => {
            return value ? !isNaN(Date.parse(value)) : false;
          }
        )
        .test(
          'is-after-current-time',
          t('eventUpsert.flow.time.form.errors.startAfterCurrentTime'),
          (value) => {
            const currentDate = new Date();
            const parsedDate = value ? parseISO(value) : null;
            return parsedDate ? isAfter(parsedDate, currentDate) : false;
          }
        ),
      endDateTime: yup.string().when('endDateTimeDisabled', {
        is: (val: boolean) => val === false,
        then: () =>
          yup
            .string()
            .required(t('eventUpsert.flow.time.form.errors.required'))
            .test(
              'is-valid-date-format',
              t('eventUpsert.flow.time.form.errors.dateFormat'),
              (value) => {
                return value ? !isNaN(Date.parse(value)) : false;
              }
            )
            .test(
              'is-after-start-date-time',
              t('eventUpsert.flow.time.form.errors.endBeforeStartDate'),
              function (value) {
                const parsedDate = value ? parseISO(value) : null;
                if (!parsedDate) return false;

                const startDateTime = this.parent.startDateTime;
                const parsedStartDate = startDateTime
                  ? parseISO(startDateTime)
                  : null;

                if (!parsedStartDate) return true;

                return isAfter(parsedDate, parsedStartDate);
              }
            ),
        otherwise: () => yup.string().optional(),
      }),
    });
  }, [t]);

export type EventUpsertTimeFormTypes = yup.InferType<
  ReturnType<typeof useTimeFormValidation>
>;
