import { QueryClient } from '@tanstack/react-query';

let queryClientInstance: QueryClient | null = null;

export const getQueryClient = () => {
  if (!queryClientInstance) {
    queryClientInstance = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 1000 * 60 * 5, // Default stale time of 5 minutes
          retry: false, // We rarely have timeout/sloppy connections
        },
      },
    });
  }
  return queryClientInstance;
};

export const queryClient = getQueryClient();
