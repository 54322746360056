import { ExpandMoreRounded } from '@mui/icons-material';
import { Card, Stack, styled, Table } from '@mui/material';
import { Button, Text } from '@understory-io/pixel';
import { ReactNode, useState } from 'react';

export const Accordion = ({
  title,
  children,
}: {
  title: ReactNode | string;
  children: ReactNode;
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Stack gap={2}>
      <StyledAccordionButton
        size="large"
        variant="text"
        rightIcon={
          <ExpandMoreRounded
            fontSize="medium"
            style={{
              transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
              transition: 'all 0.3s ease-in-out',
            }}
          />
        }
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <Text fontSize="medium" variant="medium">
          {title}
        </Text>
      </StyledAccordionButton>
      {expanded && children}
    </Stack>
  );
};

const StyledAccordionButton = styled(Button)({
  cursor: 'pointer',
  padding: 16,
  marginInline: -16,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 16,
  flexDirection: 'row',
  backgroundColor: 'unset',
});

export const StyledInfoBox = styled(Card)({
  width: '100%',
  padding: 0,
  borderRadius: 8,
});

export const StyledTable = styled(Table)(() => ({
  border: 'none',
  borderCollapse: 'collapse',
  marginTop: '-12px',
  '& th, td': {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    fontSize: 'inherit',
    color: '#000',
    border: 'none',
    padding: 0,
    paddingTop: '12px',
    verticalAlign: 'top',
  },
  '& th:first-of-type': {
    width: '35%',
  },
}));
