import styled from '@emotion/styled';
import Logout from '@mui/icons-material/Logout';
import { Avatar, Skeleton, Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useQueryClient } from '@tanstack/react-query';
import { lightTheme, Text } from '@understory-io/pixel';
import { useNavigate } from 'react-router';

import { useProfile } from '../../Hooks/useProfile';
import { useAuthStore } from '../../Store/useAuthStore';

export const AccountPreview = () => {
  const navigate = useNavigate();

  const { clearAuth } = useAuthStore();
  const {
    company: { data: company, isLoading: companyIsLoading },
    me: { data: me, isLoading: meIsLoading },
  } = useProfile();

  const queryClient = useQueryClient();

  const label = me?.name ? me.name.split(' ')[0] : me?.email ?? '';
  const isLoading = meIsLoading || companyIsLoading;
  const src = me?.pictures?.profile?.url ?? company?.pictures?.logo?.url;

  const handleLogOut = async () => {
    await queryClient.removeQueries();
    clearAuth();
    navigate('/login', { replace: true });
  };

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={1}
    >
      <Stack direction={'row'} alignItems={'center'} gap={0.8}>
        <Avatar
          sx={{
            marginRight: 1,
            width: '32px',
            height: '32px',
            fontSize: '1.2em',
            fontWeight: '500',
          }}
          src={src}
        >
          {isLoading ? 'AA' : getInitials(label)}
        </Avatar>
        <Stack
          sx={{
            '& > span': { lineHeight: 'unset' },
          }}
        >
          <StyledText variant={'medium'}>
            {isLoading ? <Skeleton width={120} /> : label}
          </StyledText>
          <Text
            fontSize={'xsmall'}
            style={{
              color: lightTheme.palette.neutral.n400,
            }}
          >
            {isLoading ? <Skeleton width={100} /> : company?.name}
          </Text>
        </Stack>
      </Stack>
      <MenuItem sx={{ p: 1, borderRadius: 1 }} onClick={handleLogOut}>
        <Logout fontSize="small" />
      </MenuItem>
    </Stack>
  );
};

export const getInitials = (label?: string) => {
  if (!label) return '';
  const parts = label.split(' ').filter((e, i) => i < 2);
  return parts.map((el) => el.slice(0, 1).toUpperCase());
};

const StyledText = styled(Text)({
  maxWidth: '140px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
