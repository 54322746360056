import { ArrowOutwardRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { DashboardRequestReport } from './dashboard-request-report';
import { useDashboardHeaderActions } from './use-dashboard-header-actions';

type DashboardHeaderActionsProps = {
  isNavDrawer?: boolean;
};

export const DashboardHeaderActions = ({
  isNavDrawer,
}: DashboardHeaderActionsProps) => {
  const { t } = useTranslate('dashboard');
  const { isSm, isLg } = useResponsive();

  const {
    stripeCompleted,
    experienceCompleted,
    handleStripeClick,
    handlePageClick,
  } = useDashboardHeaderActions();

  if (isSm && !isNavDrawer) {
    return <DashboardRequestReport />;
  }

  return (
    <Stack direction={isSm ? 'column-reverse' : 'row'} gap={isSm ? 1 : 2}>
      {stripeCompleted && (
        <Button
          rightIcon={<ArrowOutwardRounded />}
          variant="secondary"
          size="medium"
          onClick={handleStripeClick}
        >
          {t('buttons.goToStripe')}
        </Button>
      )}
      {(experienceCompleted || !isLg) && (
        <Button
          rightIcon={<ArrowOutwardRounded />}
          variant="primary"
          size="medium"
          onClick={handlePageClick}
        >
          {t('buttons.goToHoldbar')}
        </Button>
      )}
    </Stack>
  );
};
