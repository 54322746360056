import styled from '@emotion/styled';
import { CloseRounded } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { renderDate, renderDateTime } from '@understory-io/utils-date';

import { states } from '../../../../Components/CustomCard/CustomCard';
import { ChipState } from '../../../../Modals/search/SearchDialog';
import { useEventPopover } from './event-popover-context';

export const PopoverHeader = () => {
  const { selectedEvent, handleClosePopover } = useEventPopover();

  if (!selectedEvent) return null;

  const { title, startDateTime, endDateTime, status } = selectedEvent;

  return (
    <Stack
      padding={4}
      paddingLeft={6}
      direction="row"
      justifyContent="space-between"
      gap={2}
    >
      <Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <Text variant="medium" fontSize="xlarge">
            {title}
          </Text>
          <Chip
            color={(states[status][1] as ChipState) ?? 'default'}
            sx={{
              height: 16,
              width: 16,
              borderRadius: '100%',
            }}
          />
        </Stack>
        <Stack direction="row" gap={1}>
          <Text>{renderDate(startDateTime)}</Text>
          <Text color={lightTheme.palette.neutral.n300}>•</Text>
          <Text>{renderDateTime(startDateTime, endDateTime)}</Text>
        </Stack>
      </Stack>
      <StyledCloseButton
        variant="text"
        size="medium"
        onClick={handleClosePopover}
      >
        <CloseRounded sx={{ display: 'block' }} />
      </StyledCloseButton>
    </Stack>
  );
};

const StyledCloseButton = styled(Button)({
  padding: 0,
  height: 32,
  width: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
