import { Card, Divider, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { useCallback, useMemo, useState } from 'react';

import { CategoryTotals } from '../../../../../Api/Analytics';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { trackOverviewMetricTileClicked } from '../../../../../tracking/overview/interactions';
import { useDashboardPageContext } from '../../../use-dashboard-page-context';
import { DashboardGraphsLoaderCardMetrics } from './dashboard-graphs-loader-card-metrics/dashboard-graphs-loader-card-metrics';
import { DashboardGraphsLoaderCardSeries } from './dashboard-graphs-loader-card-series/dashboard-graphs-loader-card-series';

export const CURRENCY_METRIC_KEYS = [
  'totalSales',
  'discounts',
  'refunds',
  'netRevenue',
  'ordersAvgValue',
  'giftCardsValue',
  'giftCardsAvgValue',
];

export const PERCENTAGE_METRIC_KEYS = ['utilityRate'];

type DashboardGraphsLoaderCardProps = {
  categoryKey: keyof CategoryTotals;
  metrics: {
    [key: string]: number;
  };
};

export const DashboardGraphsLoaderCard = ({
  categoryKey,
  metrics,
}: DashboardGraphsLoaderCardProps) => {
  const { t } = useTranslate('dashboard');

  const { graphSeries } = useDashboardPageContext();

  const [selectedMetric, setSelectedMetric] = useState<string>(
    Object.keys(metrics)[0]
  );

  const getMetric = useCallback(
    (key: string) => {
      return {
        key,
        value: metrics[key],
      };
    },
    [metrics]
  );

  const metric = useMemo(
    () => getMetric(selectedMetric),
    [selectedMetric, getMetric]
  );

  const graphSeriesToRender = useMemo(() => {
    if (!graphSeries || !selectedMetric) {
      return { keys: [], values: [] };
    }

    const dates = Object.keys(graphSeries);
    const values = dates.map((date) => {
      const items = graphSeries[date]?.[categoryKey] ?? [];

      const value =
        items.find((item) => item.key === selectedMetric)?.value ?? 0;
      const rest = items.filter((item) => item.key !== selectedMetric);

      return { value, rest };
    });

    return {
      keys: dates,
      values,
    };
  }, [graphSeries, categoryKey, selectedMetric]);

  const handleSelectMetric = (key: string) => {
    setSelectedMetric(key);
    trackOverviewMetricTileClicked(key);
  };

  return (
    <Stack
      component={Card}
      sx={{ p: 3, height: '100%', justifyContent: 'space-between' }}
      gap={2}
    >
      <Stack
        gap={2}
        divider={<Divider sx={{ color: lightTheme.palette.neutral.n100 }} />}
      >
        <Text variant="medium" fontSize="xlarge" style={{ userSelect: 'none' }}>
          {t(`metricCategory.${categoryKey}`)}
        </Text>
        <DashboardGraphsLoaderCardMetrics
          selectedMetric={metric}
          metrics={metrics}
          handleSelectMetric={handleSelectMetric}
        />
      </Stack>
      <DashboardGraphsLoaderCardSeries
        selectedMetric={metric}
        graphSeriesToRender={graphSeriesToRender}
      />
    </Stack>
  );
};
