import { InsertPhotoOutlined, UploadFileOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';
import { MediaItem } from '@understory-io/utils-types';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { deleteUpload } from '../../../Api';
import { getURLFromMediaItem } from '../../../Components/DraggableGallery/Media';
import { useTranslate } from '../../../Hooks/useTranslate';
import {
  trackQuickSetupMediaStepMediaItemDeleted,
  trackQuickSetupMediaStepUploadFilesClicked,
} from '../../../tracking/quick-setup/step-events';
import { LocalMediaItem } from '../preview-experience/media-preview';
import { Media } from './media';
import { MediaPreview } from './media-preview';
import { useUploader } from './use-uploader';

export const MediaUpload = ({
  id,
  uploadedMedia,
  onChange,
}: {
  id: string;
  uploadedMedia: MediaItem[];
  onChange: (media: MediaItem[]) => void;
}) => {
  const handleUplaod = useCallback(
    (newMedia: MediaItem[]) => {
      onChange([...uploadedMedia, ...newMedia]);
    },
    [onChange, uploadedMedia]
  );

  const handleDelete = (mediaItem: MediaItem) => {
    const filteredMedia = uploadedMedia.filter(
      (media) => getURLFromMediaItem(media) !== getURLFromMediaItem(mediaItem)
    );

    onChange(filteredMedia);

    try {
      deleteUpload(mediaItem);
      trackQuickSetupMediaStepMediaItemDeleted();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dropzone
      id={id}
      onUploaded={handleUplaod}
      uploadedMedia={uploadedMedia}
      onDelete={handleDelete}
    />
  );
};

const Dropzone = ({
  id,
  onUploaded,
  uploadedMedia,
  onDelete,
}: {
  id: string;
  onUploaded: (files: MediaItem[]) => void;
  uploadedMedia: MediaItem[];
  onDelete: (mediaItem: MediaItem) => void;
}) => {
  const { t } = useTranslate('onboarding.dialog.step.media.upload');

  const { onDrop, filestoBeUploaded } = useUploader({
    id,
    fileType: 'cover',
    modelType: 'experience',
    onUploaded,
  });

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 40,
    useFsAccessApi: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.webp', '.gif'],
      // TODO: HB-1785 - accept video files
      // 'video/*': ['.mp4', '.webm', '.mov'],
    },
    noClick: true,
  });

  const onUploadClick = () => {
    open();
    trackQuickSetupMediaStepUploadFilesClicked();
  };

  const hasFiles =
    Boolean(uploadedMedia?.length) || Boolean(filestoBeUploaded.length);

  // TODO: HB-1785 . remove string split
  const acceptFilesLabel = t('acceptedFiles', 'experience.create.media').split(
    ', mp4'
  )[0];

  return (
    <Stack gap={2}>
      <Stack
        {...getRootProps()}
        gap={2}
        sx={{
          transition: 'background 0.1s ease-in-out, border 0.1s ease-in-out',
          background: isDragActive
            ? lightTheme.palette.action.a100
            : `${lightTheme.palette.action.a100}25`,
          border: isDragActive
            ? `2px dashed ${lightTheme.palette.action.a200}`
            : `2px dashed ${lightTheme.palette.neutral.n100}`,
          borderRadius: 1,
        }}
        padding={1}
      >
        <input {...getInputProps()} />

        {(Boolean(uploadedMedia.length) || Boolean(filestoBeUploaded)) && (
          <Stack
            direction="row"
            gap={2}
            flexWrap="nowrap"
            overflow="auto"
            maxWidth="100%"
          >
            {uploadedMedia?.map((mediaItem: LocalMediaItem, index) => (
              <Media key={index} mediaItem={mediaItem} handleClear={onDelete}>
                <MediaPreview mediaItem={mediaItem} />
              </Media>
            ))}
            {filestoBeUploaded.map(({ tempUrl, progress, type }) => (
              <TempMedia
                key={tempUrl}
                url={tempUrl}
                progress={progress}
                type={type}
              />
            ))}
          </Stack>
        )}
        {!uploadedMedia?.length && !filestoBeUploaded.length && (
          <Stack alignItems="center" gap={1} padding={6}>
            <Text>{t('dropzoneLabel')}</Text>
            <Button
              variant="secondary"
              size="medium"
              type="button"
              onClick={onUploadClick}
              leftIcon={<UploadFileOutlined />}
            >
              {t('buttonLabel')}
            </Button>
            <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
              {acceptFilesLabel}
            </Text>
          </Stack>
        )}
      </Stack>
      {hasFiles && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Button
            variant="secondary"
            size="medium"
            type="button"
            onClick={onUploadClick}
            leftIcon={<UploadFileOutlined />}
            style={{ alignSelf: 'flex-start' }}
          >
            {t('buttonLabel')}
          </Button>
          <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
            {acceptFilesLabel}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

const TempMedia = ({
  url,
  progress,
  type,
}: {
  url: string;
  progress: number;
  type: 'image' | 'video';
}) => {
  return (
    <Stack position="relative" justifyContent="center" alignItems="center">
      {type === 'video' ? (
        <Stack
          height={'130px'}
          width="130px"
          justifyContent="center"
          alignItems="center"
          borderRadius={1}
          color={lightTheme.palette.neutral.n300}
          sx={{
            border: `1px solid ${lightTheme.palette.neutral.n100}`,
            background: '#F4F4F9',
          }}
        >
          <InsertPhotoOutlined color="inherit" />
        </Stack>
      ) : (
        <Box
          component="img"
          src={url}
          sx={{
            opacity: progress === 100 ? 'unset' : 0.5,
            display: 'block',
            width: '130px',
            height: '130px',
            borderRadius: '12px',
            aspectRatio: '1 / 1',
            objectFit: 'cover',
          }}
        />
      )}
      <Progress progress={progress} />
    </Stack>
  );
};

const PROGRESS_RADIUS = 20;
const PI = Math.PI;

export const Progress = ({ progress }: { progress: number }) => {
  const circumference = 2 * PI * PROGRESS_RADIUS;
  const offset = circumference * ((100 - progress) / 100);
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      style={{
        transform: 'rotate(-90deg)',
        position: 'absolute',
      }}
    >
      <circle
        r={PROGRESS_RADIUS}
        cx="25"
        cy="25"
        fill="transparent"
        stroke={lightTheme.palette.neutral.n100}
        strokeWidth="4px"
      ></circle>
      <circle
        style={{ transition: 'all 0.1s ease-in-out' }}
        r={PROGRESS_RADIUS}
        cx="25"
        cy="25"
        fill="transparent"
        stroke={lightTheme.palette.action.a200}
        strokeLinecap="round"
        strokeWidth="4px"
        strokeDasharray={`${circumference}px`}
        strokeDashoffset={`${offset}px`}
      ></circle>
    </svg>
  );
};
