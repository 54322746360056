import { TFunction } from 'i18next';
import randomBytes from 'randombytes';
import { ComponentPropsWithoutRef, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import { TDiscount, useDiscount } from '../Hooks/useDiscount';
import { useProfile } from '../Hooks/useProfile';
import { useTranslate } from '../Hooks/useTranslate';
import { trackGrowthDiscountCodeCreationCompleted } from '../tracking/growth/growth-events';
import { isEmptyObject } from '../Utils/helpers';
import { discountSchema, discountSyiSteps } from './SyiPage/config';
import { SyiPage, walkObjectRecursively } from './SyiPage/SyiPage';

const getTitle = (t: TFunction, id: string | undefined) =>
  id === 'create'
    ? `${t('new', 'utils.generic')} ${t('discount', 'utils.generic')}`
    : `${t('edit', 'utils.generic')} ${t('discount', 'utils.generic')}`;

const preparePayload = (payload: object) => {
  const { overrides, ...sanitized } = walkObjectRecursively(
    walkObjectRecursively(
      payload,
      (key, value) =>
        value !== null && value !== undefined && !key.startsWith('_')
    ),
    (_, value) => !isEmptyObject(value)
  ) as { overrides?: { id?: string }[] };
  return {
    ...sanitized,
    ...(overrides && {
      overrides: overrides?.filter((el) => Boolean(el?.id)) ?? [],
    }),
  };
};

export const DiscountPage = ({
  children,
}: ComponentPropsWithoutRef<'main'>) => {
  const { t } = useTranslate('utils.generic');

  const { id } = useParams<{ id: string }>();

  const location = useLocation();
  const navigate = useNavigate();

  const { returnUrl } = useMemo(() => {
    const { returnUrl } = (location?.state as any) ?? {};
    return {
      returnUrl,
    };
  }, [location.state]);

  const { defaultCurrency } = useProfile();

  const _id = useMemo(
    () => (id !== 'create' && id ? id : randomBytes(16).toString('hex')),
    [id]
  );

  const defaultValues: { [key: string]: any } = {
    currency: defaultCurrency,
    _applyForAll: true,
    _limitTimesRedeemed: false,
    _shouldExpire: false,
    scope: 'total',
    percentage: true,
  };

  const { discount, updateDiscount, deleteDiscount } = useDiscount(_id);

  const _steps = useMemo(() => {
    return discountSyiSteps.map((el) => {
      return {
        ...el,
        label: t(el.key, 'discounts.create.progress'),
      };
    });
  }, [t]);

  const onSubmit = async (data: object) => {
    const discount = preparePayload(data) as TDiscount;
    await updateDiscount.mutateAsync(discount);
    trackGrowthDiscountCodeCreationCompleted(discount);
    return navigate(`/growth/discounts`, { replace: true });
  };

  return (
    <SyiPage
      modelType={'discount'}
      steps={_steps}
      title={getTitle(t, id)}
      deleteItem={deleteDiscount}
      updateItem={updateDiscount}
      item={discount}
      id={_id}
      schema={discountSchema}
      returnUrl={returnUrl ?? '/growth/discounts'}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      isCreateFlow={id === 'create'}
    >
      {children}
    </SyiPage>
  );
};
