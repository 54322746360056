import { Stack } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

export const MediaUpload = () => {
  return (
    <Stack
      sx={{
        backgroundColor: lightTheme.palette.contrast.surface1,
        height: 184,
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: lightTheme.palette.neutral.n100,
        borderRadius: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Media upload
    </Stack>
  );
};
