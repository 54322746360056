import { ChevronRightRounded } from '@mui/icons-material';
import { Chip, Stack, styled } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useNavigate } from 'react-router';

import { states } from '../../../../Components/CustomCard/CustomCard';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { TBooking } from '../../../../Hooks/useBookings';
import { Variant } from '../../../../Hooks/useExperience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { renderGuestTypes } from '../../../../Utils/eventHelpers';
import {
  EventDetailsListColumns,
  EventDetailsListColumnWidths,
} from './shared';

type BookingListItemProps = {
  booking: TBooking;
  variants?: Variant[];
};

export const BookingListItem = ({
  booking,
  variants,
}: BookingListItemProps) => {
  const { t, i18n } = useTranslate('utils.tables.header');
  const { isMd } = useResponsive();

  const navigate = useNavigate();

  const handleClick = (bookingId: string) => () => {
    navigate(`/booking/${bookingId}`);
  };

  // Guest count is the 4th element
  const [name, , , count] = EventDetailsListColumns;

  if (isMd) {
    return (
      <StyledListItem
        key={booking.id}
        tabIndex={0}
        py={2}
        px={2}
        mx={-2}
        onClick={handleClick(booking.id)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick(booking.id)();
          }
        }}
      >
        <Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text
              fontSize="small"
              variant="medium"
              style={{ textTransform: 'capitalize' }}
            >
              {name.value?.(booking)}
            </Text>
            <Chip
              color={(states?.[booking.status]?.[1] as never) ?? 'default'}
              sx={{
                height: 10,
                width: 10,
                borderRadius: 12,
              }}
            />
          </Stack>

          <Text fontSize="small">
            {`${count.value?.(booking)} ${
              Number(count.value?.(booking)) > 1 ? t('guests') : t('guest')
            }`}{' '}
            -{' '}
            {renderGuestTypes(booking, 'variant', i18n.language, variants).join(
              ', '
            )}
          </Text>
        </Stack>
        <ChevronRightRounded />
      </StyledListItem>
    );
  }

  return (
    <StyledListItem
      key={booking.id}
      tabIndex={0}
      py={1.5}
      px={4}
      onClick={handleClick(booking.id)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClick(booking.id)();
        }
      }}
    >
      {EventDetailsListColumns.map((el) => {
        return (
          <StyledListItemText
            key={el.key}
            fontSize="small"
            style={{
              width:
                EventDetailsListColumnWidths[
                  el.key as keyof typeof EventDetailsListColumnWidths
                ],
              textAlign: el.key === 'count' ? 'center' : 'start',
            }}
          >
            {el.value?.(booking, i18n.language, variants)}
          </StyledListItemText>
        );
      })}
    </StyledListItem>
  );
};

const StyledListItem = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledListItemText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ::first-letter {
    text-transform: capitalize;
  }
`;
