import { Divider, Grid, Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useMemo } from 'react';

import { EmptyScreen } from '../../../../Components/EmptyScreen/EmptyScreen';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useVoucher } from '../../../../Hooks/useVoucher';
import { useGiftCardsList } from './domain/use_giftcards_list';
import {
  GiftCardsListItemDesktop,
  GiftCardsListItemMobile,
} from './GiftCardsListItems';
import {
  GiftCardsSkeletonDesktop,
  GiftCardsSkeletonMobile,
} from './GiftCardsListSkeletons';

export type TVoucher = {
  id: string;
  name: string;
  totalAmount: number;
  amountLeft: number;
  expiryDate: string;
  status: string;
};

export const GiftCardsList = ({
  searchState,
}: {
  searchState: {
    query: string;
    status: string;
  };
}) => {
  const { t } = useTranslate('utils.tables.header');
  const { isMd } = useResponsive();

  const {
    boughtVouchers: { data: vouchers, isLoading },
  } = useVoucher();

  const { listColumns } = useGiftCardsList();

  const giftCardsInView = useMemo(() => {
    return vouchers?.filter((el) => {
      const { query, status } = searchState;

      if (query) {
        const nameMatch = el?.customer?.name
          ?.toLowerCase()
          .includes(query?.toLowerCase());
        const emailMatch = el?.customer?.email
          ?.toLowerCase()
          .includes(query?.toLowerCase());
        const codeMatch = el?.code
          ?.toLowerCase()
          .includes(query?.toLowerCase());
        return nameMatch || emailMatch || codeMatch;
      }

      if (status !== 'all') {
        return el?.status === status;
      }

      return true;
    });
  }, [vouchers, searchState]);

  if (giftCardsInView?.length === 0 && !isLoading) {
    return (
      <EmptyScreen
        mt={6}
        imageSrc={'/empty-state-nature.svg'}
        title={''}
        description={t('emptyState.description', 'giftcards')}
      />
    );
  }

  return (
    <Stack>
      {!isMd && (
        <Grid container p={'12px'}>
          {listColumns.map((el) => (
            <Grid key={el.key} item {...el.props}>
              <Text variant="medium" fontSize="small">
                {t(el.key)}
              </Text>
            </Grid>
          ))}
        </Grid>
      )}
      {!isMd && (
        <Stack gap={1}>
          {!isLoading ? (
            giftCardsInView?.map((giftCard) => (
              <GiftCardsListItemDesktop key={giftCard.id} giftCard={giftCard} />
            ))
          ) : (
            <GiftCardsSkeletonDesktop />
          )}
        </Stack>
      )}
      {isMd && (
        <Stack divider={<Divider />}>
          {!isLoading ? (
            giftCardsInView?.map((giftCard) => (
              <GiftCardsListItemMobile key={giftCard.id} giftCard={giftCard} />
            ))
          ) : (
            <GiftCardsSkeletonMobile />
          )}
        </Stack>
      )}
    </Stack>
  );
};
