import { useNavigate } from 'react-router';

import { useExperience } from '../../../../Hooks/useExperience';
import { EventSourceType } from '../../../../tracking/events/eventProperties';
import { trackEventEditStarted } from '../../../../tracking/events/trackEventEditStarted';
import { TEventWithTitle } from '../../../../Utils/eventHelpers';
import { isCompleted } from './shared';

export const useEditEventAction = (
  source: EventSourceType,
  event?: TEventWithTitle
) => {
  const navigate = useNavigate();

  const {
    experience: { data: experience },
  } = useExperience(event?.experienceId);

  const goToEditEvent = () => {
    trackEventEditStarted(event!, experience, source);

    navigate(`/event/${event?.id}/edit`);
  };

  const isDisabled =
    !['active', 'inactive'].includes(event?.status as string) &&
    isCompleted(event);

  return {
    goToEditEvent,
    isDisabled,
  };
};
