import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useAuth } from '../../Hooks/useAuth';
import type { TInput } from './AuthForm';
import { AuthForm } from './AuthForm';
import { AuthLayout } from './AuthLayout';

interface IForgotPasswordFormValues {
  username: string;
}

const inputs: Array<TInput<IForgotPasswordFormValues>> = [
  {
    key: 'username',
    type: 'email',
    isRequired: true,
    autoFocus: true,
    variant: 'outlined',
  },
];

export const ForgotPasswordPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { forgotPassword } = useAuth();

  const onSubmit = async (data: IForgotPasswordFormValues) => {
    const { username } = data;
    try {
      await forgotPassword(username);
      toast.success(t('auth.toast.resetPasswordRequested'));
      navigate('/login', { replace: true });
    } catch (err) {
      console.log('Unknown username');
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <AuthLayout
      title={t('auth.titles.forgotPassword')}
      onBack={handleBack}
      hideFooter={true}
      description={t('auth.descriptions.forgotPassword')}
    >
      <AuthForm
        inputs={inputs}
        buttonLabel={t('auth.buttons.requestResetPassword')}
        onSubmit={onSubmit}
      />
    </AuthLayout>
  );
};
