import { Checkbox, Stack, styled } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { EventUpsertTimeFormTypes } from './use_time_form_validation';

export const EventUpsertTimeFormEndTimeToggle = () => {
  const { t } = useTranslation();
  const { control, setValue, watch } =
    useFormContext<Pick<EventUpsertTimeFormTypes, 'endDateTimeDisabled'>>();
  const { updateEventField } = useEventUpsert();

  const endDateTimeDisabled = watch('endDateTimeDisabled');

  const toggleCheckbox = () => {
    const newValue = !endDateTimeDisabled;
    setValue('endDateTimeDisabled', newValue);
    updateEventField('endDateTimeDisabled', newValue);
  };

  return (
    <StyledStack
      direction="row"
      alignItems="center"
      gap={0.5}
      onClick={toggleCheckbox}
    >
      <Controller
        name="endDateTimeDisabled"
        control={control}
        render={({ field }) => (
          <StyledCheckbox
            {...field}
            checked={field.value}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      />
      <Text fontSize="small" style={{ cursor: 'pointer' }}>
        {t('eventUpsert.flow.time.form.endDateToggle.label')}
      </Text>
    </StyledStack>
  );
};

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

const StyledStack = styled(Stack)`
  cursor: pointer;
  width: fit-content;
`;
