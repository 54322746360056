import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  styled,
} from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { FC, useCallback } from 'react';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { FILTER_ALL_VALUES } from '../../constants';
import { SpecificFilterProps } from './Filter';
import { FilterValue } from './FilterValue';

export const AccordionFilter: FC<SpecificFilterProps> = ({
  inputId,
  checkedIds,
  label,
  onChange,
  options,
}) => {
  const { t } = useTranslate('');

  const allLabel = t('all', 'utils.tables.filters');

  const onCheckedChange = useCallback(
    (id: string, checked: boolean) => {
      if (id === FILTER_ALL_VALUES) {
        // If the user selects all, we want to toggle all options
        onChange((selected) =>
          selected.length >= options.length ? [] : options.map(({ id }) => id)
        );
        return;
      }

      onChange((selected) =>
        checked
          ? [...selected, id]
          : selected.filter((selectedId) => selectedId !== id)
      );
    },
    [onChange, options]
  );

  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{ '&:before': { height: '0px' } }}
      >
        <AccordionSummary
          aria-controls={inputId}
          id={inputId}
          expandIcon={<ExpandMoreIcon />}
          sx={{
            padding: 0,
            '.accordion-summary-content': {
              overflow: 'hidden',
            },
          }}
          classes={{ content: 'accordion-summary-content' }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={2}
            flex={1}
            marginRight={2}
            overflow="hidden"
          >
            <Text>{label}</Text>
            <Text
              color={lightTheme.palette.neutral.n300}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <FilterValue options={options} selected={checkedIds} />
            </Text>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Stack gap={1}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={
                    options.length === checkedIds.length ||
                    checkedIds.length === 0
                  }
                />
              }
              onChange={(_, checked) =>
                onCheckedChange(FILTER_ALL_VALUES, checked)
              }
              label={
                <Text fontSize="small" textTransform="capitalize">
                  {allLabel}
                </Text>
              }
            />
            {options.map(({ id, name }) => (
              <FormControlLabel
                key={id}
                control={<StyledCheckbox checked={checkedIds.includes(id)} />}
                onChange={(_, checked) => onCheckedChange(id, checked)}
                label={
                  <Text fontSize="small" textTransform="capitalize">
                    {name}
                  </Text>
                }
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin: 2px 12px;
`;
