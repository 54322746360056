import { Box, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { useFormContext } from 'react-hook-form';

import { Loading } from '../../../Components/Loading/Loading';
import { useProfile } from '../../../Hooks/useProfile';
import { OnboardingFormInputs } from '../onboarding-flow-form';
import { OnboardingStepLayout } from './step-layout';

export const EndStep = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { watch } = useFormContext<OnboardingFormInputs>();
  const experienceId = watch('experienceId');
  const { companyProfileUrl } = useProfile();
  const companyUrl = companyProfileUrl();
  const previewUrl = `${companyUrl}/experience/${experienceId}?preview=true&hide-branding=true`;

  const boxRef = useRef(null);

  return (
    <OnboardingStepLayout>
      {isLoaded && (
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          overflow="hidden"
          sx={{ pointerEvents: 'none' }}
        >
          <Confetti canvasRef={boxRef.current} />
        </Box>
      )}
      <Stack
        width="100%"
        flexGrow={1}
        sx={{ aspectRatio: { md: '16 / 7' } }}
        position="relative"
      >
        <iframe
          onLoad={() => setIsLoaded(true)}
          style={{
            height: '100%',
            border: 'none',
          }}
          src={previewUrl}
        />
        {!isLoaded && <Loading isLoading={true} />}
      </Stack>
    </OnboardingStepLayout>
  );
};
