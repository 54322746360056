import { PriceBreakdown } from '@understory-io/utils-types';
import { useMemo } from 'react';

import { IExperience, useExperience } from './useExperience';
import { useLocalizedStringFormatter } from './useLocalizedStringFormatter';
import { useProfile } from './useProfile';

type CommonProduct<T extends string> = {
  type: T;
  id: string;
  name: string;

  /**
   * @deprecated use priceBreakdown instead
   * */
  currency: string;

  /**
   * @deprecated use priceBreakdown instead
   * */
  price: number;

  priceBreakdown: PriceBreakdown;
  /**
   * @deprecated use priceBreakdown instead
   * */
  vatId?: string;

  /**
   * @deprecated use priceBreakdown instead
   * */
  vatRate: number;
  experienceId: string;
  companyId: string;
  explanation?: string;
};

type VariantProduct = CommonProduct<'variant'> & {
  seatProps: {
    type: string;
    min: number;
    max?: number;
    customName?: string;
  };
};

type AddonProduct = CommonProduct<'addon'> & {
  parent: string;
};

export type Product = VariantProduct | AddonProduct;

const isVariantProduct = (product: Product): product is VariantProduct =>
  product.type === 'variant';

const isAddonProduct = (product: Product): product is AddonProduct =>
  product.type === 'addon';

export type UseProductsReturn = {
  products: Product[];
  variants: Array<VariantProduct & { addons: AddonProduct[] }>;
  isError: boolean;
  isLoading: boolean;
};

export const useProducts = (experienceId?: string): UseProductsReturn => {
  const {
    experience: { data: experience, isError, isLoading },
  } = useExperience(experienceId);

  const localizer = useLocalizedStringFormatter();

  const { defaultCurrency } = useProfile();

  const products = useMemo(() => {
    if (!experience) return [];

    const { companyId } = experience;

    const commonVariant = {
      type: 'variant',
      seatProps: getSeatProps(experience),
      experienceId: experience.id,
      currency: defaultCurrency,
      companyId,
    } as const;

    return experience.price.variants.flatMap((variant) => {
      const variantId = `variant/${variant.id}`;

      const experienceVatId = experience.price.vatId;
      const experienceVatRate = Number(experience.price.vatRate);

      const variantProduct: Product = {
        ...commonVariant,
        id: variantId,
        name: localizer(variant.name),
        explanation: localizer(variant.explanation),
        price: Number(variant.price),
        vatId: variant.vatId ?? experienceVatId,
        vatRate: Number(variant.vatRate ?? experienceVatRate),
        priceBreakdown: variant.priceBreakdown,
      };

      return [
        variantProduct,
        ...(variant.addons?.map<AddonProduct>((addon) => ({
          type: 'addon',
          id: `addon/${addon.id}`,
          name: localizer(addon.name),
          currency: defaultCurrency,
          price: Number(addon.price),
          vatId: addon.vatId ?? experienceVatId,
          vatRate: Number(addon.vatRate ?? experienceVatRate),
          parent: variantId,
          experienceId: experience.id,
          companyId,
          explanation: localizer(addon.explanation),
          priceBreakdown: addon.priceBreakdown,
        })) ?? []),
      ];
    });
  }, [defaultCurrency, experience, localizer]);

  const variants = useMemo(() => {
    const allAddons = products.filter(isAddonProduct);

    return products.filter(isVariantProduct).map((product) => ({
      ...product,
      addons: allAddons.filter((addon) => addon.parent === product.id),
    }));
  }, [products]);

  return {
    products,
    variants,
    isError,
    isLoading,
  };
};

function getSeatProps(experience: IExperience): VariantProduct['seatProps'] {
  const min = getMinSeats(experience);
  const max = getMaxSeats(experience);

  const { type } = experience.seats;

  return {
    type,
    min,
    max,
  };
}

function getMinSeats(experience: IExperience): number {
  const {
    seats: { type, minParticipants },
  } = experience;

  if (type === 'single') {
    return 1;
  }
  if (type === 'couple') {
    return 2;
  }
  return minParticipants;
}

function getMaxSeats({
  seats: { type, maxParticipants },
}: IExperience): number | undefined {
  if (type === 'single' || type === 'couple') {
    return undefined;
  }

  return maxParticipants;
}
