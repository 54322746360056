import { Divider, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { ExperienceTag } from '@understory-io/utils-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IExperience } from '../../../../Hooks/useExperience';
import { TagItem } from './tag-item';

export const TagList = ({
  tags,
  experiences,
}: {
  tags?: ExperienceTag[];
  experiences?: IExperience[];
}) => {
  const { t } = useTranslation();

  const usedTagsMap = useMemo(
    () =>
      experiences
        ?.flatMap(({ tagIds }) => tagIds)
        .reduce(
          (total, tagId) => ({ ...total, [tagId]: (total[tagId] ?? 0) + 1 }),
          {} as Record<string, number>
        ),
    [experiences]
  );

  if (!tags || !tags.length) {
    return (
      <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
        {t('storefront.tags.list.empty')}
      </Text>
    );
  }

  return (
    <Stack
      sx={{ gap: 3 }}
      divider={<Divider sx={{ display: { md: 'none' } }} />}
    >
      {tags.map((tag) => {
        const usedOnExperiencesCount = usedTagsMap?.[tag.id] ?? 0;

        return (
          <TagItem
            key={tag.id}
            tag={tag}
            experienceCount={usedOnExperiencesCount}
            experiences={experiences ?? []}
          />
        );
      })}
    </Stack>
  );
};
