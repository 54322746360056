import { Grid, Skeleton, Stack } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { Fragment } from 'react';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { ExperienceDetailsCard } from '../experience-details-card';

type ExperienceDetailsCardSkeletonProps = {
  label: string;
  items: string[];
};

function ExperienceDetailsCardSkeleton({
  label,
  items,
}: ExperienceDetailsCardSkeletonProps) {
  const { isSm } = useResponsive();

  return (
    <Grid item xs={1}>
      <ExperienceDetailsCard showDivider>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 1,
            paddingY: 1,
          }}
        >
          <Text variant="medium" fontSize="small">
            {label}
          </Text>

          <Skeleton
            sx={{
              width: 24,
              height: 24,
              transform: 'unset',
              display: { md: 'none' },
            }}
          />
        </Stack>
        <Grid
          sx={{ display: { xs: 'none', md: 'flex' } }}
          container
          columns={isSm ? 3 : 2}
          spacing={1.5}
        >
          {items.map((label) => (
            <Fragment key={label}>
              <Grid item xs={1}>
                <Text fontSize="xsmall">{label}</Text>
              </Grid>
              <Grid item xs={2} sm={1}>
                <Skeleton height={16} width={150} />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </ExperienceDetailsCard>
    </Grid>
  );
}

export default ExperienceDetailsCardSkeleton;
