import { Box, Stack, useMediaQuery } from '@mui/material';
import { Text } from '@understory-io/pixel';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { useAuth } from '../../Hooks/useAuth';
import { useFireOnce } from '../../Hooks/useFireOnce';
import { TabBar, TTab } from '../TabBar/TabBar';
import { Page } from './Page';

interface PageTabsProps {
  title: string;
  tabs: TTab[];
  children: React.ReactNode;
}

export const PageTabs = ({ title, tabs, children }: PageTabsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fireOnce = useFireOnce();

  const isMdOrHigher = useMediaQuery('(min-width: 960px)');

  const { canAccess } = useAuth();

  const { pathname } = useLocation();

  const id = pathname ? pathname.split('/')[2] : null;
  const route = pathname.length > 1 ? pathname.split('/')[1] : null;

  const [activeTab, setActiveTab] = useState(0);

  const _tabs = useMemo(() => {
    return tabs
      .filter(
        ({ scopes }) =>
          !scopes ||
          scopes.length === 0 ||
          scopes.some((scope) => canAccess(scope))
      )
      .filter((tabs) => isMdOrHigher || tabs.allowedOnMobile)
      .map((tab) => ({
        ...tab,
        label: t(`${route}.navigation.${tab.key}`),
      }));
  }, [tabs, isMdOrHigher, route, canAccess, t]);

  useEffect(() => {
    if (_tabs.length === 0) {
      navigate('/', { replace: true });
      return;
    }

    const tabIndex = _tabs.findIndex((x) => x.key === id);
    if (tabIndex === -1) {
      navigate(_tabs[0].key, { replace: true });
    } else {
      setActiveTab(tabIndex);

      fireOnce(() => _tabs[tabIndex].trackingFn?.());
    }
  }, [_tabs, id, fireOnce, navigate]);

  const handleChangeTab = useCallback(
    (index: number) => {
      const tab = _tabs[index];
      setActiveTab(index);

      navigate(`${tab.key}`);
      tab.trackingFn?.();
    },
    [_tabs, navigate]
  );

  return (
    <Page maxWidth={1050} pb={10}>
      <Stack gap={3}>
        <Text fontSize="h5">{title}</Text>
        {_tabs.length > 1 && (
          <TabBar
            items={_tabs}
            onChange={handleChangeTab}
            selected={activeTab}
            data-intercom-target={`page-tabs-${id}`}
          />
        )}
        <Box flexGrow={1} data-intercom-target="tab-content">
          {children}
        </Box>
      </Stack>
    </Page>
  );
};
