import NiceModal from '@ebay/nice-modal-react';
import { DeleteForeverRounded, EditRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Card,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { lightTheme } from '@understory-io/pixel';
import { renderDate } from '@understory-io/utils-date';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import { getDiscountStatus, type TDiscount } from '../../Hooks/useDiscount';
import { TranslateFunction, useTranslate } from '../../Hooks/useTranslate';
import { DiscountIcon } from '../../Icons';
import { ConfirmDialog } from '../../Modals/ConfirmDialog';
import { StatusBadge } from '../badge/status-badge';
import { ContextMenu } from '../context-menu/context-menu';
import { EmptyScreen } from '../EmptyScreen/EmptyScreen';

const columns = [
  {
    key: 'none',
    props: {
      xs: 0.5,
    },
    value: () => <DiscountIcon />,
    valueProps: {
      display: 'flex',
      pl: 0.5,
      alignItems: 'center',
    },
  },
  {
    key: 'code',
    label: 'Kode',
    props: {
      xs: 1.5,
    },
    value: (el: TDiscount) => el?.code,
  },
  {
    key: 'created',
    label: 'Oprettet',
    props: {
      xs: 2,
    },
    value: (el: TDiscount) => renderDate(el.created, 'ddmmyyyy'),
  },
  {
    key: 'value',
    label: 'Værdi',
    props: {
      xs: 1.5,
    },
    value: (el: TDiscount) =>
      el.overrides
        ? '-'
        : `${el?.rate} ${el.percentage ? '%' : el?.currency.toUpperCase()}`,
  },
  {
    key: 'expiry',
    label: 'Udløb',
    value: (el: TDiscount) =>
      el.expiry ? renderDate(el.expiry.endDateTime) : '-',
    props: {
      xs: 2,
    },
  },
  {
    key: 'redeemed',
    label: 'Indløst',
    value: (el: TDiscount) => el.timesRedeemed ?? 0,
    props: {
      xs: 1.5,
    },
  },
  {
    key: 'status',
    label: 'Status',
    props: {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: (el: TDiscount, _: TranslateFunction) => (
      <StatusBadge type="discount" size="small" state={getDiscountStatus(el)} />
    ),
  },
];

export const DiscountsList = ({
  discounts,
  onDelete,
  ...props
}: BoxProps & { onDelete: (id: string) => void; discounts?: TDiscount[] }) => {
  const { t } = useTranslate('utils.tables.header');

  return (
    <Box {...props}>
      {discounts?.length === 0 ? (
        <EmptyScreen
          mt={6}
          imageSrc={'/empty-state-nature.svg'}
          title={t('emptyState.title', 'discounts')}
          description={t('emptyState.description', 'discounts')}
        />
      ) : (
        <Grid container p={'12px'} mb={1}>
          {columns.map((el) => (
            <Grid
              fontSize={'0.75em'}
              fontWeight={600}
              textTransform={'uppercase'}
              key={el.key}
              item
              {...el.props}
              component={Typography}
            >
              {el.key !== 'none' && t(el.key)}
            </Grid>
          ))}
        </Grid>
      )}

      <Stack spacing={1}>
        {discounts?.map((discount) => (
          <DiscountItem
            key={discount.id}
            discount={discount}
            onDelete={onDelete}
          />
        )) ?? (
          <Stack spacing={1}>
            <Stack spacing={4} pl={7} component={Card} direction={'row'}>
              <Skeleton width={60} />
              <Skeleton width={180} />
              <Skeleton width={240} />
              <Skeleton width={164} />
            </Stack>
            <Stack spacing={4} pl={7} component={Card} direction={'row'}>
              <Skeleton width={60} />
              <Skeleton width={180} />
              <Skeleton width={211} />
              <Skeleton width={120} />
            </Stack>
            <Stack spacing={4} pl={7} component={Card} direction={'row'}>
              <Skeleton width={60} />
              <Skeleton width={180} />
              <Skeleton width={240} />
              <Skeleton width={164} />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

const DiscountItem = ({
  discount,
  onDelete,
}: {
  discount: TDiscount;
  onDelete: (id: string) => void;
}) => {
  const { t } = useTranslate('utils.generic');

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/discount/${discount.id}/edit/details`);
  };

  return (
    <Grid
      container
      key={discount.id}
      component={Card}
      p={1.5}
      alignItems={'center'}
      sx={{
        cursor: 'pointer',
        '&:hover': { backgroundColor: lightTheme.palette.neutral.n100 },
      }}
      onClick={() => handleClick()}
    >
      {columns.map((el) => (
        <Grid
          fontSize={'0.88em'}
          key={el.key}
          item
          {...el.props}
          {...el.valueProps}
          component={Typography}
        >
          {el.value?.(discount, t)}
        </Grid>
      ))}
      <Grid
        item
        sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}
      >
        <ContextMenu
          type="icon"
          open={isOpen}
          onOpenChange={setIsOpen}
          options={[
            {
              label: t('edit'),
              onClick: handleClick,
              scopes: ['marketing.write'],
              icon: <EditRounded fontSize="small" />,
            },
            {
              label: t('delete'),
              onClick: () => {
                NiceModal.show(ConfirmDialog, {
                  headline: t('headline', 'dialogs.confirmDelete', {
                    type: t('thisDiscount', 'utils.generic'),
                  }),
                  title: t('title', 'dialogs.confirmDelete'),
                  confirmLabel: t('actions.primary', 'dialogs.confirmDelete'),
                }).then(() => {
                  onDelete(discount.id);
                });
              },
              scopes: ['marketing.write'],
              icon: <DeleteForeverRounded fontSize="small" />,
              textColor: lightTheme.palette.error.e300,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};
