import { Chip, ChipProps, styled } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

const Styled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'disableDelete',
})<{ selected: boolean; disableDelete: boolean }>(
  ({ theme, selected, disableDelete }) => ({
    backgroundColor: selected
      ? lightTheme.palette.action.a100
      : lightTheme.palette.contrast.white,
    color: selected
      ? lightTheme.palette.action.a300
      : lightTheme.palette.neutral.n500,
    padding: 8,
    fontSize: '14px',
    verticalAlign: 'middle',
    ['& .MuiSvgIcon-root']: {
      color: selected
        ? lightTheme.palette.action.a200
        : lightTheme.palette.neutral.n300,
    },
    ['& .MuiChip-deleteIcon']: {
      color: disableDelete
        ? selected
          ? lightTheme.palette.action.a200
          : theme.palette.text.primary
        : 'rgba(0,0,0,0.15)',
      pointerEvents: disableDelete ? 'none' : 'initial',
    },
  })
);

export const CustomChip = ({
  selected = false,
  deleteIcon,
  onDelete,
  ...props
}: ChipProps & { selected?: boolean }) => {
  return (
    <Styled
      deleteIcon={
        deleteIcon && !onDelete
          ? selected
            ? deleteIcon
            : undefined
          : deleteIcon
      }
      disableDelete={Boolean(deleteIcon && !onDelete)}
      onDelete={
        onDelete
          ? onDelete
          : deleteIcon
            ? onDelete
              ? onDelete
              : selected
                ? console.log
                : undefined
            : undefined
      }
      variant={'outlined'}
      clickable
      selected={selected}
      {...props}
    />
  );
};
