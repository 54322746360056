import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { lightTheme } from '@understory-io/pixel';

export const CalendarGrid = styled(Box)<{
  $columns: number;
  $divideHeaders?: boolean;
}>`
  display: grid;

  background-color: ${lightTheme.palette.contrast.white};
  border: 1px solid ${lightTheme.palette.neutral.n100};
  border-radius: 8px;

  ${({ $columns, $divideHeaders }) => css`
    grid-template-columns: repeat(${$columns}, minmax(0, 1fr));

    & > * {
      border: 1px solid ${lightTheme.palette.neutral.n100};
      border-left: none;
      border-bottom: none;

      &:nth-child(${$columns}n) {
        border-right-color: transparent;
      }

      // The first row / header row
      &:nth-child(-n + ${$columns}) {
        border-top: none;

        ${!$divideHeaders &&
        css`
          border: none;
        `}
      }
    }
  `};
`;
