import { Experience } from '@understory-io/utils-types';
import {
  differenceInDays,
  differenceInSeconds,
  secondsInDay,
  startOfDay,
  subSeconds,
} from 'date-fns';

import { countVariants } from '../../../../features/bookings/booking_details/ui/booking_details_summary/domain/use_booking_details_summary';
import { EventType } from '../../../../features/events/event_upsert/domain/types';
import { EventUpsertTimeFormTypes } from '../../../../features/events/event_upsert/ui/time/ui/time_form/use_time_form_validation';
import { getLocalized } from '../../../../Hooks/useBookings';
import { getFormattedDate } from '../../../helpers';

export const eventProperties = (
  timeForm: EventUpsertTimeFormTypes,
  event: EventType,
  experiences?: Experience[]
) => {
  const { startDateTime, endDateTime } = timeForm;

  const {
    id: event_id,
    visibility,
    assignedGuides,
    seatCount,
    experienceId: experience_id,
    languages: language_list,
    addresses: location,
  } = event;

  const eventExperience = experiences?.find(
    (experience) => experience.id === experience_id
  );

  const experience_name = getLocalized(eventExperience?.headline, 'en') ?? '';

  const event_scheduled_date = getFormattedDate(new Date(startDateTime));

  const days_until_event_starts = differenceInDays(
    new Date(startDateTime),
    new Date()
  );

  const days_until_event_closes = endDateTime
    ? differenceInDays(new Date(endDateTime), new Date())
    : undefined;

  const is_recurring =
    event.recurring.selectedOptionKey === 'yes'
      ? event.recurring.frequency.value
      : undefined;

  const number_of_times =
    event.intervals.selectedOptionKey === 'yes'
      ? event.intervals.value.length + 1
      : 1;

  const number_of_guests = event.booking?.name
    ? countVariants(event.booking.guests)
    : 0;

  const days_to_booking_closing =
    differenceInSeconds(
      subSeconds(
        startOfDay(new Date(event.startDateTime)),
        Number(eventExperience?.cutoffTimeSeconds ?? 0)
      ),
      startOfDay(new Date())
    ) / secondsInDay;

  return {
    event_capacity: seatCount?.value ?? 0,
    event_id,
    experience_id,
    experience_name,
    is_private: visibility === 'private',
    language_list,
    location: location?.value ?? '',
    number_of_guides: assignedGuides.length,
    days_until_event_starts,
    days_until_event_closes,
    days_to_booking_closing,
    event_scheduled_date,
    is_recurring,
    number_of_times,
    language: event.booking?.language,
    number_of_guests,
    flow_type: event.variant,
  };
};
