import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Stack, styled } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router';

import useContentLayout from '../../Hooks/layout/useContentLayout';
import useResponsive from '../../Hooks/layout/useResponsive';

// This component is designed for mobile-first (md breakpoint and down) layouts.
// For larger screens, the header, back button, and actions are the responsibility of the consumer.

type ViewProps = {
  children: ReactNode;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  back?: boolean | (() => void);
  actions?: ReactNode;
  hideHeader?: boolean;
  hideBack?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const View = ({
  title,
  subtitle,
  actions,
  back = false,
  hideBack = false,
  hideHeader = false,
  children,
  ...otherProps
}: ViewProps) => {
  const navigate = useNavigate();
  const { isMd } = useResponsive();

  const hasViewHeader = !!(!hideHeader && isMd);
  const { contentPadding } = useContentLayout();

  // providing a back function will override the default back button behavior
  // to enable default back provide back={true} or back={false} to disable
  const handleBack = () => {
    if (typeof back === 'boolean' && back) {
      navigate(-1);
    } else if (typeof back === 'function') {
      back();
    }
  };

  return (
    <StyledView {...otherProps}>
      {hasViewHeader && (
        <Stack
          aria-label="header"
          role="header"
          direction="row"
          width="100%"
          justifyContent={back ? 'space-between' : 'flex-end'}
          p={contentPadding}
          pt={2}
        >
          {back && !hideBack && (
            <StyledBackButton onClick={handleBack}>
              <ArrowBackRoundedIcon />
            </StyledBackButton>
          )}
          {actions && <Stack direction="row">{actions}</Stack>}
        </Stack>
      )}
      <StyledViewMain aria-label="main" role="main" p={contentPadding}>
        {children}
      </StyledViewMain>
    </StyledView>
  );
};

const StyledView = styled(Stack)`
  z-index: 1;
  height: 100%;
  min-height: 100vh;
`;

const StyledViewMain = styled(Stack)(
  ({ theme }) => `
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: var(--content-max-width);

  padding-top: ${theme.spacing(4)};

  ${theme.breakpoints.up('md')} {
    padding-top: ${theme.spacing(12)};
  }
`
);

const StyledBackButton = styled('button')`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
`;
