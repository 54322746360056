import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { Button, lightTheme } from '@understory-io/pixel';
import { AnimatePresence, motion } from 'framer-motion';

import useResponsive from '../../Hooks/layout/useResponsive';
import { useTranslate } from '../../Hooks/useTranslate';

export const FixedActionsBar = ({
  unsavedChanges,
  isSubmitting,
  onSubmit,
  onReset,
}: {
  unsavedChanges?: boolean;
  isSubmitting?: boolean;
  onSubmit?: () => void;
  onReset?: () => void;
}) => {
  const { t } = useTranslate('buttons');
  const { isLg } = useResponsive();

  return (
    <AnimatePresence>
      {unsavedChanges && (
        <StyledMotionDiv
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={motionVariants}
          transition={{ duration: 0.2 }}
          isLg={isLg}
        >
          <Stack
            justifyContent={'flex-end'}
            gap={1}
            direction={{ xs: 'column', lg: 'row' }}
          >
            <Button
              onClick={onReset}
              variant="secondary"
              size="medium"
              type="button"
              disabled={isSubmitting}
            >
              {t('undo')}
            </Button>
            <Button
              onClick={onSubmit}
              variant="primary"
              size="medium"
              type="submit"
              loading={isSubmitting}
            >
              {t('save')}
            </Button>
          </Stack>
        </StyledMotionDiv>
      )}
    </AnimatePresence>
  );
};

const StyledMotionDiv = styled(motion.div, {
  shouldForwardProp: (prop) => !['isLg'].includes(prop),
})<{ isLg: boolean }>`
  position: fixed;
  bottom: 0;
  left: ${({ isLg }) => (isLg ? 0 : '260px')};
  right: 0;
  padding: ${({ isLg }) => (isLg ? '16px' : '16px 96px 16px 16px')};
  background-color: white;
  border-top: 1px solid ${lightTheme.palette.neutral.n100};
  z-index: 100;
  width: auto;
  box-shadow: 0px -4px 2px rgba(0, 0, 0, 0.02);
`;

const motionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 50 },
};
