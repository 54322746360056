import { Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { FC } from 'react';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../Hooks/useTranslate';

type CreateBookingFormActionsProps = {
  isLoading: boolean;
};

export const CreateBookingFormActions: FC<CreateBookingFormActionsProps> = ({
  isLoading,
}) => {
  const { t } = useTranslate('dialogs.createBooking.actions');
  const { isSm } = useResponsive();

  return (
    <Stack
      padding={isSm ? 2 : 4}
      paddingLeft={isSm ? 2 : 6}
      direction="row"
      justifyContent="end"
      gap={1}
    >
      <Button variant="primary" size="large" type="submit" disabled={isLoading}>
        {t('primary')}
      </Button>
    </Stack>
  );
};
