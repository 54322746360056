import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguages } from '../locales/use-languages';

export const useSelectedLanguages = (languages?: string[]) => {
  const { i18n } = useTranslation();

  const { languages: defaultLanguages } = useLanguages();

  return useMemo(
    () => mapSelectedLanguages(languages, i18n.languages, defaultLanguages),
    [defaultLanguages, i18n.languages, languages]
  );
};

const mapSelectedLanguages = (
  selected: string[] | undefined,
  backofficeLanguages: readonly string[],
  languageOptions: ReturnType<typeof useLanguages>['languages']
) => {
  if (!selected) return undefined;

  const possibleLanguages = selected.flatMap((lang) => {
    const langConfig = languageOptions.data?.items.find(
      (item) => item.code === lang
    );
    if (!langConfig) {
      return [];
    }

    const { flagSrc, name } = langConfig;
    return { key: lang, label: name, icon: flagSrc };
  });

  // Sorts the languages based on detected language in the backoffice
  return possibleLanguages.sort((a, b) => {
    const aIndex = backofficeLanguages.indexOf(a.key);
    const bIndex = backofficeLanguages.indexOf(b.key);

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  });
};
