import NiceModal from '@ebay/nice-modal-react';
import { AddOutlined } from '@mui/icons-material';
import { Divider, Skeleton, Stack } from '@mui/material';
import { Button, lightTheme, Text } from '@understory-io/pixel';

import { useGetLocations } from '../../../Hooks/data/useLocations';
import { useExperience } from '../../../Hooks/useExperience';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { CreateLocationDialog } from './dialogs/create-location-dialog';
import { LocationList } from './location-list';

export const LocationManagementSection = () => {
  return (
    <Stack
      sx={{
        backgroundColor: { sm: lightTheme.palette.contrast.white },
        borderStyle: { sm: 'solid' },
        borderWidth: { sm: 1 },
        borderColor: { sm: lightTheme.palette.neutral.n100 },
        borderRadius: { sm: 1 },
        paddingY: { sm: 3 },
        paddingX: { sm: 4 },
        gap: 2,
      }}
      divider={<Divider />}
    >
      <LocationManagementContainerHeader />
      <LocationManagementList />
    </Stack>
  );
};

const LocationManagementContainerHeader = () => {
  const { t } = useTranslate('storefront.locations.header');

  return (
    <Stack
      sx={{
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        gap: 2,
        justifyContent: 'space-between',
        paddingBottom: { xs: 3, sm: 0 },
      }}
    >
      <Text variant="medium">{t('title')}</Text>
      <Button
        type="button"
        variant="primary"
        size="medium"
        leftIcon={<AddOutlined />}
        onClick={() => NiceModal.show(CreateLocationDialog)}
      >
        {t('buttonLabel')}
      </Button>
    </Stack>
  );
};

const LocationManagementList = () => {
  const { company } = useProfile();
  const { locations } = useGetLocations(company.data?.id ?? '');
  const { experiences } = useExperience(undefined, { type: 'owned' });

  const isLoading =
    company.isLoading || locations.isLoading || experiences.isLoading;

  if (isLoading) return <LocationListLoadingSkeleton />;

  return (
    <LocationList
      locations={locations.data}
      experiences={experiences.data ?? []}
    />
  );
};

const LocationListLoadingSkeleton = () => {
  return (
    <Stack
      sx={{ gap: 3 }}
      divider={<Divider sx={{ display: { md: 'none' } }} />}
    >
      <Skeleton width={200} />
      <Skeleton width={200} />
      <Skeleton width={200} />
    </Stack>
  );
};
