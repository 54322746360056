import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Text } from '@understory-io/pixel';
import { FC } from 'react';
import { Link } from 'react-router';

import { usePageBreadcrumbContext } from '../../domain/use_page_breadcrumb_context';

type Props = {
  title?: string;
  href: string;
};

export const PageBreadcrumbItem: FC<Props> = ({ title, href }) => {
  const { isLast } = usePageBreadcrumbContext();

  if (!title) {
    return <Skeleton width={100} />;
  }

  return (
    <StyledText fontSize="xsmall" variant={isLast ? 'medium' : 'normal'}>
      <Link to={href}>{title}</Link>
    </StyledText>
  );
};

const StyledText = styled(Text)`
  cursor: pointer;
  line-height: normal;
`;
