import styled from '@emotion/styled';
import { css, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { format, isSameDay } from 'date-fns';
import { FC, useState } from 'react';
import { Link } from 'react-router';

import { useCalendarLink } from '../../hooks/useCalendarLink';

type Props = {
  date: Date;
};

export const CalendarDateHeader: FC<Props> = ({ date }) => {
  const [today] = useState(new Date());

  const getCalendarLink = useCalendarLink();

  return (
    <Link to={getCalendarLink(date, 'day')}>
      <Stack p={2} textAlign="center" alignItems="center">
        <DayOfWeek fontSize="small" variant="medium">
          {format(date, 'EEE')}
        </DayOfWeek>
        <DayOfMonth
          fontSize="large"
          variant="medium"
          $isToday={isSameDay(date, today)}
        >
          {format(date, 'd')}
        </DayOfMonth>
      </Stack>
    </Link>
  );
};

const DayOfWeek = styled(Text)`
  text-transform: capitalize;
  color: ${lightTheme.palette.neutral.n300};
`;

const DayOfMonth = styled(Text)<{ $isToday?: boolean }>`
  width: 32px;
  height: 32px;
  line-height: 32px;

  ${({ $isToday }) =>
    $isToday &&
    css`
      color: white;
      background: ${lightTheme.palette.action.a300};
      border-radius: 50%;
    `}
`;
