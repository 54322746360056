import { Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { InfoBox } from '../../../../Components/InfoBox/InfoBox';
import { formatMoney } from '../../../../Components/VariantSelect/VariantSelect';

type GiftCardDetailsProps = {
  balanceCents: number;
  initialValueCents: number;
  expiryDate: Date;
  code: string;
  currency: string;
};

export const GiftCardDetails = ({
  balanceCents,
  initialValueCents,
  expiryDate,
  code,
  currency,
}: GiftCardDetailsProps) => {
  const { t } = useTranslation();

  const moneyFormatter = formatMoney(t, true);

  return (
    <InfoBox title={t('giftCard.details.title')}>
      <Stack sx={{ gap: 1.5 }}>
        <ListItem
          label={t('giftCard.details.balanceLabel')}
          value={moneyFormatter({
            value: balanceCents / 100,
            nativeCurrency: currency,
          })}
        />
        <ListItem
          label={t('giftCard.details.initialBalanceLabel')}
          value={moneyFormatter({
            value: initialValueCents / 100,
            nativeCurrency: currency,
          })}
        />
        <ListItem
          label={t('giftCard.details.expiryLabel')}
          value={format(expiryDate, 'PPP')}
        />
        <ListItem label={t('giftCard.details.codeLabel')} value={code} />
      </Stack>
    </InfoBox>
  );
};

const ListItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
        '& > *': {
          width: '50%',
        },
      }}
    >
      <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
        {label}
      </Text>
      <Text
        fontSize="small"
        variant="medium"
        color={lightTheme.palette.neutral.n400}
      >
        {value}
      </Text>
    </Stack>
  );
};
