import { CircularProgress, Stack } from '@mui/material';
import { Button } from '@understory-io/pixel';
import { useFormContext } from 'react-hook-form';

import useResponsive from '../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../Hooks/useTranslate';
import { useEconomicConfigurationCancel } from './use-economic-configuration-actions';

export const EconomicConfigurationActions = () => {
  const { t } = useTranslate('utils.generic');
  const { isSm } = useResponsive();

  const { formState } = useFormContext();

  const { handleCancel } = useEconomicConfigurationCancel();

  return (
    <Stack
      gap={2}
      direction={isSm ? 'column' : 'row'}
      alignSelf={isSm ? 'auto' : 'flex-end'}
    >
      {!isSm && (
        <Button
          variant="secondary"
          size="medium"
          style={{ minWidth: '200px' }}
          onClick={handleCancel}
        >
          {t('cancel')}
        </Button>
      )}
      <Button
        variant="primary"
        size="medium"
        type="submit"
        style={{ minWidth: '200px' }}
        disabled={formState.isSubmitting}
        leftIcon={formState.isSubmitting && <CircularProgress size={20} />}
      >
        {t('done')}
      </Button>
    </Stack>
  );
};
