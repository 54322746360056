import { Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  ExperienceStatus,
  PublicCompanyProfile,
  SharedExperience,
} from '@understory-io/utils-types';
import { AxiosError } from 'axios';
import randomBytes from 'randombytes';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Await,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useRouteError,
} from 'react-router';
import { toast } from 'react-toastify';

import { updateExperience } from '../../../Api';
import { AppShell } from '../../../Components/AppShell/AppShell';
import { PageBreadcrumb } from '../../../Components/Page/page_breadcrumb';
import { PageBreadcrumbBreadcrumbsExperience } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperience';
import { PageBreadcrumbBreadcrumbsExperienceDetails } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperienceDetails';
import { PageBreadcrumbBreadcrumbs } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { getLocalized } from '../../../Hooks/useBookings';
import { t } from '../../../i18n/config';
import { ErrorPage } from '../../../Pages/error-page';
import { queryClient } from '../../../query-client';
import { trackExperienceDetailsPageOpened } from '../../../tracking/experiences/details/card/trackExperienceDetailsPageOpened';
import { isEventState, isEventStatus } from '../../../Utils/eventHelpers';
import {
  calculateGuestCounts,
  generateExperienceDetails,
  generateExperienceOtherDetails,
  getDistributorNames,
} from '../utils/experience-details-helpers';
import { isSharedExperience } from '../utils/is-shared-experience';
import { EventListCard } from './event-list/event-list-card';
import { EventListFormInput } from './event-list/event-list-form';
import { getExperienceActions } from './experience-details-actions';
import { ExperienceDetailsGrid } from './experience-details-grid';
import { ExperienceDetailsHeader } from './experience-details-header';
import {
  getCompanyDomainQuery,
  getCompanyProfileQuery,
  getCompanyUsersQuery,
  getConncetionsQuery,
  getEventListQuery,
  getExperienceQuery,
  getExperienceWaitlistQuery,
  getLocationsByExperienceQuery,
} from './queries';
import ExperienceDetailsCardSkeleton from './skeletons/experience-details-card-skeleton';

export type User = {
  id: string;
  name?: string;
  email?: string;
};

const DEFAULT_FILTER: EventListFormInput = {
  state: 'future',
  status: 'anyStatus',
  page: 1,
};

export type LoaderData =
  ReturnType<typeof loader> extends Promise<infer R> ? R : never;

export async function loader({ params, request }: LoaderFunctionArgs) {
  const id = params.id;
  if (!id) throw new Response('Invalid id', { status: 404 });

  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const locationIdParam = searchParams.get('locationId');
  const stateParam = searchParams.get('state');
  const statusParam = searchParams.get('status');
  const pageParam = searchParams.get('page');
  const pageNumber = pageParam ? parseInt(pageParam, 10) : null;

  const eventFilters: EventListFormInput = {
    state:
      stateParam && isEventState(stateParam)
        ? stateParam
        : DEFAULT_FILTER['state'],
    status:
      statusParam && isEventStatus(statusParam)
        ? statusParam
        : DEFAULT_FILTER['status'],
    page: pageNumber ? Math.max(pageNumber, 1) : DEFAULT_FILTER['page'],
    ...(locationIdParam && { locationId: locationIdParam }),
  };

  // Critical data promises
  const experience = await getExperienceQuery(id);
  const isShared = isSharedExperience(experience);

  const distributorNames = getConncetionsQuery(experience.companyId).then(
    (connections) => getDistributorNames(connections, id)
  );

  // Tracking
  trackExperienceDetailsPageOpened(experience);

  return {
    experience: experience,
    eventFilters,
    isShared,
    waitlist: getExperienceWaitlistQuery(id),
    experienceActions: await getExperienceActions(
      experience,
      isShared,
      `?${searchParams.toString()}`
    ),
    experienceOwnerCompanyProfile: isShared
      ? (getCompanyProfileQuery(
          experience.ownerCompanyId,
          isShared
        ) as Promise<PublicCompanyProfile>)
      : null,
    experienceId: id,
    otherDetails: Promise.all([
      getCompanyDomainQuery(experience.companyId),
      (experience as SharedExperience).ownerCompanyId
        ? getCompanyDomainQuery((experience as SharedExperience).ownerCompanyId)
        : null,
    ]),
    experienceDetails: Promise.all([
      getEventListQuery(id),
      distributorNames,
      isShared
        ? (getCompanyProfileQuery(
            experience.ownerCompanyId,
            isShared
          ) as Promise<PublicCompanyProfile>)
        : null,
    ]),
    eventList: Promise.all([
      getEventListQuery(id, {
        page: eventFilters.page,
        eventFilter: {
          locationId: eventFilters.locationId,
          state: eventFilters.state,
          status: eventFilters.status,
        },
      }),
      getCompanyUsersQuery(),
    ]),
    locations: getLocationsByExperienceQuery(id),
  };
}

export const ExperienceDetailsPage = () => {
  const { i18n } = useTranslation();
  const {
    eventList,
    eventFilters,
    experience,
    experienceId,
    isShared,
    experienceActions,
    experienceDetails,
    otherDetails,
    experienceOwnerCompanyProfile,
    waitlist,
    locations,
  } = useLoaderData() as LoaderData;
  const queryClient = useQueryClient();

  useEffect(() => {
    // Updating the status using react router actions does not invalidate
    // the experience query, so going back to the overview would show the
    // incorrect status. Running this invalidation on mount since posting
    // to the actions will refresh the page, and re-run this invalidation
    queryClient.invalidateQueries({
      queryKey: ['experiences'],
    });
  }, [queryClient]);

  return (
    <>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbBreadcrumbsExperience />
        <PageBreadcrumbBreadcrumbsExperienceDetails
          experienceId={experienceId}
        />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack width="100%" maxWidth={1200} minWidth={350} gap={4}>
          {/* Suspense is used to show the header while the company profile is loading */}
          <Suspense
            fallback={
              <ExperienceDetailsHeader
                title={getLocalized(experience.headline, i18n.language) ?? ''}
                subtitle={t(
                  `experience.details.header.subtitle.${experience.visibility}`
                )}
                status={experience.status}
                actions={experienceActions}
                experienceId={experienceId}
              />
            }
          >
            <Await resolve={experienceOwnerCompanyProfile}>
              {(profile) => {
                const subtitle = profile
                  ? t('experience.details.header.subtitle.sharedBy', {
                      companyName: profile?.name,
                    })
                  : t(
                      `experience.details.header.subtitle.${experience.visibility}`
                    );

                return (
                  <ExperienceDetailsHeader
                    title={
                      getLocalized(experience.headline, i18n.language) ?? ''
                    }
                    subtitle={subtitle}
                    status={experience.status}
                    actions={experienceActions}
                    experienceId={experienceId}
                  />
                );
              }}
            </Await>
          </Suspense>
          <Stack gap={2}>
            <Grid
              container
              columns={{ xs: 1, sm: 2 }}
              columnSpacing={2}
              rowSpacing={1.5}
            >
              <Suspense
                fallback={
                  <ExperienceDetailsCardSkeleton
                    label={t('experience.details.section.label.details')}
                    items={[
                      t('experience.details.label.events'),
                      t('experience.details.label.guests'),
                      t('experience.details.label.language'),
                    ]}
                  />
                }
              >
                <Await resolve={experienceDetails}>
                  {([events, distributorNames, sharedCompanyProfile]) => {
                    const { guestCount, slotsCount } = calculateGuestCounts({
                      events: events.events,
                    });

                    const experienceDetails = generateExperienceDetails({
                      experience,
                      upcomingEventsCount: events.totalCount,
                      guestCount,
                      slotsCount,
                      isShared: isShared,
                      distributorCompanyNames: distributorNames,
                      experienceOwnerCompanyProfile: sharedCompanyProfile,
                    });

                    return (
                      <ExperienceDetailsGrid
                        label={t('experience.details.section.label.details')}
                        items={experienceDetails}
                        waitlist={waitlist}
                      />
                    );
                  }}
                </Await>
              </Suspense>
              <Suspense
                fallback={
                  <ExperienceDetailsCardSkeleton
                    label={t('experience.details.section.label.otherDetails')}
                    items={[
                      t('experience.details.label.creationDate'),
                      t('experience.details.label.detailsPage'),
                      t('experience.details.label.bookingLink'),
                    ]}
                  />
                }
              >
                <Await resolve={otherDetails}>
                  {([companyDomain, sharedCompanyDomain]) => {
                    const experienceOtherDetails =
                      generateExperienceOtherDetails({
                        experience: experience,
                        experienceDetailsBaseURL: companyDomain,
                        experienceBookingFlowBaseURL: sharedCompanyDomain,
                        isShared: isShared,
                      });

                    return (
                      <ExperienceDetailsGrid
                        label={t(
                          'experience.details.section.label.otherDetails'
                        )}
                        items={experienceOtherDetails}
                      />
                    );
                  }}
                </Await>
              </Suspense>
            </Grid>
            <EventListCard
              key={JSON.stringify(eventFilters)}
              eventFilters={eventFilters}
              eventList={eventList}
              experience={experience}
              locationsPromise={locations}
            />
          </Stack>
        </Stack>
      </PageBreadcrumb>
    </>
  );
};

export const ExperienceDetailsErrorPage = () => {
  const error = useRouteError();

  const isAxiosError = (error as AxiosError).isAxiosError;

  return (
    <AppShell hideContainerPadding>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbBreadcrumbsExperience />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <ErrorPage
          resource="experience"
          error={
            isAxiosError
              ? (error as AxiosError)
              : ({ request: { status: 0 } } as AxiosError)
          }
        />
      </PageBreadcrumb>
    </AppShell>
  );
};

export async function updateStatusLoader({ params }: LoaderFunctionArgs) {
  const id = params.id;

  if (!id) {
    return redirect('/experiences');
  }

  return redirect(`/experience/${id}`);
}

export async function updateStatusAction({
  request,
  params,
}: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('experience.details.updateStatus.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    const id = params.id;
    if (!id) throw new Error('Missing id');

    const experience = await getExperienceQuery(id);

    queryClient.invalidateQueries({
      queryKey: ['experience', id],
    });

    const formData = await request.formData();
    const action = formData.get('action');
    if (!action) throw new Error('Missing action');
    if (action !== 'activate' && action !== 'deactivate') {
      throw new Error('Invalid action');
    }

    const newStatus: ExperienceStatus =
      action === 'activate' ? 'active' : 'inactive';

    await updateExperience(id, { ...experience, status: newStatus });

    toast.dismiss(loadingToastId);
    toast.success(t('experience.details.updateStatus.toast.success'), {
      delay: 500,
      autoClose: 5000,
    });

    const url = new URL(request.url);

    return redirect(`/experience/${id}${url.search}`);
  } catch (error) {
    console.error(error);
    toast.dismiss(loadingToastId);
    toast.error(t('experience.details.updateStatus.toast.error'), {
      delay: 500,
    });
    const url = new URL(request.url);

    return redirect(url.toString().replace('/update-status', ''));
  }
}
