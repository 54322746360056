import { CheckRounded } from '@mui/icons-material';
import { CircularProgress, Stack } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'usehooks-ts';

const STEP_TIMING_MS = 3_000;
const MAX_ITEMS = 6;
const MAX_LAST_ITEMS = 3;

export const PreviewListLoader = ({
  translationContext = 'auth.preview.loading',
}: {
  translationContext?: string;
}) => {
  const { t } = useTranslation();

  const [items, setItems] = useState([
    { id: 0, title: t(`${translationContext}.item1`) },
  ]);

  useInterval(() => {
    setItems((prev) => {
      if (prev.length === MAX_ITEMS) {
        const randomMessage = Math.ceil(Math.random() * MAX_LAST_ITEMS);

        return [
          ...prev.slice(0, prev.length - 1),
          {
            id: prev.length,
            title: t(`${translationContext}.lastItem${randomMessage}`),
          },
        ];
      }
      return [
        ...prev,
        {
          id: prev.length,
          title: t(`${translationContext}.item${prev.length + 1}`),
        },
      ];
    });
  }, STEP_TIMING_MS);

  return (
    <AnimatePresence>
      <Stack gap={0.8} mt={1.5}>
        {items.map((item, index) => (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.4 }}
          >
            <Stack
              direction={'row'}
              gap={1}
              key={item.id}
              alignItems={'center'}
            >
              {index === items.length - 1 ? (
                <CircularProgress size={16} />
              ) : (
                <CheckRounded
                  fontSize="small"
                  sx={{
                    width: 16,
                    height: 16,
                  }}
                  htmlColor={lightTheme.palette.success.s300}
                />
              )}
              <Text fontSize="small">{item.title}</Text>
            </Stack>
          </motion.div>
        ))}
      </Stack>
    </AnimatePresence>
  );
};

export const ParentPreviewListLoader = ({ step }: { step: string }) => {
  return (
    <AnimatePresence>
      {step === 'creating' && (
        <motion.div
          key={'list-loader'}
          initial={{ opacity: 1, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 30 }}
          transition={{ duration: 0.8 }}
        >
          <PreviewListLoader />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
