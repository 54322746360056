import { createFilterOptions, FilterOptionsState } from '@mui/material';

import { t } from '../../../../../../i18n/config';
import { TagFilterItem } from '../tags';

const filter = createFilterOptions<TagFilterItem>();

export const filterOptions = (
  tags: TagFilterItem[],
  params: FilterOptionsState<TagFilterItem>
) => {
  const filtered = filter(tags, params);
  const { inputValue, getOptionLabel } = params;

  const isExisting = tags.some(
    (tag) =>
      inputValue.toLocaleLowerCase() === getOptionLabel(tag).toLocaleLowerCase()
  );

  if (inputValue && !isExisting) {
    filtered.push({
      id: 'new',
      label: t('experience.edit.dialog.tags.autoComplete.createTag', {
        value: inputValue,
      }),
    });
  }

  return filtered;
};
