import { Text } from '@understory-io/pixel';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';

import { ampli } from '../../../Ampli';
import { Loading } from '../../../Components/Loading/Loading';
import {
  GetInvitationResult,
  InvitationStatus,
  useAuth,
} from '../../../Hooks/useAuth';
import { useTranslate } from '../../../Hooks/useTranslate';
import { DialogContainer, InviteDialog } from './invite-dialog';

export type InvitationData = {
  email: string;
  companyName: string;
  inviterName: string;
  name?: string;
  id: string;
  existingUser: boolean;
};

type InvitationError = {
  status: number;
  message: string;
};

export const InviteView = () => {
  const { t } = useTranslate('invite');

  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('token');
  const navigate = useNavigate();
  const { getInvitation } = useAuth();
  const [error, setError] = useState<InvitationError | undefined>(undefined);
  const [invitation, setInvitation] = useState<InvitationData | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!inviteToken || invitation || error || isLoading) return;
    setIsLoading(true);
    getInvitation(inviteToken)
      .then((authInvitation) => {
        setInvitation({ ...authInvitation, id: inviteToken });
        ampli.acceptInvitationViewed({
          inviter_is_internal: authInvitation.inviterIsInternal,
          invitee_is_internal: authInvitation.inviteeIsInternal,
          inviter_name: authInvitation.inviterName,
        });
      })
      .catch((e) => {
        const error = e as GetInvitationResult;
        if (error) {
          switch (error.status) {
            case InvitationStatus.EXPIRED:
              setError({
                status: error.status,
                message: t('invitationExpired', 'auth.errors'),
              });
              break;
            case InvitationStatus.NOT_FOUND:
              setError({
                status: error.status,
                message: t('invitationNotFound', 'auth.errors'),
              });
              break;
            default:
              setError({
                status: error.status,
                message: t('invalidInvitationTitle', 'auth.errors'),
              });
              break;
          }
        } else {
          setError({
            status: InvitationStatus.INVALID,
            message: t('invalidInvitationTitle', 'auth.errors'),
          });
        }
      })
      .finally(() => setIsLoading(false));
  }, [error, getInvitation, invitation, inviteToken, isLoading, t]);

  // Redirect to /signup when no invite token is present
  if (!inviteToken) navigate('/signup', { replace: true });

  if (error) return <ErrorDialog message={error.message} />;

  if (invitation) return <InviteDialog invitation={invitation} />;

  return <InviteLoading />;
};

const ErrorDialog = ({ message }: { message: string }) => {
  const { t } = useTranslate('invite');

  return (
    <DialogContainer sx={{ gap: 2 }}>
      <Text variant="medium" fontSize="xlarge">
        {t('cannotAccept', 'auth.errors')}
      </Text>
      <Text>{message}</Text>
    </DialogContainer>
  );
};

const InviteLoading = () => {
  return (
    <DialogContainer sx={{ position: 'relative', minHeight: '500px', gap: 2 }}>
      <Loading isLoading={true} />
    </DialogContainer>
  );
};
