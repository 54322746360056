import { ExperienceTag } from '@understory-io/utils-types';

import { apiServices } from './apiServices';
import api, { IShallowSuccessResponse } from './index';

type GetAllResponse = {
  items: ExperienceTag[];
};

type UpsertResponse = {
  item: ExperienceTag;
};

export const getExperienceTags = async () => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.get<GetAllResponse>(
    `${apiServices.experiences}/experience-tags`
  );

  return data.items;
};

export const createExperienceTag = async (payload: ExperienceTag['name']) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.post<UpsertResponse>(
    `${apiServices.experiences}/experience-tags`,
    payload
  );

  return data;
};

export const updateExperienceTag = async (payload: ExperienceTag) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.put<UpsertResponse>(
    `${apiServices.experiences}/experience-tags/${payload.id}`,
    payload
  );

  return data;
};

export const deleteExperienceTag = async (id: ExperienceTag['id']) => {
  if (typeof apiServices.experiences === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EXPERIENCES_URL is not defined'
    );
  }

  const { data } = await api.delete<IShallowSuccessResponse>(
    `${apiServices.experiences}/experience-tags/${id}`
  );

  return data;
};
