import { CompanyProfile } from '@understory-io/utils-types';
import isEqual from 'lodash.isequal';

import { VatFormData } from '../../../../Modals/vat-form/domain/use-vat-form-validation';
import { CountryVatRates } from './types';
import { CompanyFormData } from './use-company-form-validation';

export const getDisplayVatNumber = (
  vatNumber: string,
  prefix: string,
  suffix: string
) => {
  return vatNumber.replace(prefix, '').replace(suffix, '');
};

export const getFullVatNumber = (
  vatNumber: string,
  prefix: string,
  suffix: string
) => {
  return `${prefix ?? ''}${vatNumber}${suffix ?? ''}`;
};

export const getAllVatRates = (
  countryVatRates: CountryVatRates,
  selectedVatCountry: string
) => {
  return [
    { standard: countryVatRates?.[selectedVatCountry]?.rates?.standard },
    ...(countryVatRates?.[selectedVatCountry]?.rates?.superReduced
      ? [
          {
            superReduced:
              countryVatRates?.[selectedVatCountry]?.rates?.superReduced,
          },
        ]
      : []),
    ...(countryVatRates?.[selectedVatCountry]?.rates?.reduced ?? []),
  ];
};

export const getVatCompliance = (
  {
    vatCompliance,
  }:
    | CompanyFormData
    | VatFormData
    | {
        vatCompliance: {
          isVatRegistered: boolean;
          vatRegistration: {
            country: string;
            vatNumber: string;
            defaultVatCategory: string;
          };
        };
      },
  countryVatRates?: CountryVatRates,
  company?: CompanyProfile
) => {
  const companyVatRegistration = company?.vatCompliance?.vatRegistrations?.[0];
  const companyLastUpdated = company?.vatCompliance?.lastUpdated;
  const hasCountry = 'country' in vatCompliance.vatRegistration;

  if (!countryVatRates) {
    return {
      lastUpdated: new Date().toISOString(),
      vatRegistrations: [],
    };
  }

  if (!hasCountry) {
    return {
      lastUpdated: new Date().toISOString(),
      vatRegistrations: [],
    };
  }

  if (Object.keys(vatCompliance.vatRegistration).length === 0) {
    return {
      lastUpdated: new Date().toISOString(),
      vatRegistrations: [],
    };
  }

  const isSameRegistration = isEqual(
    companyVatRegistration,
    vatCompliance.vatRegistration
  );

  const lastUpdated =
    isSameRegistration && companyLastUpdated
      ? companyLastUpdated
      : new Date().toISOString();

  const rates =
    countryVatRates?.[
      (
        vatCompliance.vatRegistration as VatFormData['vatCompliance']['vatRegistration']
      ).country
    ].rates;

  if (!vatCompliance?.vatRegistration) {
    return {
      lastUpdated,
      vatRegistrations: [],
    };
  }

  const { country, vatNumber, defaultVatCategory } =
    vatCompliance.vatRegistration as VatFormData['vatCompliance']['vatRegistration'];

  return {
    lastUpdated,
    vatRegistrations: vatCompliance.isVatRegistered
      ? [{ country, vatNumber, defaultVatCategory, rates }]
      : [],
  };
};

export const getCompanyFormDefaults = (
  company?: Partial<CompanyProfile>
): CompanyFormData | undefined => {
  if (!company) {
    return undefined;
  }

  const vatCompliance = company.vatCompliance?.vatRegistrations[0]
    ? {
        isVatRegistered: true,
        vatRegistration: company.vatCompliance.vatRegistrations[0],
      }
    : { isVatRegistered: false };

  const companyData = {
    ...company,
    vatCompliance,
  } as unknown as CompanyFormData;

  return companyData;
};

export const filteredVatRates = [
  'periodicals',
  'newspapers',
  'writers-composers',
  'hairdressing',
  'repair-of-shoes-and-leather-goods',
  'repair-of-clothing-and-household-linen',
  'medical-equipment-for-disabled-persons',
  'tv-licenses',
  'childrens-car-seats',
  'water-supplies',
  'pharmaceutical-products',
  'medical-and-dental-care',
];
