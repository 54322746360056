import { Stack, StepButton } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';

import { OnboardingItem } from '../../../../../Hooks/useOnBoarding';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useOnboardingWidgetStepper } from '../use-onboarding-widget-stepper';

type OnboardingWidgetStepperButtonProps = {
  step: OnboardingItem;
};

export const OnboardingWidgetStepperButton = ({
  step,
}: OnboardingWidgetStepperButtonProps) => {
  const { t } = useTranslate('onboardingSetup');
  const { getProgress } = useOnboardingWidgetStepper();

  return (
    <StepButton
      disableRipple={true}
      sx={{
        p: 0,
        m: 0,
        '& .MuiStepLabel-root': {
          p: '0 !important',
          gap: 0.5,
        },
      }}
    >
      <Stack>
        <Text
          variant="medium"
          fontSize="xsmall"
          style={{
            color: step.completed ? lightTheme.palette.neutral.n300 : 'black',
          }}
        >
          {t(`${step.key}.stepLabel`)}
        </Text>
        <Text
          fontSize="xsmall"
          color={step.completed ? lightTheme.palette.neutral.n300 : 'black'}
        >
          {getProgress(step.steps)}%
        </Text>
      </Stack>
    </StepButton>
  );
};
