import { Pagination, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { trackExperienceGoToPagination } from '../../../../tracking/experiences/details/list/trackExperienceGoToPagination';
import { EventListFormInput } from './event-list-form';

type EventListPaginationProps = {
  currentPage: number;
  maxPage: number;
};

export const EventListPagination = ({
  currentPage,
  maxPage,
}: EventListPaginationProps) => {
  const { setValue } = useFormContext<EventListFormInput>();

  return (
    <Stack alignItems="flex-end">
      <Pagination
        count={maxPage}
        page={currentPage}
        onChange={(_, page) => {
          if (page === currentPage) return;
          trackExperienceGoToPagination(page);
          setValue('page', page);
        }}
      />
    </Stack>
  );
};
