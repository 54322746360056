import { Experience } from '@understory-io/utils-types';

import { ampli } from '../../../Ampli';
import { eventProperties, transformToUserProperties } from './eventProperties';

export const trackExperiencesPageViewed = (
  experiences: Experience[],
  userId: string
) => {
  const properties = eventProperties(experiences);
  const userProperties = transformToUserProperties(properties);

  if (userId) ampli.identify(userId, userProperties);
  return ampli.experiencesPageViewed(properties);
};
