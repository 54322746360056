import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import * as yup from 'yup';

import { localizedSchema } from '../../../../../../../Pages/SyiPage/config';
import { EventType } from '../../../../domain/types';

export const useDetailsFormValidation = (
  t: TFunction,
  currentEvent: EventType
) =>
  useMemo(() => {
    const totalBookedSlots =
      currentEvent.bookings?.reduce((acc, booking) => acc + booking.slots, 0) ??
      0;

    return yup.object().shape({
      experienceId: yup
        .string()
        .required(t('eventUpsert.flow.details.form.errors.required')),
      locationId: yup
        .string()
        .required(t('eventUpsert.flow.details.form.errors.required')),
      addresses: yup
        .object()
        .shape({
          selectedOptionKey: yup.string(),
          value: yup.string(),
        })
        .required(),
      seatCount: yup.object().shape({
        selectedOptionKey: yup.string().required(),
        value: yup
          .number()
          .required(t('eventUpsert.flow.details.form.errors.required'))
          .min(1, t('eventUpsert.flow.details.form.errors.minSeats'))
          .test(
            'is-greater-than-or-equal-to-booked-slots',
            t('eventUpsert.flow.details.form.errors.minSeatsBookedSlots', {
              totalBookedSlots: totalBookedSlots,
            }),
            (value) => (value ?? 0) >= totalBookedSlots
          )
          .typeError(t('eventUpsert.flow.details.form.errors.type')),
      }),
      languages: yup.array().of(yup.string().required()),
      information: localizedSchema(),
    });
  }, [t, currentEvent]);

export type DetailsFormData = yup.InferType<
  ReturnType<typeof useDetailsFormValidation>
>;
