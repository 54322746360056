import { useQuery } from '@tanstack/react-query';

export const useCurrencies = () => {
  const CurrenciesQueryKey = ['currencies'];

  const currencies = useQuery({
    queryKey: CurrenciesQueryKey,

    queryFn: async () => {
      return { items: defaultCurrencies };
    },
  });

  return currencies;
};

const defaultCurrencies = [
  {
    name: 'Danish Kroner',
    code: 'DKK',
    symbol: null,
    flagSrc: '/flags/svg/da.svg',
  },
  {
    name: 'Euro',
    code: 'EUR',
    symbol: '€',
    flagSrc: '/flags/svg/eu.svg',
  },
  {
    name: 'Swedish Kroner',
    code: 'SEK',
    symbol: null,
    flagSrc: '/flags/svg/sv.svg',
  },
  {
    name: 'Norwegian Kroner',
    code: 'NOK',
    symbol: null,
    flagSrc: '/flags/svg/no.svg',
  },
  {
    name: 'British Pound',
    code: 'GBP',
    symbol: '£',
    flagSrc: '/flags/svg/en.svg',
  },
  {
    name: 'US Dollar',
    code: 'USD',
    symbol: '$',
    flagSrc: '/flags/svg/us.svg',
  },
];
