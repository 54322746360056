import styled from '@emotion/styled';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { lightTheme, Text } from '@understory-io/pixel';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export const DaysInput = forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <TextField
        {...props}
        ref={ref}
        onChange={(event) => {
          const inputValue = event.target.value;
          const numberValue = Number(inputValue);

          // If the input is not a number or is less than 0, set it to 0
          if (isNaN(numberValue) || numberValue < 0) {
            event.target.value = '0';
          }

          props.onChange?.(event);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledText fontSize="small">
                {t(
                  'eventUpsert.flow.time.form.closeRegistration.value.daysBefore'
                )}
              </StyledText>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

DaysInput.displayName = 'DaysInput';

const StyledText = styled(Text)`
  color: ${lightTheme.palette.neutral.n300};
`;
