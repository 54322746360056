import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { Skeleton, Text } from '@understory-io/pixel';
import { Location } from '@understory-io/utils-types';
import { FC, Suspense, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Await } from 'react-router';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateResourceTypeInputs } from './create-resource-type-inputs';

type Props = {
  locations: Promise<Location[]>;
};

export const SelectLocation: FC<Props> = ({ locations }) => {
  return (
    <Stack gap={3}>
      <Suspense
        fallback={
          <>
            <Skeleton variant={'rectangular'} height={32} width={'100%'} />
            <Skeleton variant={'rectangular'} height={32} width={'100%'} />
          </>
        }
      >
        <Await resolve={locations}>
          {(locations: Location[]) => (
            <LocationDropdown locations={locations} />
          )}
        </Await>
      </Suspense>
    </Stack>
  );
};

type LocationDropdownProps = {
  locations: Location[];
};

const LocationDropdown: FC<LocationDropdownProps> = ({ locations }) => {
  const { t } = useTranslate('resourceManagement.resourceType.createNew');

  const { formState } = useFormContext<CreateResourceTypeInputs>();

  const locationsSorted = useMemo(() => {
    if (!locations) return [];

    return [...locations].sort((a, b) =>
      a.locationName.localeCompare(b.locationName)
    );
  }, [locations]);

  if (!locations || !locations.length) {
    return <Text fontSize="small">message</Text>;
  }

  const errorMessage = formState.errors.locationId?.message;

  return (
    <FormControl error={true}>
      <FormLabel id="locationId">
        <Text fontSize="small" variant="medium">
          {t('selectLocation')}
        </Text>
      </FormLabel>
      <Controller
        rules={{
          required: t('required', 'utils.errors'),
        }}
        name="locationId"
        render={({ field }) => (
          <Select
            {...field}
            sx={{ mt: 1 }}
            error={!!errorMessage}
            fullWidth
            variant="outlined"
          >
            {locationsSorted.map((location) => (
              <MenuItem key={location.locationId} value={location.locationId}>
                <Text fontSize="small">{location.locationName}</Text>
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errorMessage && (
        <FormHelperText error={true}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};
