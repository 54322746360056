import { Text } from '@understory-io/pixel';
import { ReactNode } from 'react';

import { ShowForScope } from '../AllowForScope/AllowForScope';
import { StyledContextMenuItem } from '../Popover/radix_popover_styles';

export type ContextMenuItemProps = {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
  disabled?: boolean;
  scopes?: string[];
  textColor?: string;
};

export default function ContextMenuItem({
  label,
  onClick,
  icon,
  disabled,
  scopes,
  textColor,
}: ContextMenuItemProps) {
  return (
    <ShowForScope scopes={scopes ?? []}>
      <StyledContextMenuItem
        disabled={disabled}
        leftIcon={icon}
        size="medium"
        variant="text"
        onClick={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          onClick();
        }}
        sx={{ color: textColor ?? undefined }}
      >
        <Text fontSize="small" color="inherit">
          {label}
        </Text>
      </StyledContextMenuItem>
    </ShowForScope>
  );
}
