import { Box, BoxProps, CircularProgress, styled } from '@mui/material';
import { MediaItem } from '@understory-io/utils-types';
import { ComponentPropsWithoutRef } from 'react';
import { useDropzone } from 'react-dropzone';

import { useUploader } from '../../Hooks/useUploader';
import { TFileTypes, TModelTypes } from '../../types';

const Styled = styled(Box)({
  overflow: 'hidden',
  position: 'relative',
  border: '1px dashed rgba(100,100,100,0.15)',
  textAlign: 'center',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(100,100,100,.05)',
  ['&:hover']: {
    backgroundColor: 'rgba(100,100,100,.15)',
  },
});

const StyledOverlay = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  background: 'rgba(250,250,250,0.9)',
  zIndex: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'common.main',
});

const StyledProgress = styled('div')(({ progress }: { progress: number }) => ({
  width: `${progress}%`,
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 127, 255, 0.1)',
  transition: 'width 160ms',
}));

const LoadingOverlay = ({
  progress,
  children,
}: { progress: number } & ComponentPropsWithoutRef<typeof StyledOverlay>) => {
  return (
    <StyledOverlay>
      <StyledProgress progress={progress} />
      <Box sx={{ zIndex: 5 }}>{children}</Box>
    </StyledOverlay>
  );
};

interface IDropzoneProps {
  modelType: TModelTypes;
  id?: string;
  fileType: TFileTypes;
  onPrepared?: (
    args: Omit<MediaItem, 'id' | 'provider'> & { localUrl: string },
    key: TFileTypes,
    uploadFunc: () => Promise<void>
  ) => void;
  containerProps?: BoxProps;
  maxFiles?: number;
  hasImages?: boolean;
  showProgress?: boolean;
}

export const GalleryDropzone = ({
  children,
  showProgress = true,
  maxFiles = 40,
  modelType,
  id,
  onPrepared,
  fileType,
  containerProps = {},
}: ComponentPropsWithoutRef<typeof Box> & IDropzoneProps) => {
  const handlePrepared = (
    media: Omit<MediaItem, 'id' | 'provider'> & { localUrl: string },
    fileType: TFileTypes,
    uploadFunc: () => Promise<void>
  ) => {
    onPrepared?.(media, fileType, uploadFunc);
  };

  const { loading, uploadProgress, onDrop } = useUploader({
    fileType,
    modelType,
    id,
    onPrepared: handlePrepared,
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles,
    useFsAccessApi: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.webp', '.gif'],
      'video/*': ['.mp4', '.webm', '.mov'],
    },
  });

  return (
    <Styled
      {...containerProps}
      sx={{
        ...(containerProps?.sx ?? {}),
      }}
      {...getRootProps()}
    >
      {Boolean(loading) && (
        <LoadingOverlay progress={showProgress ? uploadProgress : 0}>
          <CircularProgress size={40} />
        </LoadingOverlay>
      )}

      <input {...getInputProps()} />

      {children}
    </Styled>
  );
};
